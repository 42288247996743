import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyTagsStyles } from './styles';
import { ApiTag, tagsApi } from '../../../api';
import { InputSingleField } from '../form/inputField/InputSingleField';
import { JustPlusIcon, SaveIcon } from '../../../assets';
import { Tag } from '../tag';
export const CompanyTags = () => {
  const {
    id
  } = useParams();
  const [tags, setTags] = useState<ApiTag[]>([]);
  const [adding, setAdding] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const createTag = () => {
    if (name.length && id) {
      tagsApi.createCompanyTag(+id, name).then(res => {
        setTags(prev => [...prev, res.data]);
        setName('');
        setAdding(false);
      });
    }
  };
  useEffect(() => {
    if (id) {
      tagsApi.getCompanyTags(+id).then(res => {
        setTags(res.data);
      });
    }
  }, []);
  return <CompanyTagsStyles>
      <div className="headWrapper">
        <span>Теги</span>
        <button onClick={() => setAdding(!adding)} type="button">
          <JustPlusIcon width={20} color="rgb(0, 0, 0)" height={20} />
        </button>
      </div>
      {tags && id && tags.map(el => <Tag key={el.id} offset={1} tag={el} setTags={setTags} />)}
      {adding && <div className="itemsWrapper">
          <InputSingleField value={name} name="tag" placeholder="Tag name" onChange={setName} />
          <button type="button" onClick={createTag}>
            <SaveIcon width={20} color="rgb(0, 0, 0)" height={20} />
          </button>
        </div>}
    </CompanyTagsStyles>;
};