import styled from 'styled-components';

export const InternalStyles = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  max-height: calc(-62px + 100vh);
  position: relative;
  overflow: hidden;

  .searchWrapper {
    display: flex;
    align-items: center;
    padding: 0 19px;
    border-bottom: 1px solid #f0f0f0;

    input {
      width: 100%;
      height: 48px;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    button {
      min-width: 130px;
    }

    input::placeholder {
      font-size: 15px;
      color: #6c6c6c;
    }
  }
`;
