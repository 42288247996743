import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FieldPickerStyles } from './styles';
import { useDebounce } from '../../../hooks';
import { ApiCompanyField, FieldType, TPage, fieldsApi, pagesApi } from '../../../api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../atoms';
import { FieldSelector } from '../../molecules';
import { toast } from 'react-toastify';
type TFieldPicker = {
  selectedPage: TPage | null;
  fieldsCount: number;
  reQuery: boolean;
  setReQuery: Dispatch<SetStateAction<boolean>>;
};
export const FieldPicker: FC<TFieldPicker> = ({
  selectedPage,
  fieldsCount,
  reQuery,
  setReQuery
}) => {
  const {
    companyId,
    formId
  } = useParams();
  const [searchParams, setSearchParams] = useState<string>('');
  const [fields, setFields] = useState<ApiCompanyField[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMoreFields, setHasMoreFields] = useState(true);
  const debouncedSearch = useDebounce(searchParams, 1000);
  const countFields = useRef<number>(0);
  const infiniteScrollRef = useRef<HTMLDivElement>(null);
  const fieldsContainerRef = useRef<HTMLDivElement>(null);
  const checkIfMoreFields = () => {
    if (infiniteScrollRef.current && fieldsContainerRef.current && fields.length) {
      const infiniteScrollHeight = infiniteScrollRef.current.clientHeight;
      const sidebarListHeight = fieldsContainerRef.current.clientHeight;
      if (infiniteScrollHeight >= sidebarListHeight && fields.length < countFields.current) {
        setPageNumber(prev => prev + 1);
      }
    }
  };
  useEffect(() => {
    checkIfMoreFields();
  }, [fields, countFields.current]);
  useEffect(() => {
    if (companyId) {
      setLoading(true);
      fieldsApi.getCompanyFields(+companyId, (pageNumber ? +pageNumber - 1 : 0) * 10, 10, searchParams).then(res => {
        setFields(prev => [...prev, ...res.data]);
        countFields.current = res.count || 0;
        if (fields.length + res.data.length >= (res.count || 0)) {
          setHasMoreFields(false);
        }
        setLoading(false);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [pageNumber, debouncedSearch]);
  useEffect(() => {
    setPageNumber(1);
    setFields([]);
    setHasMoreFields(true);
  }, [searchParams]);
  return <FieldPickerStyles>
      <div className="header">
        <h6>Доступні поля форм суб’єкту моніторингу</h6>
      </div>
      <div className="search">
        <input type="text" value={searchParams} onChange={event => {
        setSearchParams(event.target.value);
      }} placeholder="Пошук..." />
      </div>
      <div className="fields" ref={infiniteScrollRef} id="scrollableContainer">
        <InfiniteScroll dataLength={fields.length} next={() => {
        setPageNumber(prev => prev + 1);
      }} loader={loading ? <Loader margin={10} /> : null} hasMore={hasMoreFields} scrollableTarget="scrollableContainer">
          <div className="fields-list" ref={fieldsContainerRef}>
            {fields.filter(item => {
            const type = item.type as FieldType;
            return Object.values(FieldType).includes(type);
          }).map((el, index) => <FieldSelector key={`${el.type}-${index}`} type={el.type as FieldType} previewText={el.question} handleClick={() => {
            if (selectedPage) {
              pagesApi.addFieldToPage(selectedPage.id, {
                fieldID: el.id!,
                position: fieldsCount + 1
              }).then(res => {
                if (res.statusCode === 201) {
                  setReQuery(!reQuery);
                  toast.success('Поле додано');
                }
              });
            }
          }} />)}
          </div>
        </InfiniteScroll>
      </div>
    </FieldPickerStyles>;
};