import styled from 'styled-components';
export const NotifySettingsStyles = styled.div`
  .notifyWrapper {
    display: flex;
    align-items: center;
    margin: 15px 0;
    gap: 15px;

    input {
      outline: none;
      font-size: 14px;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(229, 229, 229);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px 16px;
      max-width: 200px;
      width: 100%;
    }
  }

  .selectField {
    margin-top: 0;
  }

  button {
    width: 100% !important;
    height: 40px !important;
  }
`;