import styled from 'styled-components';

export const CollapsibleStyles = styled.div`
  .collapsible {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .collapsible-header {
    background-color: #f5f5f5;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .row {
      display: flex;
    }
  }

  .collapsible-content {
    padding: 10px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    &.open {
      display: none;
    }
  }

  .collapse-button {
    transition: all 0.3s ease 0s;
    &.opened {
      transform: rotate(180deg);
    }
  }
`;
