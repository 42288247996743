import { AddUserStyles } from './AddUserStyles';
import { getTranslationByLangOrEng } from '../../i18n';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { rolesApi, authApi } from '../../api';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { getErrorMessage, handleKeyUp } from '../../utils';
import { formInitialValues, FormValues, updateRolesList, validationNewUserSchema } from './AddUserUtils';
import { IUserRole } from '../../entities';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { InputField, Loader, RoleSelect, SubHeader, TransparentButton } from '../../components';
import { availablePageSizes } from '../../constants';
export const AddUser = React.memo(() => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>(formInitialValues);
  const [roleList, setRoleList] = useState<IUserRole[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const pageSizeRef = useRef<number>(availablePageSizes[2]);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  async function handleLoadMore() {
    setUploading(true);
    rolesApi.getRoles(currentPage * pageSizeRef.current, pageSizeRef.current).then(response => {
      setRoleList([...(roleList || []), ...(Array.isArray(response.data) ? response.data : [])]);
      setUploading(false);
    });
    setCurrentPage(currentPage + 1);
  }
  useEffect(() => {
    setIsLoading(true);
    try {
      handleLoadMore();
      setInitialValues(formInitialValues);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, []);
  const renderForm = ({
    values,
    errors,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
      <div className="formSection">
        <h3>Global information</h3>
        <div className="row">
          <InputField extraBlockStyles={{
          width: '100%'
        }} name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_name_placeholder')} value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'user_name_title')} required />

          <InputField extraBlockStyles={{
          width: '100%'
        }} name="email" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_email_placeholder')} value={values.email} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'user_email_title')} required />
        </div>

        <div className="row">
          <RoleSelect label={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_title')} name="roles" roles={roleList.filter(role => role.id !== 26)} userRoles={values.roles} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_role_placeholder')} value="" search autoloadable autoloading={uploading} handleAutoload={handleLoadMore} handleSelect={option => {
          const selectedRole = values.roles.includes(option.id as number);
          setFieldValue('roles', selectedRole ? values.roles.filter(role => role !== option.id) : [...values.roles, option.id]);
        }} error={errors.roles as string} />
        </div>

        <div className="horizontalLine" />

        <h3>Change password?</h3>
        <div className="row">
          <InputField extraBlockStyles={{
          width: '100%'
        }} name="passwords.password" onChange={setFieldValue} onKeyUp={() => handleKeyUp('passwords.password', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_new_password_placeholder')} value={values.passwords?.password} error={typeof errorMessage === 'object' ? getErrorMessage('passwords.password', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'user_new_password_title')} type="password" />

          <InputField extraBlockStyles={{
          width: '100%'
        }} name="passwords.passwordConfirmation" onChange={setFieldValue} onKeyUp={() => handleKeyUp('passwords.passwordConfirmation', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_password_confirmation_placeholder')} value={values.passwords?.passwordConfirmation} error={typeof errorMessage === 'object' ? getErrorMessage('passwords.passwordConfirmation', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'user_password_confirmation_title')} type="password" />
        </div>

        <div className="horizontalLine" />

        <div className="formErrorContainer">
          {errors.passwords && <span>{errors.passwords}</span>}
        </div>
        {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}

        <SubmitButton extraBlockStyles={{
        maxWidth: 600,
        width: '100%'
      }}>
          {getTranslationByLangOrEng(interfaceLanguage, 'save_button')}
        </SubmitButton>

        <div className="horizontalLine" />
      </div>
    </Form>;
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setIsLoading(true);
    try {
      const res = await authApi.createUser({
        name: values.name,
        email: values.email,
        password: values?.passwords?.password!,
        passwordConfirmation: values?.passwords?.passwordConfirmation!
      });
      await updateRolesList(values.roles, [], res?.data?.id!);
      if (res.statusCode >= 200 && res.statusCode < 300) {
        setErrorMessage('');
        navigate('/users');
      } else {
        setErrorMessage(`${res.statusCode} помилкове тіло запиту`);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Помилкове тіло запиту');
    }
    setSubmitting(false);
  }
  return <AddUserStyles>
      <Helmet>
        <title>Create user Voicer</title>
      </Helmet>

      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'user_page_create')}>
        <div className="buttonsContainer">
          <TransparentButton handleClick={() => navigate('/users')} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} filled />
        </div>
      </SubHeader>

      {isLoading || !initialValues ? <Loader /> : <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationNewUserSchema(interfaceLanguage, 0)}>
          {renderForm}
        </Formik>}
    </AddUserStyles>;
});