import styled from 'styled-components';

export const CustomSelectStyles = styled.div<{ isOpened?: boolean }>`
  .form-group {
    position: relative;
    .labelContainer {
      margin-bottom: 8px;
      display: flex;

      label {
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        color: #000000;
      }

      .required {
        color: #ff0101;
      }
    }

    &.error input {
      border-color: #f4cfcf;
      background-color: #fff;
    }

    &.valid input {
      border-color: #eaf6dd;
      background-color: #fff;
    }

    .form-field-error {
      height: 24px;
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
    }

    .fieldContainer {
      position: relative;
      display: flex;
      gap: 16px;
      align-items: center;
    }

    .showHidePasswordButton {
      cursor: pointer;
      outline: none;
      background-color: transparent;
      border: none;
      padding: 0;
      position: absolute;
      right: 20px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .addButtonWrapper {
    margin-top: 8px;
  }

  .selectField {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: transparent;
    border: 3px solid #fff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0px 0px 0px 16px;
    position: relative;
    cursor: pointer;
    user-select: none;

    .loading {
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(230, 230, 230, 0.7);
      display: grid;
      place-items: center;
      border-radius: 4px;
    }
    .icon {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      transform: ${({ isOpened }) => (isOpened ? 'rotate(3.14rad)' : 'rotate(0rad)')};
    }
    .selectedValue {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrowDownActive {
        transform: rotate(180deg);
      }
    }

    .valueContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 20px;
      align-items: center;
      font-size: 14px;
      color: #000;

      &.withExtraItem {
        grid-template-columns: auto 1fr auto;
      }
    }

    .placeholder {
      font-weight: 400;
      font-size: 16px;
      color: #0c0005;
    }

    label {
      font-size: 14px;
      line-height: 14px !important;
      padding: 0;
      margin: 0;
    }

    .options {
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      width: 100%;
      overflow-y: auto;
      box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.15);
      max-height: 230px;

      & > .option:last-child .row {
        border-bottom: none;
      }

      .option {
        .row {
          width: 100%;
          height: 36px;
          display: grid;
          grid-template-columns: 1fr auto;
          align-items: center;
          border-bottom: 1px solid #eaeaea;
          box-sizing: border-box;
        }
        .row:hover {
          background-color: #f9f9f9;
        }

        input {
          display: none;
          height: 32px;
        }

        button {
          width: 100%;
          height: 100%;
          font-size: 14px;
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 20px;

          .labelContent {
            display: grid;
            align-items: center;
            text-align: left;
            padding-left: 16px;
            width: 100%;
            height: 100%;
            color: #6e6e6e;
            box-sizing: border-box;
          }

          &.withImage .labelContent {
            grid-template-columns: auto 1fr;
            grid-gap: 8px;
          }

          &.expandButton {
            align-items: center;
            width: 24px;
          }
        }
      }
    }

    .emoji {
      display: flex;
    }
  }

  .searchWrapper {
    height: 32px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    box-sizing: border-box;
    padding: 0 16px;
    background-color: #fcfcfc;

    input {
      flex-grow: 1;
      margin-left: 12px;
      padding: 0;
      border: none;
      outline: none;
      font-size: 14px;
      color: #000;
      background-color: #fcfcfc;

      &::placeholder {
        font-size: 14px;
        color: #6e6e6e;
      }
    }
  }

  .selectErrorContainer {
    position: absolute;
    width: 100%;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
    bottom: -15px;
  }

  .colors {
    display: flex;
    align-items: center;

    &.valueContainer {
      grid-gap: 12px;
    }

    &.colorOptionContent {
      color: #6e6e6e;
      width: 100%;
      height: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      grid-template-columns: auto 1fr auto;
      grid-gap: 12px;
      align-items: center;

      .value {
        width: 100%;
      }
    }

    .color {
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }
  }

  .allItemsUploaded {
    text-align: center;
    margin: 8px 0;
    font-size: 14px;
    color: #000;
  }
`;
