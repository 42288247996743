import styled from 'styled-components';

export const PictureStyles = styled.div`
   width: 100%;

   .img-wrapper {
        width: 100%;

        img {
            width: 100%;
        }
   }
`;
