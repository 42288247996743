import styled from 'styled-components';

export const UserProfileFormStyles = styled.div`
  .formContainer {
    box-sizing: border-box;
    width: 100%;
    padding: 24px 17px;
    max-width: 700px;

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .inputs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        gap: 40px;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 0;
        }

        & > div {
          width: calc(50% - 20px);

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }

  .channelsWrap {
    display: flex;
    flex-direction: column;
    max-width: 750px;
    width: 100%;

    .channelsManagementWrap {
      display: grid;
      grid-template-columns: auto 1fr;
      flex-grow: 1;
    }

    .channelsTitle {
      margin-bottom: 16px;
    }

    .channelsButtonsWrap {
      margin-bottom: 67px;
      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      row-gap: 6px;
      max-width: 420px;
      width: 100%;

      span {
        font-size: 14px;
        color: #000000;
      }

      button {
        margin-right: 10px;
        width: 130px;
        height: 32px;
        border: 1px solid #000;
        background: #000;
        border-radius: 4px;
        color: #ffffff;
        font-size: 14px;
      }

      .disabled {
        background: #fafafa;
        color: #e5e5e5;
        border: 1px solid #e5e5e5;
      }
    }
  }

  .modalBody {
    background: #ffffff;
  }
`;
