import styled from 'styled-components';

export const CategoryChipsStyles = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 15px;
    width: 100%;

    .chip {
        box-sizing: border-box;
        border-radius: 8px;
        padding: 6px 10px;
        display: flex;
        align-items: center;
        gap: 7px;
        background: rgb(240, 245, 245);
        height: 100%;
        border: 1px solid rgb(27, 202, 185);

        .chipDel {
            transform: rotate(45deg);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
`;
