import styled from 'styled-components';

export const CheckboxesGroupStyles = styled.footer`
  margin-bottom: 24px;

  .body {
    box-sizing: border-box;

    .labelContainer {
      .required {
        width: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .addOption {
      width: 100%;
      max-width: 500px;
    }

    .options {
      .optionsList {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 8px;
      }
    }
  }

  .checkboxFieldContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 8px;
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
