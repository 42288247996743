import React, { FC } from 'react';
import { TIcon } from './TIcon';
export type TSpecIcon = TIcon & {
  dynamicClass?: boolean;
};
export const EyeIcon: FC<TSpecIcon> = React.memo(({
  dynamicClass
}) => <svg viewBox="0 0 22 20" fill="none" width={22} height={20}>
    <path fillRule="evenodd" clipRule="evenodd" d="M11 17C4.92487 17 0 10 0 10C0 10 4.92487 3 11 3C17.0751 3 22 10 22 10C22 10 17.0751 17 11 17ZM4.54234 11.9979C3.73487 11.2881 3.06045 10.5738 2.56141 10C3.06045 9.4262 3.73487 8.7119 4.54234 8.0021C6.41534 6.35547 8.69938 5 11 5C13.3006 5 15.5847 6.35547 17.4577 8.0021C18.2651 8.7119 18.9396 9.4262 19.4386 10C18.9396 10.5738 18.2651 11.2881 17.4577 11.9979C15.5847 13.6445 13.3006 15 11 15C8.69938 15 6.41534 13.6445 4.54234 11.9979Z" fill="black" />
    <g className={`${dynamicClass ? 'hide' : ''}`}>
      <path d="M2 17L18 1" stroke="white" strokeWidth="2" />
      <path d="M4 19L20 3" stroke="white" strokeWidth="2" />
      <path d="M3 18L19 2" stroke="black" strokeWidth="2" />
    </g>
    <circle cx="11" cy="10" r="3" fill="black" data-svg-origin="8 7" transform="matrix(1,0,0,1,0,0)" style={{
    transformOrigin: '0px 0px'
  }} />
  </svg>);