import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { TFieldConstruct } from '../../companyFields';
import { toast } from 'react-toastify';
import { ApiCompanyField, ApiCreatedField, ApiUpdatedField, FieldType, TAdditionalParams, fieldsApi } from '../../../../api';
import { addAdditionalParam } from '../utils';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Checkbox, InputField } from '../../../molecules';
import { SubmitButton } from '../../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import { DocumentIcon, ImageIcon, MicrophoneIcon, VideoIcon } from '../../../../assets';
import { FieldFilesStyles } from '../styles';
import SquareCheckbox from '../../../form/squareCheckbox/SquareCheckbox';
type TFileUpload = {
  field: TFieldConstruct;
  setFields: Dispatch<SetStateAction<ApiCompanyField[]>>;
  setField: Dispatch<SetStateAction<TFieldConstruct | null>>;
};
type TFiles = 'audio' | 'document' | 'image' | 'video';
export interface IFileUploadField {
  question: string;
  required: boolean;
  multiple_file_input: boolean;
  options: {
    key: string;
    selected: boolean;
  }[];
}
const initialValue: IFileUploadField = {
  question: '',
  required: false,
  multiple_file_input: false,
  options: [{
    key: 'audio',
    selected: false
  }, {
    key: 'document',
    selected: false
  }, {
    key: 'image',
    selected: false
  }, {
    key: 'video',
    selected: false
  }]
};
export const validationSchema = Yup.object({
  question: Yup.string().required('* Required')
});
export const FileUpload: FC<TFileUpload> = ({
  field,
  setFields,
  setField
}) => {
  const {
    id
  } = useParams();
  const [initialValues, setInitialValues] = useState<IFileUploadField>();
  const [apiField, setApiField] = useState<ApiCompanyField | null>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  function getFileTypeImage(type: TFiles) {
    const types = {
      audio: <MicrophoneIcon color="#000" />,
      document: <DocumentIcon color="#000" />,
      image: <ImageIcon color="#000" />,
      video: <VideoIcon color="#000" />
    };
    return types[type];
  }
  function getFileTypeName(type: TFiles) {
    const types = {
      audio: 'Аудіо',
      document: 'Документ',
      image: 'Фото',
      video: 'Video'
    };
    return types[type];
  }
  const onSubmit = async (values: IFileUploadField, {
    setSubmitting
  }: FormikHelpers<IFileUploadField>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    const styles: TAdditionalParams[] = [];
    if (id) {
      if (apiField) {
        options = [...apiField.options];
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.multiple_file_input, 'multiple_file_input', options);
        addAdditionalParam(values.options.filter(el => el.selected).map(e => e.key).join('|||'), 'options', options);
        const data: ApiUpdatedField<FieldType.FileUpload> = {
          contentTypes: '*',
          question: values.question,
          options,
          styles
        };
        fieldsApi.updateFormField(apiField.id!, data).then(res => {
          if (res.statusCode === 200) {
            setFields(prev => prev.map(el => {
              if (el.id === apiField.id) {
                return {
                  ...el,
                  ...data
                };
              }
              return el;
            }));
            toast.success('Поле успішно оновлено');
          }
        });
      } else {
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.multiple_file_input, 'multiple_file_input', options);
        addAdditionalParam(values.options.filter(el => el.selected).map(e => e.key).join('|||'), 'options', options);
        const data: ApiCreatedField = {
          field: {
            [FieldType.FileUpload]: {
              contentTypes: '*',
              question: values.question,
              options,
              styles
            }
          }
        };
        fieldsApi.createCompanyFields(+id, data).then(res => {
          if (res.statusCode === 201) {
            setFields(prev => [res.data, ...prev]);
            setField({
              id: res.data.id,
              type: field.type
            });
            toast.success('Поле успішно додано');
          }
        });
      }
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<IFileUploadField>) => <Form>
      <InputField name="question" onChange={setFieldValue} onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)} placeholder="Введіть запитання для поля" value={values.question} error={typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined} />
      <div className="files">
        <h4>Виберіть які типи завантаження файлів будуть доступні на сторінці форми</h4>
        {values.options.map((field: {
        key: string;
        selected: boolean;
      }, index) => <div className="files-container" key={field.key}>
            <SquareCheckbox name={`options[${index}].selected`} value={values.options![index].selected!} onChange={handleChange} checkboxWrapperStyles={{
          alignItems: 'center'
        }}>
              <div className="files-checkbox">
                <div className="files-icon">
                  {getFileTypeImage(field.key as TFiles)}
                </div>
                <span>{getFileTypeName(field.key as TFiles)}</span>
              </div>
            </SquareCheckbox>
          </div>)}
      </div>

      <Checkbox name="required" value={values.required!} onChange={handleChange}>
        <span className="checkboxValue">
          Обовʼязкове поле
        </span>
      </Checkbox>

      <Checkbox name="multiple_file_input" value={values.multiple_file_input!} onChange={handleChange}>
        <span className="checkboxValue">
          Підвантаження декількох файлів одночасно
        </span>
      </Checkbox>

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '15px 45px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    if (field.id) {
      fieldsApi.getTargetField(field.id).then(res => {
        const required = res.data.options.find(option => option.key === 'required')?.value === 'true';
        const multiple_file_input = res.data.options.find(option => option.key === 'multiple_file_input')?.value === 'true';
        const options = res.data.options.find(option => option.key === 'options')?.value.split('|||') || [];
        setApiField(res.data);
        setInitialValues({
          question: res.data.question,
          required,
          multiple_file_input,
          options: initialValue.options.map(el => ({
            ...el,
            selected: options.includes(el.key)
          }))
        });
      });
    } else {
      setInitialValues(initialValue);
    }
  }, [field]);
  return <FieldFilesStyles>
      <div className="field-body">
        {initialValues && <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {renderForm}
          </Formik>}
      </div>
    </FieldFilesStyles>;
};