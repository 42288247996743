/* eslint-disable class-methods-use-this */
import { ICompanyUser, IUser } from '../../entities';
import { mapApiUserToIUser, mapApiUsersToIUsers } from '../Mappers';
import baseApi from '../baseApi';
import { ApiResponse } from '../entities';
import IUsersApi from './IUsersApi';

class UsersApi implements IUsersApi {
  public async getFilteredUsers(
    data: { email?: string; name?: string; q?: string; companyID?: string },
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<IUser[]>> {
    const query = Object.entries(data)
      .map((item) => item.join('='))
      .join('&');
    const res = await baseApi.getData(
      `/users?offset=${offset || 0}&limit=${limit || 10}${
        query ? `&${query}` : ''
      }`,
    );
    return Promise.resolve({
      data: mapApiUsersToIUsers(res.data.data),
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getUser(id: number): Promise<ApiResponse<IUser>> {
    const { data, statusCode } = await baseApi.getData(`/auth/users/${id}`);
    return Promise.resolve({
      data: mapApiUserToIUser(data),
      statusCode,
    });
  }

  public async getCrmUser(id: number): Promise<ApiResponse<IUser>> {
    const { data, statusCode } = await baseApi.getData(`/users?id=${id}`);
    return Promise.resolve({
      data: mapApiUserToIUser(data.data[0]),
      statusCode,
    });
  }

  public async deleteUser(id: number): Promise<ApiResponse<null>> {
    return baseApi.deleteData(`/users/${id}`);
  }

  public async getNodeUsers(id: number): Promise<ApiResponse<IUser[]>> {
    return baseApi.getData(`/nodes/${id}/view`);
  }

  public async getUsersByCompany(
    id: number,
    offset?: number,
    limit?: number,
    q?: string,
  ): Promise<ApiResponse<ICompanyUser[]>> {
    const res = await baseApi.getData(
      `/companies/${id}/users?offset=${offset || 0}&limit=${limit || 10}&q=${
        q || ''
      }`,
    );

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }
}

export const usersApi = new UsersApi();
