import React from 'react';
import { FieldIconStyles } from './styles';
import { typeColors, typeIcons } from '../../organisms';
import { FieldType } from '../../../api';
interface FieldIconProps {
  type: FieldType;
  title?: string;
}
export const FieldIcon = React.memo(({
  type,
  title
}: FieldIconProps) => <FieldIconStyles title={title} backgroundColor={typeColors[type]}>
    {typeIcons[type]}
  </FieldIconStyles>);