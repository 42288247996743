import React, { useState, useRef, useEffect } from 'react';
import { Formik, FormikProps, FormikHelpers, Form, FieldArray } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyReviewTableColumnsSettingsStyles } from './CompanyReviewTableColumnsSettingsStyles';
import SubmitButton from '../form/submitButton/SubmitButton';
import { useAppSelector, useAppDispatch, setCompanyMetadata } from '../../state';
import { getTranslationByLangOrEng } from '../../i18n';
import { formattedTableColumnsSettings } from '../../constants';
import { ApiResponse, ApiMetadata, companyApi } from '../../api';
import { Loader, ToggleSwitcher } from '../atoms';
import { CompaniesInfoModal, ModalConfirmDecline } from '../organisms';
export interface FormValues {
  tableSettings: {
    name: string;
    text: string;
    value: boolean;
    id?: number;
  }[];
}
export type SettingsOptionProps = {
  name: string;
  text: string;
  value: boolean;
};
export default function CompanyReviewTableColumnsSettings() {
  const {
    id
  } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const companyMetadata = useAppSelector(state => state.company.companyMetadata);
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [afterSaveModal, setAfterSaveModal] = useState<boolean>(false);
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>('');
  const [dataSaveError, setDataSaveError] = useState<string>('');
  const settingsOptions = formattedTableColumnsSettings(interfaceLanguage);
  const settings: SettingsOptionProps[] = Object.values(settingsOptions);
  const formRef = useRef<FormikProps<FormValues>>(null);
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setConfirmSaveModalOpen(true);
    setSubmitting(false);
  }
  const onSaveModalConfirm = async (values: FormValues) => {
    try {
      const reviewTableSettingsToDelete = values?.tableSettings?.filter(item => !item.value && item.id);
      const reviewTableSettingsToCreate = values?.tableSettings?.filter(item => item.value && !item.id);
      if (reviewTableSettingsToDelete.length) {
        const promises: Promise<ApiResponse<{
          code: number;
          message: string;
        }>>[] = [];
        reviewTableSettingsToDelete.forEach((item: any) => {
          promises.push(companyApi.deleteCompanyMetadata(item.id!));
        });
        await Promise.all(promises);
      }
      if (reviewTableSettingsToCreate.length) {
        const promises: Promise<ApiResponse<ApiMetadata>>[] = [];
        reviewTableSettingsToCreate.forEach((item: any) => {
          promises.push(companyApi.createCompaniesMetadata({
            key: `reviewTableSetting_${Math.random()}`,
            value: item.name
          }, +id!));
        });
        await Promise.all(promises);
      }
    } catch (e) {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
      setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    } finally {
      if (!submitError) {
        setConfirmSaveModalOpen(false);
        setAfterSaveModal(true);
      }
    }
  };
  function mapTableSettingsKeyNames(name: string) {
    switch (name) {
      case 'source':
        return 'sources';
      case 'node':
        return 'nodes';
      case 'status':
        return 'statuses';
      default:
        return name;
    }
  }
  useEffect(() => {
    const tableSettingsWithIds: {
      [key: string]: {
        id: number;
      };
    } = {};
    if (companyMetadata && id) {
      for (const item of companyMetadata.reviewTableSettings!) {
        tableSettingsWithIds[mapTableSettingsKeyNames(item.name)] = {
          id: item.id!
        };
      }
      const tableSettings = settings.map(item => tableSettingsWithIds?.hasOwnProperty(mapTableSettingsKeyNames(item.name)) ? {
        ...item,
        value: true,
        id: tableSettingsWithIds[mapTableSettingsKeyNames(item.name)].id
      } : item);
      setInitialValues({
        tableSettings
      });
    } else if (id) {
      companyApi.getCompanyMetadataByCompanyId(+id).then(res => {
        if (res.statusCode === 200) {
          dispatch(setCompanyMetadata(res.data));
          for (const item of res.data.reviewTableSettings!) {
            tableSettingsWithIds[mapTableSettingsKeyNames(item.name)] = {
              id: item.id!
            };
          }
          const tableSettings = settings.map(item => tableSettingsWithIds?.hasOwnProperty(mapTableSettingsKeyNames(item.name)) ? {
            ...item,
            value: true,
            id: tableSettingsWithIds[mapTableSettingsKeyNames(item.name)].id
          } : item);
          setInitialValues({
            tableSettings
          });
        }
      }).catch(e => {
        console.log('e', e);
      });
    } else {
      setInitialValues({
        tableSettings: []
      });
    }
  }, [id]);
  const renderForm = ({
    values,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
      <div className="tableSettingsWrapper">
        <FieldArray name="tableSettings" render={() => <>
              <div className="headWrapper">
                <span>
                  {getTranslationByLangOrEng(interfaceLanguage, 'companies_review_table_columns_settings_title')}
                </span>
              </div>

              <div className="tableColumnsSettingsList">
                {values?.tableSettings?.map((option: SettingsOptionProps, index: number) => <div key={option.name} className="switcherOptionWrapper">
                      <ToggleSwitcher name={`tableSettings[${index}].name`} value={values?.tableSettings?.[index].value || false} onChange={() => setFieldValue(`tableSettings[${index}].value`, !values?.tableSettings?.[index].value)}>
                        <span className="checkboxValue">{option.text}</span>
                      </ToggleSwitcher>
                    </div>)}
              </div>
            </>} />
      </div>
      <SubmitButton extraButtonStyles={{
      width: '100%',
      maxWidth: '540px'
    }}>
        {getTranslationByLangOrEng(interfaceLanguage, 'save_button')}
      </SubmitButton>
      <span className="uploadError">{submitError}</span>
    </Form>;
  return <CompanyReviewTableColumnsSettingsStyles>
      {!initialValues ? <Loader /> : <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit}>
          {renderForm}
        </Formik>}
      {confirmSaveModalOpen && <ModalConfirmDecline onClose={() => setConfirmSaveModalOpen(false)} onConfirm={() => onSaveModalConfirm(formRef.current!.values)} onDecline={() => setConfirmSaveModalOpen(false)} confirmText={getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button')} declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} title={getTranslationByLangOrEng(interfaceLanguage, 'companies_columns_settings_confirmation_before_save_modal_title')} error={!!dataSaveError} errorText={dataSaveError} firstButtonStyles={{
      background: '#ff0000'
    }} secondButtonStyles={{
      background: '#000'
    }} />}
      {afterSaveModal && <CompaniesInfoModal onConfirm={() => setAfterSaveModal(false)} onClose={() => {
      setAfterSaveModal(false);
    }} onDecline={() => navigate('/companies')} />}
    </CompanyReviewTableColumnsSettingsStyles>;
}