import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const EmojiIcon: FC<TIcon> = React.memo(({
  width = 28,
  height = 28,
  color = '#000'
}) => <svg width={width} height={height} viewBox="0 0 28 28">
      <g fill={color}>
        <path d="M 13.4375 2.164062 C 11.621094 2.226562 9.863281 2.667969 8.246094 3.460938 C 5.648438 4.742188 3.652344 6.699219 2.347656 9.238281 C 1.628906 10.632812 1.207031 12.078125 1.027344 13.753906 C 0.972656 14.296875 0.984375 15.839844 1.046875 16.386719 C 1.300781 18.574219 1.957031 20.355469 3.171875 22.148438 C 5.0625 24.953125 8.046875 26.933594 11.382812 27.601562 C 12.972656 27.917969 14.8125 27.933594 16.433594 27.640625 C 19.679688 27.058594 22.671875 25.183594 24.609375 22.511719 C 26.40625 20.039062 27.226562 17.183594 27.027344 14.113281 C 26.738281 9.75 24.207031 5.847656 20.28125 3.71875 C 18.175781 2.574219 15.988281 2.078125 13.4375 2.164062 Z M 14.867188 4.320312 C 16.320312 4.441406 17.585938 4.796875 18.910156 5.457031 C 20.539062 6.265625 21.863281 7.386719 22.875 8.808594 C 23.960938 10.335938 24.640625 12.121094 24.835938 13.964844 C 24.917969 14.703125 24.878906 15.851562 24.753906 16.648438 C 24.375 19.058594 23.023438 21.484375 21.1875 23.046875 C 19.523438 24.460938 17.511719 25.351562 15.324219 25.640625 C 14.757812 25.714844 13.261719 25.714844 12.707031 25.640625 C 10.628906 25.363281 8.753906 24.566406 7.105469 23.265625 C 6.710938 22.953125 5.988281 22.242188 5.652344 21.835938 C 4.433594 20.359375 3.578125 18.511719 3.289062 16.710938 C 3.1875 16.082031 3.160156 15.707031 3.160156 14.984375 C 3.160156 13.578125 3.394531 12.375 3.929688 11.039062 C 4.097656 10.621094 4.570312 9.703125 4.839844 9.285156 C 5.320312 8.527344 5.980469 7.734375 6.582031 7.1875 C 8.15625 5.761719 10.132812 4.792969 12.1875 4.441406 C 12.484375 4.390625 12.660156 4.371094 13.300781 4.3125 C 13.550781 4.285156 14.53125 4.292969 14.867188 4.320312 Z M 14.867188 4.320312 " />
        <path d="M 9.296875 8.597656 C 8.675781 8.726562 8.203125 9.03125 7.859375 9.535156 C 7.402344 10.195312 7.378906 11.113281 7.792969 11.8125 C 7.925781 12.03125 8.335938 12.433594 8.558594 12.5625 C 9.0625 12.859375 9.734375 12.933594 10.316406 12.761719 C 10.753906 12.636719 11.214844 12.285156 11.5 11.867188 C 11.996094 11.136719 11.941406 10.09375 11.371094 9.382812 C 11.074219 9.019531 10.59375 8.71875 10.148438 8.625 C 9.957031 8.585938 9.445312 8.566406 9.296875 8.597656 Z M 9.296875 8.597656 " />
        <path d="M 17.863281 8.628906 C 17.753906 8.648438 17.558594 8.722656 17.425781 8.785156 C 16.320312 9.328125 15.882812 10.585938 16.421875 11.667969 C 16.542969 11.902344 16.609375 11.996094 16.828125 12.214844 C 17.332031 12.710938 17.917969 12.914062 18.621094 12.835938 C 19.097656 12.78125 19.472656 12.613281 19.832031 12.289062 C 20.976562 11.261719 20.679688 9.417969 19.269531 8.769531 C 19.148438 8.710938 18.953125 8.644531 18.84375 8.621094 C 18.589844 8.570312 18.125 8.570312 17.863281 8.628906 Z M 17.863281 8.628906 " />
        <path d="M 8.675781 16.625 C 8.015625 16.722656 7.621094 17.351562 7.804688 18.007812 C 7.941406 18.480469 8.273438 19.183594 8.582031 19.648438 C 9.503906 21.03125 11.019531 22.039062 12.660156 22.363281 C 15.179688 22.863281 17.730469 21.929688 19.214844 19.964844 C 19.527344 19.558594 19.644531 19.363281 19.875 18.894531 C 20.253906 18.101562 20.347656 17.667969 20.210938 17.3125 C 20.097656 17.019531 19.957031 16.863281 19.660156 16.707031 C 19.542969 16.644531 19.484375 16.636719 19.203125 16.636719 C 18.914062 16.636719 18.871094 16.644531 18.726562 16.714844 C 18.398438 16.890625 18.269531 17.050781 18.105469 17.503906 C 17.722656 18.582031 16.941406 19.441406 15.902344 19.933594 C 13.578125 21.039062 10.773438 19.898438 9.921875 17.503906 C 9.804688 17.167969 9.71875 17.023438 9.554688 16.878906 C 9.320312 16.667969 9.003906 16.582031 8.675781 16.625 Z M 8.675781 16.625 " />
      </g>
    </svg>);