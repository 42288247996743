import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientStatusesStyles } from './ClientStatusesStyles';
import { useAppSelector } from '../../../../state';
import { reviewsStatusIconsAndText } from '../../../../constants';
import { Checkbox } from '../../../molecules';
import { companySettingsApi } from '../../../../api';
type TClientStatuses = {
  apiStatuses: number[] | undefined;
};
export const ClientStatuses: FC<TClientStatuses> = ({
  apiStatuses
}) => {
  const {
    id: companyID
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [statuses, setStatuses] = useState<number[]>([]);
  const handleChange = useCallback((id: number) => {
    const isNumberInArray = statuses.some(item => item === id);
    let sendedArray: number[] = [];
    if (isNumberInArray) {
      sendedArray = statuses.filter(item => item !== id);
    } else {
      sendedArray = [...statuses, id].sort((a, b) => a - b);
    }
    if (companyID) {
      companySettingsApi.updateCompanySettingsKey(+companyID, 'client-statuses', {
        value: sendedArray
      }).then(res => {
        setStatuses(sendedArray);
      });
    }
  }, [statuses]);
  useEffect(() => {
    setStatuses(apiStatuses || []);
  }, [apiStatuses]);
  return <ClientStatusesStyles>
      <h3>Налаштування статистики статусів в розділі відгуки</h3>
      <div className="clientStatuses">
        {Object.entries(reviewsStatusIconsAndText(interfaceLanguage)).map(([key, value]) => {
        const Icon = value.icon;
        return <div className="item">
                <Checkbox hideError extraBlockStyles={{
            width: 'auto'
          }} name={`client-status-${key}`} value={statuses.includes(+key)} onChange={() => handleChange(+key)} />
                <Icon witdth={30} height={30} />
                <span>{value.text}</span>
              </div>;
      })}
      </div>
      <div className="horizontalLine" />
    </ClientStatusesStyles>;
};