import styled from 'styled-components';

export const CustomTabsMenuStyles = styled.div`
  .tabsContainer {
    width: 100%;
    padding-left: 16px;
    padding-right: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;

    .tabs {
      display: flex;
      align-items: flex-end;
      box-sizing: border-box;

      button {
        height: 24px;
        display: flex;
        align-items: flex-start;
        border-bottom: 3px solid #fff;
        margin-right: 32px;

        font-size: 15px;
        line-height: 16px;
        color: #7c7878;
        transition: all 0.3s;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border-bottom: 3px solid #000;
          color: #000;
          transition: all 0.3s;
        }
      }
    }

    button {
      height: 24px;
      display: flex;
      align-items: flex-start;
      border-bottom: 3px solid #fff;
      margin-right: 32px;

      font-size: 15px;
      line-height: 16px;
      color: #7c7878;
      transition: all 0.3s;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        border-bottom: 3px solid #000;
        color: #000;
        transition: all 0.3s;
      }
    }
  }
`;
