import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../i18n';

export const fieldsValidation = (lang: string) => Yup.object({
  nodeId: Yup.number().required(
    getTranslationByLangOrEng(lang, 'validation_required'),
  ),
  newFormName: Yup.string().required(
    getTranslationByLangOrEng(lang, 'validation_required'),
  ),
});
