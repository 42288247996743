import styled from 'styled-components';

export const CsiRegionRatingsDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;

  h2 {
    width: 80%;
    text-align: center;
    font-size: 24px;

    @media screen and (max-width: 1400px) {
      font-size: 18px;
    }
  }

  .bars {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 25px;

    &-item {
      width: calc(33% - 15px);
    }
  }
`;
