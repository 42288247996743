import styled from 'styled-components';

export const SubHeaderStyles = styled.div<{
  tableVersion: boolean;
  strechChild?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${({ tableVersion }) => (tableVersion ? '48px' : '64px')};
  padding: 5px 16px;
  border-bottom: 1px solid #f0f0f0;
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 1400px) {
    padding: 5px 12px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding: 12px;
  }

  &.withoutBorder {
    border-bottom: none;
  }

  h1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    color: #000000;

    @media screen and (max-width: 1400px) {
      font-size: 24px;
    }
  }

  .childrenContainer {
    display: flex;
    align-items: center;
    gap: 15px;
    ${({ strechChild }) => strechChild && { width: '100%' }}
  }

  .titleContainer {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000;
    display: flex;
    flex-direction: column;
    gap: 5px;

    a {
      color: #000;
    }

    .arrowRight {
      margin: 0 4px;
      font-weight: 600;
      color: #222;
    }

    .path {
      display: flex;
    }
  }

  .review-count {
    position: absolute;
    bottom: 1px;

    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000;
    flex-shrink: 0;
  }
`;
