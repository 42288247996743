import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const ViberSocialIcon: FC<TIcon> = React.memo(({
  width = 24,
  height = 24
}) => <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.28146 4.27824L4.27824 4.28146C3.2586 5.29878 2.6592 6.6423 2.33699 8.83686C2.00489 11.0988 2 13.997 2 17.9967C2 21.9964 2.00489 24.8947 2.337 27.1575C2.65922 29.3528 3.25879 30.6991 4.27985 31.7201L4.28147 31.7218C5.29878 32.7414 6.6423 33.3408 8.83686 33.663C11.0988 33.9951 13.997 34 17.9967 34C21.9964 34 24.8947 33.9951 27.1575 33.663C29.3529 33.3408 30.6991 32.7412 31.7201 31.7201C32.7415 30.6988 33.3409 29.3539 33.663 27.1599C33.9951 24.898 34 21.9998 34 17.9967C34 13.9937 33.9951 11.0955 33.663 8.8336C33.341 6.64018 32.7418 5.29538 31.7209 4.27411C30.6999 3.25577 29.3547 2.6577 27.1605 2.33627C24.8982 2.00487 21.9996 2 17.9967 2C13.9937 2 11.0955 2.00489 8.83451 2.33698C6.6413 2.6591 5.29908 3.2583 4.28146 4.27824ZM33.1344 2.85911C36 5.72475 36 10.1048 36 17.9967C36 25.8887 36 30.2687 33.1344 33.1344C30.2687 36 25.8821 36 17.9967 36C10.1113 36 5.72475 36 2.86564 33.1344C0 30.2687 0 25.8821 0 17.9967C0 10.1113 0 5.72475 2.86564 2.86564C5.72475 0 10.1048 0 17.9967 0C25.8887 0 30.2687 0 33.1344 2.85911Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.28146 4.27824L4.27824 4.28146C3.2586 5.29878 2.6592 6.6423 2.33699 8.83686C2.00489 11.0988 2 13.997 2 17.9967C2 21.9964 2.00489 24.8947 2.337 27.1575C2.65922 29.3528 3.25879 30.6991 4.27985 31.7201L4.28147 31.7218C5.29878 32.7414 6.6423 33.3408 8.83686 33.663C11.0988 33.9951 13.997 34 17.9967 34C21.9964 34 24.8947 33.9951 27.1575 33.663C29.3529 33.3408 30.6991 32.7412 31.7201 31.7201C32.7415 30.6988 33.3409 29.3539 33.663 27.1599C33.9951 24.898 34 21.9998 34 17.9967C34 13.9937 33.9951 11.0955 33.663 8.8336C33.341 6.64018 32.7418 5.29538 31.7209 4.27411C30.6999 3.25577 29.3547 2.6577 27.1605 2.33627C24.8982 2.00487 21.9996 2 17.9967 2C13.9937 2 11.0955 2.00489 8.83451 2.33698C6.6413 2.6591 5.29908 3.2583 4.28146 4.27824ZM33.1344 2.85911C36 5.72475 36 10.1048 36 17.9967C36 25.8887 36 30.2687 33.1344 33.1344C30.2687 36 25.8821 36 17.9967 36C10.1113 36 5.72475 36 2.86564 33.1344C0 30.2687 0 25.8821 0 17.9967C0 10.1113 0 5.72475 2.86564 2.86564C5.72475 0 10.1048 0 17.9967 0C25.8887 0 30.2687 0 33.1344 2.85911Z" fill="url(#paint0_linear)" />
      <path d="M29.1551 19.893C29.8711 13.866 28.8111 10.061 26.8991 8.34002L26.9001 8.33902C23.8141 5.40002 13.3921 4.96502 9.70008 8.47102C8.04208 10.186 7.45808 12.703 7.39408 15.819C7.33008 18.936 7.25408 24.775 12.6951 26.359H12.7001L12.6951 28.778C12.6951 28.778 12.6581 29.758 13.2841 29.955C14.0001 30.187 14.3241 29.732 16.5511 27.072C20.2751 27.395 23.1351 26.655 23.4601 26.547C24.2121 26.295 28.4671 25.732 29.1551 19.893ZM16.9181 25.37C16.9181 25.37 14.5611 28.309 13.8281 29.072C13.5881 29.32 13.3251 29.297 13.3291 28.805C13.3291 28.482 13.3471 24.789 13.3471 24.789C8.73408 23.467 9.00608 18.495 9.05608 15.894C9.10608 13.292 9.58208 11.161 10.9861 9.72602C14.2251 6.68902 23.3621 7.36802 25.6901 9.55602C28.5361 12.079 27.5231 19.207 27.5291 19.45C26.9441 24.324 23.4961 24.633 22.8621 24.844C22.5911 24.934 20.0761 25.581 16.9181 25.37V25.37Z" fill="white" />
      <path d="M29.1551 19.893C29.8711 13.866 28.8111 10.061 26.8991 8.34002L26.9001 8.33902C23.8141 5.40002 13.3921 4.96502 9.70008 8.47102C8.04208 10.186 7.45808 12.703 7.39408 15.819C7.33008 18.936 7.25408 24.775 12.6951 26.359H12.7001L12.6951 28.778C12.6951 28.778 12.6581 29.758 13.2841 29.955C14.0001 30.187 14.3241 29.732 16.5511 27.072C20.2751 27.395 23.1351 26.655 23.4601 26.547C24.2121 26.295 28.4671 25.732 29.1551 19.893ZM16.9181 25.37C16.9181 25.37 14.5611 28.309 13.8281 29.072C13.5881 29.32 13.3251 29.297 13.3291 28.805C13.3291 28.482 13.3471 24.789 13.3471 24.789C8.73408 23.467 9.00608 18.495 9.05608 15.894C9.10608 13.292 9.58208 11.161 10.9861 9.72602C14.2251 6.68902 23.3621 7.36802 25.6901 9.55602C28.5361 12.079 27.5231 19.207 27.5291 19.45C26.9441 24.324 23.4961 24.633 22.8621 24.844C22.5911 24.934 20.0761 25.581 16.9181 25.37V25.37Z" fill="url(#paint1_linear)" />
      <path d="M18.2214 10.2969C17.8364 10.2969 17.8364 10.8969 18.2214 10.9019C21.2084 10.9249 23.6684 13.0069 23.6954 16.8259C23.6954 17.2289 24.2854 17.2239 24.2804 16.8209H24.2794C24.2474 12.7059 21.5614 10.3199 18.2214 10.2969V10.2969Z" fill="white" />
      <path d="M18.2214 10.2969C17.8364 10.2969 17.8364 10.8969 18.2214 10.9019C21.2084 10.9249 23.6684 13.0069 23.6954 16.8259C23.6954 17.2289 24.2854 17.2239 24.2804 16.8209H24.2794C24.2474 12.7059 21.5614 10.3199 18.2214 10.2969V10.2969Z" fill="url(#paint2_linear)" />
      <path d="M22.151 16.193C22.142 16.591 22.731 16.61 22.736 16.207C22.785 13.938 21.3859 12.069 18.7569 11.872C18.3719 11.844 18.3319 12.449 18.7159 12.477C20.9959 12.65 22.197 14.206 22.151 16.193V16.193Z" fill="white" />
      <path d="M22.151 16.193C22.142 16.591 22.731 16.61 22.736 16.207C22.785 13.938 21.3859 12.069 18.7569 11.872C18.3719 11.844 18.3319 12.449 18.7159 12.477C20.9959 12.65 22.197 14.206 22.151 16.193V16.193Z" fill="url(#paint3_linear)" />
      <path d="M21.5212 18.7741C21.0272 18.4881 20.5242 18.6661 20.3162 18.9471L19.8812 19.5101C19.6602 19.7961 19.2472 19.7581 19.2472 19.7581C16.2332 18.9611 15.4272 15.8071 15.4272 15.8071C15.4272 15.8071 15.3902 15.3801 15.6662 15.1511L16.2102 14.7011C16.4822 14.4851 16.6542 13.9651 16.3772 13.4541C15.6372 12.1171 15.1402 11.6561 14.8872 11.3021C14.6212 10.9691 14.2212 10.8941 13.8052 11.1191H13.7962C12.9312 11.6251 11.9842 12.5721 12.2872 13.5471C12.8042 14.5751 13.7542 17.8521 16.7822 20.3281C18.2052 21.4991 20.4572 22.6991 21.4132 22.9761L21.4222 22.9901C22.3642 23.3041 23.2802 22.3201 23.7692 21.4291V21.4221C23.9862 20.9911 23.9142 20.5831 23.5972 20.3161C23.0352 19.7681 22.1872 19.1631 21.5212 18.7741Z" fill="white" />
      <path d="M21.5212 18.7741C21.0272 18.4881 20.5242 18.6661 20.3162 18.9471L19.8812 19.5101C19.6602 19.7961 19.2472 19.7581 19.2472 19.7581C16.2332 18.9611 15.4272 15.8071 15.4272 15.8071C15.4272 15.8071 15.3902 15.3801 15.6662 15.1511L16.2102 14.7011C16.4822 14.4851 16.6542 13.9651 16.3772 13.4541C15.6372 12.1171 15.1402 11.6561 14.8872 11.3021C14.6212 10.9691 14.2212 10.8941 13.8052 11.1191H13.7962C12.9312 11.6251 11.9842 12.5721 12.2872 13.5471C12.8042 14.5751 13.7542 17.8521 16.7822 20.3281C18.2052 21.4991 20.4572 22.6991 21.4132 22.9761L21.4222 22.9901C22.3642 23.3041 23.2802 22.3201 23.7692 21.4291V21.4221C23.9862 20.9911 23.9142 20.5831 23.5972 20.3161C23.0352 19.7681 22.1872 19.1631 21.5212 18.7741Z" fill="url(#paint4_linear)" />
      <path d="M19.1684 14.1039C20.1294 14.1599 20.5954 14.6619 20.6454 15.6929C20.6634 16.0959 21.2484 16.0679 21.2304 15.6649C21.1664 14.3189 20.4644 13.5689 19.2004 13.4989C18.8154 13.4759 18.7794 14.0809 19.1684 14.1039V14.1039Z" fill="white" />
      <path d="M19.1684 14.1039C20.1294 14.1599 20.5954 14.6619 20.6454 15.6929C20.6634 16.0959 21.2484 16.0679 21.2304 15.6649C21.1664 14.3189 20.4644 13.5689 19.2004 13.4989C18.8154 13.4759 18.7794 14.0809 19.1684 14.1039V14.1039Z" fill="url(#paint5_linear)" />
    </svg>);