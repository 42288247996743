import React, { FC, useEffect } from 'react';
import { PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { PieDataAnnotation } from './PieDataAnnotation';
import { DataItem } from '../../../../../pages';
type TPieArch = {
  index: number;
  arc: PieArcDatum<DataItem>;
  path: any;
  color: string;
};
export const PieArch: FC<TPieArch> = ({
  index,
  arc,
  path,
  color
}) => {
  useEffect(() => {}, []);
  return <g data-element-type="chart-data-point">
      <path d={path(arc)} fill={color} />
      {arc.endAngle - arc.startAngle !== 0 ? <PieDataAnnotation arc={arc} path={path} index={index} title={arc.data.label} subtitle={`${arc.value}% ${arc.data.count ? `(${arc.data.count})` : ''}`} /> : null}
    </g>;
};