import React, { CSSProperties, useRef } from 'react';
import { UploadFileButtonStyles } from './UploadFileButtonStyles';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { TransparentButton } from '../../atoms';
interface UploadFileButtonProps {
  onChange?: (file: File | undefined) => void;
  onChangeMultipleFiles?: (files: FileList | undefined) => void;
  formats: string;
  name?: string;
  fieldName?: string;
  label?: string;
  extraButtonStyles?: CSSProperties;
  multiple?: boolean;
  disabled?: boolean;
  extraButtonTextStyles?: CSSProperties;
}
export const UploadFileButton = ({
  onChange,
  onChangeMultipleFiles,
  formats,
  name,
  fieldName,
  label,
  extraButtonStyles,
  multiple,
  disabled,
  extraButtonTextStyles
}: UploadFileButtonProps) => {
  const fileInput = useRef<any>(null);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const handleClick = () => {
    if (fileInput.current) {
      // @ts-ignore
      fileInput.current.click();
    }
  };
  return <UploadFileButtonStyles className="uploadFileButton">
      <div className="uploadFileButtonContainer">
        {label && <div className="label">{label}</div>}
        <input name={fieldName} autoComplete="off" className="defaultFilePicker" ref={fileInput} type="file" accept={formats} onChange={event => {
        if (onChangeMultipleFiles && multiple && event.currentTarget.files) {
          onChangeMultipleFiles(event.currentTarget.files);
        } else if (onChange && event.currentTarget.files) {
          onChange(event.currentTarget.files[0]);
          event.currentTarget.value = '';
        } else if (onChange) {
          onChange(undefined);
        }
      }} multiple={multiple} disabled={disabled} />
        <TransparentButton disabled={disabled} extraStyles={extraButtonStyles} extraButtonTextStyles={extraButtonTextStyles} handleClick={handleClick} text={name || getTranslationByLangOrEng(interfaceLanguage, 'choose_file_button')} filled />
      </div>
    </UploadFileButtonStyles>;
};