import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientStatusesEmptyChatStyles } from './ClientStatusesEmptyChatStyles';
import { useClickOutside } from '../../../../hooks';
import { ArrowDownIcon, CheckMark } from '../../../../assets';
import { reviewsStatusIconsAndText } from '../../../../constants';
import { useAppSelector } from '../../../../state';
import { companySettingsApi } from '../../../../api';
import { CustomSelect } from '../../../molecules';
type TClientStatusesEmptyChat = {
  apiClientStatusesEmptyChat: number[];
};
export const ClientStatusesEmptyChat: FC<TClientStatusesEmptyChat> = React.memo(({
  apiClientStatusesEmptyChat
}) => {
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [open, setOpen] = useState(false);
  const [clientStatusesEmptyChat, setClientStatusesEmptyChat] = useState<number[]>([]);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const handleUpdate = useCallback((statusID: number) => {
    const isNumberInArray = clientStatusesEmptyChat.some(item => item === statusID);
    let statuses: number[] = [];
    if (isNumberInArray) {
      statuses = clientStatusesEmptyChat.filter(item => item !== statusID);
    } else {
      statuses = [...clientStatusesEmptyChat, statusID].sort((a, b) => a - b);
    }
    if (id) {
      companySettingsApi.updateCompanySettingsKey(+id, 'client-statuses-when-chat-is-empty', {
        value: statuses
      }).then(res => {
        setClientStatusesEmptyChat(statuses);
      });
    }
  }, [clientStatusesEmptyChat]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  useEffect(() => {
    if (apiClientStatusesEmptyChat) {
      setClientStatusesEmptyChat(apiClientStatusesEmptyChat);
    }
  }, [apiClientStatusesEmptyChat]);
  return <ClientStatusesEmptyChatStyles>
        <div className="clientStatusesEmptyChat">
          <h3>Статуси якщо відсутнє повідомлення в чаті від користувача</h3>

          <div className="selectField">
            <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
              <span className="placeholder">Оберіть статуси</span>
              <div className={open ? 'arrowDownActive' : ''}>
                <ArrowDownIcon />
              </div>
            </div>

            {open && <div className="options" ref={optionsListRef}>
                {Object.entries(reviewsStatusIconsAndText(interfaceLanguage)).map(([key, value]) => {
            const Icon = value.icon;
            return <div key={key} className="option">
                      <div className="rowSel">
                        <button type="button" onClick={() => handleUpdate(+key)}>
                          <div className="labelContent">
                            <div className="default">
                              {clientStatusesEmptyChat.includes(+key) && <CheckMark />}
                            </div>
                            <Icon width={25} height={25} />
                            <span>
                              {value.text.length > 20 ? `${value.text.slice(0, 20)}...` : value.text}
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>;
          })}
              </div>}
          </div>
        </div>
      </ClientStatusesEmptyChatStyles>;
});