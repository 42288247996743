import React, { Dispatch, FC, SetStateAction } from 'react';
import { useAppSelector } from '../../../state';
import { NotificationsStyles } from './NotificationsStyles';
import { ApiContacts, notifiableSourcesApi, userContactsApi } from '../../../api';
import { getTranslationByLangOrEng } from '../../../i18n';
import { EmailIcon, TelegramIcon, ViberIcon } from '../../../assets';
import { Checkbox } from '../form';
type TNotifications = {
  setContacts: Dispatch<SetStateAction<ApiContacts[]>>;
  contacts: ApiContacts[];
  hidens?: string[];
};
export const Notifications: FC<TNotifications> = React.memo(({
  setContacts,
  contacts,
  hidens = []
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const handleToggleNotification = (contact: ApiContacts) => {
    userContactsApi.putContactNotification(contact.id, {
      active: !contact.active
    }).then(res => {
      if (res.statusCode === 200) {
        setContacts(prev => prev.map(cont => cont.id === contact.id ? {
          ...cont,
          active: !cont.active
        } : cont));
      }
    });
  };
  return <NotificationsStyles>
        <div className="contacts-wrapper">
          <h3>
            {getTranslationByLangOrEng(interfaceLanguage, 'notifications')}
          </h3>
          <div className="contacts">
            {contacts.map(cont => {
          if ('telegramID' in cont) {
            return <div key={cont.telegramID} title={cont.telegramID}>
                    <TelegramIcon width={38} height={38} />
                    <Checkbox name={`telegram-${cont.id}`} value={cont.active} onChange={() => handleToggleNotification(cont)} />
                  </div>;
          }
          if ('viberID' in cont) {
            return <div key={cont.viberID} title={cont.viberID}>
                    <ViberIcon width={38} height={38} />
                    <Checkbox name={`viber-${cont.id}`} value={cont.active} onChange={() => handleToggleNotification(cont)} />
                  </div>;
          }
          if ('email' in cont && !hidens.includes('email')) {
            return <div key={cont.email} title={cont.email}>
                    <EmailIcon width={38} height={38} />
                    <Checkbox name={`email-${cont.id}`} value={cont.active} onChange={() => handleToggleNotification(cont)} />
                  </div>;
          }
          return null;
        })}
          </div>
        </div>
      </NotificationsStyles>;
});