import React from 'react';
import { FooterStyles } from './styles';
import { FacebookSocialIcon, InstagramSocialIcon, LogoFooterIcon, TelegramSocialIcon, ViberSocialIcon } from '../../../assets';
export const Footer = () => {
  const socials: {
    link: string;
    icon: any;
  }[] = [{
    link: 'https://www.facebook.com/diia.gov.ua',
    icon: FacebookSocialIcon
  }, {
    link: 'https://t.me/diia_gov',
    icon: TelegramSocialIcon
  }, {
    link: 'https://www.instagram.com/diia.gov.ua/',
    icon: InstagramSocialIcon
  }, {
    link: 'https://vb.me/diia',
    icon: ViberSocialIcon
  }];
  return <FooterStyles>
      <div className="footer-logo">
        <LogoFooterIcon height={36} width={80} />
        <div className="footer-links">
          <p>diia.gov.ua</p>
          <p>2024. Всі права захищені.</p>
        </div>
      </div>
      <div className="footer-social">
        <p>Слідкуй за нами тут:</p>
        {socials.map(el => {
        const Icon = el.icon;
        return <a key={el.link} href={el.link} target="_blank" className="social-item" rel="noreferrer">
              <Icon />
            </a>;
      })}
      </div>
    </FooterStyles>;
};