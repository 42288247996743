import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { formApi } from '../../../../api';
import { DeepArrayItem } from '../../../../entities';
import { translations } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import { getNodeNamesArray, getNodesDeepArray } from '../../../../utils';
import { Loader } from '../../../atoms';
import MultiSelectWithChildren from '../../../form/customSelect/multiSelectWithChildren/MultiSelectWithChildren';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { TransparentButton } from '../../../atoms/transparentButton/TransparentButton';
import { resetFilterButtonStyles } from '../reviewsTableFiltersUtils';
import { INodeFilter, NodeFilterItem, NodeFilterValues } from './additionalSettings';
import { NodeFilterStyles } from './NodeFilterStyles';
import { getAllSelectedNodeIds, getParentId, getPathForInitialList, handleSelectNode, markAllNodesWithAllSelectedChildren } from './utils';
export const NodeFilter = React.memo(({
  filterName,
  filterParams,
  setReQuery,
  setApply,
  setFilterParams,
  resizable,
  containerExtraStyles,
  extraFilterFormStyles,
  optionsExtraStyles
}: INodeFilter) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [loadingDataByCompany, setLoadingDataByCompany] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<NodeFilterValues>();
  const ref = useRef<any>();
  const formRef = useRef<FormikProps<NodeFilterValues>>(null);
  const nodesRef = useRef<DeepArrayItem[]>([]);
  const nodeNamesRef = useRef<NodeFilterItem[]>([]);
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        formRef.current?.submitForm();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  async function onSubmit(values: NodeFilterValues, {
    setSubmitting
  }: FormikHelpers<NodeFilterValues>) {
    const params: {
      [key: string]: number | string | any;
    } = {};
    const selectedNodeIds: number[] = [];
    if (values.node) {
      getAllSelectedNodeIds(selectedNodeIds, values.node);
    }
    if (selectedNodeIds.length > 0) {
      params[filterName ?? 'nodes'] = selectedNodeIds;
    } else if (filterParams?.[filterName ?? 'nodes'] && selectedNodeIds.length === 0) {
      delete filterParams[filterName ?? 'nodes'];
    }
    const resultParams: {
      [key: string]: any;
    } = {};
    Object.entries({
      ...filterParams,
      ...params
    }).forEach(item => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });
    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();
    setSubmitting(false);
  }
  const additionalResetHandlers = () => {
    if (filterParams[filterName ?? 'nodes']) {
      delete filterParams[filterName ?? 'nodes'];
    }
    setFilterParams({
      ...filterParams
    });
    setApply({
      ...filterParams
    });
    setReQuery();
  };
  async function getNodesBySelectedCompany(companyId: number) {
    setLoadingDataByCompany(true);
    const nodes = await formApi.getCompanyNodes(companyId);
    if (nodes.statusCode >= 200 && nodes.statusCode < 300) {
      const nodeNamesArray: {
        id: number;
        name: string;
        deep: number;
        parentId: number | undefined;
      }[] = [];
      getNodeNamesArray(nodeNamesArray, nodes.data || []);
      nodeNamesArray.map((item, index) => {
        if (item.deep > 0) {
          item.parentId = getParentId(nodeNamesArray, item.deep, index);
        }
        return item;
      });
      nodeNamesRef.current = nodeNamesArray.map(item => ({
        ...item,
        selected: false,
        hasSelectedChildren: false,
        allChildrenAreSelected: false
      }));
      nodesRef.current = getNodesDeepArray(nodeNamesArray);
    }
    setLoadingDataByCompany(false);
  }
  useEffect(() => {
    getNodesBySelectedCompany(+filterParams.companyID).then(res => {
      const nodesArray = filterParams?.[filterName ?? 'nodes'] && filterParams[filterName ?? 'nodes'].length > 0 ? nodeNamesRef.current?.map(node => filterParams?.[filterName ?? 'nodes']?.includes(node.id) ? {
        ...node,
        selected: true
      } : node) : nodeNamesRef.current;
      function findParentNode(array: NodeFilterItem[], item: NodeFilterItem) {
        const parentNodeIndex = array.findIndex(node => node.id === item.parentId);
        if (parentNodeIndex !== undefined) {
          array[parentNodeIndex].hasSelectedChildren = true;
          if (array[parentNodeIndex].deep !== 0 && !array[parentNodeIndex - 1].hasSelectedChildren) {
            findParentNode(array, array[parentNodeIndex]);
          }
        }
      }
      nodesArray.forEach(item => {
        if (item.selected && item.deep !== 0) {
          findParentNode(nodesArray, item);
        }
      });
      const deepNodes = getNodesDeepArray(nodesArray);
      markAllNodesWithAllSelectedChildren(deepNodes);
      setInitialValues({
        node: deepNodes
      });
    });
  }, []);
  const renderForm = ({
    values,
    handleChange,
    setFieldValue,
    resetForm
  }: FormikProps<NodeFilterValues>) => <Form className={extraFilterFormStyles}>
        <div className={containerExtraStyles}>
          <SubmitButton extraBlockStyles={{
        width: '100%',
        maxWidth: 270
      }} extraButtonStyles={{
        width: '100%',
        height: 32,
        fontSize: 16,
        fontWeight: 500
      }}>
            {translations[interfaceLanguage].apply_filters_button}
          </SubmitButton>
          <TransparentButton handleClick={() => {
        resetForm();
        additionalResetHandlers();
      }} text={translations[interfaceLanguage].reset_reviews_filter} extraStyles={resetFilterButtonStyles} extraButtonTextStyles={{
        color: '#ffffff'
      }} extraContainerStyles={{
        width: '100%',
        maxWidth: 270,
        fontSize: 16,
        fontWeight: 500
      }} />

          <div className="selectContainer">
            <MultiSelectWithChildren showLastElement name="node" options={values.node || []} selectKey="name" handleSelect={(value, path, filtered) => {
          const changedNodesData = [...(values.node || [])];
          const pathForInitialList: number[] = [];
          if (filtered) {
            getPathForInitialList(pathForInitialList, value, nodeNamesRef.current, values.node!);
          }
          handleSelectNode(changedNodesData, value, filtered ? pathForInitialList : path || [], {
            hasSelectedChildren: value.children.length > 0 && !value.hasSelectedChildren,
            allChildrenAreSelected: value.children.length > 0 && !value.selected
          });
          setFieldValue('node', changedNodesData);
        }} search required hideError isLoading={loadingDataByCompany} disabled={!nodesRef.current.length} optionsContainerStyles={optionsExtraStyles} />
          </div>
        </div>
      </Form>;
  return <NodeFilterStyles ref={ref} style={resizable ? {
    overflow: 'auto',
    resize: 'both'
  } : {}}>
        {initialValues ? <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
            {renderForm}
          </Formik> : <Loader margin={0} />}
      </NodeFilterStyles>;
});