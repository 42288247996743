import { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps, FieldArray } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { AddNodeStyles } from './AddNodeStyles';
import SubmitButton from '../../../components/form/submitButton/SubmitButton';
import { formApi, sourcesApi, companyApi, usersApi } from '../../../api';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppDispatch, useAppSelector, setNodeNames, setNodeMetadata, setCompanySources } from '../../../state';
import CustomSelectTiedNode from '../../../components/form/customSelect/CustomSelectTiedNode';
import { getNodesDeepArray, getErrorMessage, handleKeyUp, getNodeNamesArray } from '../../../utils';
import { DeleteIcon } from '../../../assets';
import CustomSelect from '../../../components/form/customSelect/CustomSelect';
import { FormValues, networks, validationSchema } from './additionalSettings';
import { Helmet } from 'react-helmet-async';
import { ICompanyUser, ICompanyNodeExtraField } from '../../../entities';
import { Checkbox, InputField, Loader, ModalConfirmDecline, SubHeader, TransparentButton } from '../../../components';
export default function AddNode() {
  const {
    companyId,
    nodeId
  } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    selectedCompany,
    nodeNames,
    nodeMetadata
  } = useAppSelector(state => state.company);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [companyName, setCompanyName] = useState('');
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');
  const [usersByCompany, setUsersByCompany] = useState<ICompanyUser[]>([]);
  const nodeNameRef = useRef<FormikProps<FormValues>>(null);
  function getFormInitialData(nodes: {
    id: number;
    name: string;
    deep: number;
  }[]) {
    if (companyId) {
      companyApi.getCompanyById(+companyId).then(res => setCompanyName(res.data.name));
      sourcesApi.getSourcesByCompanyId(+companyId).then(res => {
        dispatch(setCompanySources(res.data));
      }).catch(e => console.log('get request sources err', e));
    }
    if (nodeId) {
      formApi.getNodeById(+nodeId).then(res => {
        if (res.statusCode === 200) {
          dispatch(setNodeMetadata(res.data));
          const data = {
            name: res.data.name,
            slug: res.data.slug,
            code: res.data.code,
            tiedNode: res.data.tiedNode,
            secondTiedNode: res.data.secondTiedNode,
            address: res.data.address || {
              value: ''
            },
            nodes: getNodesDeepArray(nodes),
            sms: res.data.sms || {
              active: false,
              key: '',
              alphaName: ''
            },
            viber: res.data.viber || {
              active: false,
              key: '',
              alphaName: ''
            },
            telegram: res.data.telegram || {
              active: false,
              key: '',
              alphaName: ''
            },
            whatsapp: res.data.whatsapp || {
              active: false,
              key: '',
              alphaName: ''
            },
            email: res.data.email || {
              active: false,
              key: '',
              alphaName: ''
            },
            responsibleIDs: res.data.responsibleIDs || [],
            enableOverdue: res.data.enableOverdue
          };
          setInitialValues({
            ...data
          });
        }
      });
    } else {
      setInitialValues({
        name: '',
        slug: '',
        code: '',
        tiedNode: undefined,
        secondTiedNode: undefined,
        address: {
          value: ''
        },
        nodes: getNodesDeepArray(nodes),
        sms: {
          active: false,
          key: '',
          alphaName: ''
        },
        viber: {
          active: false,
          key: '',
          alphaName: ''
        },
        telegram: {
          active: false,
          key: '',
          alphaName: ''
        },
        whatsapp: {
          active: false,
          key: '',
          alphaName: ''
        },
        email: {
          active: false,
          key: '',
          alphaName: ''
        },
        responsibleIDs: [],
        enableOverdue: false
      });
    }
  }
  function getFilteredNodeNames(initialArray: {
    id: number;
    name: string;
    deep: number;
  }[], nodeId: string) {
    const currentNodeDeep = nodeId ? initialArray.find(item => item.id === +nodeId)?.deep || 0 : undefined;
    let hisBranch = false;
    return initialArray.filter(item => {
      if (item.id === +nodeId) {
        hisBranch = true;
        return false;
      }
      if (hisBranch && currentNodeDeep !== undefined && currentNodeDeep < item.deep) {
        return false;
      }
      hisBranch = false;
      return true;
    });
  }
  useEffect(() => {
    if (companyId !== undefined && +companyId === selectedCompany?.id && nodeNames) {
      const filteredNodeNames = [...nodeNames];
      getFormInitialData([{
        id: 0,
        name: '-',
        deep: 0
      }, ...(nodeId ? getFilteredNodeNames(filteredNodeNames, nodeId) : filteredNodeNames)]);
    } else if (companyId !== undefined) {
      formApi.getCompanyNodes(+companyId!).then(res => {
        const nodeNamesArray: {
          id: number;
          name: string;
          deep: number;
        }[] = [];
        getNodeNamesArray(nodeNamesArray, res.data || []);
        getFormInitialData([{
          id: 0,
          name: '-',
          deep: 0
        }, ...(nodeId ? getFilteredNodeNames(nodeNamesArray, nodeId) : nodeNamesArray)]);
        dispatch(setNodeNames(nodeNamesArray));
      });
    }
    usersApi.getUsersByCompany(+companyId!).then(res => setUsersByCompany(res.data));
  }, [nodeId]);
  const handleCancel = () => {
    navigate(`/companies/${companyId}/nodes`);
  };
  const onSaveModalConfirm = useCallback(async (values: FormValues) => {
    if (companyId) {
      const res = await formApi.createNode({
        name: values.name,
        code: values.code,
        companyID: +companyId,
        parentID: values.tiedNode || 0,
        // @ts-ignore
        referencedID: values.secondTiedNode || 0,
        enableOverdue: values.enableOverdue
      });
      if (res.statusCode >= 200 && res.statusCode < 300) {
        if (values.address.value) {
          await formApi.createNodeMetadata({
            key: 'address',
            value: values.address.value,
            parentID: res.data.id
          });
        }
        const promises: Promise<any>[] = [];
        networks.forEach(socialNetwork => {
          const socialNetworkKey: 'sms' | 'viber' | 'telegram' | 'whatsapp' | 'email' = socialNetwork.key;
          if (values[socialNetworkKey].key) {
            promises.push(formApi.createNodeMetadata({
              key: `${socialNetworkKey}_key`,
              value: values[socialNetworkKey].key,
              parentID: res.data.id
            }));
          }
          if (values[socialNetworkKey].alphaName) {
            promises.push(formApi.createNodeMetadata({
              key: `${socialNetworkKey}_alphaName`,
              value: values[socialNetworkKey].alphaName,
              parentID: res.data.id
            }));
          }
          if (values[socialNetworkKey].active) {
            promises.push(formApi.createNodeMetadata({
              key: `${socialNetworkKey}_active`,
              value: 'true',
              parentID: res.data.id
            }));
          }
        });
        await Promise.all(promises);
        handleCancel();
      } else {
        setErrorMessage(res.data);
        setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
      }
    }
  }, [nodeMetadata]);
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setConfirmSaveModalOpen(true);
    setSubmitting(false);
  }
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<FormValues>) => <Form>
      <div className="formSection">
        <div className="formSectionInnerWrap">
          <div>
            <InputField extraBlockStyles={{
            width: '100%',
            maxWidth: '500px'
          }} name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_name_placeholder')} value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_name_label')} required />

            <InputField extraBlockStyles={{
            width: '100%',
            maxWidth: '500px'
          }} name="code" onChange={setFieldValue} onKeyUp={() => handleKeyUp('code', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_code_placeholder')} value={values.code} error={typeof errorMessage === 'object' ? getErrorMessage('code', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_code_label')} disabled={nodeId !== undefined} />

            <CustomSelectTiedNode label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_label')} name="tiedNode" options={values.nodes} selectKey="name" placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_placeholder')} value={nodeNames?.find(node => node.id === values.tiedNode)} handleSelect={node => {
            setFieldValue('tiedNode', node.id);
          }} formGroupStyles={{
            width: '253px'
          }} search expandable />

            <CustomSelectTiedNode label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_add_tied_node_label_second')} name="secondTiedNode" options={values.nodes} selectKey="name" placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_placeholder')} value={nodeNames?.find(node => node.id === values.secondTiedNode)} handleSelect={node => {
            setFieldValue('secondTiedNode', node.id);
          }} formGroupStyles={{
            width: '253px'
          }} search expandable />

            <InputField extraBlockStyles={{
            width: '100%',
            maxWidth: '500px'
          }} name="address.value" onChange={setFieldValue} onKeyUp={() => handleKeyUp('address.value', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_address_placeholder')} value={values.address.value} error={typeof errorMessage === 'object' ? getErrorMessage('address.value', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_address_label')} />
            <Checkbox name="enableOverdue" value={values.enableOverdue} onChange={handleChange}>
              <span className="checkboxValue">
                {getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_lang_delay')}
              </span>
            </Checkbox>
          </div>
        </div>
      </div>
      <div className="formSection additionalSettings">
        <SubmitButton extraButtonStyles={{
        width: '100%',
        maxWidth: '500px'
      }}>
          {getTranslationByLangOrEng(interfaceLanguage, 'save_button')}
        </SubmitButton>
        {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
      </div>
    </Form>;
  return <AddNodeStyles>
      <Helmet>
        <title>
          {nodeId ? `Editing settings ${nodeNameRef?.current?.values.name || 'New node'} ${companyName} Voicer` : `Creating a node ${companyName} Voicer`}
        </title>
      </Helmet>

      <SubHeader title={nodeId ? getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_edit_title') : getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_title')} pathArray={[{
      name: companyName,
      route: `/companies/${companyId}/nodes`
    }, ...(nodeNameRef?.current?.values.name ? [{
      name: nodeNameRef?.current?.values.name
    }] : [{
      name: 'New node'
    }])]}>
        <TransparentButton handleClick={handleCancel} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} filled />
      </SubHeader>

      {!initialValues ? <Loader /> : <Formik innerRef={nodeNameRef} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema(interfaceLanguage)}>
          {renderForm}
        </Formik>}

      {confirmSaveModalOpen && <ModalConfirmDecline onClose={() => setConfirmSaveModalOpen(false)} onConfirm={() => onSaveModalConfirm(nodeNameRef.current!.values)} onDecline={() => setConfirmSaveModalOpen(false)} confirmText={nodeId ? getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button') : getTranslationByLangOrEng(interfaceLanguage, 'create_button')} declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} title={nodeId ? getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_update_modal_title') : getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_modal_title')} error={!!dataSaveError} errorText={dataSaveError} firstButtonStyles={{
      background: '#ff0000'
    }} secondButtonStyles={{
      background: '#000'
    }} />}
    </AddNodeStyles>;
}