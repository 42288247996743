/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import {
  ApiAttachForm,
  ApiAttachToNodes,
  ApiConstructorForm,
  ApiCreateForm,
  ApiNode,
  ApiResponse,
} from '../entities';
import IConstructorApi from './IConstructorApi';

class ConstructorApi implements IConstructorApi {
  public async getCompanyForms(id: number, offset?: number, limit?: number, sourceID?: number): Promise<ApiResponse<ApiConstructorForm[]>> {
    const res = await baseApi.getData(`/companies/${id}/forms?offset=${offset || 0}&limit=${limit || 10}${sourceID ? `&sourceID=${sourceID}` : ''}`);

    return Promise.resolve({
      data: res.data.forms,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async createForm(data: ApiCreateForm): Promise<ApiResponse<ApiConstructorForm>> {
    return baseApi.postData('/forms', data);
  }

  public async updateForm(id: number, data: ApiCreateForm): Promise<ApiResponse<ApiConstructorForm>> {
    return baseApi.putData(`/forms/${id}`, data);
  }

  public async getForm(id: number): Promise<ApiResponse<ApiConstructorForm>> {
    return baseApi.getData(`/forms/${id}`);
  }

  public async getNodeForms(id: number): Promise<ApiResponse<ApiConstructorForm[]>> {
    return baseApi.getData(`/nodes/${id}/forms`);
  }

  public async getFormNodes(id: number): Promise<ApiResponse<ApiNode[]>> {
    const res = await baseApi.getData(`/forms/${id}/nodes`);

    return Promise.resolve({
      data: res.data.map((el: { node: ApiNode; }) => el.node),
      statusCode: res.statusCode,
    });
  }

  public async attachForm(id: number, data: ApiAttachForm): Promise<ApiResponse<ApiConstructorForm>> {
    return baseApi.postData(`/nodes/${id}/forms`, data);
  }

  public async attachToNodes(id: number, data: ApiAttachToNodes): Promise<ApiResponse<any>> {
    return baseApi.postData(`/forms/${id}/nodes`, data);
  }

  public async deleteForm(id: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/forms/${id}`);
  }

  public async detachForm(id: number, formId: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/nodes/${id}/forms/${formId}`);
  }
}

export const constructorApi = new ConstructorApi();
