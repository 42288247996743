/* eslint-disable class-methods-use-this */
import { mapChatImg } from '../Mappers';
import baseApi from '../baseApi';
import { ApiResponse } from '../entities';
import IChatApi from './IChatApi';

class ChatApi implements IChatApi {
  public async sendChatImg(file: File): Promise<
    ApiResponse<{
      attachmentUrl: string;
      DeleteUrl: string;
      attachmentType: number;
    }>
  > {
    const res = await baseApi.postData(
      '/reviews/chat/attachments',
      mapChatImg(file),
      true,
    );
    return res;
  }
}

export const chatApi = new ChatApi();
