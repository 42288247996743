import styled from 'styled-components';

export const ColorPickerStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  @media screen and (max-wight: 768px) {
    padding: 15px;
  }

  .colorPicker {
    margin-bottom: 12px;
  }
`;
