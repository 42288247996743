import React, { FC, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../state';
import { ApiTag, tagsApi } from '../../../api';
import { useClickOutside } from '../../../hooks';
import { ArrowDownIcon } from '../../../assets';
import { SelectOption } from './categoryOption';
import { TReviewTags } from './types';
import { ReviewTagsStyles } from './styles';
import { TagsChips } from '../../molecules';
export const ReviewTags: FC<TReviewTags> = ({
  reviewID,
  companyID,
  tags,
  setTags
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [companyTags, setCompanyTags] = useState<ApiTag[]>([]);
  const [open, setOpen] = useState(false);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const handleSelect = (item: ApiTag) => {
    const isAdded = tags.some(el => el.id === item.id);
    if (item.id) {
      if (isAdded) {
        tagsApi.deleteReviewTag(reviewID, item.id).then(res => {
          setTags(prev => prev.filter(el => el.id !== item.id));
        });
      } else {
        tagsApi.setTagsToReview(reviewID, [item.id]).then(res => {
          setTags(prev => [...prev, item]);
        });
      }
    }
  };
  const handleRemove = (item: ApiTag) => {
    if (item.id) {
      tagsApi.deleteReviewTag(reviewID, item.id).then(res => {
        setTags(prev => prev.filter(el => el.id !== item.id));
      });
    }
  };
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  useEffect(() => {
    tagsApi.getCompanyTags(companyID).then(res => {
      setCompanyTags(res.data || []);
    });
  }, []);
  return <ReviewTagsStyles>
      <TagsChips tags={tags} handleRemove={handleRemove} />
      <div className="selectWrapper">
        <div className="selectField">
          <div className="selectValue" ref={selectedValueFieldRef} onClick={() => setOpen(!open)}>
            <div className="placeholder">Обрати тег для відгуку</div>
            <div className={open ? 'active' : ''}>
              <ArrowDownIcon />
            </div>
          </div>

          {open && <div className="selectOptions" ref={optionsListRef}>
              {companyTags.length === 0 && <div className="selectOption">
                  <div className="selectRow" style={{
              paddingLeft: '16px'
            }}>
                    <span>Empty</span>
                  </div>
                </div>}
              {companyTags.map(el => <SelectOption key={el.id} offset={1} handleSelect={handleSelect} tag={el} tags={tags} />)}
            </div>}
        </div>
      </div>
    </ReviewTagsStyles>;
};