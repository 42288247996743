import styled from 'styled-components';

export const ConstructorStyles = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: calc(-62px + 100vh);
  position: relative;
  overflow: hidden;

  .constructor {
    margin-top: 25px;
    
    &-radiowrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin-bottom: 25px;
    }

    &-radio {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-line {
      width: 100%;
      height: 1px;
      background-color: rgb(240, 240, 240);
      margin: 18px 0px;
    }
  }

  .color {
      width: 100px;

      &-wrapper {
          max-width: 320px;
          display: flex;
          flex-direction: column;
          gap: 15px;
      }

      &-selected {
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 320px;
          width: 100%;
          gap: 16px;
      }

      &-row {
          display: flex;
          align-items: center;
          gap: 12px;
      }

      &-item {
          width: 24px;
          height: 24px;
          border-radius: 4px;
      }

      &-selector {
          display: flex;
      }
    }
`;
