import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../i18n';
import { MutableRefObject, useRef, useState } from 'react';
import { rolesApi, usersApi, authApi } from '../../api';
import { DeepArrayItem } from '../../entities/DeepArrayItem';
import { NodeFilterItem } from '../../components/molecules/reviewsTableFilters/nodeFilter/additionalSettings';
import { getNodeNamesArray, getNodesDeepArray } from '../../utils';
import { getParentId } from '../../components/molecules/reviewsTableFilters/nodeFilter/utils';
export interface FormValues {
  name: string;
  email: string;
  roles: number[];
  passwords?: {
    password?: string;
    passwordConfirmation?: string;
  };
}
export const formInitialValues: FormValues = {
  name: '',
  email: '',
  roles: [],
  passwords: {
    password: '',
    passwordConfirmation: ''
  }
};
export const validationNewUserSchema = (lang: string, value: number) => Yup.object({
  name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  email: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  roles: Yup.array().min(value, getTranslationByLangOrEng(lang, 'validation_required')),
  passwords: Yup.object().shape({
    password: Yup.string(),
    passwordConfirmation: Yup.string()
  }).test('check-password-equals', 'Passwords not equal', passwords => passwords.password === passwords.passwordConfirmation)
});
export const validationUserSchema = (lang: string, value: number) => Yup.object({
  email: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  roles: Yup.array().min(value, getTranslationByLangOrEng(lang, 'validation_required'))
});
export async function updateRolesList(rolesToAttach: number[], rolesToDetach: number[], userId: number) {
  if (rolesToAttach.length) {
    await authApi.attachRolesToUser(userId, rolesToAttach);
  }
  if (rolesToDetach.length) {
    await authApi.detachRolesFromUser(userId, rolesToDetach);
  }
}