import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TCategoryOption } from '../types';
import { ApiCategory, categoryApi } from '../../../../api';
import { ArrowDownIcon, CheckMark } from '../../../../assets';
const SelectOption_1 = _compiledBlock(_props => <div className="selectOption">
      <div className="selectRow" style={_props.v0}>
        <div className="selectCheckbox">
          <div className={_props.v1} onClick={_props.v2}>
            {_props.v3}
          </div>
          <span>{_props.v4}</span>
        </div>
        {_props.v5}
      </div>
      {_props.v6}
    </div>, {
  name: "SelectOption_1",
  portals: ["v3", "v4", "v5", "v6"]
});
export const SelectOption: FC<TCategoryOption> = ({
  category,
  handleSelect,
  offset,
  companyID,
  categories
}) => {
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState<ApiCategory[]>([]);
  const isSelected = useMemo(() => categories.some(el => el.id === category.id), [categories]);
  useEffect(() => {
    if (open) {
      categoryApi.getCategoriesByCompany(companyID, category.id).then(res => {
        setChildren(res.data || []);
      });
    }
  }, [open]);
  return /*@million jsx-skip*/<SelectOption_1 v0={{
    paddingLeft: `${offset * 16}px`
  }} v1={isSelected ? 'selectMarker selectActive' : 'selectMarker'} v2={() => handleSelect(category)} v3={isSelected && <CheckMark />} v4={category.name} v5={!category.isLeaf && <div className={open ? 'active' : ''} onClick={() => setOpen(!open)}>
            <ArrowDownIcon />
          </div>} v6={!!children.length && open && !category.isLeaf && children.map(el => <SelectOption key={el.id} offset={offset + 1} handleSelect={handleSelect} category={el} companyID={companyID} categories={categories} />)} />;
};