/* eslint-disable class-methods-use-this */
import { ILanguage } from '../../entities';
import { mapApiLanguagesToILanguages } from '../Mappers';
import baseApi from '../baseApi';
import { ApiResponse } from '../entities';
import ILanguagesApi from './ILanguagesApi';

class LanguagesApi implements ILanguagesApi {
  public async getLanguages(
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<ILanguage[]>> {
    const path = `/languages?offset=${offset || 0}&limit=${limit || 10}`;
    const { data, statusCode } = await baseApi.getData(path);

    return {
      data:
        statusCode >= 200 && statusCode < 300
          ? mapApiLanguagesToILanguages(data)
          : [],
      statusCode,
      count: data.count,
    };
  }

  public async createLanguage(data: {
    name: string;
  }): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.postData('/languages', data);
  }

  public async deleteLanguage(data: {
    name: string;
  }): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.deleteData('/languages', data);
  }

  public async getCompanyLanguages(
    id: number,
  ): Promise<ApiResponse<ILanguage[]>> {
    return baseApi.getData(`/companies/${id}/languages`);
  }

  public async updateCompanyLanguages(
    id: number,
    data: ILanguage[],
  ): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.putData(`/companies/${id}/languages`, {
      languageIDs: data.map((item) => item.id),
    });
  }

  public async createCompanyLanguages(
    id: number,
    data: ILanguage[],
  ): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.postData(`/companies/${id}/languages`, {
      languageIDs: data.map((item) => item.id),
    });
  }

  public async deleteCompanyLanguages(
    id: number,
  ): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.deleteData(`/companies/${id}/languages`);
  }
}

export const languagesApi = new LanguagesApi();
