import React, { useEffect, useRef, useState } from 'react';
import { PosterModalStyles } from './PosterModalStyles';
import { BsArrowUp } from 'react-icons/bs';
import { JustPlusIcon, QuantityMinusIcon } from '../../../../assets';
import { Close } from '../../../../assets/material-icons';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { ApiPosterTemplate, ApiQRStyles, ApiQRTextStyles, companySettingsApi } from '../../../../api';
import QRCodePreview, { minQRCodeSize } from '../../../atoms/qrCodePreview/QRCodePreview';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { Loader, SubmitButton } from '../../../atoms';
import { Checkbox, InputField } from '../../../molecules';
import { UploadFileButton } from '../../../form/uploadFileButton/UploadFileButton';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import Draggable from 'react-draggable';
import { onResize, PosterModalFormValues, prepareDataToPost } from './utils';
import { companyPostersApi } from '../../../../api/companyPosters/CompanyPostersApi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
const fakeTextStyles: ApiQRTextStyles = {
  color: [0, 0, 0],
  fontsize: 14
};
export interface PosterModalProps {
  onPosterCreated(createdPoster: ApiPosterTemplate): void;
  onClose(): void;
}

// const fetchPDF = async (url: string): Promise<File> => {
//   const response = await fetch(url);
//   const blob = await response.blob();
//   const file = new File([blob], 'downloaded.pdf', { type: 'application/pdf' });
//   return file;
// };

function PosterModal({
  onClose,
  onPosterCreated
}: PosterModalProps) {
  const {
    id: companyId
  } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scale, setScale] = useState<number>(1);
  const [qrVisible, setQRVisible] = useState<boolean>(true);
  const [nowActiveResizingBlockName, setNowActiveResizingBlockName] = useState<string | null>(null);
  const [pdfDimensions, setPdfDimensions] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0
  });
  const [qrStyles, setQRStyles] = useState<ApiQRStyles>({
    logoURL: null,
    outerEyeColor: [0, 0, 0],
    innerEyeColor: [0, 0, 0],
    mainColor: [0, 0, 0],
    bgColor: [255, 255, 255],
    eyesStyle: 'square',
    style: 'square'
  });
  const [textStyles, setTextStyles] = useState<ApiQRTextStyles>({
    color: [0, 0, 0],
    fontsize: 14
  });
  const menuRef = useRef<HTMLDivElement | null>(null);
  const scaleIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const workingAreaRef = useRef<HTMLDivElement | null>(null);
  const formWrapperRef = useRef<HTMLDivElement | null>(null);
  const postalModalFormRef = useRef<HTMLFormElement | null>(null);
  const formikValuesRef = useRef<PosterModalFormValues | null>(null);
  const [maxAndMinScale, setMaxAndMinScale] = useState<{
    max: number;
    min: number;
  }>({
    max: 1.75,
    min: 0.1
  });
  const formik = useFormik<PosterModalFormValues>({
    initialValues: {
      name: '',
      poster: null,
      qrSettings: {
        x: 0,
        y: 0,
        w: 250,
        h: 250,
        invisibleQR: false,
        bordersColor: '#000'
      },
      textSettings: {
        withText: false,
        x: 0,
        y: 0,
        w: 200,
        h: 50
      }
    },
    onSubmit: async values => {
      if (values) {
        if (pdfDimensions && qrStyles && textStyles) {
          try {
            setIsLoading(true);
            formik.setSubmitting(true);
            const preparedDataToPost = await prepareDataToPost(values, pdfDimensions, qrStyles, textStyles).then(res => res);
            const response = await companyPostersApi.createPosterTemplate(preparedDataToPost, +companyId!);
            if (response.statusCode >= 200 && response.statusCode < 300) {
              onPosterCreated(response.data);
              onClose();
              toast.success('Постер успішно створено');
              setIsLoading(false);
            } else {
              throw new Error('Не вдалося створити постер');
            }
          } catch (e) {
            toast.error('Виникла помилка при збереженні постеру');
          } finally {
            formik.setSubmitting(false);
            setIsLoading(false);
          }
        }
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required')
    })
  });
  const handleMouseDown = (isIncreasing: boolean) => {
    if (scaleIntervalRef.current) return;
    scaleIntervalRef.current = setInterval(() => {
      setScale(prevScale => {
        const newScale = isIncreasing ? prevScale + 0.01 : prevScale - 0.01;
        return Math.min(Math.max(newScale, maxAndMinScale.min), maxAndMinScale.max);
      });
    }, 10);
  };
  const handleMouseUpOrLeave = () => {
    if (scaleIntervalRef.current) {
      clearInterval(scaleIntervalRef.current);
      scaleIntervalRef.current = null;
    }
  };
  const onDocumentLoadSuccess = (document: DocumentCallback) => {
    document.getPage(1).then(page => {
      const viewport = page.getViewport({
        scale: 1
      });
      if (viewport.height > window.innerHeight || viewport.width > window.innerWidth) {
        setMaxAndMinScale({
          min: 0.1,
          max: 1.75
        });
      }
      setPdfDimensions({
        width: viewport.width,
        height: viewport.height
      });
    });
  };
  const handleWheel = (event: React.WheelEvent) => {
    if (event.altKey) {
      event.preventDefault();
      setScale(prevScale => {
        const newScale = prevScale - event.deltaY * 0.001;
        return Math.min(Math.max(newScale, maxAndMinScale.min), maxAndMinScale.max);
      });
    }
  };
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const companySettings = await companySettingsApi.getCompanySettings(+companyId!).then(res => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            return res.data;
          }
        });
        if (companySettings) {
          const qrStyles = companySettings['poster-qr-default-styles'];
          const textStyles = companySettings['poster-text-default-styles'];
          if (qrStyles && textStyles) {
            setQRStyles(qrStyles);
            setTextStyles(textStyles);
          } else {
            throw new Error('Виникла помилка при завантаженні даних');
          }
        }
      } catch (e) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (pdfDimensions.height > 0 && formikValuesRef.current) {
      const formikValues = formikValuesRef.current as PosterModalFormValues;
      formik.setFieldValue('qrSettings.y', pdfDimensions.height - formikValues.qrSettings.y - formikValues.qrSettings.h);
      formik.setFieldValue('textSettings.y', pdfDimensions.height - formikValues.textSettings.y - formikValues.textSettings.h);
    }
  }, [pdfDimensions]);
  useEffect(() => {
    if (formWrapperRef.current && workingAreaRef.current) {
      const formWrapper = formWrapperRef.current;
      requestAnimationFrame(() => {
        const offsetTop = (formWrapper.scrollHeight - formWrapper.clientHeight) / 2;
        const offsetLeft = (formWrapper.scrollWidth - formWrapper.clientWidth) / 2;
        formWrapper.scrollTo({
          top: offsetTop,
          left: offsetLeft,
          behavior: 'auto'
        });
      });
    }
  }, [pdfDimensions]);
  useEffect(() => {
    const handleDocumentWheel = (event: WheelEvent) => {
      if (event.altKey) {
        event.preventDefault();
      }
    };
    document.addEventListener('wheel', handleDocumentWheel, {
      passive: false
    });
    return () => {
      document.removeEventListener('wheel', handleDocumentWheel);
    };
  }, []);
  return <PosterModalStyles propsScale={scale} qrBorderColor={formik.values.qrSettings.bordersColor}>
      <FormikProvider value={formik}>
        {isLoading ? <Loader /> : formik.values.poster ? <>
            <div className="formWrapper" ref={formWrapperRef} id="formWrapper" onWheel={handleWheel}>
              <form ref={postalModalFormRef} id="posterModalForm" style={pdfDimensions ? {
            width: pdfDimensions.width * (maxAndMinScale.max + 1),
            height: pdfDimensions.height * (maxAndMinScale.max + 1)
          } : {}} onSubmit={formik.handleSubmit}>
                <div ref={workingAreaRef} className="workingArea" id="workingArea">
                  <div className="pdfEditorWrapper" id="pdfEditorWrapper">
                    <div className="pdfEditorContent" id="pdfEditorContent">
                      <Document file={formik.values.poster} renderMode="canvas" onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={1} />
                      </Document>

                      <Draggable bounds="parent" scale={scale} position={{
                    x: formik.values.qrSettings.x,
                    y: -formik.values.qrSettings.y
                  }} onStop={(e, data) => {
                    formik.setFieldValue('qrSettings.x', Math.round(data.x));
                    formik.setFieldValue('qrSettings.y', Math.round(Math.abs(data.y)));
                  }} disabled={nowActiveResizingBlockName === 'qr'} cancel=".react-resizable-handle">
                        <ResizableBox className={`qrResizable ${nowActiveResizingBlockName === 'qr' || formik.values.qrSettings.invisibleQR ? 'dashedBorder' : ''}`} width={formik.values.qrSettings.w} height={formik.values.qrSettings.h} lockAspectRatio minConstraints={[50, 50]} maxConstraints={pdfDimensions.width > pdfDimensions.height ? [pdfDimensions.height, pdfDimensions.height] : [pdfDimensions.width, pdfDimensions.width]} resizeHandles={['sw', 'nw', 'se', 'ne']} onResize={(e, data) => onResize(e, data, formik.values, formik.setFieldValue, 'qrSettings')} onResizeStart={() => {
                      setQRVisible(false);
                      setNowActiveResizingBlockName('qr');
                    }} transformScale={scale} onResizeStop={() => {
                      setQRVisible(true);
                      setNowActiveResizingBlockName(null);
                    }}>
                          <div className="qrWrapper" style={{
                        cursor: 'grab',
                        opacity: qrVisible && !formik.values.qrSettings.invisibleQR ? '1' : '0',
                        transition: '.1s ease opacity'
                      }}>
                            {qrStyles && <QRCodePreview values={qrStyles} width={formik.values.qrSettings.w} height={formik.values.qrSettings.h} />}
                          </div>
                        </ResizableBox>
                      </Draggable>

                      {formik.values.textSettings.withText && <Draggable bounds="parent" scale={scale} position={{
                    x: formik.values.textSettings.x,
                    y: -formik.values.textSettings.y
                  }} onStop={(e, data) => {
                    formik.setFieldValue('textSettings.x', Math.round(data.x));
                    formik.setFieldValue('textSettings.y', Math.round(Math.abs(data.y)));
                  }} disabled={nowActiveResizingBlockName === 'text'} cancel=".react-resizable-handle">
                          <ResizableBox className="dashedBorder textResizable" width={formik.values.textSettings.w} height={formik.values.textSettings.h} minConstraints={[50, 20]} maxConstraints={[pdfDimensions.width, pdfDimensions.height]} resizeHandles={['s', 'e', 'w', 'n']} onResize={(e, data) => onResize(e, data, formik.values, formik.setFieldValue, 'textSettings')} onResizeStart={() => {
                      setNowActiveResizingBlockName('text');
                    }} transformScale={scale} onResizeStop={() => {
                      setNowActiveResizingBlockName(null);
                    }}>
                            <span style={{
                        cursor: 'grab',
                        fontSize: fakeTextStyles.fontsize,
                        color: `rgb(${fakeTextStyles.color[0]}, ${fakeTextStyles.color[1]}, ${fakeTextStyles.color[2]})`
                      }}>Ваш текст</span>
                          </ResizableBox>
                        </Draggable>}
                    </div>
                  </div>

                </div>
              </form>
            </div>

            <button type="button" onClick={onClose} className="closeButton">
              <Close />
            </button>

            <div className="changePDFEditorWrapperScaleButtonsWrapper">
              <button type="button" className="increaseScaleButton" onMouseDown={() => handleMouseDown(true)} onMouseUp={handleMouseUpOrLeave} onMouseLeave={handleMouseUpOrLeave}>
                <JustPlusIcon height={20} width={20} color="#000" />
              </button>

              <button type="button" className="decreaseScaleButton" onMouseDown={() => handleMouseDown(false)} onMouseUp={handleMouseUpOrLeave} onMouseLeave={handleMouseUpOrLeave}>
                <QuantityMinusIcon width={20} color="#000" />
              </button>
            </div>

            <div ref={menuRef} className={`menu${menuOpen ? ' open' : ''}`}>
              <button type="button" className="menuOpenerButton" onClick={() => setMenuOpen(!menuOpen)}>
                <BsArrowUp size="20px" />
              </button>

              <div className="menuContent">
                <div className="menuFormWrapper">
                  <div className="qrSettingsFieldsWrapper">
                    <div className="inputWrapper">
                      <span className="label">Назва постеру</span>

                      <InputField value={formik.values.name} name="name" onChange={formik.setFieldValue} placeholder="" />
                    </div>

                    <div className="inputWrapper">
                      <span className="label">Розмір QR-коду</span>

                      <InputField value={formik.values.qrSettings.w} name="qrSettings.w" onChange={(_, value) => {
                    formik.setFieldValue('qrSettings.w', +value >= minQRCodeSize ? +value : minQRCodeSize);
                    formik.setFieldValue('qrSettings.h', +value >= minQRCodeSize ? +value : minQRCodeSize);
                  }} type="number" placeholder="" min={50} />
                    </div>

                    <div className="inputWrapper">
                      <span className="label">Позиція QR-коду по осі X</span>

                      <InputField value={formik.values.qrSettings.x} name="qrSettings.x" onChange={formik.setFieldValue} type="number" placeholder="" min={0} />
                    </div>

                    <div className="inputWrapper">
                      <span className="label">Позиція QR-коду по осі Y</span>

                      <InputField value={formik.values.qrSettings.y} name="qrSettings.y" onChange={formik.setFieldValue} type="number" placeholder="" label="123" min={0} />
                    </div>

                    <div className="inputWrapper">
                      <Checkbox name="qrSettings.invisibleQR" value={formik.values.qrSettings.invisibleQR} onChange={() => formik.setFieldValue('qrSettings.invisibleQR', !formik.values.qrSettings.invisibleQR)}>
                        Зробити QR прозорим для зручності розміщення
                      </Checkbox>
                    </div>

                    <div className="inputWrapper">
                      <Checkbox name="qrSettings.bordersColor" value={formik.values.qrSettings.bordersColor === '#000'} onChange={() => formik.setFieldValue('qrSettings.bordersColor', formik.values.qrSettings.bordersColor === '#000' ? '#FFF' : '#000')}>
                        Колір меж
                        <br />
                        Білий / Чорний
                      </Checkbox>
                    </div>
                  </div>

                  <div className="splitter" />

                  <div className="textSettingsWrapper">
                    <div className="inputWrapper">
                      <Checkbox name="textSettings.withText" value={formik.values.textSettings.withText} onChange={formik.handleChange}>
                        З текстом
                      </Checkbox>
                    </div>

                    <div className="inputWrapper">
                      <span className="label">Позиція тексту по осі X</span>

                      <InputField value={formik.values.textSettings.x} name="textSettings.x" onChange={formik.setFieldValue} type="number" placeholder="" min={0} disabled={!formik.values.textSettings.withText} />
                    </div>

                    <div className="inputWrapper">
                      <span className="label">Позиція тексту по осі Y</span>

                      <InputField value={formik.values.textSettings.y} name="textSettings.y" onChange={formik.setFieldValue} type="number" placeholder="" label="123" min={0} disabled={!formik.values.textSettings.withText} />
                    </div>
                  </div>
                </div>

                <div className="menuButtons">
                  <UploadFileButton extraButtonStyles={{
                minHeight: '50px'
              }} formats=".pdf" onChange={file => {
                if (file) {
                  formik.resetForm();
                  formik.setFieldValue('poster', file);
                }
              }} name="Завантажити новий макет" />

                  <SubmitButton onClick={() => formik.handleSubmit()} isLoading={formik.isSubmitting}>Зберегти</SubmitButton>
                </div>
              </div>
            </div>
          </> : <div className="noPDFText">
            <span>Для початку роботи завантажте постер</span>

            <UploadFileButton name="Завантажити PDF макет постеру" formats=".pdf" onChange={file => file ? formik.setFieldValue('poster', file) : null} />
          </div>}
      </FormikProvider>
    </PosterModalStyles>;
}
export default PosterModal;