import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { AnswersFilterStyles } from './AnswersFilterStyles';
import { translations } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import CustomSelectWithMultipleCheckboxes from '../../../form/customSelect/CustomSelectWithMultipleCheckboxes';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { extraOneOptionStyles, resetFilterButtonStyles } from '../reviewsTableFiltersUtils';
import { Loader, TransparentButton } from '../../../atoms';
import { TAnswers, TAnswersFilter, TAnswersFilterValues } from './TAnswersFilter';
import { TQuestion, TQuestionFilterValues } from '../scoreFilter/TScoreFilter';
import { fieldsApi } from '../../../../api';
import { useLocation } from 'react-router-dom';
import { LazyCollapsible } from '../../../atoms/lazyCollapsible';
import { AnswearFilterItem } from '../../answearFilter';
// todo possible to get initial score from parent (0-5 or 0-10)
// const initialValues: TAnswersFilterValues = {
//   scores: new Array(11).fill(1).map((item, index) => ({
//     value: false,
//     name: index.toString(),
//   })),
// };

const mapAnswers = (questions: TQuestion[]) => {
  const questionAnswers: {
    [key: number]: any[];
  } = {};
  questions?.forEach(question => {
    const selectedAnswers = question.answers?.filter(answer => !!answer.value).map(e => e.id);
    if (selectedAnswers?.length) {
      questionAnswers[question.id] = selectedAnswers;
    }
  });
  return questionAnswers;
};
export const AnswersFilter = React.memo(({
  // initialValues,
  filterParams,
  setReQuery,
  setApply,
  setFilterParams,
  onClose,
  filterQuestions,
  resetFilterQuestions
}: TAnswersFilter) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const code = search.get('companyID');
  const [questions, setQuestions] = useState<TQuestionFilterValues>();
  // useMemo
  // const persistedValues = useMemo(
  //   () => ({
  //     ...questions,
  //     questions: [],
  //     // questions: questions?.questions?.map((question) => ({
  //     //   // persist previos filter params
  //     //   ...question,
  //     //   answers: filterParams?.questions?.includes(+question.name),
  //     // })),
  //   }),
  //   [],
  // );

  useEffect(() => {
    fieldsApi.getCompanyFields(+(code ?? 0)).then(fields => {
      setQuestions(() => ({
        questions: fields.data.map(e => ({
          id: e.id ?? 1,
          value: false,
          name: e.question,
          answers: [],
          question: e.question
        }))
      }));
    });
  }, []);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const ref = useRef<any>();
  const formRef = useRef<FormikProps<TQuestionFilterValues>>(null);
  async function onSubmit(values: TQuestionFilterValues, {
    setSubmitting
  }: FormikHelpers<TQuestionFilterValues>) {
    const params: {
      [key: string]: any;
    } = {};
    // const checkedAnswers: Array<any> = values?.questions?.filter((item) => item.answers?.filter((e) => e.value)) ?? [];
    // console.log(checkedAnswers);
    params.questions = values.questions;
    const resultParams: {
      [key: string]: any;
    } = {};
    Object.entries({
      ...filterParams,
      ...params
    }).forEach(item => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });
    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();
    setSubmitting(false);
  }
  const additionalResetHandlers = () => {
    if (filterParams.questions) {
      delete filterParams.questions;
    }
    resetFilterQuestions();
    setFilterParams({
      ...filterParams
    });
    setApply({
      ...filterParams
    });
    setReQuery();
    onClose();
  };
  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
    resetForm
  }: FormikProps<TQuestionFilterValues>) => <Form>
        <div>
          <SubmitButton extraButtonStyles={{
        width: '100%',
        maxWidth: '500px',
        height: 32,
        fontSize: 16,
        fontWeight: 500
      }}>
            {translations[interfaceLanguage].apply_filters_button}
          </SubmitButton>
          <TransparentButton handleClick={() => {
        resetForm();
        additionalResetHandlers();
      }} text={translations[interfaceLanguage].reset_reviews_filter} extraStyles={resetFilterButtonStyles} extraButtonTextStyles={{
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 500
      }} />
          {questions?.questions?.map((e, index) => {
        const onChange = (e: any) => {
          console.log(e);
          handleChange(e);
        };
        return <AnswearFilterItem index={index} id={e.id} question={e} onChange={onChange} values={values} filterQuestions={mapAnswers(filterQuestions)} setQuestionsAnswer={(id, answers) => {
          setQuestions(e => ({
            ...e,
            questions: values?.questions?.map(question => question.id === id ? {
              ...question,
              answers
            } : question)
          }));
        }} />;
      })}
        </div>
      </Form>;
  return <AnswersFilterStyles ref={ref}>
        {questions ? <Formik enableReinitialize innerRef={formRef} initialValues={questions} onSubmit={onSubmit}>
            {renderForm}
          </Formik> : <Loader margin={0} />}
      </AnswersFilterStyles>;
});