/* eslint-disable class-methods-use-this */
import { omit } from 'lodash';
import {
  ICompanyChatBot,
  IContact,
  IOneReviewForm,
  IReviewDiscussionMessage,
  IReviewForm,
  IReviewMessagesHistoryData,
  IReviewType,
  TApiFilter,
} from '../../entities';
import { FormMessageValues } from '../../pages/reviews/review/utilt';
import {
  formatMessageAllHistoryData,
  formatMessageHistoryData,
} from '../../utils';
import {
  mapApiReviewTypeToIReviewType,
  mapFromApiReviewFormToIOneReviewForm,
  mapFromApiReviewFormsToIOneReviewForms,
  mapFromApiReviewFormsToIReviewForms,
} from '../Mappers';
import baseApi from '../baseApi';
import { ApiAudit, ApiResponse } from '../entities';
import IReviewApi from './IReviewApi';

class ReviewApi implements IReviewApi {
  async getReviewDiscussionHistory(
    id: number,
  ): Promise<ApiResponse<IReviewMessagesHistoryData>> {
    const { statusCode, data } = await baseApi.getData(`/reviews/${id}/chat`);

    return {
      data: formatMessageHistoryData(data),
      statusCode,
    };
  }

  async getReviewAllDiscussionHistory(
    id: number,
  ): Promise<ApiResponse<IReviewMessagesHistoryData>> {
    const { statusCode, data } = await baseApi.getData(
      `/reviews/${id}/chat/history`,
    );

    return {
      data: formatMessageAllHistoryData(data, id),
      statusCode,
    };
  }

  public async createReviewTypeByCompany(
    id: number,
    name: string,
    color: string,
  ): Promise<ApiResponse<IReviewType>> {
    const { data, statusCode } = await baseApi.postData(`/reviews/${id}`);

    return {
      data: mapApiReviewTypeToIReviewType(data),
      statusCode,
    };
  }

  public async textToReview(
    id: number,
    message: FormMessageValues,
  ): Promise<ApiResponse<IReviewDiscussionMessage>> {
    return baseApi.postData(`/reviews/${id}/chat`, message);
  }

  public async getReviews(
    data: {
      form_id?: number;
      node_id?: number[];
      companyID?: number;
      status?: number[];
      source?: number[];
      from?: number;
      to?: number;
      contacts?: string[];
    },
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<IOneReviewForm[]>> {
    const partOfQueryData = omit(data, [
      'status',
      'source',
      'node_id',
      'contacts',
    ]);
    const statusesParameters = data?.status
      ? data?.status.map((id) => `status=${id}`).join('&')
      : '';
    const sourcesParameters = data?.source
      ? data?.source.map((id) => `source=${id}`).join('&')
      : '';
    const nodesParameters = data?.node_id
      ? data?.node_id.map((id) => `node_id=${id}`).join('&')
      : '';
    const contactsParameters = data?.contacts
      ? data?.contacts.map((key) => `contacts=${key}`).join('&')
      : '';
    const query = Object.entries(partOfQueryData)
      .map((item) => item.join('='))
      .join('&');
    const path = `/reviews?offset=${offset || 0}&limit=${limit || 10}${
      query ? `&${query}` : ''
    }`
      + `${statusesParameters ? `&${statusesParameters}` : ''}${
        sourcesParameters ? `&${sourcesParameters}` : ''
      }${nodesParameters ? `&${nodesParameters}` : ''}${
        contactsParameters ? `&${contactsParameters}` : ''
      }`;

    const res = await baseApi.getData(path);

    return Promise.resolve({
      data: mapFromApiReviewFormsToIOneReviewForms(res.data.data),
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async postDataToGetFilteredReviews(
    data: TApiFilter,
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<IReviewForm[]>> {
    const res = await baseApi.postData(
      `/reviews?offset=${offset || 0}&limit=${limit || 10}`,
      data,
    );

    return Promise.resolve({
      data: mapFromApiReviewFormsToIReviewForms(res.data.data),
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getContactsByReviewId(
    id: number,
  ): Promise<ApiResponse<IContact[]>> {
    const { data, statusCode } = await baseApi.getData(
      `/reviews/${id}/contacts`,
    );
    return {
      data,
      statusCode,
    };
  }

  public async updateReview(
    id: number,
    data: { status?: number; comment?: string },
  ): Promise<ApiResponse<IReviewForm>> {
    return baseApi.putData(`/reviews/${id}`, { ...data });
  }

  public async getCompanyChatBots(
    companyID: number,
  ): Promise<ApiResponse<ICompanyChatBot[]>> {
    return baseApi.getData(`/companies/${companyID}/chat-bots`);
  }

  public async deleteReview(reviewIDs: number[]): Promise<ApiResponse<any>> {
    return baseApi.deleteData('/reviews', { reviewIDs });
  }

  public async getReview(
    id: number | string,
  ): Promise<ApiResponse<IOneReviewForm>> {
    const { statusCode, data } = await baseApi.getData(`/reviews/${id}`);

    return Promise.resolve({
      data: mapFromApiReviewFormToIOneReviewForm(data),
      statusCode,
    });
  }

  public async getStatusesCount(
    userID: number,
  ): Promise<ApiResponse<{ count: number; status: number }[]>> {
    return baseApi.getData(`/counts/review/statuses?userID=${userID}`);
  }

  public async getReviewCount(
    companies: number[],
    statuses: number[],
  ): Promise<ApiResponse<number>> {
    const formattedCompanies = `companies=${companies.join(',')}`;
    const formattedStatuses = `statuses=${statuses.join(',')}`;
    return baseApi.getData(
      `/counts/review?${formattedCompanies}&${formattedStatuses}`,
    );
  }

  public async getReviewHistory(id: string): Promise<ApiResponse<ApiAudit[]>> {
    return baseApi.getData(`/audit/clients/${id}`);
  }
}

export const reviewApi = new ReviewApi();
