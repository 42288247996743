import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Link, useLocation, useNavigate, useParams, useRoutes, useSearchParams } from 'react-router-dom';
import { LoginStyles } from './styles';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { useAppDispatch, useAppSelector } from '../../state';
import { getTranslationByLangOrEng } from '../../i18n';
import { Helmet } from 'react-helmet-async';
import { getErrorMessage, getNodeNamesArray, getNodesDeepArray, handleKeyUp } from '../../utils';
import { DiiaAuthApi } from '../../api/diiaAuthApi';
import { Dropdown } from '../../components/molecules/dropdown';
import MultiSelectWithChildren from '../../components/form/customSelect/multiSelectWithChildren/MultiSelectWithChildren';
import { NodeFilterItem, NodeFilterValues } from '../../components/molecules/reviewsTableFilters/nodeFilter/additionalSettings';
import { DeepArrayItem } from '../../entities';
import { getAllSelectedNodeIds, getParentId, getPathForInitialList, getSelectedNames, handleSelectNode, markAllNodesWithAllSelectedChildren } from '../../components/molecules/reviewsTableFilters/nodeFilter/utils';
import { formApi } from '../../api';
import { accessRequest } from '../../state/thunk/accessesThunk';
import { useClickOutside } from '../../hooks';
import { DiiaArrowDownIcon } from '../../assets/icons/DiiaArrowDown';
import { InputField } from '../../components';
interface FormValues {
  nodes: any;
  position: string;
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const SelectNode = () => {
  const filterParams = {
    nodes: []
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useQuery();
  const {
    company,
    organization,
    userType
  } = useParams();
  const {
    data,
    status,
    statusCode
  } = useAppSelector(state => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [selectedCompany, setSelectedCompany] = useState<{
    id: number;
    value: string;
  } | undefined>();
  const [initialCompanyVariants, setInitialCompanyVariants] = useState([]);
  // const [value3, setValue3] = useState();

  // const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [loadingDataByCompany, setLoadingDataByCompany] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormValues>({
    nodes: [],
    position: ''
  });
  const ref = useRef<any>();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const nodesRef = useRef<DeepArrayItem[]>([]);
  const nodeNamesRef = useRef<NodeFilterItem[]>([]);
  const validationSchema = Yup.object({
    nodes: Yup.array().min(1, 'Оберіть хоча б один фронт-офіс'),
    position: Yup.string().required().min(5, 'Вкажіть вашу посаду')
  });
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        formRef.current?.submitForm();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  function getSelectedIds(items: DeepArrayItem[]): number[] {
    let selectedIds: number[] = [];
    for (const item of items) {
      if (item.selected) {
        selectedIds.push(item.id);
      }
      if (item.children && item.children.length > 0) {
        selectedIds = selectedIds.concat(getSelectedIds(item.children));
      }
    }
    return selectedIds;
  }
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    const params: {
      [key: string]: number | string | any;
    } = {};
    const selectedNodeIds: number[] = [];
    if (values.nodes) {
      getAllSelectedNodeIds(selectedNodeIds, values.nodes);
    }
    if (selectedNodeIds.length > 0) {
      params.nodes = selectedNodeIds;
    } else if (filterParams?.nodes && selectedNodeIds.length === 0) {
      // @ts-ignore
      delete filterParams.nodes;
    }
    const resultParams: {
      [key: string]: any;
    } = {};
    Object.entries({
      ...filterParams,
      ...params
    }).forEach(item => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null // @ts-ignore
      && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });
    dispatch(accessRequest({
      company: company!,
      organization: organization!,
      userType: userType!,
      position: values.position,
      // посада // посада
      companyID: selectedCompany?.id!,
      nodesIDs: getSelectedIds(values.nodes),
      userID: data?.id!
    }));
    navigate('/auth/thank_you_page');
  }
  const additionalResetHandlers = () => {
    if (filterParams.nodes) {
      // @ts-ignore
      delete filterParams.nodes;
    }
    // setFilterParams({ ...filterParams });
    // setApply({ ...filterParams });
    // setReQuery();
  };
  async function getNodesBySelectedCompany(companyId: number) {
    setLoadingDataByCompany(true);
    const nodes = await formApi.getCompanyNodesV2(companyId);
    // console.log(nodes);
    if (nodes.statusCode >= 200 && nodes.statusCode < 300) {
      const nodeNamesArray: {
        id: number;
        name: string;
        deep: number;
        parentId: number | undefined;
      }[] = [];
      getNodeNamesArray(nodeNamesArray, nodes.data || []);
      nodeNamesArray.map((item, index) => {
        if (item.deep > 0) {
          item.parentId = getParentId(nodeNamesArray, item.deep, index);
        }
        return item;
      });
      nodeNamesRef.current = nodeNamesArray.map(item => ({
        ...item,
        selected: false,
        hasSelectedChildren: false,
        allChildrenAreSelected: false
      }));
      nodesRef.current = getNodesDeepArray(nodeNamesArray);
    }
    // console.log(nodesRef.current);
    setLoadingDataByCompany(false);
  }
  function findItemById(items: DeepArrayItem[], id: number): DeepArrayItem | undefined {
    for (const item of items) {
      if (item.id === id) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const found = findItemById(item.children, id);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }
  function toggleItemSelectedById(items: DeepArrayItem[], id: number): DeepArrayItem[] {
    return items.map(item => {
      if (item.id === id) {
        return {
          ...item,
          selected: !item.selected // Toggle the selected field
        };
      }
      if (item.children && item.children.length > 0) {
        return {
          ...item,
          children: toggleItemSelectedById(item.children, id) // Recursively process children
        };
      }
      return item; // Return item unchanged if no matching id is found
    });
  }
  useEffect(() => {
    formApi.getCompanies().then(e => {
      // @ts-ignore
      setInitialCompanyVariants(e.data.map(e => ({
        ...e,
        value: e.name
      })));
    });
  }, []);
  useEffect(() => {
    if (selectedCompany?.id) {
      getNodesBySelectedCompany(selectedCompany?.id).then(res => {
        const nodesArray = filterParams?.nodes && filterParams.nodes.length > 0 // @ts-ignore
        ? nodeNamesRef.current?.map(node => filterParams?.nodes?.includes(node.id) ? {
          ...node,
          selected: true
        } : node) : nodeNamesRef.current;
        function findParentNode(array: NodeFilterItem[], item: NodeFilterItem) {
          const parentNodeIndex = array.findIndex(node => node.id === item.parentId);
          if (parentNodeIndex !== undefined) {
            array[parentNodeIndex].hasSelectedChildren = true;
            if (array[parentNodeIndex].deep !== 0 && !array[parentNodeIndex - 1].hasSelectedChildren) {
              findParentNode(array, array[parentNodeIndex]);
            }
          }
        }
        nodesArray.forEach(item => {
          if (item.selected && item.deep !== 0) {
            findParentNode(nodesArray, item);
          }
        });
        const deepNodes = getNodesDeepArray(nodesArray);
        markAllNodesWithAllSelectedChildren(deepNodes);
        // console.log(deepNodes);
        setInitialValues({
          nodes: deepNodes,
          position: ''
        });
      });
    }
  }, [selectedCompany]);
  const id = 'dropdown-menu-id-3h3hfds3h';
  const [isOpened, setIsOpened] = useState(false);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const closeMenu = (el: HTMLElement | null) => {
    setIsOpened(false);
    el?.classList.remove('opened');
    setTimeout(e => {
      el?.classList.remove('fade-in-out');
    }, 20);
  };
  const openMenu = (el: HTMLElement | null, elements: HTMLCollection) => {
    setIsOpened(true);
    // find or other dropdown menus and close them
    Array.from(elements).filter(e => e.id !== `dropdown-menu-${id}`).forEach(e => e.classList.remove('fade-in-out', 'opened'));
    // add class for fade animation (changes display from none to flex)
    el?.classList.add('fade-in-out');
    // runs animation
    setTimeout(e => {
      el?.classList.add('opened');
    }, 0);
  };
  const optionsListRef = useRef<HTMLDivElement>(null);
  const toggleMenu = (isOpen: boolean) => {
    // current dropdown element
    const el = document.getElementById(`dropdown-menu-${id}`);
    // all dropdown elements
    const elements = document.getElementsByClassName('dropdown-menu');
    if (!isOpen) {
      closeMenu(el);
    } else {
      openMenu(el, elements);
    }
  };
  const setNewValue = (val: any) => {
    if (isOpened) {
      // setValue(val);
      toggleMenu(false);
    }
  };
  useClickOutside(optionsListRef, () => {
    const el = document.getElementById(`dropdown-menu-${id}`);
    closeMenu(el);
  }, selectedValueFieldRef.current);
  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue
  }: FormikProps<FormValues>) => {
    const selectedNames: string[] = getSelectedNames(values.nodes);
    console.log(selectedNames);
    return <Form autoComplete="off">
        <Dropdown extraStyles={{
        marginBottom: 10
      }} id="2739h3rh973r2" placeholder="Суб’єкт моніторингу" value={selectedCompany} variants={initialCompanyVariants} setValue={val => {
        setSelectedCompany(val);
      }} />
        {selectedCompany?.id && <div className="selectorContainer">
            <div className="container" ref={selectedValueFieldRef} onClick={() => toggleMenu(!isOpened)}>
              <div className="placeholder">
                <p>Фронт-офіс</p>
              </div>
              <DiiaArrowDownIcon className={`icon ${isOpened ? 'opened' : ''}`} />
            </div>
            <div ref={optionsListRef} id={`dropdown-menu-${id}`} className="dropdown-menu">
              <MultiSelectWithChildren showLastElement name="nodes" options={values.nodes || []} selectKey="name" handleSelect={(value, path, filtered) => {
            setFieldValue('nodes', toggleItemSelectedById(values.nodes, value.id));
          }} search required
          // @ts-ignore
          selectError={errors.nodes}
          // hideError
          isLoading={loadingDataByCompany} disabled={!nodesRef.current.length} />
            </div>
          </div>}
        <div className="selectedNodesContainer">
          {selectedNames.map((e: any) => <div className="selectedNode">
              <p>{e}</p>
            </div>)}
        </div>
        <InputField name="position" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder="Посада"
      // autocomplete
      value={values.position} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
        <div className="row">
          <SubmitButton type="button" onClick={() => navigate(-1)} extraButtonStyles={{
          width: 220
        }} extraBlockStyles={{
          display: 'flex',
          alignSelf: 'center'
        }}>
            Назад
          </SubmitButton>
          <SubmitButton extraButtonStyles={{
          width: 220
        }} extraBlockStyles={{
          display: 'flex',
          alignSelf: 'center'
        }}>
            Підтвердити дані
          </SubmitButton>
        </div>
      </Form>;
  };
  return <LoginStyles>
      <Helmet>
        <title>Authorization Voicer</title>
      </Helmet>

      <div className="formWrapper">
        <div className="formContainer">
          <h2>Оберіть місце роботи</h2>
          <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {renderForm}
          </Formik>
        </div>
      </div>
    </LoginStyles>;
};