import React, { CSSProperties } from 'react';
import { SubmitButtonStyles } from './styles';
import { ErrorIcon } from '../../../assets';
interface SubmitButtonProps {
  extraBlockStyles?: CSSProperties;
  extraButtonStyles?: CSSProperties;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}
export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  extraBlockStyles,
  extraButtonStyles,
  onClick,
  isLoading,
  disabled
}) => <SubmitButtonStyles style={extraBlockStyles}>
    <button type="submit" style={extraButtonStyles} onClick={onClick} disabled={isLoading || disabled}>
      {children}
    </button>
  </SubmitButtonStyles>;