export type TAdditionalParams = {
  key: string;
  value: string;
};

export type TAnswer = {
  fieldID: number;
  id: number;
  text: string;
  transitTo: number;
};

export enum FieldType {
  Caption = 'caption',
  Ces = 'ces',
  Checkbox = 'checkbox',
  ConnectionChannel = 'connection_channel',
  Csat = 'csat',
  Date = 'date',
  DetailedResponse = 'detailed_response',
  Dropdown = 'dropdown',
  Email = 'email',
  FileUpload = 'file_upload',
  Hidden = 'hidden',
  Name = 'name',
  Nps = 'nps',
  Paragraph = 'paragraph',
  Phone = 'phone',
  Picture = 'picture',
  Quantity = 'quantity',
  RadioButton = 'radio',
  Scale = 'scale',
  Confirmation = 'confirmation',
  Button = 'button',
}

type FieldMap = {
  [FieldType.Caption]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Ces]: {
    max: number;
    min: number;
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Checkbox]: {
    answers: TAnswer[];
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.ConnectionChannel]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Csat]: {
    max: number;
    min: number;
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Date]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.DetailedResponse]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Dropdown]: {
    answers: TAnswer[];
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Email]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.FileUpload]: {
    contentTypes: string;
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Hidden]: {
    options: TAdditionalParams[];
    queryKey: string;
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Name]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Nps]: {
    max: number;
    min: number;
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Paragraph]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Phone]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Picture]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Quantity]: {
    max: number;
    min: number;
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.RadioButton]: {
    answers: TAnswer[];
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Scale]: {
    max: number;
    min: number;
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Confirmation]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
  [FieldType.Button]: {
    options: TAdditionalParams[];
    question: string;
    styles: TAdditionalParams[];
  };
};

export type TTypesField = {
  [K in keyof FieldMap]: { type: K } & FieldMap[K];
}[keyof FieldMap];

export interface ApiCompanyField {
  companyID: number;
  createdAt?: string;
  id?: number;
  min?: number;
  max?: number;
  type: string;
  updatedAt?: string;
  question: string;
  pageID?: number,
  position?: number,
  answers: TAnswer[];
  options: TAdditionalParams[];
  styles: TAdditionalParams[];
}

export interface ApiCreatedField {
  field: {
    [K in FieldType]?: {
      question: string;
      options: TAdditionalParams[];
      styles: TAdditionalParams[];
    } & FieldMap[K];
  };
}

interface ApiUpdatedFieldBase {
  question: string;
  options: TAdditionalParams[];
  styles: TAdditionalParams[];
}

export type ApiUpdatedField<K extends FieldType> = ApiUpdatedFieldBase & FieldMap[K];
