import React, { CSSProperties, useCallback, useRef } from 'react';
import { SquareCheckboxStyles } from './SquareCheckboxStyles';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage, CheckboxItem } from '../../atoms';
interface CheckboxProps {
  name: string;
  value: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  extraBlockStyles?: CSSProperties;
  checkboxWrapperStyles?: CSSProperties;
  customizedCheckboxContainerStyles?: CSSProperties;
  label?: string;
  disabled?: boolean;
  error?: string;
  showError?: boolean;
}
const SquareCheckbox: React.FC<CheckboxProps> = ({
  name,
  value,
  onChange,
  extraBlockStyles,
  checkboxWrapperStyles,
  customizedCheckboxContainerStyles,
  label,
  disabled,
  error,
  showError,
  children
}) => {
  const {
    touched
  } = useFormikContext();
  const inputRef = useRef(null);
  const handleOnClick = useCallback(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
      // @ts-ignore
      inputRef.current.select();
    }
  }, [inputRef]);
  return <SquareCheckboxStyles style={extraBlockStyles}>
      <label htmlFor={name}>
        {label && <span>{label}</span>}
        <input className="checkboxDefault" type="checkbox" id={name} checked={value} ref={inputRef} onChange={onChange} disabled={disabled} />
        <div className="checkboxWrapper" style={checkboxWrapperStyles}>
          <div style={customizedCheckboxContainerStyles}>
            <CheckboxItem selected={value} handleOnClick={handleOnClick} hasLabel={!!label} disabled={disabled} />
          </div>
          {children && <div className="checkboxText">{children}</div>}
        </div>
        {showError && <div className="formErrorContainer">
            {error && <span>{error}</span>}
            {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
          </div>}
      </label>
    </SquareCheckboxStyles>;
};
export default SquareCheckbox;