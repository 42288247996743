import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const TelegramIcon: FC<TIcon> = React.memo(({
  width = 496,
  height = 512,
  color = '#3C9CE7'
}) => <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_307_73)">
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill={color} />
        <path d="M10.8037 5.35865C10.7604 5.72671 10.7063 6.18138 10.6305 6.63605C10.4898 7.52374 10.349 8.41142 10.1975 9.29911C10.1217 9.73213 10.0459 10.176 9.94848 10.609C9.8294 11.1503 9.50464 11.3018 9.0175 11.0204C8.64943 10.8038 8.30302 10.5549 7.9566 10.3167C7.58854 10.0677 7.22047 9.81873 6.85241 9.56974C6.75498 9.50479 6.64672 9.42901 6.57095 9.33158C6.45187 9.19085 6.45187 9.07177 6.53847 8.92022C6.5926 8.82279 6.66837 8.74701 6.75498 8.67123C7.32873 8.12996 7.89165 7.58869 8.4654 7.04742C8.5953 6.92834 8.71438 6.79843 8.83346 6.67935C8.88759 6.6144 8.97419 6.54945 8.89842 6.45202C8.82264 6.36541 8.73603 6.41954 8.64943 6.47367C8.40044 6.63605 8.15146 6.78761 7.9133 6.94999C7.188 7.43713 6.46269 7.92428 5.73739 8.41142C5.49923 8.5738 5.23942 8.68206 4.94713 8.67123C4.79557 8.67123 4.64402 8.62793 4.50329 8.58463C4.13522 8.4872 3.77798 8.37895 3.42074 8.23821C3.34496 8.20574 3.26919 8.18409 3.22588 8.11913C3.12846 7.98923 3.17176 7.83767 3.31249 7.74024C3.50735 7.61034 3.72386 7.53456 3.92954 7.43713C4.34091 7.2531 4.75227 7.09072 5.16364 6.91751C6.33279 6.41954 7.49111 5.91074 8.67108 5.43443C9.13658 5.25039 9.59124 5.05554 10.0676 4.90398C10.1433 4.88233 10.2299 4.86068 10.3057 4.84985C10.6413 4.7849 10.8145 4.93645 10.8145 5.36947L10.8037 5.35865Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_307_73">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>);