/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import {
  ApiCreateNotifiableSource,
  ApiNotifiableSource,
  ApiResponse,
} from '../entities';
import INotifiableSourcesApi from './INotifiableSourcesApi';

class NotifiableSourcesApi implements INotifiableSourcesApi {
  public async getNotifiableSource(
    id: number,
  ): Promise<ApiResponse<ApiNotifiableSource[]>> {
    return baseApi.getData(`/users/${id}/notifiable-sources`);
  }

  public async postNotifiableSource(
    id: number,
    sourceId: number,
    data: ApiCreateNotifiableSource,
  ): Promise<ApiResponse<ApiNotifiableSource[]>> {
    return baseApi.postData(
      `/users/${id}/notifiable-sources/${sourceId}`,
      data,
    );
  }

  public async deleteNotifiableSource(
    id: number,
    sourceId: number,
  ): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/users/${id}/notifiable-sources/${sourceId}`);
  }
}

export const notifiableSourcesApi = new NotifiableSourcesApi();
