import React, { Dispatch, SetStateAction } from 'react';
import { LocationSelectNodeItemStyles } from './styles';
import { DeepArrayItem } from '../../../../entities';
import { ArrowDownIcon } from '../../../../assets';
import { CheckboxItem } from '../../../atoms';
import { ApiAttachToNodes, constructorApi } from '../../../../api';
import { toast } from 'react-toastify';
interface SelectItemProps {
  path: number[];
  option: DeepArrayItem;
  selectKey: string;
  inputName: string;
  setOpen: (open: boolean) => void;
  expandable?: boolean;
  handleExpandRow?: (id: number) => void;
  expandedRows?: {
    [key: number]: boolean;
  };
  childrenKey: string;
  selectable?: boolean;
  checkboxSelected?: boolean;
  checkboxHasSelectedChildren?: boolean;
  checkboxAllChildrenSelected?: boolean;
  reQuery: boolean;
  setReQuery: Dispatch<SetStateAction<boolean>>;
  id: number;
}
export const LocationSelectItem = ({
  path,
  option,
  selectKey,
  inputName,
  expandable,
  handleExpandRow,
  expandedRows,
  childrenKey,
  setOpen,
  selectable,
  checkboxSelected,
  checkboxHasSelectedChildren,
  checkboxAllChildrenSelected,
  reQuery,
  setReQuery,
  id
}: SelectItemProps) => {
  const handleAttach = (nodeID: number) => {
    const reqBody: ApiAttachToNodes = {
      nodeIDs: [nodeID],
      enableOverdue: true,
      isActive: true
    };
    constructorApi.attachToNodes(id, reqBody).then(res => {
      if (res.statusCode === 201) {
        setReQuery(!reQuery);
        toast.success(`Додано до локації вітку: ${nodeID}`, {
          autoClose: 10
        });
      }
    });
  };
  const handleDetach = (nodeID: number) => {
    constructorApi.detachForm(nodeID, id).then(res => {
      if (res.statusCode === 200) {
        setReQuery(!reQuery);
        toast.success(`Відкріплено вітку: ${nodeID}`, {
          autoClose: 100
        });
      }
    });
  };
  return <LocationSelectNodeItemStyles key={`selectOption:${option.id}:${option.name}`} selectable={!!selectable}>
      <div className="rowItem">
        <input autoComplete="off" type="radio" name={inputName} value={option.name} id={`${option.name}:${option.id}`} />
        <div className="labelContent" style={{
        paddingLeft: 10 + option.deep * 10,
        textAlign: 'left'
      }}>
          {selectable && <button onClick={() => {
          if (checkboxSelected || checkboxHasSelectedChildren || checkboxAllChildrenSelected) {
            handleDetach(option.id);
          } else {
            handleAttach(option.id);
          }
        }} type="button" style={{
          marginRight: 8
        }}>
              <CheckboxItem selected={!!checkboxSelected} hasSelectedChildren={checkboxHasSelectedChildren} allChildrenAreSelected={checkboxAllChildrenSelected} handleOnClick={() => null} />
            </button>}

          <button style={{
          textAlign: 'left'
        }} onClick={() => handleExpandRow && handleExpandRow(option.id)} type="button">
            {option.name}
          </button>
        </div>
        {expandable && option.children.length > 0 && <button className={`expandButton ${expandedRows![option.id] ? 'expandable' : ''}`} type="button" onClick={() => handleExpandRow && handleExpandRow(option.id)}>
            <ArrowDownIcon />
          </button>}
      </div>

      {expandable && expandedRows![option.id] && !!childrenKey
    // @ts-ignore
    && option[childrenKey]?.map((subItem: DeepArrayItem, index) => <LocationSelectItem id={id} setReQuery={setReQuery} reQuery={reQuery} key={`itemKey:${[index]}`} path={[...path, index]} option={subItem} selectKey={selectKey} childrenKey={childrenKey} inputName={`${inputName}:${subItem.id}`} expandedRows={expandedRows} expandable={expandable && subItem.children.length > 0} handleExpandRow={handleExpandRow} setOpen={setOpen} selectable={selectable} checkboxSelected={selectable ? subItem.selected : undefined} checkboxHasSelectedChildren={selectable ? subItem.hasSelectedChildren : undefined} checkboxAllChildrenSelected={selectable ? subItem.allChildrenAreSelected : undefined} />)}
    </LocationSelectNodeItemStyles>;
};