import styled from 'styled-components';

export const TextAreaAutoResizeStyles = styled.div`
  textarea {
    resize: none;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 12px 16px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;

    &.withArrow {
      textArea {
        padding-right: 40px;
      }
    }

    button {
      transform: translate(-20px, 12px);

      &.active {
        transform: translate(-20px, 12px) rotate(180deg);
      }
    }
  }
`;
