import { FC } from 'react';
import { JustPlusIcon } from '../../../../assets';
import { TChip } from '../types';
import { useAppSelector } from '../../../../state';
export const Chip: FC<TChip> = ({
  tag,
  handleRemove
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <div className="chip">
      <div className="chipText">{tag.name}</div>
      <div className="chipDel" onClick={() => handleRemove(tag)}>
        <JustPlusIcon color="#999" width={20} height={20} />
      </div>
    </div>;
};