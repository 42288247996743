import styled from 'styled-components';

export const ResultSourcesStyles = styled.div`
  width: calc(100% - 30px);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 450px) {
    flex-direction: column;
    gap: 10px;
  }

  .notirow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
  }

  .sourceName {
    width: 20%;
  }

  input {
    outline: none;
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(229, 229, 229);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 16px;
    max-width: 150px;
  }
`;
