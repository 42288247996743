import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormContentStyles } from './styles';
import { ILanguage } from '../../../entities';
import { TLocalizations, TPage, languagesApi, localizationsApi } from '../../../api';
import { FormLocalization } from '../modal';
import { DeleteIcon } from '../../../assets';
import { FormsPages } from '../formPages';
import { toast } from 'react-toastify';
import { PageContent } from '../pageContent';
export const FormContent = () => {
  const {
    companyId,
    formId
  } = useParams();
  const [companyLangs, setCompanyLangs] = useState<ILanguage[]>([]);
  const [formLocalization, setFormLocalization] = useState<TLocalizations[]>([]);
  const [selectedLocalization, setSelectedLocalization] = useState<TLocalizations | null>(null);
  const [selectedPage, setSelectedPage] = useState<TPage | null>(null);
  const [langModal, setLangModal] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const handleRemoveLocalization = (id: number) => {
    localizationsApi.deleteLocalizations(id).then(res => {
      if (res.statusCode === 200) {
        setFormLocalization(prev => prev.filter(el => el.id !== id));
        toast.success('Локалізацію видалено');
      }
    });
  };
  useEffect(() => {
    if (companyId) {
      languagesApi.getCompanyLanguages(+companyId).then(res => {
        if (res.statusCode === 200) {
          setCompanyLangs(res.data);
        }
      });
    }
    if (formId) {
      localizationsApi.getFormLocalizations(+formId).then(res => {
        if (res.statusCode === 200) {
          setFormLocalization(res.data);
          if (res.data.length === 1) {
            setSelectedLocalization(res.data[0]);
          }
        }
      });
    }
  }, [formId, companyId, reQuery]);
  return <FormContentStyles>
      <div className="localization">
        <div className="header">
          <h6>Доступні локалізації:</h6>
        </div>
        <div className="localization-list">
          {formLocalization.map(el => <div key={el.id} className={`localization-item ${el.id === selectedLocalization?.id ? 'localization-item-selected' : ''}`} onClick={() => setSelectedLocalization(el)}>
              <p>{el.language.icon}</p>
              <h6>{el.language.name}</h6>

              <button type="button" className="localization-item-del" onClick={e => {
            e.stopPropagation();
            handleRemoveLocalization(el.id);
          }}>
                <DeleteIcon color="#C4C4C4" />
              </button>
            </div>)}
          {!(formLocalization.length === companyLangs.length) && <div onClick={() => setLangModal(true)} className="localization-add">Додати лоакалізацію</div>}
        </div>
      </div>
      {selectedLocalization && <FormsPages localization={selectedLocalization} setLocalization={setSelectedLocalization} setReQuery={setReQuery} setSelectedPage={setSelectedPage} selectedPage={selectedPage} reQuery={reQuery} />}
      {selectedLocalization && selectedPage && <PageContent localization={selectedLocalization} setLocalization={setSelectedLocalization} setReQuery={setReQuery} setSelectedPage={setSelectedPage} selectedPage={selectedPage} reQuery={reQuery} />}
      {langModal && formId && <FormLocalization onClose={() => setLangModal(false)} formId={+formId} companyLangs={companyLangs} formLocalization={formLocalization} setFormLocalization={setFormLocalization} />}
    </FormContentStyles>;
};