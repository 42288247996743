import styled from 'styled-components';
export const CopyRolesModalStyles = styled.div`
  .modalBody {
    background: #ffffff;

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .modalButtonsWrap {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: 14px;
        color: #000000;
      }
    }

    .modalError {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
      margin-bottom: 0;
    }
  }
`;