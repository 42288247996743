import { Dispatch, SetStateAction } from 'react';

export type TEditButtons = {
  haveChildren: boolean;
  nodeName: string;
  nodeId: number;
  companyId: number;
  companyName: string;
  handleDelete: (id: number) => void;
  setSelectedNode: Dispatch<SetStateAction<string>>;
  setEditModal: Dispatch<SetStateAction<boolean>>;
};

export enum StructTabKeys {
  structure = 'structure',
  company_users = 'company-users',
  company_forms = 'company-forms',
  company_fields = 'company-fields',
}

export type TTabStruct = {
  key: StructTabKeys;
  text: string;
};
