import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const PhoneIcon: FC<TIcon> = React.memo(({
  width = 16,
  height = 16,
  color = '#000'
}) => <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_307_70)">
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#0C0005" />
        <path d="M4.69048 4.30964C4.77143 4.25732 4.9619 4.1622 5.11905 4.10513C5.27619 4.04806 5.50476 4.0005 5.63333 4.0005C5.8 3.99575 5.89048 4.02428 5.99048 4.10513C6.0619 4.16696 6.34762 4.52841 6.62381 4.90413C6.9 5.27985 7.15238 5.6413 7.18571 5.70789C7.21905 5.77447 7.2381 5.88861 7.22381 5.96946C7.20476 6.04556 7.1 6.24055 6.98571 6.3975C6.87143 6.55444 6.72857 6.72566 6.67143 6.77797C6.61429 6.83029 6.56667 6.89212 6.57143 6.92065C6.57143 6.94919 6.70476 7.13943 6.87143 7.34869C7.04286 7.55795 7.47619 8.01928 7.84286 8.37122C8.20952 8.72316 8.62857 9.10363 9.04762 9.41752L9.39048 9.13217C9.58571 8.97046 9.82381 8.81827 9.92857 8.78974C10.0571 8.75169 10.1571 8.75169 10.2381 8.78023C10.3048 8.80876 10.6762 9.06083 11.0667 9.35094C11.4571 9.6363 11.8286 9.92641 11.8905 9.99775C11.9571 10.0834 12 10.1927 12 10.2974C12 10.3877 11.9619 10.5875 11.9143 10.7349C11.8667 10.8871 11.7762 11.1059 11.7048 11.2248C11.6381 11.3437 11.481 11.5292 11.3524 11.6433C11.2238 11.7574 11.0143 11.8811 10.881 11.9239C10.7476 11.9667 10.4857 12 10.2857 12C10.0619 12 9.77619 11.9572 9.52381 11.8859C9.3 11.8288 8.92381 11.6766 8.69048 11.5577C8.45714 11.4388 8.07143 11.2105 7.8381 11.0583C7.60476 10.9014 7.18571 10.578 6.90952 10.3402C6.63333 10.1024 6.25714 9.75044 6.07619 9.55544C5.89524 9.36045 5.62857 9.05607 5.47619 8.87059C5.32857 8.68511 5.07143 8.33317 4.90476 8.08586C4.7381 7.83855 4.50476 7.42954 4.39048 7.18223C4.27619 6.93492 4.1381 6.56871 4.09048 6.37372C4.0381 6.17873 4 5.86483 4 5.68411C4 5.49387 4.03333 5.2418 4.08095 5.09437C4.12857 4.94694 4.24762 4.74719 4.35714 4.62353C4.4619 4.50463 4.60952 4.36671 4.69048 4.30964Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_307_70">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>);