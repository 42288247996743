import React, { useCallback, useEffect, useState } from 'react';
import { CompanyQrStylesFormStyles } from './styles';
import { FormikProvider, useFormik } from 'formik';
import { ApiQRStyles, ApiQRTextStyles, AvailableQREyeStyle, AvailableQRStyle, companySettingsApi, filesApi } from '../../api';
import * as Yup from 'yup';
import QrStylesColorPicker from '../form/qrStylesColorPicker/QRStylesColorPicker';
import { CustomSelect, InputField } from '../molecules';
import { qrEyesStyleOptions, qrStyleOptions } from './utils';
import { UploadFileButton } from '../form/uploadFileButton/UploadFileButton';
import QRCodePreview from '../atoms/qrCodePreview/QRCodePreview';
import { Loader, SubmitButton, TransparentButton } from '../atoms';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
interface FormikValues {
  qrStylesSettings: Omit<ApiQRStyles, 'style' | 'eyesStyle'> & {
    style: {
      name: string;
      value: AvailableQRStyle;
    };
    eyesStyle: {
      name: string;
      value: AvailableQREyeStyle;
    };
  };
  textStyles: ApiQRTextStyles;
}
function CompanyQrStylesForm() {
  const {
    id: companyId
  } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialSettingsNotCreated, setInitialSettingsNotCreated] = useState<boolean>(false);
  const formik = useFormik<FormikValues>({
    initialValues: {
      qrStylesSettings: {
        style: qrStyleOptions[0],
        eyesStyle: qrEyesStyleOptions[0],
        logoURL: null,
        outerEyeColor: [0, 0, 0],
        innerEyeColor: [0, 0, 0],
        mainColor: [0, 0, 0],
        bgColor: [255, 255, 255]
      },
      textStyles: {
        fontsize: 14,
        color: [0, 0, 0]
      }
    },
    onSubmit: async values => {
      formik.setSubmitting(true);
      try {
        const qrStylesPreparedToSave: ApiQRStyles = {
          ...values.qrStylesSettings,
          style: values.qrStylesSettings.style.value,
          eyesStyle: values.qrStylesSettings.eyesStyle.value
        };
        const qrTextStylesPreparedToSave: ApiQRTextStyles = {
          color: values.textStyles.color,
          fontsize: values.textStyles.fontsize
        };
        if (initialSettingsNotCreated) {
          await companySettingsApi.createCompanySettings(+companyId!, {
            key: 'poster-qr-default-styles',
            value: qrStylesPreparedToSave
          });
          await companySettingsApi.createCompanySettings(+companyId!, {
            key: 'poster-text-default-styles',
            value: qrTextStylesPreparedToSave
          });
        } else {
          await companySettingsApi.updateCompanySettingsKey(+companyId!, 'poster-qr-default-styles', {
            value: qrStylesPreparedToSave
          });
          await companySettingsApi.updateCompanySettingsKey(+companyId!, 'poster-text-default-styles', {
            value: qrTextStylesPreparedToSave
          });
        }
        toast.success('Стилі успішно збережені');
      } catch (error) {
        toast.error('Виникла помилка при збереженні стилів');
      } finally {
        formik.setSubmitting(false);
      }
    },
    validationSchema: Yup.object().shape({
      textStyles: Yup.object().shape({
        fontsize: Yup.number().required('Обов\'язкове поле').moreThan(0, 'Розмір тексту повинен бути більшим 0')
      })
    })
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const companySettings = await companySettingsApi.getCompanySettings(+companyId!).then(res => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            return res.data;
          }
        });
        if (companySettings) {
          const qrStyles = companySettings['poster-qr-default-styles'];
          const textStyles = companySettings['poster-text-default-styles'];
          if (qrStyles && textStyles) {
            formik.setFieldValue('qrStylesSettings', {
              ...qrStyles,
              style: qrStyleOptions.find(option => qrStyles.style === option.value)!,
              eyesStyle: qrEyesStyleOptions.find(option => qrStyles.eyesStyle === option.value)!
            });
            formik.setFieldValue('textStyles', textStyles);
          } else {
            setInitialSettingsNotCreated(true);
          }
        } else {
          throw new Error('Виникла помилка при завантаженні налаштувань');
        }
      } catch (e) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  const uploadLogo = useCallback(async (file: File) => {
    try {
      await filesApi.createFile({
        file
      }).then(res => {
        formik.setFieldValue('qrStylesSettings.logoURL', res.data.url);
      });
    } catch (e) {
      toast.error('Виникла помилка при завантаженні логотипу');
    }
  }, []);
  return <CompanyQrStylesFormStyles>
      {isLoading ? <Loader /> : <>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="stylesSettingsFormsWrapper">
                <div className="qrSettings">
                  <h3>Стилі QR</h3>

                  <div className="formContentWrapper">
                    <div className="viewSettingsWrapper">
                      <div className="uploadLogoWrapper">
                        <span>Логотип</span>

                        <div className="uploadLogoWrapperButtonsWrapper">
                          <UploadFileButton name="Завантажити файл" formats=".png,.jpg" onChange={file => file ? uploadLogo(file) : null} />

                          {formik.values.qrStylesSettings.logoURL && <TransparentButton text="Видалити логотип" handleClick={() => {
                        formik.setFieldValue('qrStylesSettings.logoURL', null);
                      }} />}
                        </div>
                      </div>

                      <CustomSelect options={qrStyleOptions} selected={qrStyleOptions.find(qrStyleOption => qrStyleOption.value === formik.values.qrStylesSettings.style.value)} labelField="name" name="qrStylesSettings.style" onChange={value => {
                    formik.setFieldValue('qrStylesSettings.style', value);
                  }} placeholder="Оберіть стиль QR коду" label="Стиль QR коду" />

                      <CustomSelect options={qrEyesStyleOptions} selected={qrEyesStyleOptions.find(qrEyeStyleOption => qrEyeStyleOption.value === formik.values.qrStylesSettings.eyesStyle.value)} labelField="name" name="qrStylesSettings.eyesStyle" onChange={value => {
                    formik.setFieldValue('qrStylesSettings.eyesStyle', value);
                  }} placeholder="Оберіть стиль очей QR коду" label="Стиль очей QR коду" />
                    </div>

                    <div className="colorsSettingsWrapper">
                      <h3>Налаштування кольорів QR</h3>

                      <div className="colorsSettingsFieldsWrapper">
                        <QrStylesColorPicker name="qrStylesSettings.mainColor" label="Основний колір" value={formik.values.qrStylesSettings.mainColor} />

                        <QrStylesColorPicker name="qrStylesSettings.bgColor" label="Колір фону" value={formik.values.qrStylesSettings.bgColor} />

                        <QrStylesColorPicker name="qrStylesSettings.outerEyeColor" label="Зовнішній колір очей" value={formik.values.qrStylesSettings.outerEyeColor} />

                        <QrStylesColorPicker name="qrStylesSettings.innerEyeColor" label="Внутрішній колір очей" value={formik.values.qrStylesSettings.innerEyeColor} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="textSettings">
                  <h3 className="textStylesTitle">Стилі тексту</h3>

                  <div className="formContentWrapper">
                    <div className="viewSettingsWrapper">
                      <InputField value={formik.values.textStyles.fontsize} name="textStyles.fontsize" onChange={formik.setFieldValue} type="number" placeholder="Розмір тексту" min={1} />

                      <QrStylesColorPicker name="textStyles.color" label="Колір тексу" value={formik.values.textStyles.color} />
                    </div>
                  </div>
                </div>
              </div>

              <SubmitButton isLoading={formik.isSubmitting}>
                Зберегти зміни
              </SubmitButton>
            </form>
          </FormikProvider>

          <div className="previewWrapper">
            <QRCodePreview values={{
          style: formik.values.qrStylesSettings.style.value,
          eyesStyle: formik.values.qrStylesSettings.eyesStyle.value,
          bgColor: formik.values.qrStylesSettings.bgColor,
          innerEyeColor: formik.values.qrStylesSettings.innerEyeColor,
          outerEyeColor: formik.values.qrStylesSettings.outerEyeColor,
          mainColor: formik.values.qrStylesSettings.mainColor,
          logoURL: formik.values.qrStylesSettings.logoURL
        }} />

            <span style={{
          fontSize: `${formik.values.textStyles.fontsize}px`,
          color: `rgb(${formik.values.textStyles.color[0]}, ${formik.values.textStyles.color[1]}, ${formik.values.textStyles.color[2]})`
        }}>
              Тестовий текст: № локації 4355
            </span>
          </div>
        </>}
    </CompanyQrStylesFormStyles>;
}
export default CompanyQrStylesForm;