import React, { CSSProperties, useRef } from 'react';
import { UploadFileAreaStyles } from './styles';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { TransparentButton } from '../transparentButton';
interface UploadFileAreaProps {
  onChange: (file: File) => void;
  formats: string;
  name: string;
  fieldName?: string;
  disabled?: boolean;
}
export const UploadFileArea = ({
  onChange,
  formats,
  name,
  fieldName,
  disabled
}: UploadFileAreaProps) => {
  const fileInput = useRef<any>(null);
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    onChange(droppedFiles[0]);
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };
  return <UploadFileAreaStyles onDrop={handleDrop} onDragOver={handleDragOver} onClick={handleClick}>
      <h6><span>Оберіть фото на своєму носієві</span>  або перетягніть його сюди</h6>
      <p>Підтримуються формати: {formats}</p>
      <input name={fieldName} autoComplete="new" ref={fileInput} type="file" accept={formats} onChange={event => {
      if (event.currentTarget.files) {
        onChange(event.currentTarget.files[0]);
      }
    }} disabled={disabled} />
    </UploadFileAreaStyles>;
};