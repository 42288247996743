import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import * as React from 'react';
import { TIcon } from './TIcon';
const FileCompanyIcon_1_1 = _compiledBlock(_props => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" role="img" width={_props.v0} height={_props.v1}>
      <path fill={_props.v2} d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z" />
    </svg>, {
  name: "FileCompanyIcon_1_1",
  svg: true
});
const FileCompanyIcon_1 = ({
  width = 384,
  height = 512,
  color = '#000'
}) => /*@million jsx-skip*/<FileCompanyIcon_1_1 v0={width} v1={height} v2={color} />;
export const FileCompanyIcon: React.FC<TIcon> = React.memo(FileCompanyIcon_1);