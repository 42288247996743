import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILanguage } from '../../entities/ILanguage';

export interface LanguageState {
  languages: ILanguage[] | undefined;
  interfaceLanguage: string;
}

const initialState: LanguageState = {
  languages: undefined,
  interfaceLanguage: 'UA',
};

export const languageSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    // Reducer comes here
    setLanguages: (state, action: PayloadAction<ILanguage[] | undefined>) => ({
      ...state,
      languages: action.payload,
    }),
    setInterfaceLanguage: (state, action: PayloadAction<string>) => ({
      ...state,
      interfaceLanguage: action.payload,
    }),
  },
  extraReducers: (builder) => {},
});

export const { actions: languageActions, reducer: languageReducer } = languageSlice;
// Extract and export each action creator by name
export const { setLanguages, setInterfaceLanguage } = languageActions;
