/* eslint-disable class-methods-use-this */
import { mapImportDataToFormData } from '../Mappers';
import baseApi from '../baseApi';
import { ApiImportStatusData, ApiResponse } from '../entities';
import IImportApi from './IImportApi';

class ImportApi implements IImportApi {
  public async importNodes(data: {
    file: File;
    sheet: string;
    companyID: string;
    width?: number;
    height?: number;
  }): Promise<ApiResponse<any>> {
    const formDataFromImportData = mapImportDataToFormData(
      data.file,
      data.sheet,
      data.companyID,
    );
    return baseApi.postData('/imports/nodes', formDataFromImportData, true);
  }

  public async importUsers(data: {
    file: File;
    sheet: string;
    width?: number;
    height?: number;
  }): Promise<ApiResponse<any>> {
    const formDataFromImportData = mapImportDataToFormData(
      data.file,
      data.sheet,
      undefined,
    );
    return baseApi.postData('/imports/users', formDataFromImportData, true);
  }

  public async importReviews(data: {
    file: File;
    sheet: string;
    width?: number;
    height?: number;
  }): Promise<ApiResponse<any>> {
    const formDataFromImportData = mapImportDataToFormData(
      data.file,
      data.sheet,
      undefined,
    );
    return baseApi.postData('/imports/reviews', formDataFromImportData, true);
  }

  public async getCurrentImportStatus(): Promise<
    ApiResponse<ApiImportStatusData>
    > {
    return baseApi.getData('/imports');
  }

  public async stopCurrentImport(): Promise<ApiResponse<any>> {
    return baseApi.deleteData('/imports');
  }
}

export const importApi = new ImportApi();
