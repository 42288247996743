import React, { FC } from 'react';
import { useAppSelector } from '../../../../state';
import { EditNode } from '../../editNode';
import { Modal } from '../Modal';
import { EditNodeModalStyles } from './EditNodeModalStyles';
type TEditNodeModal = {
  onClose: () => void;
  companyId: number;
  nodeId: string;
};
export const EditNodeModal: FC<TEditNodeModal> = React.memo(({
  onClose,
  companyId,
  nodeId
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <EditNodeModalStyles>
        <Modal title="Редагувати вузол" onClose={onClose}>
          <EditNode onClose={onClose} companyId={companyId} nodeId={nodeId} />
        </Modal>
      </EditNodeModalStyles>;
});