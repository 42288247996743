/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import {
  ApiAddLocalization,
  ApiResponse,
  ApiUpdateLocalization,
  TLocalizations,
  TPage,
} from '../entities';
import ILocalizationsApi from './ILocalizationsApi';

class LocalizationsApi implements ILocalizationsApi {
  public async getFormLocalizations(id: number): Promise<ApiResponse<TLocalizations[]>> {
    return baseApi.getData(`/forms/${id}/localizations`);
  }

  public async getLocalizations(id: number): Promise<ApiResponse<TLocalizations>> {
    return baseApi.getData(`/localizations/${id}`);
  }

  public async getLocalizationPages(id: number): Promise<ApiResponse<TPage[]>> {
    return baseApi.getData(`/localizations/${id}/pages`);
  }

  public async deleteLocalizations(id: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/localizations/${id}`);
  }

  public async updateLocalizations(id: number, data: ApiUpdateLocalization): Promise<ApiResponse<TLocalizations>> {
    return baseApi.putData(`/localizations/${id}`, data);
  }

  public async setFormLocalizations(id: number, data: ApiAddLocalization): Promise<ApiResponse<TLocalizations>> {
    return baseApi.postData(`/forms/${id}/localizations`, data);
  }
}

export const localizationsApi = new LocalizationsApi();
