export enum TabKeys {
  all = 'all',
  forUser = 'forUser',
  fromUser = 'fromUser',
}

export type TTab = {
  key: TabKeys;
  text: string;
};

export const tabs: TTab[] = [
  {
    key: TabKeys.all,
    text: 'Всі завдання',
  },
  {
    key: TabKeys.forUser,
    text: 'Mої завдання',
  },
  {
    key: TabKeys.fromUser,
    text: 'Завдання делеговані мною',
  },
];
