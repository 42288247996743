/* eslint-disable class-methods-use-this */
import { IDocument } from '../../entities';
import { mapFromApiDocumentToIDocument } from '../Mappers';
import baseApi from '../baseApi';
import { ApiDocument, ApiResponse } from '../entities';
import IDocumentsApi from './IDocumentsApi';

class DocumentsApi implements IDocumentsApi {
  public async createDocument(
    data: Partial<ApiDocument>,
  ): Promise<ApiResponse<ApiDocument>> {
    return baseApi.postData('/documents', data);
  }

  public async getDocumentById(id: number): Promise<ApiResponse<IDocument>> {
    const document = await baseApi.getData(`/documents/${id}`);

    return {
      statusCode: document.statusCode,
      data: mapFromApiDocumentToIDocument(document.data),
    };
  }

  public async updateDocument(
    data: { name: string; url: string; description: string },
    id: number,
  ): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.putData(`/documents/${id}`, data);
  }

  public async deleteDocument(id: number): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.deleteData(`/documents/${id}`);

    return Promise.resolve({
      statusCode,
      data: null,
    });
  }
}

export const documentsApi = new DocumentsApi();
