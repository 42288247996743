import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { TFieldConstruct } from '../../companyFields';
import { ApiCompanyField, ApiUpdatedField, FieldType, TAdditionalParams, companyApi, fieldsApi } from '../../../../api';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { IFieldColor, IFieldFile, addAdditionalParam, createAnswersArray, mapApiColorToFieldColor, mapFieldColorToApiColor, reduceAdditionalColorParam, reduceAdditionalImageParam, reduceFieldArray } from '../../fieldConstructor';
import { SubmitButton } from '../../../atoms';
import { Checkbox, InputField, NumericScale } from '../../../molecules';
import { useParams } from 'react-router-dom';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import { DeleteIcon } from '../../../../assets';
import CustomColorsSelect from '../../../form/customSelect/CustomColorsSelect';
import SelectWithAnyItems from '../../../form/customSelect/SelectWithAnyItems';
import { mapIColorToString } from '../../../../mappers';
const defaultSignatureColor: IFieldColor = {
  name: 'Signature color',
  color: '#ccc'
};
const defaultNumbersColor: IFieldColor = {
  name: 'Numbers color',
  color: '#000'
};
const defaultSelectedButtonColor: IFieldColor = {
  name: 'Selected button color',
  color: '#eee'
};
type TNumericSettings = {
  field: TFieldConstruct;
  setFields: Dispatch<SetStateAction<ApiCompanyField[]>>;
  setField: Dispatch<SetStateAction<TFieldConstruct | null>>;
  changeMinMax?: boolean;
};
export interface INumericSettingsForm {
  change_background: boolean;
  change_mascot: boolean;
  show_default_value: boolean;
  default_value: number;
  backgroundColors: IFieldColor[];
  mascots: IFieldFile[];
  numbers_color: IFieldColor;
  signature_text_color: IFieldColor;
  selected_button_color: IFieldColor;
  min: number;
  max: number;
}
const initialValue: INumericSettingsForm = {
  change_background: false,
  change_mascot: false,
  show_default_value: false,
  default_value: 0,
  backgroundColors: [],
  mascots: [],
  numbers_color: defaultNumbersColor,
  signature_text_color: defaultSignatureColor,
  selected_button_color: defaultSelectedButtonColor,
  min: 0,
  max: 10
};
const validationSchema = Yup.object({
  min: Yup.number().test('min-less-than-max', 'Мінімальне значення не можу бути більше за максимальне', function (value) {
    const {
      max
    } = this.parent;
    return value === undefined || max === undefined || value <= max;
  })
});
export const NumericSettings: FC<TNumericSettings> = ({
  field,
  setFields,
  setField,
  changeMinMax = false
}) => {
  const {
    id
  } = useParams();
  const [initialValues, setInitialValues] = useState<INumericSettingsForm>();
  const [apiField, setApiField] = useState<ApiCompanyField | null>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [extraMainScaleColor, setExtraMainScaleColor] = useState<IFieldColor>();
  const [extraMascot, setExtraMascot] = useState<IFieldFile>();
  const [companyImages, setCompanyImages] = useState<{
    name: string;
    url: string;
  }[]>([]);
  const [companyColors, setCompanyColors] = useState<IFieldColor[]>([]);
  async function getCompanyMetadata() {
    const res = await companyApi.getCompanyMetadata(+id!);
    if (res.statusCode >= 200 && res.statusCode < 300) {
      setCompanyImages(res.data.filter(item => item.key.startsWith('image_')).map(item => {
        const imageParts = item.value.split('|||');
        return {
          name: imageParts[0],
          url: imageParts[1]
        };
      }));
      setCompanyColors(res.data.filter(item => item.key.startsWith('color_')).map(item => {
        const colorParts = item.value.split('|||');
        return {
          name: colorParts[0],
          color: colorParts[1]
        };
      }));
    }
  }
  const onSubmit = async (values: INumericSettingsForm, {
    setSubmitting
  }: FormikHelpers<INumericSettingsForm>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    const styles: TAdditionalParams[] = [];
    if (apiField) {
      options = [...apiField.options];
      addAdditionalParam(values.change_background, 'change_background', options);
      addAdditionalParam(values.change_mascot, 'change_mascot', options);
      addAdditionalParam(values.show_default_value, 'show_default_value', options);
      addAdditionalParam(values.default_value, 'default_value', options);
      addAdditionalParam(mapFieldColorToApiColor(values.signature_text_color), 'signature_text_color', styles);
      addAdditionalParam(mapFieldColorToApiColor(values.selected_button_color), 'selected_button_color', styles);
      addAdditionalParam(mapFieldColorToApiColor(values.numbers_color), 'numbers_color', styles);
      reduceAdditionalImageParam(values.mascots, styles, 'mascot_');
      reduceAdditionalColorParam(values.backgroundColors, styles, 'background_color_');
      const data: ApiUpdatedField<FieldType.Scale | FieldType.Ces | FieldType.Nps | FieldType.Csat> = {
        question: apiField.question,
        options,
        styles,
        min: +values.min,
        max: +values.max
      };
      fieldsApi.updateFormField(apiField.id!, data).then(res => {
        if (res.statusCode === 200) {
          setFields(prev => prev.map(el => {
            if (el.id === apiField.id) {
              return {
                ...el,
                ...data
              };
            }
            return el;
          }));
          fieldsApi.updateFormFieldAnswer(apiField.id!, createAnswersArray(+values.min, +values.max)).then(res => {
            console.log(res);
            toast.success('Поле успішно оновлено');
          });
        }
      });
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<INumericSettingsForm>) => <Form>
      <NumericScale numbersColor={values.numbers_color ? mapIColorToString(values.numbers_color) : mapIColorToString(defaultNumbersColor)} selectedButtonColor={values.selected_button_color ? mapIColorToString(values.selected_button_color) : mapIColorToString(defaultSelectedButtonColor)} maxValue={+values.max} defaultValue={+values.default_value} showDefaultValue={values.show_default_value} signatures={['Малоймовірно', 'Надзвичайно неймовірно']} signatureColor={values.signature_text_color.color as string} />
      <div className="color-wrapper">
        <h6>Колір чисел</h6>

        <div className="color-selector">
          <CustomColorsSelect name="numbers_color" placeholder="Виберіть колір" options={companyColors} value={values.numbers_color} handleSelect={color => {
          setFieldValue('numbers_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('numbers_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <div className="color-wrapper">
        <h6>Колір обраної відповіді</h6>

        <div className="color-selector">
          <CustomColorsSelect name="selected_button_color" placeholder="Виберіть колір" options={companyColors} value={values.selected_button_color} handleSelect={color => {
          setFieldValue('selected_button_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('selected_button_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <div className="color-wrapper">
        <h6>Колір тексту підпису</h6>

        <div className="color-selector">
          <CustomColorsSelect name="signature_text_color" placeholder="Виберіть колір" options={companyColors} value={values.signature_text_color} handleSelect={color => {
          setFieldValue('signature_text_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('signature_text_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      {changeMinMax && <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="max" type="number" min={0} onChange={setFieldValue} onKeyUp={() => handleKeyUp('max', setErrorMessage, errorMessage)} placeholder="Максимальне значення" value={values.max} error={typeof errorMessage === 'object' ? getErrorMessage('max', errorMessage) : undefined} />}
      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="default_value" type="number" min={0} max={values.max || 10} onChange={setFieldValue} onKeyUp={() => handleKeyUp('default_value', setErrorMessage, errorMessage)} placeholder="Значення за замовчуванням" value={values.default_value} error={typeof errorMessage === 'object' ? getErrorMessage('default_value', errorMessage) : undefined} />
      <Checkbox name="show_default_value" value={values.show_default_value!} onChange={handleChange}>
        <span className="checkboxValue">
          Показати значення за замовчуванням
        </span>
      </Checkbox>

      <Checkbox name="change_background" value={values.change_background!} onChange={handleChange}>
        <span className="checkboxValue">Змінювати фон при зміні рейтингу</span>
      </Checkbox>

      {values.change_background && <FieldArray name="backgroundColors" render={({
      remove,
      push
    }) => <div className="color-wrapper">
              <h6>Колір фону</h6>

              {values.backgroundColors.map((field, index) => <div className="color-selected" key={field.name}>
                  <div className="color-row">
                    <span>{index}</span>
                    <div className="color-item" style={{
            backgroundColor: typeof field.color === 'string' ? field.color : `rgba(${field.color.r}, ${field.color.g}, ${field.color.g}, ${field.color.a || 100}`
          }} />
                    <span>{field.name}</span>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button className="color-remove" type="button" onClick={() => remove(index)}>
                    <DeleteIcon />
                  </button>
                </div>)}

              <div className="color-selector">
                <CustomColorsSelect name="extraMainScaleColor" placeholder="Виберіть колір" options={companyColors} value={extraMainScaleColor} handleSelect={color => {
          push({
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" disabled={values.backgroundColors.length >= values.max + 1} extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          push({
            color: color.color,
            name: color.name
          });
        }} />
              </div>
            </div>} />}

      <Checkbox name="change_mascot" value={values.change_mascot!} onChange={handleChange}>
        <span className="checkboxValue">
          Змінювати талісман при зміні рейтингу
        </span>
      </Checkbox>

      {values.change_mascot && <FieldArray name="mascots" render={({
      remove,
      push
    }) => <div className="img-wrapper">
              {values.mascots.map((field, index) => <div className="img-selected" key={field.url}>
                  <div className="img-row">
                    <span>{index}</span>
                    <img className="img-target" src={field.url} alt={field.name} />
                    <span>
                      {typeof field === 'string' ? field : field.name}
                    </span>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button className="img-remove" type="button" onClick={() => remove(index)}>
                    <DeleteIcon />
                  </button>
                </div>)}

              {values.mascots.length < values.max! + 1 && <div className="img-addwrapper">
                  {companyImages && <div className="img-addfield">
                      <SelectWithAnyItems name="mascots" placeholder="Виберіть талісман" options={companyImages} value={extraMascot} handleSelect={file => {
            push(file);
          }} formGroupStyles={{
            width: '300'
          }} hideErrors renderItem={option => <div className="img-optionwrapper">
                            <div className="img-optionimg">
                              <img src={option.url} alt={option.name} />
                            </div>

                            <p>{option.name}</p>
                          </div>} renderSelect={value => <p>{value.name}</p>} />
                    </div>}
                </div>}
            </div>} />}

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '15px 45px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    if (field.id) {
      getCompanyMetadata();
      fieldsApi.getTargetField(field.id).then(res => {
        const change_background = res.data.options.find(option => option.key === 'change_background')?.value === 'true';
        const change_mascot = res.data.options.find(option => option.key === 'change_mascot')?.value === 'true';
        const show_default_value = res.data.options.find(option => option.key === 'show_default_value')?.value === 'true';
        const default_value = res.data.options.find(option => option.key === 'default_value')?.value || 5;
        const signature_text_color = res.data.styles.find(option => option.key === 'signature_text_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'signature_text_color')?.value!) : defaultSignatureColor;
        const numbers_color = res.data.styles.find(option => option.key === 'numbers_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'numbers_color')?.value!) : defaultNumbersColor;
        const selected_button_color = res.data.styles.find(option => option.key === 'selected_button_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'selected_button_color')?.value!) : defaultSelectedButtonColor;
        const backgroundColors = reduceFieldArray(res.data.styles, 'background_color_').map(el => {
          const color = el.value.split('|||');
          return {
            color: color[1],
            name: color[0]
          };
        });
        const mascots = reduceFieldArray(res.data.styles, 'mascot_').map(el => {
          const img = el.value.split('|||');
          return {
            url: img[1],
            name: img[0]
          };
        });
        setApiField(res.data);
        setInitialValues({
          min: res.data.min || 0,
          max: res.data.answers.length - 1 || 10,
          change_background,
          default_value: +default_value,
          backgroundColors: backgroundColors as IFieldColor[],
          mascots: mascots as IFieldFile[],
          show_default_value,
          numbers_color,
          selected_button_color,
          signature_text_color,
          change_mascot
        });
      });
    }
  }, []);
  if (!initialValues) {
    return null;
  }
  return <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
      {renderForm}
    </Formik>;
};