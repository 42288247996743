import styled from 'styled-components';

export const IssuesStyles = styled.div`
  width: 100%;
  flex: 1;
  height: 100%;
  max-height: calc(100vh - 62px);
  overflow: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;

  .issues {
    width: 100%;

    &-subheader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
    }
    
    &-wrapper {
      flex: 1;
      padding-top: 15px;
      height: 100%;
      position: relative;
      overflow: hidden;
      width: 100%;
    }

    &-tabs {
      padding-top: 15px;
    }
  }
`;
