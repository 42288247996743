import React, { FC } from 'react';
import { useAppSelector } from '../../../state';
import { TagsChipsStyles } from './styles';
import { Chip } from './chip';
import { TTagsChips } from './types';
export const TagsChips: FC<TTagsChips> = ({
  tags,
  handleRemove
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <TagsChipsStyles>
      {tags.map(el => <Chip key={el.id} tag={el} handleRemove={handleRemove} />)}
    </TagsChipsStyles>;
};