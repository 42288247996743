import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import * as React from 'react';
import { TIcon } from './TIcon';
const ScaleIcon_1_1 = _compiledBlock(_props => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img" width={_props.v0} height={_props.v1}>
      <path fill={_props.v2} d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z" />
    </svg>, {
  name: "ScaleIcon_1_1",
  svg: true
});
const ScaleIcon_1 = ({
  width = 448,
  height = 512,
  color = '#000'
}) => /*@million jsx-skip*/<ScaleIcon_1_1 v0={width} v1={height} v2={color} />;
export const ScaleIcon: React.FC<TIcon> = React.memo(ScaleIcon_1);