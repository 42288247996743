import { useEffect, useMemo } from 'react';
import { useAppSelector } from '../state';
import { TPermissionValue } from '../entities';

export const usePermissionView = () => {
  const user = useAppSelector((state) => state.user.data);
  const userRoles = user?.roles;

  const hasAllAccesses = useMemo(
    () => !!userRoles?.find((role) => role.permissions.find((el) => el.resource === '/*')),
    [userRoles],
  );

  const hasAnyAccess = (access: string) => {
    const hasAccessRole = userRoles?.find((item) => item.permissions.some((el) => el.resource.includes(access)));
    const hasAccessPermissions = hasAccessRole?.permissions.find((el) => el.resource.includes(access));
    return {
      edit: [1, 4, 8].some((num) => hasAccessPermissions?.permissions.includes(num as TPermissionValue)),
      view: !!hasAccessRole,
    };
  };

  return { hasAllAccesses, hasAnyAccess };
};
