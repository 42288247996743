import styled from 'styled-components';

export const PieChartStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .pie-legend {
    font-size: 12px;
    width: 100%;
  }
`;
