import {
  CompaniesMainInfo,
  ICompany,
  IDocument,
  INode,
  IColor,
  ISocialNetwork,
  IOneReviewForm,
  IReviewForm,
  IFormListItem,
  ICompanyDocument,
  ICompanyExtraField,
  ILanguage,
  IIssue,
  IUser,
  IUserRole,
  IPermission,
  IReviewDiscussionMessage,
  IReviewType,
  IUserPermission,
} from '../entities';
import {
  ApiCompanyDocument,
  ApiCompany,
  ApiDocument,
  ApiIssueStatus,
  ApiIssueType,
  ApiUser,
  ApiUserRole,
  ApiNode,
  ApiReviewType,
  ApiReviewForm,
  ApiReviewFormOne,
  ApiReviewDiscussionMessage,
  ApiLanguage,
  ApiIssue,
  ApiMetadata,
  ApiForm,
  ApiOption,
} from './entities';
import { format } from 'date-fns';
import { getColorFromString } from '../utils';

export const mapIColorToString = (item: IColor) => `${item.name}|||${
  typeof item.color === 'object'
    ? `rgba(${item.color.r}, ${item.color.g}, ${item.color.b}, ${item.color.a})`
    : item.color
}`;

export const mapApiLanguageToILanguage = (
  apiLanguage: ApiLanguage,
): ILanguage => ({
  id: apiLanguage.id,
  name: apiLanguage.name,
  icon: apiLanguage.icon,
});

export function mapApiLanguagesToILanguages(
  apiLanguages: ApiLanguage[],
): ILanguage[] {
  return apiLanguages.map((lang) => mapApiLanguageToILanguage(lang));
}

function getColorPartsFromApiString(
  options: ApiOption[],
  key: string,
): string[] | undefined {
  return options.find((item) => item.key === key)?.value?.split('|||');
}

function getIColorFromColorParts(
  colorParts: string[] | undefined,
  defaultName: string,
  defaultColor: { r: number; g: number; b: number; a: number } | string,
): IColor {
  return {
    name: colorParts ? colorParts[0] : 'defaultName',
    color: colorParts ? getColorFromString(colorParts[1]) : defaultColor,
  };
}

export function mapApiCompanyDocumentToICompanyDocument(
  apiDocument: ApiCompanyDocument,
): ICompanyDocument {
  return {
    companyID: apiDocument.companyID,
    name: apiDocument.name,
    description: apiDocument.description,
    url: apiDocument.url,
    id: apiDocument.file_id,
    created_at: apiDocument.created_at,
  };
}

export function mapApiCompanyDocumentsToICompanyDocuments(
  apiDocuments: ApiCompanyDocument[],
): ICompanyDocument[] {
  return apiDocuments.map((document) => mapApiCompanyDocumentToICompanyDocument(document));
}

export function mapApiFormToIFormListItem(apiForm: ApiForm): IFormListItem {
  return {
    id: apiForm.id!,
    name: apiForm.name,
    updateAt: apiForm.updatedAt!,
    status: apiForm.status === 'true',
    language_id: apiForm.languageID,
    anonymous: ['true', true].includes(apiForm.anonymous),
    companyID: apiForm.companyID,
    node_id: apiForm.nodeID,
    withSteps:
      apiForm.options?.find((item) => item.key === 'withSteps')?.value
        === 'true' || apiForm.fieldID !== undefined,
  };
}

export function mapApiFormsToIFormListItems(
  apiForms: ApiForm[],
): IFormListItem[] {
  return apiForms.map((form) => mapApiFormToIFormListItem(form));
}

export function mapFromApiMetadataToICompanyExtraField(
  data: ApiMetadata[],
): ICompanyExtraField[] {
  return data
    ?.map((item) => ({
      key: item.key,
      name: item.value.split('|||')[0],
      value: item.value.split('|||')[1],
      id: item.id,
      position: item.value.split('|||')[2],
    }))
    .sort((a, b) => +a.position - +b.position);
}

export function mapFromApiCompanyToICompany(
  company: ApiCompany,
): CompaniesMainInfo {
  return {
    id: company.id,
    name: company.name,
    domain: company.alias,
    createAt: company.createdAt,
    updateAt: company.updatedAt,
  };
}

export function mapFromApiCompaniesToICompanies(
  companies: ApiCompany[],
): CompaniesMainInfo[] {
  return companies.map((company) => mapFromApiCompanyToICompany(company));
}

export function mapApiMetadataToICompany(
  companyMetadata: ApiMetadata[],
): Partial<ICompany> {
  if (companyMetadata.length) {
    const logo = companyMetadata.find((item) => item.key === 'logo');
    const extraFields = companyMetadata.filter((item) => item.key.startsWith('extraField'));
    const images = companyMetadata.filter((item) => item.key.startsWith('image'));
    const colors = companyMetadata.filter((item) => item.key.startsWith('color'));
    const fonts = companyMetadata.filter((item) => item.key.startsWith('font'));
    const reviewTableSettings = companyMetadata.filter((item) => item.key.startsWith('reviewTableSetting'));
    const groupID = companyMetadata.find((item) => item.key === 'groupID');
    const reportID = companyMetadata.find((item) => item.key === 'reportID');
    const originUrl = companyMetadata.find(
      (item) => item.key === 'officialSiteURL',
    );

    return {
      logo: {
        id: logo?.id,
        value: logo?.value,
      },
      groupID: {
        id: groupID?.id,
        value: groupID?.value,
      },
      reportID: {
        id: reportID?.id,
        value: reportID?.value,
      },
      originUrl: {
        id: originUrl?.id,
        value: originUrl?.value || '',
      },
      extraFields: mapFromApiMetadataToICompanyExtraField(extraFields),
      logos: images.map((image) => {
        const imageParts = image.value.split('|||');

        return {
          id: image.id,
          name: imageParts[0],
          url: imageParts[1],
          created_at: image.createdAt,
        };
      }),
      colors: colors.map((color) => {
        const colorParts = color.value.split('|||');

        return {
          id: color.id,
          name: colorParts[0],
          color: getColorFromString(colorParts[1]),
          created_at: color.createdAt,
        };
      }),
      fonts: fonts.map((font) => {
        const fontParts = font.value.split('|||');

        return {
          id: font.id,
          name: fontParts[0],
          url: fontParts[1],
          created_at: font.createdAt,
        };
      }),
      reviewTableSettings: reviewTableSettings.map((setting) => ({
        id: setting.id,
        name: setting.value,
        created_at: setting.createdAt,
      })),
    };
  }

  return {
    logo: undefined,
    originUrl: undefined,
    extraFields: undefined,
    logos: undefined,
    colors: undefined,
    reviewTableSettings: undefined,
  };
}

export function mapFromFileToFormData(
  file: File,
  width?: number,
  height?: number,
): FormData {
  const formData = new FormData();
  formData.append('file', file);

  if (width) {
    formData.append('width', width.toString());
  }

  if (height) {
    formData.append('height', height.toString());
  }

  return formData;
}

export function mapFromApiMetadataToSocialNetwork(
  data: ApiMetadata[],
  socialNetwork: string,
): ISocialNetwork {
  const key = data.find((item) => item.key.startsWith(`${socialNetwork}_key`));
  const active = data.find((item) => item.key.startsWith(`${socialNetwork}_active`));
  const alphaName = data.find((item) => item.key.startsWith(`${socialNetwork}_alphaName`));

  return {
    key: key?.value || '',
    keyId: key?.id,
    active: active?.value === 'true',
    activeId: active?.id,
    alphaName: alphaName?.value || '',
    alphaNameId: alphaName?.id,
  };
}

export function mapFromApiDocumentToIDocument(data: ApiDocument): IDocument {
  return {
    name: data.description,
    id: data.file_id,
    url: data.url,
    fileName: data.name,
  };
}

export function mapFromApiNodeToINode(data: ApiNode): INode {
  const address = data.metadata?.find((item) => item.key.startsWith('address'))!;
  const subNodes = data.children?.map((node) => mapFromApiNodeToINode(node));

  return {
    id: data.id,
    companyId: data.companyID!,
    name: data.name,
    address: address?.id
      ? { id: address.id, value: address.value }
      : { value: '' },
    subNodes: subNodes || [],
    tiedNode: data.parentID || undefined,
    secondTiedNode: data.referencedID || undefined,
    email: data.metadata
      ? mapFromApiMetadataToSocialNetwork(data.metadata, 'email')
      : undefined,
    sms: data.metadata
      ? mapFromApiMetadataToSocialNetwork(data.metadata, 'sms')
      : undefined,
    viber: data.metadata
      ? mapFromApiMetadataToSocialNetwork(data.metadata, 'viber')
      : undefined,
    telegram: data.metadata
      ? mapFromApiMetadataToSocialNetwork(data.metadata, 'telegram')
      : undefined,
    slug: data.slug,
    code: data.code,
    responsibleIDs: data.responsibleIDs ? data.responsibleIDs : [],
    enableOverdue: data.enableOverdue,
    isLocation: !!data.isLocation,
  };
}

export function mapFromApiNodesToINodes(data: ApiNode[]): INode[] {
  return data.map((node) => mapFromApiNodeToINode(node));
}

export function mapFromApiReviewFormToIReviewForm(
  data: ApiReviewForm,
): IReviewForm {
  return {
    id: data.id,
    createAt: format(new Date(data.created_at), 'dd.MM.yyyy H:mm:ss'),
    contacts: data.contacts,
    source: data.source,
    language: data.language,
    answers: data.answers || [],
    status: data.status,
    comment: data.comment,
    updatedAt: format(new Date(data.updated_at), 'dd.MM.yyyy H:mm:ss'),
    node: data.node,
    categories: data.categories,
    tags: data.tags,
    sentiment: data.sentiment,
  };
}

export function mapFromApiReviewFormToIOneReviewForm(
  data: ApiReviewFormOne,
): IOneReviewForm {
  return {
    createAt: format(new Date(data.created_at), 'dd.MM.yyyy H:mm:ss'),
    id: data.id,
    answers: data.answers || [],
    status: data.status,
    comment: data.comment,
    updatedAt: format(new Date(data.updated_at), 'dd.MM.yyyy H:mm:ss'),
    node: data.node,
    wasOverdue: data.wasOverdue,
    contacts: data.contacts,
    responsibleID: data.responsibleID,
    categories: data.categories || [],
    tags: data.tags || [],
    sentiment: data.sentiment,
  };
}

export function mapFromApiReviewFormsToIOneReviewForms(
  data: ApiReviewFormOne[],
): IOneReviewForm[] {
  return data.map(mapFromApiReviewFormToIOneReviewForm);
}

export function mapFromApiReviewFormsToIReviewForms(
  data: ApiReviewForm[],
): IReviewForm[] {
  return data.map(mapFromApiReviewFormToIReviewForm);
}

export function mapApiIssueToIIssue(data: ApiIssue): IIssue {
  return {
    id: data.id,
    name: data.name,
    statusId: data.status.id!,
    companyId: data.companyID,
    nodeId: data.node.id,
    typeId: data.type.id!,
    userId: data.user.id,
    priority: data.priority,
    comment: data.comment,
    reviewId: data.reviewID,
    createdAt: data.createdAt,
  };
}

export function mapApiIssuesToIIssues(data: ApiIssue[]): IIssue[] {
  return data.map(mapApiIssueToIIssue);
}

export function mapApiPermissionToIPermission(
  apiPermission: IUserPermission,
): IUserPermission {
  return {
    id: apiPermission.id,
    name: apiPermission.name,
    resource: apiPermission.resource,
    permissions: apiPermission.permissions,
  };
}

export function mapIPermissionToApiPermission(iPermission: IUserPermission): {
  id?: number;
  name: string;
  resource: string;
  permissions: number;
} {
  return {
    name: iPermission.name,
    resource: iPermission.resource,
    permissions: (iPermission.permissions as number[]).reduce(
      (acc, perm) => acc + perm,
    ),
  };
}

export function mapApiPermissionsToIPermissions(
  apiPermissions: IUserPermission[],
): IPermission[] {
  return apiPermissions || [];
}

export function mapIPermissionsToApiPermissions(iPermissions: IPermission[]): {
  id?: number;
  name: string;
  resource: string;
  permissions: any;
}[] {
  return iPermissions.map((permission) => mapIPermissionToApiPermission(permission));
}

export function mapApiUserRoleToIUserRole(apiRole: IUserRole): IUserRole {
  return {
    id: apiRole.id,
    name: apiRole.name,
    permissions: apiRole.permissions || [],
  };
}

export function mapIUserRoleToApiUserRole(iRole: IUserRole): ApiUserRole {
  return {
    id: iRole.id,
    name: iRole.name,
    permissions: mapIPermissionsToApiPermissions(iRole.permissions),
  };
}

export function mapApiUserRolesToIUserRoles(
  apiRoles: IUserRole[],
): IUserRole[] {
  return apiRoles.map((role) => mapApiUserRoleToIUserRole(role));
}

export function mapApiUserToIUser(apiUser: ApiUser): IUser {
  return {
    id: apiUser.id,
    email: apiUser.email,
    name: apiUser.name,
    drfo: apiUser.drfoCode,
    phone: apiUser.phone,
    position: apiUser.position,
    roles: apiUser.roles ? mapApiUserRolesToIUserRoles(apiUser.roles) : [],
  };
}

export function mapApiUsersToIUsers(data: ApiUser[]): IUser[] {
  return data.map(mapApiUserToIUser);
}

export function mapApiReviewDiscussionMessageToIReviewDiscussionMessage(
  apiMessage: ApiReviewDiscussionMessage,
): IReviewDiscussionMessage {
  const author = apiMessage.external ? 'admin' : 'user';
  return {
    id: apiMessage.id,
    date: apiMessage.createdAt,
    text: apiMessage.text,
    author,
    attachmentUrl: apiMessage.attachmentUrl,
    attachmentType: apiMessage.attachmentType,
  };
}

export function mapApiReviewDiscussionMessagesToIReviewDiscussionMessages(
  apiMessages: ApiReviewDiscussionMessage[],
): IReviewDiscussionMessage[] {
  return apiMessages.map(
    mapApiReviewDiscussionMessageToIReviewDiscussionMessage,
  );
}

export function mapApiReviewTypeToIReviewType(
  apiReviewType: ApiReviewType,
): IReviewType {
  return {
    id: apiReviewType.id,
    name: apiReviewType.name,
    color: apiReviewType.color,
  };
}

export function mapImportDataToFormData(
  file: File,
  sheet: string,
  companyID: string | undefined,
  width?: number,
  height?: number,
): FormData {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('sheet', sheet);

  if (companyID) {
    formData.append('companyID', companyID);
  }

  if (width) {
    formData.append('width', width.toString());
  }

  if (height) {
    formData.append('height', height.toString());
  }

  return formData;
}

export function mapChatImg(file: File): FormData {
  const formData = new FormData();
  formData.append('attachment', file);
  return formData;
}
