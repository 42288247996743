import styled from 'styled-components';
export const GoogleIntegrationsStyles = styled.div`
  max-width: 1200px;
  width: 100%;
  border: 1px solid rgb(240, 240, 240);

  h3,
  p {
    font-size: 16px;
  }

  .header {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(240, 245, 245);
  }

  .content {
    padding: 20px;

    button {
      width: 100%;
    }

    .targetWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid rgb(230, 230, 230);

      .targetAccount {
        display: flex;
        flex-direction: column;
      }
      .targetAccountItem {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 10px;
      }
      .targetAccountItem:last-child {
        margin-bottom: 0;
      }
      .targetAccount .label {
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        border: 1px solid #cccccc;
        border-radius: 5px;
      }
      .targetAccountSubscribed .label {
        border-color: #9ec7a0;
        color: #308337;
        background-color: #dcffdf;
      }
      .targetAccountUnsubscribed .label {
        border-color: #c59999;
        color: #b54b4b;
        background-color: #ffe8e8;
      }
    }

    .accountsContainer {
      width: 100%;
      padding: 15px 0;
      display: flex;
      gap: 20px;
      align-items: center;
      flex-wrap: wrap;

      h5 {
        width: 100%;
      }

      button {
        max-width: 250px;
      }
    }
  }

  .star-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;

    i {
      font-size: 12px;
    }
  }
`;