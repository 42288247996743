import styled from 'styled-components';

export const CompanyQrStylesFormStyles = styled.div`
  padding: 26px 32px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  
  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 80px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .qrSettings, .textSettings {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .qrSettings {
      .uploadLogoWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .uploadLogoWrapperButtonsWrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;

          button {
            width: 100%;
          }
        }
      }
    }

    .formContentWrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .stylesSettingsFormsWrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      .viewSettingsWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        max-width: 500px;
      }

      .colorsSettingsWrapper {
        width: fit-content;

        .colorsSettingsFieldsWrapper {
          display: grid;
          grid-template-columns: auto auto;
          gap: 16px;

          margin-top: 16px;

          .qrStylesColorPickerOpenerButton {
            justify-content: space-between;
            width: 100%;
          }
        }
      }
    }
  }
  
  .previewWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
  }
`;
