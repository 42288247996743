import {
  mapApiReviewDiscussionMessagesToIReviewDiscussionMessages,
  ApiReviewAllMessagesHistoryData,
  ApiReviewMessagesHistoryData,
} from '../api';
import { IReviewDiscussionMessage } from '../entities/IReviewDiscussionMessage';

export function formatMessageHistoryData(
  apiHistoryData: ApiReviewMessagesHistoryData,
): { clientID?: number; messages: IReviewDiscussionMessage[] } {
  const apiLogData = apiHistoryData.log ? apiHistoryData.log : [];
  return {
    clientID: apiHistoryData.clientID,
    messages:
      mapApiReviewDiscussionMessagesToIReviewDiscussionMessages(apiLogData),
  };
}

export function formatMessageAllHistoryData(
  apiHistoryData: ApiReviewAllMessagesHistoryData,
  clientID: number,
): { clientID: number; messages: IReviewDiscussionMessage[] } {
  const apiLogData = apiHistoryData.history ? apiHistoryData.history : [];
  return {
    clientID,
    messages:
      mapApiReviewDiscussionMessagesToIReviewDiscussionMessages(apiLogData),
  };
}
