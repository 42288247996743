import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FormLocalizationStyles } from './styles';
import { Modal } from '../Modal';
import { ILanguage } from '../../../../entities';
import { TLocalizations, localizationsApi } from '../../../../api';
import { toast } from 'react-toastify';
type TFormLocalization = {
  onClose: () => void;
  formId: number;
  companyLangs: ILanguage[];
  formLocalization: TLocalizations[];
  setFormLocalization: Dispatch<SetStateAction<TLocalizations[]>>;
};
export const FormLocalization: FC<TFormLocalization> = React.memo(({
  onClose,
  formId,
  companyLangs,
  setFormLocalization,
  formLocalization
}) => {
  const handleSelect = (id: number) => {
    localizationsApi.setFormLocalizations(formId, {
      isDefault: false,
      languageID: id
    }).then(res => {
      if (res.statusCode === 201) {
        const addedLang = companyLangs.find(el => el.id === id);
        setFormLocalization(prev => [...prev, {
          ...res.data,
          language: addedLang!
        }]);
        toast.success('Локалізацію додано');
        onClose();
      }
    });
  };
  return <FormLocalizationStyles>
      <Modal title="Оберіть локалізацію для форми" onClose={onClose}>
        <div className="languages">
          {companyLangs.filter(lang => !formLocalization.map(local => local.language.id).includes(lang.id)).map(el => <div onClick={() => handleSelect(el.id!)} className="languages-item">
                  <span>{el.icon}</span>
                  <span>{el.name}</span>
                </div>)}
        </div>
      </Modal>
    </FormLocalizationStyles>;
});