import styled from 'styled-components';

export const AnswersFilterStyles = styled.div`
  font-weight: 400;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  position: absolute;
  left: 0px;
  z-index: 200;
  width: 400px;
  max-height: 450px;
  padding: 16px;
  border-radius: 4px;
  overflow: scroll;
`;
