import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../i18n';

export interface FormValues {
  name: string;
  resource: string;
  permissions: {
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
  };
}

export const formInitialValues: FormValues = {
  name: '',
  resource: '',
  permissions: {
    create: false,
    read: false,
    update: false,
    delete: false,
  },
};

export const validationSchema = (lang: string) => Yup.object({
  name: Yup.string().required(
    getTranslationByLangOrEng(lang, 'validation_required'),
  ),
  resource: Yup.string().required(
    getTranslationByLangOrEng(lang, 'validation_required'),
  ),
  permissions: Yup.object()
    .shape({
      create: Yup.boolean(),
      read: Yup.boolean(),
      update: Yup.boolean(),
      delete: Yup.boolean(),
    })
    .test(
      'permissions-least-one-selected',
      getTranslationByLangOrEng(lang, 'validation_more_one_option'),
      (values) => Object.values(values).includes(true),
    ),
});
