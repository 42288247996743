import styled, { keyframes } from 'styled-components';

import dot from '../../../../assets/icons/dot.svg';
import { AvailableQRBordersColor } from './utils';

const colors = {
  borderColor: '#c3c9c9',
  borderHoverColor: '#707272',
  backgroundColor: '#eef3f7',
}

const shownAnimation = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`

export const PosterModalStyles = styled.div<{propsScale: number, qrBorderColor: AvailableQRBordersColor}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  overflow: hidden;

  background-color: ${colors.backgroundColor};
  background-image: url(${dot});
  background-repeat: repeat;
  
  animation: 1s ease ${shownAnimation};
  
  .noPDFText {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    
    .uploadFileButton {
      width: fit-content;
    }
  }
  
  .formWrapper {
    position: relative;

    width: 100vw;
    height: 100vh;
    overflow: auto;
  }
  
  form {
    position: absolute;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    min-width: 100vw;
    min-height: 100vh;
    
    animation: 1s ease ${shownAnimation};
  }
  
  .workingArea {
    position: absolute;
    top: 50%;
    left: 50%;
    
    display: grid;
    place-items: center;

    transform: ${({ propsScale }) => `translate(-50%, -50%) scale(${propsScale})`};
  }
  
  .pdfEditorContent {
    max-width: none;
    max-height: none;
    
    .react-draggable {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
    }
  }
  
  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    padding: 6px;

    border: 1px solid ${colors.borderColor};
    background-color: ${colors.backgroundColor};
    border-radius: 100%;
    transition: .3s ease border-color;

    &:hover {
      border: 1px solid red;
    }
  }

  .changePDFEditorWrapperScaleButtonsWrapper {
    position: absolute;
    right: 20px;
    top: 50%;
    
    transform: translateY(-50%);

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 6px;

      border: 1px solid ${colors.borderColor};
      background-color: ${colors.backgroundColor};
      transition: .3s ease border-color;

      &:hover {
        border: 1px solid ${colors.borderHoverColor};
      }
    }

    .increaseScaleButton {
      border-radius: 6px 6px 0 0;
    }

    .decreaseScaleButton {
      border-radius: 0 0 6px 6px;
    }
  }

  .qrResizable {
    &.dashedBorder {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 4px);
        height: calc(100% - 4px);

        border: 2px dashed ${(props) => props.qrBorderColor};

        transition: .3s ease border-color;
      }
    }
  }

  .menu {
    position: absolute;
    bottom: 0;
    left: 0;
    
    transform: translateY(100%);
    transition: .6s ease transform;
    
    .menuContent {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 24px;

      padding: 40px 20px;
      
      max-height: 50vh;
      width: 100vw;
      
      background-color: ${colors.backgroundColor};
      border-top: 1px solid ${colors.borderColor};
      overflow: auto;
    }
    
    .menuOpenerButton {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, calc(-100% + 1px));

      width: 40px;
      height: 40px;

      background-color: ${colors.backgroundColor};
      border-radius: 10px 10px 0 0;
      border-top: 1px solid ${colors.borderColor};
      border-left: 1px solid ${colors.borderColor};
      border-right: 1px solid ${colors.borderColor};
      transition: .3s ease border-color;

      svg {
        transition: .3s ease transform;
      }
    }
    
    .menuButtons {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 20px;
    }
    
    .menuFormWrapper {
      position: relative;
      
      display: flex;
      flex-direction: column;
      gap: 24px;
      
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        
        transform: translateY(-50%);
        height: calc(100% + 80px);
        width: 1px;
        
        background-color: ${colors.borderColor};
      }
      
      .splitter {
        width: calc(100% + 20px);
        transform: translateX(-20px);
        height: 1px;
        background-color: ${colors.borderColor};
      }
    }
    
    .qrSettingsFieldsWrapper, .textSettingsWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 24px;
      
      .checkbox {
        width: fit-content;
      }
      
      .inputWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        
        flex-basis: 280px;
      }
    }

    &.open {
      transform: translateY(0);

      .menuOpenerButton {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .qrResizable {
    .react-resizable-handle {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      padding: 0;
    }
  }
  
  .textResizable, .qrResizable {
    
  }
  
  .textResizable {
    border-width: 1px;
    border-style: dashed;
    border-color: ${(props) => props.qrBorderColor};
    
    .react-resizable-handle-se {
      bottom: -6px;
      right: -6px;
    }

    .react-resizable-handle-sw {
      bottom: -6px;
      left: -6px;
    }

    .react-resizable-handle-ne {
      top: -6px;
      right: -6px;
    }

    .react-resizable-handle-nw {
      top: -6px;
      left: -6px;
    }
    
    .react-resizable-handle-w {
      left: -6px;
    }

    .react-resizable-handle-e {
      right: -6px;
    }

    .react-resizable-handle-n {
      top: -6px;
    }

    .react-resizable-handle-s {
      bottom: -6px;
    }
  }
`;
