import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { notifiableSourcesApi } from '../../../../api';
import { INotifiableSources } from '../../../../entities';
import { formatDatetime, parseDatetime } from './ReaultSourcesUtils';
import { ResultSourcesStyles } from './ResultSourcesStyles';
import { Checkbox } from '../../form';
type TResultSources = {
  source: INotifiableSources;
  userId: number;
  reQuery: boolean;
  setReQuery: Dispatch<SetStateAction<boolean>>;
};
export const ResultSources: FC<TResultSources> = React.memo(({
  source,
  userId,
  reQuery,
  setReQuery
}) => {
  const [begin, setBegin] = useState<string>(source.notiTime ? parseDatetime(source.notiTime.notifyTimeBegin) : '08:00');
  const [end, setEnd] = useState<string>(source.notiTime ? parseDatetime(source.notiTime.notifyTimeEnd) : '21:00');
  const handleCreateSource = useCallback(() => {
    notifiableSourcesApi.postNotifiableSource(userId, source.id, {
      notifyTimeBegin: formatDatetime(begin),
      notifyTimeEnd: formatDatetime(end)
    }).then(res => {
      setReQuery(!reQuery);
    });
  }, [reQuery, begin, end]);
  const handleDeleteSource = useCallback(() => {
    notifiableSourcesApi.deleteNotifiableSource(userId, source.id).then(res => {
      setReQuery(!reQuery);
    });
  }, [reQuery]);
  return <ResultSourcesStyles>
        <div className="notirow">
          <div className="sourceName">{source.name}</div>
          <Checkbox extraBlockStyles={{
        width: 50
      }} hideError name={`checkbox-${source.id}`} value={!!source.notiTime} onChange={source.notiTime ? handleDeleteSource : handleCreateSource} />
        </div>
        <div className="notirow">
          <input type="time" disabled={!!source.notiTime} value={begin} onChange={e => setBegin(e.target.value)} />
          <input type="time" disabled={!!source.notiTime} value={end} onChange={e => setEnd(e.target.value)} />
        </div>
      </ResultSourcesStyles>;
});