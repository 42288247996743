import { useCallback, useEffect, useState } from 'react';
import { companyApi } from '../api';

export function useCompanyName(id: string | undefined) {
  const [companyId, setCompanyId] = useState<string | undefined>();
  const [companyName, setCompanyName] = useState<string | number>('');

  const getCompanyName = useCallback(async () => {
    if (id) {
      const res = await companyApi.getCompanyById(+id);

      if (res.statusCode >= 200 && res.statusCode < 300) {
        setCompanyName(res.data.id);
      } else {
        setCompanyName('');
      }
    } else {
      setCompanyName('');
    }
  }, [id]);

  useEffect(() => {
    if (companyId !== id) {
      setCompanyId(id);
      getCompanyName();
    }
  }, [id]);

  return { companyName };
}
