import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import * as React from 'react';
import { TIcon } from './TIcon';
const CheckboxIcon_1 = _compiledBlock(_props => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img" width={_props.v0} height={_props.v1}>
    <path fill={_props.v2} d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
  </svg>, {
  name: "CheckboxIcon_1",
  svg: true
});
export const CheckboxIcon: React.FC<TIcon> = ({
  width = 448,
  height = 512,
  color = '#000'
}) => /*@million jsx-skip*/<CheckboxIcon_1 v0={width} v1={height} v2={color} />;