import styled from 'styled-components';

export const YearMonthsStyles = styled.div`
  background: #fff;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px;
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  gap: 10px;

  .list {
    padding-left: 10px;
  }

  .year {
    &-label {
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &-arrow {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    &-active {
      transform: rotate(180deg);
    }

    &-checkmark {
      width: 22px;
      height: 22px;
      border: 1px solid rgb(229, 229, 229);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .months {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 4px;
  }

  .month {
    display: flex;
    align-items: center;
    gap: 8px;

    &-checkmark {
      width: 18px;
      height: 18px;
      border: 1px solid rgb(229, 229, 229);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    p {
        font-size: 12px;
        color: #888;
    }
  }
`;
