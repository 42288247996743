import styled from 'styled-components';

export const AddLanguageStyles = styled.div`
  .uploadError {
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }

  form {
    max-width: 544px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }
`;
