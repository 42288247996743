import styled from 'styled-components';

export const MultiSelectItemStyles = styled.div<{ selectable: boolean }>`
  .row {
    width: 100%;
    height: 32px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    box-sizing: border-box;
  }

  input {
    display: none;
    height: 32px;
  }

  p {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    max-height: 1.3em; /* Fallback for non-webkit, line-height * 2 */
    line-height: 1.3em;
    -webkit-line-clamp: 1; /* if you change this, make sure to change the fallback line-height and height */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }

  div {
    width: 100%;
    height: 100%;
    font-size: 14px;
    grid-template-columns: 1fr;

    .labelContent {
      display: grid;
      align-items: center;
      text-align: left;
      padding-left: 16px;
      width: 100%;
      height: 100%;
      color: #6e6e6e;
      box-sizing: border-box;
      grid-template-columns: ${({ selectable }) => (selectable ? 'auto 1fr' : '1fr')};
    }

    &.withImage .labelContent {
      grid-template-columns: ${({ selectable }) => (selectable ? '22px auto 1fr' : 'auto 1fr')};
      grid-gap: 8px;
    }

    &.expandButton {
      align-items: center;
      width: 24px;
    }
  }

  .emoji {
    display: flex;
  }
`;
