import styled from 'styled-components';

export const UploadFileButtonStyles = styled.div`
  width: 100%;

  input {
    display: none;
  }

  .uploadFileButtonContainer {
    position: relative;
  }
  .label {
    margin-bottom: 8px;
  }
`;
