import { isNaN, isArray } from 'lodash';

const regex = /^\d{4}-\d{2}-\d{2}T\d{2}%3A\d{2}%3A\d{2}\.\d{3}Z$/;
// parse value to string, number or ISO 8601 date string
const parseValue = (value: any): any => {
  // initial value can be URL encoded ISO 8601 date string
  if (regex.test(value)) {
    const decodedString = decodeURIComponent(value);
    return decodedString;
  }
  // check if value is not number
  if (isNaN(+value)) {
    return value;
  }
  return +value;
};

export const parseSearchString = (searchParams: any) => {
  const filterParams: { [key: string]: string | number | number[] | string[] } = {};
  const params = searchParams
    .toString()
    .split('&')
    .reduce(
      (
        acc: { [key: string]: string | number | number[] | string[] },
        item: any,
      ) => {
        const paramParts = item.split('=');
        const value = parseValue(paramParts[1]);

        if (['from', 'to', 'companyID'].includes(paramParts[0])) {
          acc[paramParts[0]] = value;
        }

        if (paramParts[0] in acc) {
          if (isArray(acc[paramParts[0]])) (acc[paramParts[0]] as (number | string)[]).push(value);
        } else {
          acc[paramParts[0]] = [value];
        }
        return acc;
      },
      {},
    );
  Object.keys(params).forEach((key) => {
    const value: number[] | string[] = params[key];
    filterParams[key] = value;
  });
  return filterParams;
};
// old parse method
export const oldParseSearchString = (searchParams: any) => {
  const filterParams = searchParams
    .toString()
    .split('&')
    .reduce(
      (
        acc: { [key: string]: string | number | number[] | string[] },
        item: any,
      ) => {
        const paramParts = item.split('=');
        if (paramParts[0] === 'statuses') {
          if ('statuses' in acc) {
            (acc.statuses as number[]).push(+paramParts[1]);
          } else {
            acc.statuses = [+paramParts[1]];
          }
        } else if (paramParts[0] === 'scores') {
          if ('scores' in acc) {
            (acc.scores as number[]).push(+paramParts[1]);
          } else {
            acc.scores = [+paramParts[1]];
          }
        } else if (paramParts[0] === 'nodes') {
          if ('nodes' in acc) {
            (acc.nodes as number[]).push(+paramParts[1]);
          } else {
            acc.nodes = [+paramParts[1]];
          }
        } else if (paramParts[0] === 'categories') {
          if ('categories' in acc) {
            (acc.categories as number[]).push(+paramParts[1]);
          } else {
            acc.categories = [+paramParts[1]];
          }
        } else if (paramParts[0] === 'sources') {
          if ('sources' in acc) {
            (acc.sources as number[]).push(+paramParts[1]);
          } else {
            acc.sources = [+paramParts[1]];
          }
        } else if (paramParts[0] === 'contacts') {
          if ('contacts' in acc) {
            (acc.contacts as string[]).push(paramParts[1]);
          } else {
            acc.contacts = [paramParts[1]];
          }
        } else if (paramParts[0] === 'attachmentTypes') {
          if ('attachmentTypes' in acc) {
            (acc.attachmentTypes as string[]).push(paramParts[1]);
          } else {
            acc.attachmentTypes = [paramParts[1]];
          }
        } else if (paramParts[0] === 'sentiment') {
          acc.sentiment = paramParts[1];
        } else if (paramParts[0] === 'from') {
          acc.from = paramParts[1].replaceAll('%3A', ':');
        } else if (paramParts[0] === 'to') {
          acc.to = paramParts[1].replaceAll('%3A', ':');
        } else if (paramParts[0] !== '' && paramParts[1] !== undefined) {
          acc[paramParts[0]] = +paramParts[1];
        }
        return acc;
      },
      {},
    );
  return filterParams;
};
