import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { FormLocationsStyles } from './styles';
import { useParams } from 'react-router-dom';
import { DeepArrayItem } from '../../../entities';
import { useClickOutside, useDebounce } from '../../../hooks';
import { filterDeepObjectArray, getNodesDeepArray } from '../../../utils';
import { expandAllSelectedItemsAndTheirParents, expandSearchedItemsAndTheirParents, findParentNode, getNodesBySelectedCompany } from './utils';
import { constructorApi } from '../../../api';
import { NodeFilterItem } from '../../molecules/reviewsTableFilters/nodeFilter/additionalSettings';
import { markAllNodesWithAllSelectedChildren } from '../../molecules/reviewsTableFilters/nodeFilter/utils';
import { ArrowDownIcon, SearchIcon } from '../../../assets';
import { Loader } from '../../atoms';
import { LocationSelectItem } from './locationSelectINodeItem';
export const FormLocations = () => {
  const {
    companyId,
    formId
  } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const [nodesExpanded, setNodesExpanded] = useState<{
    [key: number]: boolean;
  }>({});
  const [options, setOptions] = useState<DeepArrayItem[]>([]);
  const [nodesFiltered, setNodesFiltered] = useState<DeepArrayItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [attachedNodes, setAttachedNodes] = useState<number[]>([]);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const nodesRef: MutableRefObject<DeepArrayItem[]> = useRef<DeepArrayItem[]>([]);
  const nodeNamesRef: MutableRefObject<NodeFilterItem[]> = useRef<NodeFilterItem[]>([]);
  const debouncedSearchParams = useDebounce(searchParams, 1500);

  // console.log(attachedNodes);

  const addToExpandedItemsList = (id: number) => {
    setNodesExpanded(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };
  const clearExpandedItemsList = () => {
    setNodesExpanded({});
  };
  const handleExpandRow = (id: number) => {
    if (nodesExpanded) {
      addToExpandedItemsList(id);
    }
  };
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  useEffect(() => {
    setNodesFiltered(options);
  }, [options]);
  console.log(options);
  useEffect(() => {
    if (options) {
      if (debouncedSearchParams) {
        const nodesTree = filterDeepObjectArray(options, debouncedSearchParams, 'name');
        setNodesFiltered(nodesTree);
        clearExpandedItemsList();
        expandSearchedItemsAndTheirParents(nodesTree, addToExpandedItemsList);
      } else {
        setNodesFiltered([...options]);
        clearExpandedItemsList();
        expandAllSelectedItemsAndTheirParents(options, addToExpandedItemsList);
      }
    }
  }, [debouncedSearchParams]);
  useEffect(() => {
    if (formId) {
      constructorApi.getFormNodes(+formId).then(res => {
        if (res.statusCode === 200) {
          setAttachedNodes(res.data.map(el => el.id));
        }
      });
    }
  }, [formId, reQuery]);
  useEffect(() => {
    setLoading(true);
    if (attachedNodes && companyId) {
      getNodesBySelectedCompany(+companyId, nodesRef, nodeNamesRef).then(() => {
        const nodesArray = attachedNodes.length > 0 ? nodeNamesRef.current?.map(node => attachedNodes.includes(node.id) ? {
          ...node,
          selected: true
        } : node) : nodeNamesRef.current;
        nodesArray.forEach(item => {
          if (item.selected && item.deep !== 0) {
            findParentNode(nodesArray, item);
          }
        });
        const deepNodes = getNodesDeepArray(nodesArray);
        markAllNodesWithAllSelectedChildren(deepNodes);
        setOptions(deepNodes);
        setLoading(false);
      });
    }
  }, [formId, reQuery, attachedNodes]);
  useEffect(() => {
    setSearchParams('');
  }, [open]);
  return <FormLocationsStyles>
      <h3>Підʼєднані локації</h3>
      <div className="selectField">
        <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
          <span className="placeholder">Обрати локації</span>
          <div className={open ? 'arrowDownActive' : ''}>
            <ArrowDownIcon />
          </div>
        </div>
        {open && <div className="options" ref={optionsListRef}>
            {loading ? <Loader margin={5} /> : <>
                <div className="searchWrapper">
                  <SearchIcon />
                  <input type="text" value={searchParams} onChange={e => setSearchParams(e.target.value)} placeholder="Пошук" />
                </div>
                {nodesExpanded && nodesFiltered.map((option, index) => <LocationSelectItem id={+formId!} setReQuery={setReQuery} reQuery={reQuery} key={`itemKey:${[index]}`} path={[index]} option={option} selectKey="name" childrenKey="children" inputName="node" expandedRows={nodesExpanded} expandable={option.children.length > 0} handleExpandRow={handleExpandRow} setOpen={setOpen} selectable checkboxSelected={option.selected} checkboxHasSelectedChildren={option.hasSelectedChildren} checkboxAllChildrenSelected={option.allChildrenAreSelected} />)}
              </>}
          </div>}
      </div>
    </FormLocationsStyles>;
};