import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { AddLanguageStyles } from './AddLanguageStyles';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { getTranslationByLangOrEng } from '../../i18n';
import { languagesApi } from '../../api';
import { useAppSelector } from '../../state';
import { getErrorMessage, handleKeyUp } from '../../utils';
import { InputField, SubHeader, TransparentButton } from '../../components';
interface FormValues {
  name: string;
}
const initialValues: FormValues = {
  name: ''
};
export const AddLanguage = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const validationSchema = Yup.object({
    name: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required'))
  });
  function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setSubmitting(false);
    languagesApi.createLanguage({
      name: values.name
    }).then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        navigate('/languages');
      } else {
        setErrorMessage(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
      }
    });
  }
  const renderForm = ({
    values,
    errors,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
      <InputField name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'languages_add_name_placeholder')} value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'name')} />

      <SubmitButton>
        {getTranslationByLangOrEng(interfaceLanguage, 'add_button')}
      </SubmitButton>

      {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
    </Form>;
  return <AddLanguageStyles>
      <div className="formContainer">
        <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'languages_add_title')}>
          <TransparentButton handleClick={() => navigate('/languages')} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} filled />
        </SubHeader>

        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {renderForm}
        </Formik>
      </div>
    </AddLanguageStyles>;
};