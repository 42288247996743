import { CSSProperties, useEffect, useRef, useState } from 'react';
import { ArrowDownIcon } from '../../../assets';
import { DropdownSelectorStyles } from './styles';
import { useClickOutside } from '../../../hooks';
export const DropdownSelector = ({
  defaultValue,
  value,
  setValue,
  initialValues,
  containerStyles
}: {
  value: any;
  defaultValue?: any;
  setValue: (e: any) => void;
  initialValues: any[];
  containerStyles?: CSSProperties;
}) => {
  const containerRef = useRef<any>();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  useClickOutside(containerRef, () => setIsOpened(false));
  return <DropdownSelectorStyles style={containerStyles}>
      <div className="container" ref={containerRef}>
        <div className={`controls ${isOpened ? 'opened' : ''}`} onClick={() => {
        setIsOpened(e => !e);
      }}>
          <div className="value">
            {value ?? defaultValue ?? initialValues[0]}
          </div>
          <ArrowDownIcon />
        </div>
        <div className={`items ${isOpened ? 'opened' : ''}`}>
          {initialValues.map(e => <div onClick={() => setValue(e)} className={`item ${e === value ? 'selected' : ''}`}>
              {e}
            </div>)}
        </div>
      </div>
    </DropdownSelectorStyles>;
};