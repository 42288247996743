import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { CustomSelectStyles } from './CustomSelectStyles';
import { useClickOutside } from '../../../hooks';
import { ArrowDownIcon, SearchIcon } from '../../../assets';
import { getTranslationByLangOrEng } from '../../../i18n';
import { InView } from 'react-intersection-observer';
import { useAppSelector } from '../../../state';
import { reviewsStatusIconsAndText } from '../../../constants';
import { FormErrorMessage, Loader } from '../../atoms';
interface SelectProps {
  label?: string;
  name: string;
  emptyList?: string;
  options: any[];
  selectKey?: string;
  placeholder: string;
  value: any;
  handleSelect: (options: any) => any;
  property?: string | string[];
  search?: boolean;
  formGroupStyles?: CSSProperties;
  labelContainerClassName?: string;
  labelClassName?: string;
  propertyClassName?: string;
  optionsContainerStyles?: CSSProperties;
  hideError?: boolean;
  selectError?: string;
  selectErrorName?: string;
  emptyOption?: boolean;
  required?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  autoloadable?: boolean;
  autoloading?: boolean;
  handleAutoload?: () => Promise<void>;
}
const CustomSelect: React.FC<SelectProps> = ({
  label,
  name,
  emptyList,
  options,
  selectKey,
  placeholder,
  property,
  value,
  handleSelect,
  formGroupStyles,
  labelContainerClassName,
  labelClassName,
  propertyClassName,
  optionsContainerStyles,
  search,
  hideError,
  selectError,
  selectErrorName,
  emptyOption,
  required,
  isLoading,
  disabled,
  autoloadable,
  autoloading,
  handleAutoload
}) => {
  const {
    errors,
    touched
  } = useFormikContext();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const СurrentStatusIcon = name === 'status' && value && value?.hasOwnProperty('icon') ? reviewsStatusIconsAndText(interfaceLanguage)[value?.value || value?.id || 0]?.icon : null;
  const filteredParams = useMemo(() => {
    if (search) {
      return options.filter(option => (selectKey ? `${option[selectKey]}` : `${option}`).toLowerCase().includes(searchParams.toLowerCase()));
    }
    return options;
  }, [searchParams, options]);
  useEffect(() => {
    setSearchParams('');
  }, [open]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  return <CustomSelectStyles>
      <div className={`form-group${
    // @ts-ignore
    touched[name] && !errors[name] ? ' valid' // @ts-ignore
    : touched[name] && errors[name] ? ' error' : ''}`} style={formGroupStyles || {}}>
        {label && <div className={labelContainerClassName ? `labelContainer ${labelContainerClassName}` : 'labelContainer'}>
            <label htmlFor={name}>
              <span className={labelClassName || ''}>
                {label}
                {required && <span className="required"> *</span>}
              </span>
            </label>
          </div>}
        <div className="selectField">
          <div className="selectedValue" ref={selectedValueFieldRef} onClick={() => disabled || isLoading ? null : setOpen(!open)}>
            {value ? <div className={property ? 'valueContainer withExtraItem' : 'valueContainer'}>
                {property && typeof property === 'string' && <span className={propertyClassName ? `emoji ${propertyClassName}` : 'emoji'}>
                    {value[property]}
                  </span>}
                {property && Array.isArray(property) && <span className={propertyClassName ? `emoji ${propertyClassName}` : 'emoji'}>
                    {property.map(key => value[key]).join(', ')}
                  </span>}
                <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  {name === 'status' && value && value?.hasOwnProperty('icon') && <div style={{
                display: 'flex',
                marginRight: 10
              }}>
                      <СurrentStatusIcon width={20} height={20} />
                    </div>}
                  <span>{selectKey ? value[selectKey] : value}</span>
                </div>
                <div className={open ? 'arrowDownActive' : ''}>
                  <ArrowDownIcon />
                </div>
              </div> : <>
                <span className="placeholder">{placeholder}</span>
                <div className={open ? 'arrowDownActive' : ''}>
                  <ArrowDownIcon />
                </div>
              </>}
          </div>

          {(disabled || isLoading) && <div className="loading">
              {isLoading && <Loader margin={0} height={20} />}
            </div>}

          {open && <div className="options" style={optionsContainerStyles} ref={optionsListRef}>
              {search && <div className="searchWrapper">
                  <SearchIcon />
                  <input type="text" value={searchParams} onChange={e => setSearchParams(e.target.value)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')} />
                </div>}

              {emptyOption && filteredParams.length > 0 && <div className="option">
                  <div className="row">
                    <button type="button" className={property ? 'withImage' : ''} onClick={() => {
                handleSelect('');
                setOpen(!open);
              }}>
                      <div className="labelContent">-</div>
                    </button>
                  </div>
                </div>}

              {filteredParams.length === 0 ? <div className="option">
                  <div className="row">
                    <button type="button" disabled>
                      <div className="labelContent">
                        <span>{emptyList}</span>
                      </div>
                    </button>
                  </div>
                </div> : filteredParams.map(option => {
            const Icon = name === 'status' && option && option?.hasOwnProperty('icon') ? reviewsStatusIconsAndText(interfaceLanguage)[option?.value || option?.id || 0]?.icon : null;
            return <div className="option" key={`selectOption:${selectKey ? 'id' in option ? option.id : option[selectKey] : option}`}>
                      <div className="row">
                        <input autoComplete="off" type="radio" name={name} value={selectKey ? option[selectKey] : option} id={`${selectKey ? option[selectKey] : option}`} />
                        <button type="button" className={property ? 'withImage' : ''} onClick={() => {
                  handleSelect(option);
                  setOpen(!open);
                }}>
                          <div className="labelContent">
                            {property && typeof property === 'string' && <span className="emoji">{option[property]}</span>}
                            {property && Array.isArray(property) && <span className="emoji">
                                {property.map(key => option[key]).join(', ')}
                              </span>}
                            <div style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                              {name === 'status' && option && option?.hasOwnProperty('icon') && <div style={{
                        display: 'flex',
                        marginRight: 10
                      }}>
                                    <Icon width={20} height={20} />
                                  </div>}
                              <span>
                                {selectKey ? option[selectKey] : option}
                              </span>
                            </div>
                          </div>
                          {/* )} */}
                        </button>
                      </div>
                    </div>;
          })}

              {!searchParams && autoloadable && handleAutoload && <InView as="div" onChange={inView => {
            if (inView && !autoloading) {
              handleAutoload();
            }
          }}>
                  {autoloading ? <Loader margin={0} height={20} /> : <p className="allItemsUploaded">
                      {getTranslationByLangOrEng(interfaceLanguage, 'all_items_uploaded')}
                    </p>}
                </InView>}
            </div>}
        </div>
        {!hideError && <div className="selectErrorContainer">
            {selectError && <span>{selectError}</span>}
            {!selectError && touched && <ErrorMessage name={selectErrorName || name} component={FormErrorMessage} />}
          </div>}
      </div>
    </CustomSelectStyles>;
};
export default CustomSelect;