import styled from 'styled-components';

export const LoginStyles = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .formWrapper {
    position: relative;
    width: 95%;
    max-width: 544px;
    min-width: 320px;

    &::after {
      position: absolute;
      top: -2px;
      left: -2px;
      z-index: 0;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      content: '';
      background: linear-gradient(99.68deg, #c4ed99 7.23%, #88c5f2 106.37%);
    }

    .formContainer {
      background: #fff;
      position: relative;
      z-index: 1;
      padding: 56px 48px;
      display: flex;
      flex-direction: column;
      gap: 46px;
      @media screen and (max-width: 768px) {
        padding: 32px 16px;
      }

      h2 {
        font-size: 38px;
        line-height: 135%;
        font-weight: 400;

        @media screen and (max-width: 768px) {
          font-size: 25px;
        }
      }

      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
        .login-form {
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;
          gap: 46px;
          padding-bottom: 10px;
        }
      }

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
      }

      .form__link {
        line-height: 160%;
        font-weight: 400;
        font-size: 16px;
        text-decoration: none;
        color: #353637;
        cursor: pointer;

        &:link,
        &:visited,
        &:active,
        &:hover,
        &:focus {
          color: #353637;
        }
      }
    }
  }

  .selectorContainer {
    display: flex;
    position: relative;
    margin-bottom: 10px;
  }

  .selectorTitle {
    background-color: yellow;
    margin-bottom: 10px;
  }
  .selectorItems {
    background-color: blue;
  }

  .container {
    display: flex;
    position: relative;
    width: 100%;
    height: 35px;
    padding-bottom: 2px;
    line-height: 27.2px;
    border-bottom: 2px solid #000;
    &.opened {
      border-bottom: 2px solid transparent;
    }
    transition: 0.5s;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 10px; */
    /* background-color: blue; */
    .placeholder {
      /* background-color: green; */
      position: absolute;
      left: 0;
      color: #8c8e91;
      transition: 0.3s;
      &.selected {
        top: 0px;
        font-size: 12px;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
      }
    }

    .icon {
      width: 10px;
      height: 5px;
      transition: 0.5s;
      -webkit-transform-origin: center;
      transform-origin: center;
      position: absolute;
      /* top: -10px; */
      right: 0;
      color: #8c8e91;
      transition: 0.3s;
      &.opened {
        transform: rotate(3.141292rad);
      }
    }
  }
  .dropdown {
  }

  .value {
    width: 100%;
    p {
      white-space: nowrap;
      overflow: hidden;
      height: 35px;
      width: 95%;
      text-overflow: ellipsis;
    }
  }

  .dropdown-menu {
    background-color: red;
    /* opacity: 0.1; */
    display: none;
    position: absolute;
    top: 35px;
    left: 0;
    z-index: 10;
    width: 100%;
    max-height: 230px;
    /* padding: 16px 20px; */
    overflow-y: scroll;
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
    transition: 0.5s;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    flex-direction: column;
    &.fade-in-out {
      display: flex;
      opacity: 0;
      &.opened {
        opacity: 1;
      }
    }
    .dropdown-menu-item {
      margin-bottom: 9px;
    }
  }

  .selectedNodesContainer {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .selectedNode {
      background-color: white;
      border: 1px solid #d2dbe1;
      max-width: 170px;
      margin-bottom: 4px;
      border-radius: 5px;
      padding: 5px;
      margin-right: 5px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;
