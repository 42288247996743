import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { AnalyticsStartPageStyles } from './styles';
import { TTab } from '../../../pages';
import { ArrowLink } from '../../../assets';
type TAnalyticsStartPage = {
  setSelectedTab: Dispatch<SetStateAction<string>>;
  tabs: TTab[];
};
export const AnalyticsStartPage: FC<TAnalyticsStartPage> = ({
  setSelectedTab,
  tabs
}) => {
  const handleSelect = (tab: string) => {
    setSelectedTab(tab);
  };
  return <AnalyticsStartPageStyles>
      <h2>
        Звіт за результатами оцінки рівня задоволеності субʼєктів звернення якістю надання адмністративних послуг у центрах надання адміністративних послуг
      </h2>
      <div className="links">
        {tabs.map(el => <div key={el.key} className="links-item" onClick={() => handleSelect(el.key)}>
            <span>{el.text}</span>
            <ArrowLink />
          </div>)}
      </div>
    </AnalyticsStartPageStyles>;
};