import styled from 'styled-components';
export const CampaignStyles = styled.div`
  width: 100%;
  padding: 20px 0 0 20px;
  .controlsRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }
`;