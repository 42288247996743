import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICompany,
  INode,
  ICompanyDocument,
  ILanguage,
  IEmailTemplate,
  ISource,
} from '../../entities';

export interface CompanyState {
  selectedCompany: Partial<ICompany> | null;
  selectedCompanyLanguages: ILanguage[] | undefined;
  selectedNode: Partial<INode> | null;
  companyMetadata: Partial<ICompany> | undefined;
  nodeNames: { id: number; name: string; deep: number }[] | undefined;
  document: ICompanyDocument | undefined;
  location: string | undefined;
  emailTemplate: IEmailTemplate | undefined;
  nodeMetadata: Partial<INode | undefined>;
  companySources: ISource[] | null;
}

const initialState: CompanyState = {
  selectedCompany: null,
  selectedCompanyLanguages: undefined,
  selectedNode: null,
  companyMetadata: undefined,
  nodeNames: undefined,
  document: undefined,
  location: undefined,
  emailTemplate: undefined,
  nodeMetadata: undefined,
  companySources: null,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // Reducer comes here
    setSelectedCompany: (
      state,
      action: PayloadAction<Partial<ICompany> | null>,
    ) => ({ ...state, selectedCompany: action.payload }),
    setSelectedCompanyLanguages: (
      state,
      action: PayloadAction<ILanguage[] | undefined>,
    ) => ({ ...state, selectedCompanyLanguages: action.payload }),
    setSelectedNode: (state, action: PayloadAction<Partial<INode> | null>) => ({
      ...state,
      selectedNode: action.payload,
    }),
    setCompanyMetadata: (
      state,
      action: PayloadAction<Partial<ICompany> | undefined>,
    ) => ({ ...state, companyMetadata: action.payload }),
    setNodeNames: (
      state,
      action: PayloadAction<
        { id: number; name: string; deep: number }[] | undefined
      >,
    ) => ({ ...state, nodeNames: action.payload }),
    setDocument: (
      state,
      action: PayloadAction<ICompanyDocument | undefined>,
    ) => ({ ...state, document: action.payload }),
    setLocation: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      location: action.payload,
    }),
    setEmailTemplate: (
      state,
      action: PayloadAction<IEmailTemplate | undefined>,
    ) => ({ ...state, emailTemplate: action.payload }),
    setNodeMetadata: (
      state,
      action: PayloadAction<Partial<INode> | undefined>,
    ) => ({ ...state, nodeMetadata: action.payload }),
    setCompanySources: (state, action: PayloadAction<ISource[] | null>) => ({
      ...state,
      companySources: action.payload,
    }),
  },
});

export const { actions: companyActions, reducer: companyReducer } = companySlice;
export const {
  setSelectedCompany,
  setSelectedCompanyLanguages,
  setDocument,
  setSelectedNode,
  setCompanyMetadata,
  setNodeNames,
  setLocation,
  setEmailTemplate,
  setNodeMetadata,
  setCompanySources,
} = companyActions;
