import styled from 'styled-components';

export const SelectItemStyles = styled.div<{ selectable: boolean }>`
  .row {
    width: 100%;
    height: 32px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    box-sizing: border-box;
  }

  input {
    display: none;
    height: 32px;
  }

  button {
    width: 100%;
    height: 100%;
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    .labelContent {
      display: grid;
      align-items: center;
      text-align: left;
      padding-left: 16px;
      width: 100%;
      height: 100%;
      color: #6e6e6e;
      box-sizing: border-box;
      grid-template-columns: ${({ selectable }) => (selectable ? 'auto 1fr' : '1fr')};
    }

    &.withImage .labelContent {
      grid-template-columns: ${({ selectable }) => (selectable ? '22px auto 1fr' : 'auto 1fr')};
      grid-gap: 8px;
    }

    &.expandButton {
      align-items: center;
      width: 24px;
    }
  }

  .emoji {
    display: flex;
  }
`;
