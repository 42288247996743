import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { TagStyles } from './styles';
import { ApiTag, categoryApi, tagsApi } from '../../../api';
import { InputSingleField } from '../form/inputField/InputSingleField';
import { DeleteIcon, PencilIcon, SaveIcon } from '../../../assets';
type TTag = {
  tag: ApiTag;
  offset: number;
  setTags: Dispatch<SetStateAction<ApiTag[]>>;
};
export const Tag: FC<TTag> = ({
  tag,
  offset,
  setTags
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [targetName, setTargetName] = useState<string>(tag.name);
  const delTag = () => {
    if (tag.id) {
      tagsApi.deleteTag(tag.id).then(res => {
        if (res.statusCode === 200) {
          setTags(prev => prev.filter(el => el.id !== tag.id));
        }
      });
    }
  };
  const updateTag = () => {
    if (tag.id) {
      tagsApi.changeTag(tag.id, targetName).then(res => {
        setTags(prev => prev.map(el => {
          if (el.id === tag.id) {
            return {
              ...tag,
              ...res.data
            };
          }
          return el;
        }));
        setEdit(false);
      });
    }
  };
  return <TagStyles style={{
    paddingLeft: `${offset * 16}px`
  }}>
      {edit ? <>
          <InputSingleField value={targetName} name="tag" placeholder="Tag name" onChange={setTargetName} />
          <button type="button" onClick={updateTag}>
            <SaveIcon width={15} color="rgb(0, 0, 0)" height={15} />
          </button>
        </> : <div className="tag">
          <div className="tag-name">{tag.name}</div>
          <div className="tag-utils">
            <button type="button" onClick={() => setEdit(true)}>
              <PencilIcon width={15} height={15} />
            </button>
            <button type="button" onClick={delTag}>
              <DeleteIcon width={15} height={15} />
            </button>
          </div>
        </div>}
    </TagStyles>;
};