import React, { useCallback, useMemo, useState } from 'react';
import { QRColor } from '../../../api';
import { QRStylesColorPickerStyles } from './QRStylesColorPickerStyles';
import { ColorPicker, ColorPickerColor } from '../../molecules';
import { transformColorPickerValueToQrColor, transformQRColorToColorPickerColor } from './utils';
import { Modal } from '../../organisms';
import { useFormikContext } from 'formik';
interface QrStylesColorPickerProps {
  name: string;
  label: string;
  value: QRColor;
}
function QrStylesColorPicker({
  value,
  name,
  label
}: QrStylesColorPickerProps) {
  const {
    setFieldValue
  } = useFormikContext();
  const [pickerModalOpen, setPickerModalOpen] = useState<boolean>(false);
  const colorPickerValue = useMemo(() => transformQRColorToColorPickerColor(value), [value]);
  const changeValue = useCallback((color: ColorPickerColor) => {
    setFieldValue(name, transformColorPickerValueToQrColor(color));
  }, []);
  return <QRStylesColorPickerStyles>
      <button className="qrStylesColorPickerOpenerButton" type="button" onClick={() => setPickerModalOpen(true)}>
        <span>{label}:</span>

        <div className="colorPreview" style={{
        backgroundColor: `rgb(${value[0]}, ${value[1]}, ${value[2]})`
      }} />
      </button>

      {pickerModalOpen && <Modal onClose={() => setPickerModalOpen(false)}>
          <ColorPicker color={colorPickerValue} handleSubmit={color => {
        changeValue(color);
        setPickerModalOpen(false);
      }} otherButtonText="Змінити колір" />
        </Modal>}
    </QRStylesColorPickerStyles>;
}
export default React.memo(QrStylesColorPicker);