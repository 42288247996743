import { Dispatch, SetStateAction } from 'react';

export function handleKeyUp(
  key: string,
  setErrorHandler: Dispatch<SetStateAction<any>>,
  error: any,
) {
  setErrorHandler(
    typeof error === 'object' ? { ...error, [key]: undefined } : error,
  );
}

export function getErrorMessage(key: string, error: any) {
  return typeof error === 'object'
    ? error[key]
        && `* ${error[key].charAt(0).toUpperCase()}${error[key].substring(1)}`
    : undefined;
}
