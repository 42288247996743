import { translations } from '../i18n';

export function getInterfaceLanguage() {
  // if (localStorage.getItem('selectedLanguage')) {
  //   return localStorage.getItem('selectedLanguage')
  //     && Object.keys(translations).includes(
  //       localStorage.getItem('selectedLanguage')!,
  //     )
  //     ? localStorage.getItem('selectedLanguage')
  //     : 'UA';
  // }
  // return (
  //   navigator.languages
  //     .find((lang) => Object.keys(translations).find(
  //       (item) => item === lang.slice(0, 2).toUpperCase(),
  //     ))
  //     ?.slice(0, 2)
  //     .toUpperCase() || 'UA'
  // );
  return 'UA';
}
