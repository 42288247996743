/* eslint-disable class-methods-use-this */
import { IIssue } from '../../entities';
import baseApi from '../baseApi';
import {
  ApiCreateTicket,
  ApiCreateTicketStatus,
  ApiCreateTicketType,
  ApiIssue,
  ApiIssueStatus,
  ApiIssueType,
  ApiResponse,
} from '../entities';
import { mapApiIssuesToIIssues } from '../Mappers';
import IIssuesApi from './IIssuesApi';

class IssuesApi implements IIssuesApi {
  public async getFilteredTickets(
    filters: { [key: string]: string | number | string[] | number[] },
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<ApiIssue[]>> {
    const { statusCode, data: response } = await baseApi.postData(
      `/tickets/searches?limit=${limit}&offset=${offset}`,
      { ...filters },
    );

    return Promise.resolve({
      data: response.data,
      statusCode,
      count: response.count,
    });
  }

  public async getTicket(id: number): Promise<ApiResponse<ApiIssue>> {
    const { statusCode, data } = await baseApi.getData(`/tickets/${id}`);
    return Promise.resolve({ data, statusCode });
  }

  public async createTicket(
    companyId: number,
    data: ApiCreateTicket,
  ): Promise<ApiResponse<ApiIssue>> {
    const { statusCode, data: response } = await baseApi.postData(
      `/companies/${companyId}/tickets`,
      data,
    );

    return {
      data: response,
      statusCode,
    };
  }

  public async updateTicket(
    id: number | string,
    data: ApiCreateTicket,
  ): Promise<ApiResponse<ApiIssue>> {
    return baseApi.putData(`/tickets/${id}`, data);
  }

  public async getTicketStatusesByCompany(
    companyId: number,
  ): Promise<ApiResponse<ApiIssueStatus[]>> {
    const { data, statusCode } = await baseApi.getData(
      `/companies/${companyId}/ticket_statuses`,
    );

    return {
      data,
      statusCode,
    };
  }

  public async createTicketStatusByCompany(
    companyId: number,
    data: ApiCreateTicketStatus,
  ): Promise<ApiResponse<ApiIssueStatus>> {
    const response = await baseApi.postData(
      `/companies/${companyId}/ticket_statuses`,
      data,
    );

    return {
      data: response.data,
      statusCode: response.statusCode,
    };
  }

  public async deleteTicketStatus(id: number): Promise<ApiResponse<null>> {
    const res = await baseApi.deleteData(`/ticket_statuses/${id}`);

    return Promise.resolve({
      data: null,
      statusCode: res.statusCode,
    });
  }

  public async updateTicketStatus(
    id: number,
    data: ApiCreateTicketStatus,
  ): Promise<ApiResponse<ApiIssueStatus>> {
    const { data: response, statusCode } = await baseApi.putData(
      `/ticket_statuses/${id}`,
      data,
    );

    return {
      data: response,
      statusCode,
    };
  }

  public async getTicketTypesByCompany(
    companyId: number,
  ): Promise<ApiResponse<ApiIssueType[]>> {
    const { data, statusCode } = await baseApi.getData(
      `/companies/${companyId}/ticket_types`,
    );

    return {
      data,
      statusCode,
    };
  }

  public async getTicketsByReviewId(
    id: number,
  ): Promise<ApiResponse<ApiIssue[]>> {
    const { data, statusCode } = await baseApi.getData(
      `/reviews/${id}/tickets`,
    );

    return {
      data,
      statusCode,
    };
  }

  public async updateTicketType(
    id: number,
    data: ApiCreateTicketType,
  ): Promise<ApiResponse<ApiIssueType>> {
    const { data: response, statusCode } = await baseApi.putData(
      `/ticket_types/${id}`,
      data,
    );

    return {
      data: response,
      statusCode,
    };
  }

  public async createTicketTypeByCompany(
    companyId: number,
    data: ApiCreateTicketType,
  ): Promise<ApiResponse<ApiIssueType>> {
    const { data: response, statusCode } = await baseApi.postData(
      `/companies/${companyId}/ticket_types`,
      data,
    );

    return {
      data: response,
      statusCode,
    };
  }

  public async deleteTicketType(id: number): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.deleteData(`/ticket_types/${id}`);

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async deleteTicket(id: number): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.deleteData(`/tickets/${id}`);

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }
}

export const issuesApi = new IssuesApi();
