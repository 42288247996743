import styled from 'styled-components';

export const AnalyticsStartPageStyles = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  h2 {
    width: 80%;
    text-align: center;
    font-size: 24px;
  }

  .links {
    flex: 1;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 15px 25px;

    &-item {
      width: calc(50% - 15px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border: 3px solid rgb(77, 76, 74);
      background-color: rgb(239, 245, 249);
      padding: 15px 5px;

      svg {
        flex-shrink: 0;
      }

      span {
        font-size: 16px;
      }

      &:hover {
        background-color: rgb(77, 76, 74);
        color: #fff;

        svg {
            fill: #fff;
        }
      }
    }
  }
`;
