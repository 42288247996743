/* eslint-disable class-methods-use-this */
import {
  mapApiCompanyDocumentsToICompanyDocuments,
  mapFromFileToFormData,
} from '../Mappers';
import { ICompanyDocument } from '../../entities';
import { ApiResponse, ApiFile } from '../entities';
import IFilesApi from './IFilesApi';
import baseApi from '../baseApi';

class FilesApi implements IFilesApi {
  public async createFile(data: {
    file: File;
    width?: number;
    height?: number;
  }): Promise<ApiResponse<ApiFile>> {
    const formDataFromData = mapFromFileToFormData(data.file);
    return baseApi.postData('/file', formDataFromData, true);
  }

  public async getFile(id: number): Promise<ApiResponse<ApiFile>> {
    return baseApi.getData(`/file/${id}`);
  }

  public async updateFile(
    id: number,
    data: { file: File; width?: number; height?: number },
  ): Promise<ApiResponse<ApiFile>> {
    const formDataFromData = mapFromFileToFormData(data.file);
    return baseApi.putData(`/file/${id}`, formDataFromData, true);
  }

  public async deleteFile(id: number): Promise<ApiResponse<ApiFile>> {
    return baseApi.deleteData(`/file/${id}`);
  }

  public async getCompanyDocuments(
    id: number,
  ): Promise<ApiResponse<ICompanyDocument[]>> {
    const { data, statusCode } = await baseApi.getData(
      `/companies/${id}/documents`,
    );

    return {
      data: mapApiCompanyDocumentsToICompanyDocuments(data),
      statusCode,
    };
  }
}

export const filesApi = new FilesApi();
