import { IContact } from '../entities';

export const getTypeOfContact = (
  contact: IContact,
  reviewNetwork?: string,
): string => {
  if (contact.phone) {
    return reviewNetwork === 'sms' ? 'sms' : 'call';
  }
  if (contact.telegramID) {
    return 'telegram';
  }
  if (contact.whatsappID) {
    return 'whatsapp';
  }
  if (contact.viberID) {
    return 'viber';
  }
  return '';
};
