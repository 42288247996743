import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import * as React from 'react';
import { TIcon } from './TIcon';
const ExtraQuestionIcon_1_1 = _compiledBlock(_props => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" role="img" width={_props.v0} height={_props.v1}>
      <path fill={_props.v2} d="M96 96c-17.7 0-32 14.3-32 32s-14.3 32-32 32s-32-14.3-32-32C0 75 43 32 96 32h97c70.1 0 127 56.9 127 127c0 52.4-32.2 99.4-81 118.4l-63 24.5 0 18.1c0 17.7-14.3 32-32 32s-32-14.3-32-32V301.9c0-26.4 16.2-50.1 40.8-59.6l63-24.5C240 208.3 256 185 256 159c0-34.8-28.2-63-63-63H96zm48 384c-22.1 0-40-17.9-40-40s17.9-40 40-40s40 17.9 40 40s-17.9 40-40 40z" />
    </svg>, {
  name: "ExtraQuestionIcon_1_1",
  svg: true
});
const ExtraQuestionIcon_1 = ({
  width = 320,
  height = 512,
  color = '#000'
}) => /*@million jsx-skip*/<ExtraQuestionIcon_1_1 v0={width} v1={height} v2={color} />;
export const ExtraQuestionIcon: React.FC<TIcon> = React.memo(ExtraQuestionIcon_1);