import React from 'react';
import { useAppSelector } from '../../../state';
import { CompanyTagsAndCategoriesStyles } from './styles';
import { CompanyCategories, CompanyTags } from '../../molecules';
export const CompanyTagsAndCategories = () => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <CompanyTagsAndCategoriesStyles>
      <CompanyTags />
      <CompanyCategories />
    </CompanyTagsAndCategoriesStyles>;
};