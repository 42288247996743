/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiConstructorForm, constructorApi, exportApi, formApi } from '../../../api';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { Table } from '../table';
import { FormsStyles } from '../../../pages/companies/forms/styles';
import { CustomMenuField, CustomNameField } from '../../molecules';
import { TransparentButton } from '../../atoms';
import { DeleteIcon, DocumentIcon, PencilIcon } from '../../../assets';
import download from 'downloadjs';
import { Modal } from '../modal';
export const CompanyForms = React.memo(() => {
  const {
    id: companyId,
    nodeId
  } = useParams();
  const navigate = useNavigate();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [feedbackForms, setFeedbackForms] = useState<ApiConstructorForm[]>([]);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string;
    accessor: string;
    Cell?: any;
  }[]>();
  const [deleteError, setDeleteError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const formsCount = useRef<number>(0);
  const selectedFormsIdRef = useRef<number | null>(null);
  const handleDeleteRow = React.useCallback((id: number) => {
    constructorApi.deleteForm(id).then(res => {
      setFeedbackForms(prev => prev.filter(el => el.id !== id));
    });
  }, [feedbackForms]);
  const deleteForm = () => {
    if (selectedFormsIdRef.current) {
      formApi.deleteFeedbackForm(selectedFormsIdRef.current).then(res => {
        if (res.statusCode === 200) {
          handleDeleteRow(selectedFormsIdRef.current!);
          setModalDeleteVisible(false);
          selectedFormsIdRef.current = null;
          // setMenuVisible(false);
        } else {
          setDeleteError(true);
        }
      });
    }
  };
  const EditButtonsCell = React.useCallback((data: any) => {
    if (data.row.original) {
      return <CustomMenuField formId={data.row.original.id} companyId={+companyId!} handleDelete={handleDeleteRow} allFeedbackForms={feedbackForms || []} formName={data.row.original.name} />;
    }
    return null;
  }, [nodeId]);
  useEffect(() => {
    setLoading(true);
    if (companyId) {
      constructorApi.getCompanyForms(+companyId, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
        setFeedbackForms(prev => [...prev, ...res.data]);
        formsCount.current = res.count ? res.count : 0;
        setLoading(false);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [pageNumber]);
  const downloadJSON = (formId: number) => {
    exportApi.exportForm(formId).then(res => {
      const filename = `${res.data.name}.json`;
      const mimeType = 'application/json';
      download(JSON.stringify(res.data.features), filename, mimeType);
    });
  };
  useEffect(() => {
    if (feedbackForms.length) {
      setTableHeaders([{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_header_name'),
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <h3>{row.original.name}</h3>
      }, {
        Header: '',
        accessor: 'edit',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <button type="button" onClick={() => {
          navigate(`/companies/${companyId}/forms/${data.row.original.id}`);
        }}>
              <PencilIcon width={20} height={20} />
              <span>
                {getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_edit_button')}
              </span>
            </button>
      }, {
        Header: '',
        accessor: 'downloadJson',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <button type="button" onClick={() => downloadJSON(data.row.original.id)}>
              <DocumentIcon width={20} height={20} />
              <span>Викачати JSON</span>
            </button>
      }, {
        Header: '',
        accessor: 'delete',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <button type="button" onClick={() => {
          selectedFormsIdRef.current = data.row.original.id;
          setModalDeleteVisible(true);
        }}>
              <DeleteIcon />
              <span>
                {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
              </span>
            </button>
      }]);
    }
  }, [feedbackForms]);
  return <FormsStyles>
      <div className="searchWrapper" style={{
      padding: '10px 19px',
      justifyContent: 'flex-end'
    }}>
        <TransparentButton handleClick={() => navigate(`/companies/${companyId}/forms/create`)} text="Додати нову форму" filled />
      </div>
      <Table columns={tableHeaders || []} data={feedbackForms} hideFieldsSplitters hiddenHeaders={['customEditLink', 'editButtons', 'menu']} handleDeleteRow={handleDeleteRow} totalCount={formsCount.current} currentPage={pageNumber} offset={50} loading={loading} pageNumberHandler={value => setPageNumber(value)} />
      {modalDeleteVisible && <Modal onClose={() => {
      setModalDeleteVisible(false);
      selectedFormsIdRef.current = null;
    }} extraStyles={{
      width: 300
    }} title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_delete_button')}>
          <div className="modalButtonsWrap">
            <p className="modalButtonsCaption">
              {getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_delete_confirm_text')}
            </p>

            <button type="button" onClick={() => {
          setModalDeleteVisible(false);
          selectedFormsIdRef.current = null;
        }} className="declineButton">
              {getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
            </button>
            <button className="deleteButton" type="button" onClick={() => {
          deleteForm();
        }}>
              {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
            </button>

            {deleteError && <p className="modalError">
                {getTranslationByLangOrEng(interfaceLanguage, 'delete_error')}
              </p>}
          </div>
        </Modal>}
    </FormsStyles>;
});