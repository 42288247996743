import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../state';
import { StructureStyles } from './styles';
import { ApiCompanyNode, nodesApi } from '../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { SearchIcon } from '../../../assets';
import { DebounceInput } from 'react-debounce-input';
import { getTranslationByLangOrEng } from '../../../i18n';
import { Loader, TransparentButton } from '../../atoms';
import { Node, NodeBranch } from '../../molecules';
import { EditNodeModal } from '../modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from '../../../hooks';
export const Structure = () => {
  const {
    id
  } = useParams();
  const navigate = useNavigate();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [rootNodes, setRootNodes] = useState<ApiCompanyNode[]>([]);
  const [searchingNodes, setSearchingNodes] = useState<ApiCompanyNode[]>([]);
  const [selectedNode, setSelectedNode] = useState<string>('');
  const [editModal, setEditModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const searchedNods = useRef<number>(0);
  const debouncedSearchParams = useDebounce(searchParams, 500);
  const controller = new AbortController();
  const signal: AbortSignal = controller.signal;
  useEffect(() => {
    if (id) {
      nodesApi.getNodesByCompany(+id).then(res => {
        setRootNodes(res.data);
      });
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    if (id && debouncedSearchParams.length) {
      nodesApi.getFilteredNodesByCompany(+id, debouncedSearchParams, signal, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
        setSearchingNodes(prev => [...prev, ...res.data.map(el => ({
          ...el,
          isLeaf: true
        }))]);
        searchedNods.current = res.count || 0;
        setLoading(false);
      });
    } else {
      setSearchingNodes([]);
    }
  }, [debouncedSearchParams, pageNumber]);
  return <StructureStyles>
      <div className="searchWrapper">
        <SearchIcon />
        <input placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search_for_name')} type="text" value={searchParams} onChange={event => {
        controller.abort();
        setSearchingNodes([]);
        setPageNumber(1);
        setSearchParams(event.target.value);
      }} />
        <TransparentButton handleClick={() => navigate(`/companies/${id}/nodes/add`)} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_table_add_node_button')} filled />
      </div>
      {!!searchingNodes.length && <InfiniteScroll dataLength={searchingNodes.length} style={{
      height: 'auto',
      overflow: 'visible'
    }} next={() => setPageNumber(prev => prev + 1)} hasMore={pageNumber * 10 < searchedNods.current} loader={loading ? <Loader margin={10} /> : null}>
          {searchingNodes.map((el, index, array) => <NodeBranch key={el.id} node={el} setSelectedNode={setSelectedNode} setEditModal={setEditModal} isLast={index === array.length - 1} signal={signal} />)}
        </InfiniteScroll>}
      {!searchingNodes.length && <div className="nodeList">
          {rootNodes.map((el, index, array) => <Node key={el.id} isLast={index === array.length - 1} node={el} deep={0} setSelectedNode={setSelectedNode} setEditModal={setEditModal} setRootNodes={setRootNodes} />)}
        </div>}

      {selectedNode && editModal && <EditNodeModal onClose={() => {
      setEditModal(false);
    }} companyId={+id!} nodeId={selectedNode} />}
    </StructureStyles>;
};