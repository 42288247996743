import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyCategoriesStyles } from './styles';
import { ApiCategory, categoryApi } from '../../../api';
import { Category } from '../category';
import { InputSingleField } from '../form/inputField/InputSingleField';
import { JustPlusIcon, SaveIcon } from '../../../assets';
export const CompanyCategories = () => {
  const {
    id
  } = useParams();
  const [categories, setCategories] = useState<ApiCategory[]>([]);
  const [adding, setAdding] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const createCategory = () => {
    if (name.length && id) {
      categoryApi.createCategory(+id, {
        name
      }).then(res => {
        setCategories(prev => [...prev, res.data]);
        setName('');
        setAdding(false);
      });
    }
  };
  useEffect(() => {
    if (id) {
      categoryApi.getCategoriesByCompany(+id).then(res => {
        setCategories(res.data);
      });
    }
  }, []);
  return <CompanyCategoriesStyles>
      <div className="headWrapper">
        <span>Категорії</span>
        <button onClick={() => setAdding(!adding)} type="button">
          <JustPlusIcon width={20} color="rgb(0, 0, 0)" height={20} />
        </button>
      </div>
      {categories && id && categories.map(el => <Category key={el.id} offset={1} category={el} companyID={+id} setCategories={setCategories} />)}
      {adding && <div className="itemsWrapper">
          <InputSingleField value={name} name="category" placeholder="Category name" onChange={setName} />
          <button type="button" onClick={createCategory}>
            <SaveIcon width={20} color="rgb(0, 0, 0)" height={20} />
          </button>
        </div>}
    </CompanyCategoriesStyles>;
};