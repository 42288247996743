import styled from 'styled-components';

export const QRPublishingDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
  align-items: center;
  overflow: auto;
  box-sizing: border-box;

  @media screen and (max-width: 1400px) {
    gap: 10px;
  }

  h2 {
    width: 80%;
    text-align: center;
    font-size: 24px;

    @media screen and (max-width: 1400px) {
      font-size: 18px;
    }
  }

  .rating {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box !important;

    &-col {
      box-sizing: border-box;
      font-size: 14px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1400px) {
        font-size: 12px;
      }
    }

    &-item {
      font-size: 14px;

      @media screen and (max-width: 1400px) {
        font-size: 12px;
      }
    }
  }
`;
