import { CampaignStyles } from './CampaignStyles';
import { CheckboxItem, FilterSelectInput, NameCustomField, Table, TransparentButton } from '../../../components';
import React, { FC, forwardRef, useEffect, useRef, useState } from 'react';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { TCampaignData } from '../../../components/organisms/campaign/TCampaign';
import { campaignApi } from '../../../api/campaign/CampaignApi';
import { ApiCampaign } from '../../../api/entities/ApiCampaigns';
import { createSearchParams } from 'react-router-dom';
import { extraFieldsSettings } from '../utils';
import format from 'date-fns/format';
export const CampaignTable = forwardRef<{
  [key: string]: number | string | string[] | number[];
}, TCampaignData>(({
  companyID,
  setAddCampaign,
  setTemplater
}, filterParamsRef) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string;
    accessor: string;
    Cell?: any;
  }[]>();
  const [campainCount, setCampainCount] = useState<number>(0);
  const [campaignValues, setCampaigns] = useState<ApiCampaign[]>();
  const [filterVariants, setFilterVariants] = useState<{
    [key: string]: {
      id: string;
      value: boolean;
      name: string;
      icon?: any;
    }[];
  }>();
  const setFilterParams = (params: any) => {};
  const applyFilters = (params: any) => {
    // @ts-ignore
    filterParamsRef.current = params;
    setReQuery(e => !e);
  };
  useEffect(() => {
    // @ts-ignore
    const params = filterParamsRef.current;
    campaignApi.getCampaignDataByCompany(companyID, {
      ...(params.from ? {
        from: params.from
      } : {}),
      ...(params.to ? {
        to: params.to
      } : {}),
      ...(params.region ? {
        region: params.region
      } : {}),
      ...(params.serviceName ? {
        serviceName: params.serviceName
      } : {}),
      ...(params.clientGender ? {
        clientGender: params.clientGender
      } : {}),
      ...(params.serviceResult ? {
        serviceResult: params.serviceResult
      } : {}),
      ...(params.serviceStatus ? {
        serviceStatus: params.serviceStatus
      } : {}),
      ...(params.idf ? {
        idf: params.idf
      } : {}),
      // @ts-ignore
      ...(params.clientPhone && params.clientPhone.length < 2 // @ts-ignore
      ? {
        hasPhone: params.clientPhone[0] === '1'
      } : {}),
      // @ts-ignore
      ...(params.clientEmail && params.clientEmail.length < 2 // @ts-ignore
      ? {
        hasEmail: params.clientEmail[0] === '1'
      } : {})
    }, 0, 10).then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        setCampaigns(res.data);
        setCampainCount(res.count ?? 0);
      }
    });
  }, [companyID, reQuery]);
  useEffect(() => {
    campaignApi.getCampaignFilters(companyID).then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        const filters: {
          [key: string]: {
            id: string;
            value: boolean;
            name: string;
            icon: any;
          }[];
        } = {};
        Object.keys(res.data).forEach(key => {
          // @ts-ignore
          filters[key] = res.data[key].map((e: string) => ({
            id: e,
            value: false,
            name: e
          }));
        });
        // console.log();
        setFilterVariants({
          ...filters,
          clientPhone: [{
            id: '1',
            value: false,
            name: 'З номером телефону'
          }, {
            id: '2',
            value: false,
            name: 'Без номеру телефона'
          }],
          clientEmail: [{
            id: '1',
            value: false,
            name: 'З email'
          }, {
            id: '2',
            value: false,
            name: 'Без email'
          }]
        });
      }
    });
  }, [companyID]);
  useEffect(() => {
    if (campaignValues?.length && filterVariants) {
      setTableHeaders([{
        Header: '№',
        accessor: 'id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.id} />
      }, {
        Header: getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_node'),
        accessor: 'tsnap',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => {
          let fullName = data.row.original.tsnap;
          const spl = fullName.split(';');
          if (spl.length === 3) {
            fullName = [spl[0], spl[2]].join(';');
          }
          return <NameCustomField name={fullName} />;
        }
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: 'Область',
        // Header: () => (
        //   <FilterSelectInput
        //     type="checkbox"
        //     initiaFilterlValues={filterVariants.state}
        //     filtersOpenerStyles={{ minWidth: 220 }}
        //     columnName="state"
        //     title={getTranslationByLangOrEng(
        //       interfaceLanguage,
        //       'campaign_table_header_state',
        //     )}
        //     filterParams={filterParamsRef.current}
        //     setFilterParams={setFilterParams}
        //     setReQuery={() => setReQuery(!reQuery)}
        //     setApply={applyFilters}
        //   />
        // ),
        accessor: 'state',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.state?.name} />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput type="checkbox" initiaFilterlValues={filterVariants.region} filtersOpenerStyles={{
          minWidth: 220
        }} columnName="region" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_district')}
        // @ts-ignore
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'region',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.region} />
      }, {
        // Header: 'IDF',
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput type="checkbox" initiaFilterlValues={filterVariants.idf} filtersOpenerStyles={{
          minWidth: 220
        }} columnName="idf" title="IDF"
        // @ts-ignore
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'idf',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.idf} />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput type="dataInterval" filtersOpenerStyles={{
          minWidth: 220
        }} columnName="createdAt" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_visited_at')}
        // @ts-ignore
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'visited_at',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={format(new Date(data.row.original.visitedAt), 'dd.MM.yyyy HH:mm')} />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput type="checkbox" initiaFilterlValues={filterVariants.serviceName} filtersOpenerStyles={{
          minWidth: 220
        }} columnName="serviceName" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_service_name')}
        // @ts-ignore
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'serviceName',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.serviceName} />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput type="checkbox" initiaFilterlValues={filterVariants.serviceResult} filtersOpenerStyles={{
          minWidth: 220
        }} columnName="serviceResult" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_service_result')}
        // @ts-ignore
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'serviceResult',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.serviceResult} />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput type="checkbox" initiaFilterlValues={filterVariants.serviceStatus} filtersOpenerStyles={{
          minWidth: 220
        }} columnName="serviceStatus" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_service_status')}
        // @ts-ignore
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'service_status',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.serviceStatus} />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_service_in_time'),
        // Header: () => (
        //   <FilterSelectInput
        //     type="checkbox"
        //     initiaFilterlValues={filterVariants.serviceIntime}
        //     filtersOpenerStyles={{ minWidth: 220 }}
        //     columnName="serviceIntime"
        //     title={getTranslationByLangOrEng(
        //       interfaceLanguage,
        //       'campaign_table_header_service_in_time',
        //     )}
        //     filterParams={filterParamsRef.current}
        //     setFilterParams={setFilterParams}
        //     setReQuery={() => setReQuery(!reQuery)}
        //     setApply={applyFilters}
        //   />
        // ),
        accessor: 'serviceIntime',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.serviceInTime ? 'Вчасно' : 'Невчасно'} />
      }, {
        // Header: getTranslationByLangOrEng(interfaceLanguage, 'phone'),
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput type="checkbox" initiaFilterlValues={filterVariants.clientPhone} filtersOpenerStyles={{
          minWidth: 220
        }} columnName="clientPhone" title="Номер телефону"
        // @ts-ignore
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'clientPhone',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.clientPhone} />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput type="checkbox" initiaFilterlValues={filterVariants.clientEmail} filtersOpenerStyles={{
          minWidth: 220
        }} columnName="clientEmail" title="Email"
        // @ts-ignore
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'clientEmail',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.clientEmail} />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput type="checkbox" initiaFilterlValues={filterVariants.clientGender} filtersOpenerStyles={{
          minWidth: 220
        }} columnName="clientGender" title={getTranslationByLangOrEng(interfaceLanguage, 'campaign_table_header_client_gender')}
        // @ts-ignore
        filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'clientGender',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.clientGender} />
      }]);
    }
    // @ts-ignore
  }, [campaignValues, filterParamsRef?.current, companyID, filterVariants]);
  return <CampaignStyles>
      <div className="controlsRow">
        {campaignValues && <p style={{
        marginLeft: 10
      }}>Кількість контактів: {campainCount}</p>}
        <TransparentButton filled text="Створити розсилку" handleClick={setAddCampaign} />
        <TransparentButton filled text="Редактор шаблонів" handleClick={setTemplater} />
      </div>
      {tableHeaders && campaignValues && <Table minHeight="600px" columns={tableHeaders} data={campaignValues}
    // onClickToRow={handleRowClick}
    extraFieldsSettings={extraFieldsSettings} />}
    </CampaignStyles>;
});

// eslint-disable-next-line no-empty-pattern
// export const CampaignTable: FC<TCampaignData> = React.memo(
//   ({ companyID, setAddCampaign, setTemplater }) => {
//     const { interfaceLanguage } = useAppSelector((state) => state.languages);
//     const [reQuery, setReQuery] = useState<boolean>(false);
//     const [tableHeaders, setTableHeaders] = useState<{ Header: string; accessor: string; Cell?: any }[]>();
//     const [campainCount, setCampainCount] = useState<number>(0);
//     const [campaignValues, setCampaigns] = useState<ApiCampaign[]>();
//     const [filterVariants, setFilterVariants] = useState<{
//       [key: string]: {
//         id: string;
//         value: boolean;
//         name: string;
//         icon?: any;
//       }[];
//     }>();
//     const filterParamsRef = useRef<{
//       [key: string]: number | string | string[] | number[];
//     }>({
//       companyID,
//     });

//     const setFilterParams = (params: any) => {};

//     const applyFilters = (params: any) => {
//       filterParamsRef.current = params;
//       setReQuery((e) => !e);
//     };

//     useEffect(() => {
//       const params = filterParamsRef.current;
//       campaignApi
//         .getCampaignDataByCompany(
//           companyID,
//           {
//             ...(params.from ? { from: params.from } : {}),
//             ...(params.to ? { to: params.to } : {}),
//             ...(params.region ? { region: params.region } : {}),
//             ...(params.serviceName ? { serviceТame: params.serviceName } : {}),
//             ...(params.clientGender
//               ? { clientGender: params.clientGender }
//               : {}),
//             ...(params.serviceResult
//               ? { serviceResult: params.serviceResult }
//               : {}),
//             ...(params.serviceStatus
//               ? { serviceStatus: params.serviceStatus }
//               : {}),
//             ...(params.idf ? { idf: params.idf } : {}),
//             // @ts-ignore
//             ...(params.clientPhone && params.clientPhone.length < 2 // @ts-ignore
//               ? { hasPhone: params.clientPhone[0] === '1' }
//               : {}),
//             // @ts-ignore
//             ...(params.clientEmail && params.clientEmail.length < 2 // @ts-ignore
//               ? { hasEmail: params.clientEmail[0] === '1' }
//               : {}),
//           },
//           0,
//           10,
//         )
//         .then((res) => {
//           if (res.statusCode >= 200 && res.statusCode < 300) {
//             setCampaigns(res.data);
//             setCampainCount(res.count ?? 0);
//           }
//         });
//     }, [companyID, reQuery]);

//     useEffect(() => {
//       campaignApi.getCampaignFilters(companyID).then((res) => {
//         if (res.statusCode >= 200 && res.statusCode < 300) {
//           const filters: {
//             [key: string]: {
//               id: string;
//               value: boolean;
//               name: string;
//               icon: any;
//             }[];
//           } = {};

//           Object.keys(res.data).forEach((key) => {
//             // @ts-ignore
//             filters[key] = res.data[key].map((e: string) => ({
//               id: e,
//               value: false,
//               name: e,
//             }));
//           });
//           // console.log();
//           setFilterVariants({
//             ...filters,
//             clientPhone: [
//               {
//                 id: '1',
//                 value: false,
//                 name: 'З номером телефону',
//               },
//               {
//                 id: '2',
//                 value: false,
//                 name: 'Без номеру телефона',
//               },
//             ],
//             clientEmail: [
//               {
//                 id: '1',
//                 value: false,
//                 name: 'З email',
//               },
//               {
//                 id: '2',
//                 value: false,
//                 name: 'Без email',
//               },
//             ],
//           });
//         }
//       });
//     }, [companyID]);

//     useEffect(() => {
//       if (campaignValues?.length && filterVariants) {
//         setTableHeaders([
//           {
//             Header: '№',
//             accessor: 'id',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField name={data.row.original.id} />
//             ),
//           },
//           {
//             Header: getTranslationByLangOrEng(
//               interfaceLanguage,
//               'campaign_table_header_node',
//             ),
//             accessor: 'tsnap',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => {
//               let fullName = data.row.original.tsnap;
//               const spl = fullName.split(';');
//               if (spl.length === 3) {
//                 fullName = [spl[0], spl[2]].join(';');
//               }
//               return <NameCustomField name={fullName} />;
//             },
//           },
//           {
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: 'Область',
//             // Header: () => (
//             //   <FilterSelectInput
//             //     type="checkbox"
//             //     initiaFilterlValues={filterVariants.state}
//             //     filtersOpenerStyles={{ minWidth: 220 }}
//             //     columnName="state"
//             //     title={getTranslationByLangOrEng(
//             //       interfaceLanguage,
//             //       'campaign_table_header_state',
//             //     )}
//             //     filterParams={filterParamsRef.current}
//             //     setFilterParams={setFilterParams}
//             //     setReQuery={() => setReQuery(!reQuery)}
//             //     setApply={applyFilters}
//             //   />
//             // ),
//             accessor: 'state',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField name={data.row.original.state?.name} />
//             ),
//           },
//           {
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: () => (
//               <FilterSelectInput
//                 type="checkbox"
//                 initiaFilterlValues={filterVariants.region}
//                 filtersOpenerStyles={{ minWidth: 220 }}
//                 columnName="region"
//                 title={getTranslationByLangOrEng(
//                   interfaceLanguage,
//                   'campaign_table_header_district',
//                 )}
//                 filterParams={filterParamsRef.current}
//                 setFilterParams={setFilterParams}
//                 setReQuery={() => setReQuery(!reQuery)}
//                 setApply={applyFilters}
//               />
//             ),
//             accessor: 'region',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField name={data.row.original.region} />
//             ),
//           },
//           {
//             // Header: 'IDF',
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: () => (
//               <FilterSelectInput
//                 type="checkbox"
//                 initiaFilterlValues={filterVariants.idf}
//                 filtersOpenerStyles={{ minWidth: 220 }}
//                 columnName="idf"
//                 title="IDF"
//                 filterParams={filterParamsRef.current}
//                 setFilterParams={setFilterParams}
//                 setReQuery={() => setReQuery(!reQuery)}
//                 setApply={applyFilters}
//               />
//             ),
//             accessor: 'idf',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField name={data.row.original.idf} />
//             ),
//           },
//           {
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: () => (
//               <FilterSelectInput
//                 type="dataInterval"
//                 filtersOpenerStyles={{ minWidth: 220 }}
//                 columnName="createdAt"
//                 title={getTranslationByLangOrEng(
//                   interfaceLanguage,
//                   'campaign_table_header_visited_at',
//                 )}
//                 filterParams={filterParamsRef.current}
//                 setFilterParams={setFilterParams}
//                 setReQuery={() => setReQuery(!reQuery)}
//                 setApply={applyFilters}
//               />
//             ),
//             accessor: 'visited_at',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField
//                 name={format(
//                   new Date(data.row.original.visitedAt),
//                   'dd.MM.yyyy HH:mm',
//                 )}
//               />
//             ),
//           },
//           {
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: () => (
//               <FilterSelectInput
//                 type="checkbox"
//                 initiaFilterlValues={filterVariants.serviceName}
//                 filtersOpenerStyles={{ minWidth: 220 }}
//                 columnName="serviceName"
//                 title={getTranslationByLangOrEng(
//                   interfaceLanguage,
//                   'campaign_table_header_service_name',
//                 )}
//                 filterParams={filterParamsRef.current}
//                 setFilterParams={setFilterParams}
//                 setReQuery={() => setReQuery(!reQuery)}
//                 setApply={applyFilters}
//               />
//             ),
//             accessor: 'serviceName',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField name={data.row.original.serviceName} />
//             ),
//           },
//           {
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: () => (
//               <FilterSelectInput
//                 type="checkbox"
//                 initiaFilterlValues={filterVariants.serviceResult}
//                 filtersOpenerStyles={{ minWidth: 220 }}
//                 columnName="serviceResult"
//                 title={getTranslationByLangOrEng(
//                   interfaceLanguage,
//                   'campaign_table_header_service_result',
//                 )}
//                 filterParams={filterParamsRef.current}
//                 setFilterParams={setFilterParams}
//                 setReQuery={() => setReQuery(!reQuery)}
//                 setApply={applyFilters}
//               />
//             ),
//             accessor: 'serviceResult',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField name={data.row.original.serviceResult} />
//             ),
//           },
//           {
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: () => (
//               <FilterSelectInput
//                 type="checkbox"
//                 initiaFilterlValues={filterVariants.serviceStatus}
//                 filtersOpenerStyles={{ minWidth: 220 }}
//                 columnName="serviceStatus"
//                 title={getTranslationByLangOrEng(
//                   interfaceLanguage,
//                   'campaign_table_header_service_status',
//                 )}
//                 filterParams={filterParamsRef.current}
//                 setFilterParams={setFilterParams}
//                 setReQuery={() => setReQuery(!reQuery)}
//                 setApply={applyFilters}
//               />
//             ),
//             accessor: 'service_status',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField name={data.row.original.serviceStatus} />
//             ),
//           },
//           {
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: getTranslationByLangOrEng(
//               interfaceLanguage,
//               'campaign_table_header_service_in_time',
//             ),
//             // Header: () => (
//             //   <FilterSelectInput
//             //     type="checkbox"
//             //     initiaFilterlValues={filterVariants.serviceIntime}
//             //     filtersOpenerStyles={{ minWidth: 220 }}
//             //     columnName="serviceIntime"
//             //     title={getTranslationByLangOrEng(
//             //       interfaceLanguage,
//             //       'campaign_table_header_service_in_time',
//             //     )}
//             //     filterParams={filterParamsRef.current}
//             //     setFilterParams={setFilterParams}
//             //     setReQuery={() => setReQuery(!reQuery)}
//             //     setApply={applyFilters}
//             //   />
//             // ),
//             accessor: 'serviceIntime',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField
//                 name={data.row.original.serviceInTime ? 'Вчасно' : 'Невчасно'}
//               />
//             ),
//           },
//           {
//             // Header: getTranslationByLangOrEng(interfaceLanguage, 'phone'),
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: () => (
//               <FilterSelectInput
//                 type="checkbox"
//                 initiaFilterlValues={filterVariants.clientPhone}
//                 filtersOpenerStyles={{ minWidth: 220 }}
//                 columnName="clientPhone"
//                 title="Номер телефону"
//                 filterParams={filterParamsRef.current}
//                 setFilterParams={setFilterParams}
//                 setReQuery={() => setReQuery(!reQuery)}
//                 setApply={applyFilters}
//               />
//             ),
//             accessor: 'clientPhone',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField name={data.row.original.clientPhone} />
//             ),
//           },
//           {
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: () => (
//               <FilterSelectInput
//                 type="checkbox"
//                 initiaFilterlValues={filterVariants.clientEmail}
//                 filtersOpenerStyles={{ minWidth: 220 }}
//                 columnName="clientEmail"
//                 title="Email"
//                 filterParams={filterParamsRef.current}
//                 setFilterParams={setFilterParams}
//                 setReQuery={() => setReQuery(!reQuery)}
//                 setApply={applyFilters}
//               />
//             ),
//             accessor: 'clientEmail',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField name={data.row.original.clientEmail} />
//             ),
//           },

//           {
//             // @ts-ignore
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Header: () => (
//               <FilterSelectInput
//                 type="checkbox"
//                 initiaFilterlValues={filterVariants.clientGender}
//                 filtersOpenerStyles={{ minWidth: 220 }}
//                 columnName="clientGender"
//                 title={getTranslationByLangOrEng(
//                   interfaceLanguage,
//                   'campaign_table_header_client_gender',
//                 )}
//                 filterParams={filterParamsRef.current}
//                 setFilterParams={setFilterParams}
//                 setReQuery={() => setReQuery(!reQuery)}
//                 setApply={applyFilters}
//               />
//             ),
//             accessor: 'clientGender',
//             // eslint-disable-next-line react/no-unstable-nested-components
//             Cell: (data: any) => (
//               <NameCustomField name={data.row.original.clientGender} />
//             ),
//           },
//         ]);
//       }
//     }, [campaignValues, filterParamsRef.current]);

//     return (
//       <CampaignStyles>
//         <div className="controlsRow">
//           {campaignValues && (
//             <p style={{ marginLeft: 10 }}>
//               Кількість контактів: {campainCount}
//             </p>
//           )}
//           <TransparentButton
//             filled
//             text="Створити розсилку"
//             handleClick={setAddCampaign}
//           />
//           <TransparentButton
//             filled
//             text="Редактор шаблонів"
//             handleClick={setTemplater}
//           />
//         </div>
//         {tableHeaders && campaignValues && (
//           <Table
//             minHeight="600px"
//             columns={tableHeaders}
//             data={campaignValues}
//             // onClickToRow={handleRowClick}
//             extraFieldsSettings={extraFieldsSettings}
//           />
//         )}
//       </CampaignStyles>
//     );
//   },
// );