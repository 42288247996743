import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { TextAreaStyles } from './styles';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage } from '../formErrorMessage';
interface TextAreaProps {
  name: string;
  value: string | number | undefined;
  placeholder: string;
  onChange: (key: string, value: string) => void;
  extraBlockStyles?: CSSProperties;
  onKeyUp?: () => void;
  disabled?: boolean;
  showError?: boolean;
  error?: string | undefined;
  extraTextAreaStyles?: CSSProperties;
  onKeyPress?: any;
  cols?: number;
  rows?: number;
}
export const TextArea = ({
  name,
  placeholder,
  value,
  disabled,
  extraBlockStyles,
  onChange,
  onKeyUp,
  showError = true,
  error,
  extraTextAreaStyles,
  onKeyPress,
  cols,
  rows
}: TextAreaProps) => {
  const {
    touched
  } = useFormikContext();
  return <TextAreaStyles style={extraBlockStyles}>
      <textarea style={extraTextAreaStyles} name={name} value={value} onChange={event => onChange(name, event.target.value)} onKeyUp={onKeyUp} placeholder={placeholder} disabled={disabled} className={disabled ? 'disabled textArea' : 'textArea'} cols={cols} rows={rows} onKeyPress={onKeyPress} />
      {showError && <div className="formErrorContainer">
          {error && <span>{error}</span>}
          {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
        </div>}
    </TextAreaStyles>;
};