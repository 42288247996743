import styled from 'styled-components';
import image from '../../../assets/images/defaultCompanyImage.png';

export const TableStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .tableContainer {
    width: 100%;
    flex: 1;
    height: 100%;
    overflow: auto;

    .infinite-scroll-component__outerdiv {
      height: 100%;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    position: relative;

    &.fixed {
      thead {
        position: sticky;
        top: 0;
      }
    }
  }

  tr {
    border-bottom: 1px solid #dee6eb;
  }

  th,
  td {
    border-right: 1px solid #f0f0f0;
    vertical-align: middle;
    padding-top: 15px;
    padding-bottom: 15px;

    @media screen and (max-width: 1400px) {
      padding-bottom: 10px;
      padding-top: 10px;
    }


    &:last-child {
      border-right: none;
    }

    .fieldContent {
      padding: 0 16px;
      height: 100%;
      display: flex;
      align-items: center;

      span {
        &.withBackground {
          height: 24px;
          border-radius: 12px;
          padding: 0 8px;
          background-color: #eafbf2;
          display: flex;
          align-items: center;
        }
      }
    }

    .arrows {
      margin-left: 14px;
      display: flex;
      flex-direction: column;

      div {
        &:first-child {
          margin-bottom: -6px;
        }
        &:last-child {
          margin-top: -6px;
        }

        &.active svg {
          filter: invert(38%) sepia(19%) saturate(2462%) hue-rotate(129deg)
            brightness(109%) contrast(89%);
        }
      }

      .arrowUp {
        transform: rotate(180deg);
      }
    }
  }

  thead,
  tbody {
    vertical-align: middle;
  }

  thead {
    background-color: white;
    border-bottom: 2px solid black;
    th {
      padding: 5px;
    }

    @media screen and (max-width: 1400px) {
     font-size: 14px;
    }
  }

  .clickableRow {
    cursor: pointer;

    &:hover {
      background-color: #1bcab92b;
      /* border-bottom: 1px solid black; */
    }
  }

  .menuList {
    z-index: 1;
    position: absolute;
    top: calc(50% + 15px);
    right: 42px;
    width: 260px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));

    list-style: none;
    font-size: 14px;
    color: #000000;

    li {
      height: 41px;
      background: #ffffff;
      border-bottom: 1px solid #e5e5e5;
      box-sizing: border-box;

      &:last-child {
        border-bottom: none;
      }
    }

    button {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f0f5f5;
        transition: background-color 0.3s;
      }
    }

    span {
      margin-left: 12px;
    }
  }

  .imageContainer {
    margin-right: 8px;
    width: 64px;
    height: 48px;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: center center;
    padding: 2px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;

    .image {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &.default {
      background-color: #f0f0f0;
      display: grid;
      place-items: center;
    }
  }

  .dotsContainer {
    position: relative;
    width: 88px;

    button {
      padding: 0 20px;
    }
  }
`;
