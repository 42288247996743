import styled from 'styled-components';

export const LocationsOutOfRatingStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;

  align-items: center;
  @media screen and (max-width: 1400px) {
    gap: 10px;
  }

  h2 {
    width: 80%;
    text-align: center;
    font-size: 24px;

    @media screen and (max-width: 1400px) {
      font-size: 18px;
    }
  }

  .rating {
    width: 100%;
    flex: 1;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box !important;

    &-col {
      box-sizing: border-box;
      padding: 10px 15px;
      font-size: 14px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1400px) {
        padding: 6px 10px;
        font-size: 12px;
      }
    }

    &-item {
      font-size: 14px;

      @media screen and (max-width: 1400px) {
        font-size: 12px;
      }
    }
  }

  .counts {
    display: flex;
    align-items: center;
    gap: 25px;

    &__item {
      font-size: 16px;
    }
  }
`;
