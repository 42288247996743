import { IPreviewPosterTemplate } from '../../../entities/ICreatePosters';
import { ApiPosterTemplate } from '../../../api';

export const mapApiPosterDataToPreviewPosterData = (poster: ApiPosterTemplate): IPreviewPosterTemplate => ({
  url: poster.url,
  items: poster.items.map((posterItem) => {
    if (posterItem.type === 'qr') {
      return {
        x: posterItem.x,
        height: posterItem.height,
        width: posterItem.width,
        y: posterItem.y,
        type: posterItem.type,
        qrStyles: posterItem.qrStyles,
      }
    }
    return {
      x: posterItem.x,
      height: posterItem.height,
      width: posterItem.width,
      y: posterItem.y,
      type: posterItem.type,
      textStyles: posterItem.textStyles,
    }
  }),
})
