import { AcessRequestStyles } from './AcessRequestStyles';
import { getTranslationByLangOrEng } from '../../i18n';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiResponse } from '../../api';
import { availablePageSizes, hiddenReviewFilterStatuses, reviewsStatusIconsAndText } from '../../constants';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { accessesApi, AccessRequest } from '../../api/accessesApi/Accesses';
import format from 'date-fns/format';
import { FilterSelectInput, Loader, SubHeader, SubmitButton, Table, ToggleSwitcher, TransparentButton } from '../../components';
import { IReviewStatus } from '../../entities';
import { DropdownFilter } from '../../components/molecules/dropDownFilter';
import { FilterSelectInputStyles } from '../../components/molecules/reviewsTableFilters/filterSelectInput/FilterSelectInputStyles';
import { ArrowDownIcon, TableFilterIcon } from '../../assets';
import { useClickOutside } from '../../hooks';
export const initialServicesTypeValues = [{
  id: 'pending',
  value: false,
  name: 'Очікує'
}, {
  id: 'approved',
  value: false,
  name: 'Підтверджено'
}, {
  id: 'rejected',
  value: false,
  name: 'Відхилено'
}];
const statuses: {
  [key: string]: string;
} = {
  pending: 'Очікує',
  approved: 'Підтверджено',
  rejected: 'Відхилено'
};
const statusesColors: {
  [key: string]: string;
} = {
  pending: '#6c6c6c',
  approved: '#6c6c6c',
  rejected: '#6c6c6c'
};
function NameCustomField({
  name,
  logo,
  textColor
}: {
  name: string;
  logo?: string;
  textColor?: string;
}) {
  return <div style={{
    marginTop: 7,
    marginBottom: 7
  }} className="customNameWrapper">
      {logo && <div className={logo ? 'imageContainer' : 'imageContainer default'}>
          <div className="image">{logo && <img src={logo} alt="logo" />}</div>
        </div>}
      <span style={{
      color: textColor || '#000'
    }}>{name}</span>
    </div>;
}
type AccessRefType = (AccessRequest & {
  manager: any;
  worker: any;
  roles: any[];
})[];
export const AccessRequests = () => {
  const {
    pageNumber
  } = useParams();
  const navigate = useNavigate();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  // const [itemsListChanged, setItemsListChanged] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string;
    accessor: string;
    Cell?: any;
  }[]>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const isManager = true;
  const [isFilterOpened, setIsFilterOpened] = useState<boolean>(false);
  const filtersRef = useRef<any>();
  const shouldBeCanceled = useRef<boolean>(false);
  const accessesRef = useRef<AccessRefType>();
  const itemsQuantityRef = useRef<number>(0);
  const pageSizeRef = useRef<number>(availablePageSizes[0]);
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});

  // const isFilterQuestions = filterQuestions;
  useClickOutside(filtersRef, () => setIsFilterOpened(false));
  function workWithResponse(response: ApiResponse<AccessRefType>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const newItems = response.data;
      if (!shouldBeCanceled.current) {
        accessesRef.current = [...(Array.isArray(newItems) ? newItems : [])];
        itemsQuantityRef.current = response.count!;
        setUploading(false);
      }
    }
  }
  const fetchData = async () => {
    setUploading(true);
    accessesRef.current = undefined;
    const accesses = await accessesApi.accessReviewList({
      status: filterParamsRef.current.statuses,
      offset: (pageNumber ? +pageNumber - 1 : 0) * pageSizeRef.current,
      limit: pageSizeRef.current,
      isManager
    });
    workWithResponse({
      statusCode: accesses.statusCode,
      count: accesses.count,
      // @ts-ignore
      data: accesses.data
    });
  };
  useEffect(() => {
    if (!uploading) {
      fetchData();
    }
  }, [pageNumber, reQuery, isManager, filterParamsRef.current]);
  const applyFilters = (params: any) => {
    filterParamsRef.current = params;
    setReQuery(e => !e);
    // const path = createSearchParams(
    //   params as Record<string, string | string[]>,
    // );
    // navigate(`/reviews/page/1?${path}`);
  };

  // const filterItemsAfterDelete = useCallback(
  //   (id: number) => {
  //     rolesRef.current = rolesRef.current?.filter((user) => user.id !== id) || [];
  //     setItemsListChanged(!itemsListChanged);
  //   },
  //   [rolesRef.current, reQuery],
  // );

  const setFilterParams = (params: any) => {
    filterParamsRef.current = params;
    setIsFilterOpened(false);
  };
  const approveRequest = async (id: number) => {
    const res = await accessesApi.approveAccessRequest(id);
    setReQuery(e => !e);
  };
  const rejectRequest = async (id: number) => {
    const res = await accessesApi.rejectAccessRequest(id);
    setReQuery(e => !e);
  };
  const reviewStatuses = Object.values(reviewsStatusIconsAndText(interfaceLanguage))?.filter(item => !hiddenReviewFilterStatuses.includes(item?.value));
  console.log(`isFilterOpened: ${isFilterOpened}`);
  useEffect(() => {
    if (accessesRef.current) {
      setTableHeaders([{
        Header: 'ID',
        accessor: 'id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.id} textColor="#6c6c6c" />
      }, {
        Header: 'Від кого',
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.worker.name} textColor="#6c6c6c" />
      }, {
        Header: 'Кому',
        accessor: 'responsible_name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.manager.name} textColor="#6c6c6c" />
      }, {
        Header: 'Дата створення',
        accessor: 'date',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={format(new Date(data.row.original.createdAt), 'dd MMMM yyyy')} textColor="#6c6c6c" />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => {
          console.log(`isFilterOpened 2: ${isFilterOpened}`);
          return <FilterSelectInputStyles ref={filtersRef}>
                <div className="filterRow">
                  <div
              // style={filtersOpenerStyles}
              className={`filtersOpener ${isFilterOpened ? 'filtersOpener-active' : ''}`} onClick={() => {
                console.log('!first');
                setIsFilterOpened(!isFilterOpened);
              }}>
                    <span className="filterTitle">Статус</span>
                    <ArrowDownIcon />
                  </div>
                  {/* @ts-ignore */}
                  {filterParamsRef?.current?.statuses?.length && <TableFilterIcon width={15} height={15} />}
                </div>
                {isFilterOpened && <div style={{
              width: 250,
              position: 'absolute',
              top: 30
            }}>
                    <DropdownFilter name="statuses" initialValues={initialServicesTypeValues} filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(e => !e)}
              // @ts-ignore
              setApply={applyFilters}
              // @ts-ignore
              onClose={() => setIsFilterOpened(false)} />
                  </div>}
              </FilterSelectInputStyles>
          // <FilterSelectInput
          //   columnName="statuses"
          //   title="Статус"
          //   filterParams={filterParamsRef.current}
          //   setFilterParams={setFilterParams}
          //   setReQuery={() => setReQuery(!reQuery)}
          //   setApply={applyFilters}
          //   status={(reviewStatuses as IReviewStatus[]) || []}
          // />
          ;
        },
        accessor: 'status',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={statuses[data.row.original.status]} textColor={statusesColors[data.row.original.status]} />
      }, {
        Header: 'Фронт-офіс',
        accessor: 'nodes',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) =>
        // @ts-ignore
        <div style={{
          paddingTop: 5,
          paddingBottom: 5
        }}>
              {row.original.nodes.map((e: any) => <NameCustomField name={` - ${e.map((e: any) => e.name).join(', ')}`} textColor="#6c6c6c" />)}
            </div>
      }, {
        Header: 'Роль',
        accessor: 'role',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) =>
        // @ts-ignore
        // <div style={{ paddingTop: 5, paddingBottom: 5 }}>
        //   {row.original.nodes.map((e: any) => (
        <NameCustomField name={row.original.role} textColor="#6c6c6c" />
        //   ))}
        // </div>
      }, {
        Header: 'Опрацьовано',
        accessor: 'processedBy',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => row.original.processedBy ? <NameCustomField name={row.original.processedBy.name} textColor="#6c6c6c" /> : <div />
      }, {
        Header: '',
        accessor: 'accept',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => data.row.original.status === 'pending' ? <SubmitButton extraButtonStyles={{
          backgroundColor: '#04c65d',
          height: 32,
          paddingRight: 15,
          paddingLeft: 15
        }} onClick={() => approveRequest(data.row.original.id)}>
              <p>Підтвердити</p>
            </SubmitButton> : <div />
      }, {
        Header: '',
        accessor: 'reject',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => row.original.status === 'pending' || row.original.status === 'approved' ? <SubmitButton extraButtonStyles={{
          backgroundColor: '#000',
          height: 32,
          paddingRight: 15,
          paddingLeft: 15
        }} onClick={() => rejectRequest(row.original.id)}>
                <p>Відхилити</p>
              </SubmitButton> : <div />
      }]);
    }
  }, [accessesRef.current, reQuery, isFilterOpened, filterParamsRef.current]);
  const extraFieldsSettings = {
    editButtons: {
      justifyContent: 'flex-end'
    }
  };

  // const onClickToRow = async (data: any) => {};

  return <AcessRequestStyles>
      <Helmet>
        <title>Accesses Voicer</title>
      </Helmet>
      <div>
        <SubHeader title="Запити на доступ">
          <div className="buttonsContainer">
            <TransparentButton handleClick={() => navigate('/users')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
          </div>
        </SubHeader>
        {uploading && <Loader />}
        {!!accessesRef.current && !!tableHeaders && !uploading && <Table minHeight="400px" pagination columns={tableHeaders} data={accessesRef.current} hideFieldsSplitters extraFieldsSettings={extraFieldsSettings} headerColumnCounts={{
        role: itemsQuantityRef.current
      }} rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_role_title')} hiddenHeaders={['editButtons']} fullWidthColumns={['editButtons']} pageSize={pageSizeRef.current} currentPage={pageNumber ? +pageNumber : 1} rowsQuantity={itemsQuantityRef.current} pageNumberHandler={page => {
        if (page) {
          navigate(`/users/access_requests/page/${page}`);
        }
      }}
      // onClickToRow={onClickToRow}
      // sortableColumns={['name']}
      />}
      </div>
    </AcessRequestStyles>;
};