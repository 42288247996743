import styled from 'styled-components';

export const CompanyFieldsStyles = styled.div`
    display: flex;
    /* grid-template-columns: 1fr 300px; */
    justify-content: space-between;
    flex: 1;
    height: 100%;
    overflow: auto;

    .fields {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
        flex: 1;

        &-wrapper {
            
        }

        &-select {
            display: flex;
            flex-direction: column;
            gap: 25px;

            &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                h3 {
                    font-size: 24px;
                }

                button {
                    transform: rotate(45deg);
                }
            }
        }

        &-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
        }
    }

    .sidebar {
        display: flex;
        flex-direction: column;
        background: rgb(231, 238, 243);
        box-sizing: border-box;
        border-left: 1px solid rgb(229, 229, 229);
        height: 100%;
        max-width: 300px;

        .infinite-scroll-component__outerdiv {
            height: 100%;
            width: 100%;
        }

        &-title {
            flex-shrink: 0;
            display: flex;
            background: #fff;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            padding: 0px 16px;
            border-bottom: 1px solid rgb(229, 229, 229);

            button {
                cursor: pointer;
            }
        }

        &-search {
            flex-shrink: 0;
            height: 48px;
            padding: 0px 16px;
            border-bottom: 1px solid rgb(229, 229, 229);
            background-color: rgb(252, 252, 252);

            input {
                width: 100%;
                background: none;
                height: 100%;
                outline: none;
                border: none;
            }
        }

        &-list {
            padding: 16px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            position: relative;
            gap: 16px;
        }

        &-fields {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            position: relative;
            overflow: auto;
            /* height: 300px; */
        }
    }
`;
