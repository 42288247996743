import styled from 'styled-components';
export const DatePickerStyles = styled.div`
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 30%;

    .calendars {
      display: flex;
      flex-direction: row;
      gap: 20px;
      margin-bottom: 10px;

      @media screen and (max-width: 1024px) {
        flex-direction: 'column';
        gap: '8px';
      }

      .calendar {
        display: flex;
        flex-direction: column;
        text-align: center;

        span {
          margin-bottom: 8px;
        }

        .react-calendar {
          width: 250px;
        }

        .react-calendar__navigation button {
          min-width: 32px;
        }
      }
    }
  }
`;