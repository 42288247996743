import styled from 'styled-components';
export const NotificationsStyles = styled.div`
    width: 100%;

    .contacts-wrapper {
        margin 15px 0;
        
        .contacts {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            gap: 5px;

            div {
                display: flex;
                align-items: center;
                gap: 15px;
            }
        }
    }
`;