import { ApiIssueStatus, ApiResponse, issuesApi } from '../../api';

export const functionsForStatuses = [
  issuesApi.deleteTicketStatus,
  issuesApi.updateTicketStatus,
  issuesApi.createTicketStatusByCompany,
];

export const functionsForTypes = [
  issuesApi.deleteTicketType,
  issuesApi.updateTicketType,
  issuesApi.createTicketTypeByCompany,
];

async function manipulateData(
  dataType: 'type' | 'status',
  actionType: 'create' | 'update' | 'delete',
  items: any[],
  companyId: number,
) {
  if (items.length > 0) {
    const promises: Promise<ApiResponse<ApiIssueStatus | null>>[] = [];

    items.forEach((item) => {
      let action;

      if (dataType === 'type') {
        switch (actionType) {
          case 'create':
            action = issuesApi.createTicketTypeByCompany(companyId, item!);
            break;
          case 'update':
            action = issuesApi.updateTicketType(item.id, item!);
            break;
          case 'delete':
            action = issuesApi.deleteTicketType(item!);
            break;
        }
      }

      if (dataType === 'status') {
        switch (actionType) {
          case 'create':
            action = issuesApi.createTicketStatusByCompany(companyId, item!);
            break;
          case 'update':
            action = issuesApi.updateTicketStatus(item.id, item!);
            break;
          case 'delete':
            action = issuesApi.deleteTicketStatus(item!);
            break;
        }
      }

      if (action) {
        promises.push(action);
      }
    });

    await Promise.all(promises);
  }
}

export async function updateItemsList(
  dataType: 'type' | 'status',
  companyId: number,
  initialItems: any[],
  items: any[],
) {
  const initialItemNames = initialItems.reduce(
    (acc: { [key: number]: string }, item) => {
      if (item.id) {
        acc[item.id] = item.name;
      }

      return acc;
    },
    {},
  );

  const initialItemsIds = initialItems.map((item) => item.id!);
  const currentItemsIds = items.map((item) => item.id);
  const itemsToDelete: number[] = initialItemsIds.filter(
    (x) => !currentItemsIds.includes(x),
  );
  const itemsToUpdate: any[] = items.filter((item) => (item.id ? item.name !== initialItemNames[item.id] : false));
  const itemsToCreate: any[] = items.filter((item) => !item.id);

  await manipulateData(dataType, 'delete', itemsToDelete, companyId);
  await manipulateData(dataType, 'update', itemsToUpdate, companyId);
  await manipulateData(dataType, 'create', itemsToCreate, companyId);
}
