import React, { CSSProperties, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { CustomSelectStyles } from './CustomSelectStyles';
import { SearchIcon } from '../../../assets';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { translations } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { FormErrorMessage } from '../../atoms';
interface SelectProps {
  label?: string;
  name: string;
  options: any[];
  placeholder: string;
  value: any;
  renderItem: (option: any, index: number) => ReactElement<any, any>;
  renderSelect: (option: any) => ReactElement<any, any>;
  handleSelect: (option: any) => any;
  search?: boolean;
  formGroupStyles?: CSSProperties;
  optionsContainerStyles?: CSSProperties;
  extraStyles?: CSSProperties;
  hideErrors?: boolean;
  selectFieldExtraClassName?: string;
  optionsExtraClassName?: string;
  optionExtraClassName?: string;
  searchKey?: string;
}
const SelectWithAnyItems: React.FC<SelectProps> = ({
  label,
  name,
  options,
  placeholder,
  value,
  renderItem,
  renderSelect,
  selectFieldExtraClassName,
  optionsExtraClassName,
  optionExtraClassName,
  handleSelect,
  formGroupStyles,
  optionsContainerStyles,
  search,
  extraStyles,
  hideErrors,
  searchKey
}) => {
  const {
    errors,
    touched
  } = useFormikContext();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const filteredParams = useMemo(() => {
    if (search) {
      return options.filter(option => option[searchKey || 'name'].toLowerCase().includes(searchParams.toLowerCase()));
    }
    return options;
  }, [searchParams, options]);
  useEffect(() => {
    setSearchParams('');
  }, [open]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  return <CustomSelectStyles style={extraStyles}>
      <div className={`form-group${
    // @ts-ignore
    touched[name] && !errors[name] ? ' valid' // @ts-ignore
    : touched[name] && errors[name] ? ' error' : ''}`} style={formGroupStyles || {}}>
        {label && <div className="labelContainer">
            <label htmlFor={name}>{label}</label>
          </div>}
        <div className="selectField">
          <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
            {value ? <div className={selectFieldExtraClassName ? `valueContainer colors ${selectFieldExtraClassName}` : 'valueContainer colors'}>
                {renderSelect(value)}
              </div> : <span className="placeholder">{placeholder}</span>}
          </div>
          {open && <div className={optionsExtraClassName ? `options ${optionsExtraClassName}` : 'options'} style={optionsContainerStyles || {}} ref={optionsListRef}>
              {search && <div className="searchWrapper">
                  <SearchIcon />
                  <input type="text" value={searchParams} onChange={e => setSearchParams(e.target.value)} placeholder={translations[interfaceLanguage].search} />
                </div>}
              {filteredParams.map((option, index) => <div className={optionExtraClassName ? `option ${optionExtraClassName}` : 'option'} key={`selectOption:${option.id}:${option.name}`}>
                  <input autoComplete="off" type="radio" name={name} value={option.name} id={`${option.name}:${option.id}`} />
                  <button type="button" onClick={() => {
              handleSelect(option);
              setOpen(!open);
            }}>
                    {renderItem(option, index)}
                  </button>
                </div>)}
            </div>}
        </div>
        {!hideErrors && <div className="selectErrorContainer">
            <ErrorMessage name={name} component={FormErrorMessage} />
          </div>}
      </div>
    </CustomSelectStyles>;
};
export default SelectWithAnyItems;