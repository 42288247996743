import { ApiReviewFormAnswer } from '../../../api';

export enum DropDownKeys {
  // reviewID locally, cliendID backend name
  reviewID = 'clientID',
  comment = 'comment',
  phone = 'phone',
}

export type TDropDownValue = {
  name: DropDownKeys;
  value: boolean;
  label: string;
  placeholder: string;
};
