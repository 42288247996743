import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { CsiRegionRatingsDashboardStyles } from './styles';
import { BarChart, HorizontalBar, PieChart } from '../../analyticsCharts';
import { analyticsApi, ApiCsi, ApiCsiRegionRating, fieldsApi, TPostDashboardData } from '../../../../api';
import { DataItem } from '../../../../pages';
import { Loader } from '../../../atoms';
type PieData = {
  label: string;
  data: DataItem[];
};
type TCsiRegionRatingsDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[];
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const colors: string[] = ['#FFDB4D', '#89DB33', '#5B5AFF', '#CC2E4F'];
export const CsiRegionRatingsDashboard: FC<TCsiRegionRatingsDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const [ratingData, setRatingData] = useState<ApiCsiRegionRating | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const maxReviewsCount = useMemo(() => ratingData ? Math.max(...ratingData.reviews.map(el => el.views)) : 0, [ratingData]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostDashboardData = {
      from: from as string,
      to: to as string
    };
    setLoading(true);
    analyticsApi.getCsiRegionRatings(+companyID, filteredData).then(res => {
      setRatingData(res.data);
      setLoading(false);
    });
  }, [reQuery]);
  return <CsiRegionRatingsDashboardStyles>
      <h2>Рейтинг регіонів за регультатами оцінки рівня задоволеності</h2>

      {loading && <Loader />}

      {!loading && <div className="bars">
          <div className="bars-item">
            {!!ratingData?.csi.length && <HorizontalBar width={dimensions.width / 3.5} height={dimensions.height} hideAxis data={ratingData.csi.map(el => ({
          label: el.label,
          value: el.count,
          fill: colors[0]
        })).sort((a, b) => b.value - a.value)} />}
          </div>
          <div className="bars-item">
            {!!ratingData?.nps.length && <HorizontalBar width={dimensions.width / 3.5} height={dimensions.height} hideAxis data={ratingData.nps.map((el, index) => ({
          label: el.label,
          value: el.count,
          fill: el.count >= 0 ? colors[2] : colors[3]
        })).sort((a, b) => b.value - a.value)} />}
          </div>
          <div className="bars-item">
            {!!ratingData?.reviews.length && <HorizontalBar width={dimensions.width / 3.5} height={dimensions.height} hideAxis data={ratingData.reviews.map(el => ({
          label: el.label,
          value: el.views / maxReviewsCount * 100,
          fill: colors[1],
          count: el.views
        })).sort((a, b) => b.value - a.value)} />}
          </div>
        </div>}

    </CsiRegionRatingsDashboardStyles>;
};