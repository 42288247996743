import styled from 'styled-components';

export const AddIssueStyles = styled.div`
  width: 100%;

  .ticket {
    &-wrapper {
      width: 100%;
      max-width: 600px;
      padding: 0 25px;

      form {
        display: flex;
        flex-direction: column;
        gap: 25px;
      }
    }

    &-dashes  {
      width: 100%;
      border-bottom: 1px dashed rgb(225, 225, 225);
    }

    &-link {
      color: #898989;
      font-size: 14px;
    }
  }
`;
