import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CategoryStyles } from './styles';
import { ApiCategory, categoryApi } from '../../../api';
import { InputSingleField } from '../form/inputField/InputSingleField';
import { ArrowDownIcon, DeleteIcon, DotIcon, JustPlusIcon, PencilIcon, SaveIcon } from '../../../assets';
type TCategory = {
  category: ApiCategory;
  companyID: number;
  offset: number;
  setCategories: Dispatch<SetStateAction<ApiCategory[]>>;
};
const DotOffset: FC<{
  count: number;
}> = ({
  count
}) => <div className="dot-wrapper">
    {Array.from({
    length: count
  }, () => count).map(el => <DotIcon />)}
  </div>;
export const Category: FC<TCategory> = ({
  category,
  companyID,
  offset,
  setCategories
}) => {
  const [adding, setAdding] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [children, setChildren] = useState<ApiCategory[]>([]);
  const [name, setName] = useState<string>('');
  const [targetName, setTargetName] = useState<string>(category.name);
  const addCategory = () => {
    if (name.length) {
      categoryApi.createCategory(companyID, {
        name,
        parentID: category.id
      }).then(res => {
        setChildren(prev => [...prev, res.data]);
        setCategories(prev => prev.map(el => {
          if (el.id === category.id) {
            return {
              ...el,
              isLeaf: false
            };
          }
          return el;
        }));
        setName('');
        setAdding(false);
      });
    }
  };
  const delCategory = () => {
    categoryApi.delCategoryById(category.id).then(res => {
      if (res.statusCode === 200) {
        setCategories(prev => prev.filter(el => el.id !== category.id));
      }
    });
  };
  const updateCategory = () => {
    categoryApi.changeCategoryById(category.id, {
      name: targetName,
      parentID: category.parentID
    }).then(res => {
      setCategories(prev => prev.map(el => {
        if (el.id === category.id) {
          return {
            ...category,
            ...res.data
          };
        }
        return el;
      }));
      setName('');
      setEdit(false);
    });
  };
  useEffect(() => {
    if (!category.isLeaf && expanded) {
      categoryApi.getCategoriesByCompany(companyID, category.id).then(res => {
        setChildren(res.data);
      });
    }
  }, [expanded]);
  return <>
      <CategoryStyles style={{
      paddingLeft: `${offset * 16}px`
    }}>
        {edit ? <>
            {offset > 1 && <DotOffset count={offset - 1} />}
            <InputSingleField value={targetName} name="category" placeholder="Category name" onChange={setTargetName} />
            <button type="button" onClick={updateCategory}>
              <SaveIcon width={15} color="rgb(0, 0, 0)" height={15} />
            </button>
          </> : <>
            {offset > 1 && <DotOffset count={offset - 1} />}
            <div className="category">
              <div className="category-name">{category.name}</div>
              <div className="category-utils">
                <button type="button" onClick={() => setEdit(true)}>
                  <PencilIcon width={15} height={15} />
                </button>
                <button type="button" onClick={() => setAdding(!adding)}>
                  <JustPlusIcon width={15} color="rgb(0, 0, 0)" height={15} />
                </button>
                <button type="button" onClick={delCategory}>
                  <DeleteIcon width={15} height={15} />
                </button>
              </div>
            </div>
            {!category.isLeaf && <button type="button" onClick={() => setExpanded(!expanded)} className={expanded ? 'expand active' : 'expand'}>
                <ArrowDownIcon />
              </button>}
          </>}
      </CategoryStyles>
      {expanded && !!children.length && children.map(el => <Category category={el} key={el.id} companyID={companyID} offset={offset + 1} setCategories={setChildren} />)}
      {adding && <div className="itemsWrapper" style={{
      paddingLeft: `${offset * 16}px`
    }}>
          <InputSingleField value={name} name="category" placeholder="Category name" onChange={setName} />
          <button type="button" onClick={addCategory}>
            <SaveIcon width={20} color="rgb(0, 0, 0)" height={20} />
          </button>
        </div>}
    </>;
};