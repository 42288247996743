import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { TemplatersStyles } from './TemplatersStyles';
import { ApiGetTemplates, massMailingApiV2 } from '../../../api';
import { WrapBlocks, initialValues, tabsEdit } from './utils';
import { useAppSelector } from '../../../state';
import { DeleteIcon, PencilIcon, SimplePlusIcon } from '../../../assets';
import { CustomSelect, InputField } from '../../molecules';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { getTranslationByLangOrEng } from '../../../i18n';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import TextArea from '../../form/textArea/TextArea';
type TTemplaters = {
  companyID: number;
  setTemplater: Dispatch<SetStateAction<boolean>>;
};
const channels = [{
  channel: 'SMS'
}, {
  channel: 'Viber'
}, {
  channel: 'Email'
}];
export const Templaters: FC<TTemplaters> = ({
  companyID,
  setTemplater
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [selectedChanell, setSelectedChanell] = useState<{
    channel: 'SMS' | 'Viber' | 'Email';
  }>();
  const [templates, setTemplates] = useState<ApiGetTemplates[]>([]);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [selectedTemplates, setSelectedTemplates] = useState<ApiGetTemplates>(initialValues(companyID));
  const [edit, setEdit] = useState<boolean>(true);
  const validationSchema = Yup.object({
    name: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
    template: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required'))
  });
  const handleAdd = () => {
    setSelectedTemplates(initialValues(companyID));
    setEdit(true);
  };
  const handleDelete = (tempId: number) => {
    if (selectedChanell?.channel === 'SMS') {
      massMailingApiV2.deleteSmsTemplate(tempId).then(res => {
        setTemplates(prev => prev.filter(el => el.id !== tempId));
        setSelectedTemplates(initialValues(companyID));
        setEdit(true);
      });
    }
    if (selectedChanell?.channel === 'Viber') {
      massMailingApiV2.deleteViberTemplate(tempId).then(res => {
        setTemplates(prev => prev.filter(el => el.id !== tempId));
        setSelectedTemplates(initialValues(companyID));
        setEdit(true);
      });
    }
    if (selectedChanell?.channel === 'Email') {
      massMailingApiV2.deleteEmailTemplate(tempId).then(res => {
        setTemplates(prev => prev.filter(el => el.id !== tempId));
        setSelectedTemplates(initialValues(companyID));
        setEdit(true);
      });
    }
  };
  const onSubmit = async (values: ApiGetTemplates, {
    setSubmitting
  }: FormikHelpers<ApiGetTemplates>) => {
    setSubmitting(false);
    if (selectedChanell?.channel === 'SMS') {
      if (values.id) {
        massMailingApiV2.editSmsTemplate(values.id, {
          companyID: values.companyID,
          name: values.name,
          template: values.template
        }).then(res => {
          setTemplates(prev => prev.map(el => {
            if (el.id === values.id) {
              return res.data;
            }
            return el;
          }));
          setEdit(false);
          setTemplater(false);
        });
      } else {
        massMailingApiV2.createSmsTemplate({
          companyID: values.companyID,
          name: values.name,
          template: values.template
        }).then(res => {
          setTemplates(prev => [...prev, res.data]);
          setSelectedTemplates(res.data);
          setEdit(false);
          setTemplater(false);
        });
      }
    }
    if (selectedChanell?.channel === 'Viber') {
      if (values.id) {
        massMailingApiV2.editViberTemplate(values.id, {
          companyID: values.companyID,
          name: values.name,
          template: values.template
        }).then(res => {
          setTemplates(prev => prev.map(el => {
            if (el.id === values.id) {
              return res.data;
            }
            return el;
          }));
          setEdit(false);
          setTemplater(false);
        });
      } else {
        massMailingApiV2.createViberTemplate({
          companyID: values.companyID,
          name: values.name,
          template: values.template
        }).then(res => {
          setTemplates(prev => [...prev, res.data]);
          setSelectedTemplates(res.data);
          setEdit(false);
          setTemplater(false);
        });
      }
    }
    if (selectedChanell?.channel === 'Email') {
      if (values.id) {
        massMailingApiV2.editEmailTemplate(values.id, {
          companyID: values.companyID,
          name: values.name,
          template: values.template,
          subject: values.template
        }).then(res => {
          setTemplates(prev => prev.map(el => {
            if (el.id === values.id) {
              return res.data;
            }
            return el;
          }));
          setEdit(false);
          setTemplater(false);
        });
      } else {
        massMailingApiV2.createEmailTemplate({
          companyID: values.companyID,
          name: values.name,
          template: values.template,
          subject: values.template
        }).then(res => {
          setTemplates(prev => [...prev, res.data]);
          setSelectedTemplates(res.data);
          setEdit(false);
          setTemplater(false);
        });
      }
    }
  };
  useEffect(() => {
    setEdit(!selectedTemplates.id);
  }, [selectedTemplates]);
  useEffect(() => {
    if (selectedChanell?.channel === 'SMS') {
      massMailingApiV2.getSmsTemplates().then(res => {
        setTemplates(res.data.filter(e => e.companyID === companyID));
      });
    }
    if (selectedChanell?.channel === 'Viber') {
      massMailingApiV2.getViberTemplates().then(res => {
        setTemplates(res.data.filter(e => e.companyID === companyID));
      });
    }
    if (selectedChanell?.channel === 'Email') {
      massMailingApiV2.getEmailTemplates(companyID).then(res => {
        setTemplates(res.data.templates);
      });
    }
    setSelectedTemplates(initialValues(companyID));
  }, [selectedChanell?.channel]);
  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue
  }: FormikProps<ApiGetTemplates>) => <Form autoComplete="off">
      <InputField name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder="Введіть назву шаблона" value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} label="Template name" disabled={!edit} />
      {edit && <TextArea extraTextAreaStyles={{
      height: 290
    }} name="template" onChange={setFieldValue} onKeyUp={() => handleKeyUp('template', setErrorMessage, errorMessage)} placeholder="Введіть текст шаблона" error={typeof errorMessage === 'object' ? getErrorMessage('template', errorMessage) : undefined} value={values.template} />}
      {!edit && <WrapBlocks text={values.template} />}
      <SubmitButton isError={touched && Object.keys(errors).length > 0}>
        {values.id ? 'Оновити' : 'Створити'}
      </SubmitButton>
      {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
    </Form>;
  // console.log(selectedTemplates.template);
  return <TemplatersStyles>
      <CustomSelect containerStyle={{
      marginTop: 0
    }} name="channels" options={channels}
    // multiple
    placeholder="Канал комунікації" labelField="channel" selected={selectedChanell} onChange={value => {
      setSelectedChanell(value);
    }} />
      {(selectedChanell?.channel === 'SMS' || selectedChanell?.channel === 'Viber' || selectedChanell?.channel === 'Email') && <div className="tools">
          <CustomSelect options={templates} selected={selectedTemplates} valueField="id" labelField="name" name="template" onChange={setSelectedTemplates} placeholder="Оберіть шаблон" withoutForm />
          <button className="addNewTemplate" type="button" onClick={handleAdd}>
            <SimplePlusIcon color="#FFF" />
          </button>
        </div>}

      {(selectedChanell?.channel === 'SMS' || selectedChanell?.channel === 'Viber' || selectedChanell?.channel === 'Email') && <div className="form">
          {!edit && <button className="editTemplate" type="button" onClick={() => setEdit(true)}>
              <PencilIcon color="#FFF" />
            </button>}
          {!edit && <button className="deleteTemplate" type="button" onClick={() => handleDelete(selectedTemplates.id!)}>
              <DeleteIcon />
            </button>}
          {selectedChanell?.channel === 'Email' && selectedTemplates.template && !edit && <div className="preview">
                <iframe width="90%" srcDoc={selectedTemplates.template} title={selectedTemplates.id?.toString()} />
              </div>}
          {selectedTemplates && <Formik enableReinitialize initialValues={selectedTemplates} onSubmit={onSubmit} validationSchema={validationSchema}>
              {renderForm}
            </Formik>}
        </div>}
    </TemplatersStyles>;
};