export enum TabMailKeys {
  createCampain = 'createCampain',
  campains = 'campains',
}

export type TTabMail = {
  key: TabMailKeys;
  text: string;
};

export const tabsEdit: (lang: string) => TTabMail[] = (lang: string) => [
  {
    key: TabMailKeys.createCampain,
    text: 'Нові розсилки',
  },
  {
    key: TabMailKeys.campains,
    text: 'Відправлені розсилки',
  },
];

export const extraFieldsSettings = {
  tsnap: {
    width: '220px',
  },
};
