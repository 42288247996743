import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { FieldConstructorStyles } from './styles';
import { TFieldConstruct, typeNames } from '../companyFields';
import { DeleteIcon, Trash } from '../../../assets';
import { FieldIcon } from '../../atoms';
import { ApiCompanyField, FieldType, fieldsApi } from '../../../api';
import { Caption } from './caption';
import { Name } from './name';
import { Phone } from './phone';
import { DetailedResponse } from './detailedResponse';
import { Email } from './email';
import { Date } from './date';
import { Hidden } from './hidden';
import { Paragraph } from './paragraph';
import { Picture } from './picture';
import { Scale } from './scale';
import { Ces } from './ces';
import { Csat } from './csat';
import { Nps } from './nps';
import { Dropdown } from './dropdown';
import { RadioButton } from './radioButton';
import { CheckboxButton } from './checkbox';
import { FileUpload } from './fileUpload';
import { ConnectionChannel } from './connectionChannel';
import { QuantityField } from './quantity';
import { Confirmation } from './confirmation';
import { Button } from './button';
type TField = {
  field: TFieldConstruct;
  setFields: Dispatch<SetStateAction<ApiCompanyField[]>>;
  setField: Dispatch<SetStateAction<TFieldConstruct | null>>;
};
export const FieldConstructor: FC<TField> = ({
  field,
  setFields,
  setField
}) => {
  const handleRemove = (id: number) => {
    fieldsApi.deleteFormField(id).then(res => {
      if (res.statusCode === 200) {
        setFields(prev => prev.filter(el => el.id !== id));
        setField(null);
      }
    });
  };
  const getField = useCallback((field: TFieldConstruct) => {
    switch (field.type) {
      case FieldType.Caption:
        return <Caption setField={setField} setFields={setFields} field={field} />;
      case FieldType.Name:
        return <Name setField={setField} setFields={setFields} field={field} />;
      case FieldType.Phone:
        return <Phone setField={setField} setFields={setFields} field={field} />;
      case FieldType.DetailedResponse:
        return <DetailedResponse setField={setField} setFields={setFields} field={field} />;
      case FieldType.Email:
        return <Email setField={setField} setFields={setFields} field={field} />;
      case FieldType.Date:
        return <Date setField={setField} setFields={setFields} field={field} />;
      case FieldType.Paragraph:
        return <Paragraph setField={setField} setFields={setFields} field={field} />;
      case FieldType.Picture:
        return <Picture setField={setField} setFields={setFields} field={field} />;
      case FieldType.Scale:
        return <Scale setField={setField} setFields={setFields} field={field} />;
      case FieldType.Hidden:
        return <Hidden setField={setField} setFields={setFields} field={field} />;
      case FieldType.Ces:
        return <Ces setField={setField} setFields={setFields} field={field} />;
      case FieldType.Csat:
        return <Csat setField={setField} setFields={setFields} field={field} />;
      case FieldType.Nps:
        return <Nps setField={setField} setFields={setFields} field={field} />;
      case FieldType.Dropdown:
        return <Dropdown setField={setField} setFields={setFields} field={field} />;
      case FieldType.RadioButton:
        return <RadioButton setField={setField} setFields={setFields} field={field} />;
      case FieldType.Checkbox:
        return <CheckboxButton setField={setField} setFields={setFields} field={field} />;
      case FieldType.FileUpload:
        return <FileUpload setField={setField} setFields={setFields} field={field} />;
      case FieldType.ConnectionChannel:
        return <ConnectionChannel setField={setField} setFields={setFields} field={field} />;
      case FieldType.Quantity:
        return <QuantityField setField={setField} setFields={setFields} field={field} />;
      case FieldType.Confirmation:
        return <Confirmation setField={setField} setFields={setFields} field={field} />;
      case FieldType.Button:
        return <Button setField={setField} setFields={setFields} field={field} />;
    }
  }, [field]);
  return <FieldConstructorStyles>
      <div className="field-header">
        <div className="field-type">
          <FieldIcon type={field.type} />
          <p>{typeNames[field.type]}</p>
        </div>

        {!!field.id && <button type="button" onClick={() => setField(null)}>
            <DeleteIcon color="#C4C4C4" />
          </button>}
      </div>
      {getField(field)}
      <div className="field-utils">
        {!!field.id && <button type="button" onClick={() => handleRemove(field.id!)}>
            <Trash width={15} height={20} />
            <span>Видалити</span>
          </button>}
      </div>
    </FieldConstructorStyles>;
};