import { INode } from '../entities';

export function getNodesDeepDictionary(
  deepDictionary: { [key: number]: number },
  nodes: Partial<INode>[],
  deep = 0,
) {
  for (let i = 0; i < nodes.length; i++) {
    deepDictionary[nodes[i].id as number] = deep;

    if (nodes[i].subNodes?.length) {
      getNodesDeepDictionary(deepDictionary, nodes[i].subNodes!, deep + 1);
    }
  }
}

export function getNodesDictionary(
  nodesDictionary: { [key: number]: INode | Partial<INode> },
  nodes: (INode | Partial<INode>)[],
) {
  for (let i = 0; i < nodes.length; i++) {
    nodesDictionary[nodes[i].id as number] = nodes[i];

    if (nodes[i].subNodes?.length) {
      getNodesDictionary(nodesDictionary, nodes[i].subNodes!);
    }
  }
}

export function getNodesDeepEntriesArray(
  entriesArray: [number | string, number][],
  nodes: Partial<INode>[],
  deep = 0,
) {
  for (let i = 0; i < nodes.length; i++) {
    entriesArray.push([nodes[i].id as number, deep]);

    if (nodes[i].subNodes?.length) {
      getNodesDeepEntriesArray(entriesArray, nodes[i].subNodes!, deep + 1);
    }
  }
}

export function getNodesArray(
  array: Partial<INode>[],
  nodes: Partial<INode>[],
) {
  for (let i = 0; i < nodes.length; i++) {
    array.push(nodes[i]);

    if (nodes[i].subNodes?.length) {
      getNodesArray(array, nodes[i].subNodes!);
    }
  }
}

export function getNodeNamesArray(
  array: Partial<{ id: number; name: string; deep: number }>[],
  nodes: Partial<INode>[],
) {
  const nodeDeeps: { [key: number]: number } = {};
  getNodesDeepDictionary(nodeDeeps, nodes);

  function fillArray(nodesList: Partial<INode>[]) {
    for (let i = 0; i < nodesList.length; i++) {
      array.push({
        id: nodesList[i].id,
        name: nodesList[i].name,
        deep: nodeDeeps[nodesList[i].id!],
      });

      if (nodesList[i].subNodes?.length) {
        fillArray(nodesList[i].subNodes!);
      }
    }
  }

  fillArray(nodes);
}
