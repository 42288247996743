import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { PageContentStyles } from './styles';
import { ApiCompanyField, FieldType, TAdditionalParams, TAnswer, TLocalizations, TPage, localizationsApi, pagesApi } from '../../../api';
import { FieldPicker } from '../fieldPicker';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { FieldIcon, SubmitButton } from '../../atoms';
import { Checkbox, CustomSelect, InputField } from '../../molecules';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { DragDropContext, Droppable, Draggable, DropResult, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { addAdditionalParam } from '../fieldConstructor';
import { DeleteIcon } from '../../../assets';
import { toast } from 'react-toastify';
type TPageContent = {
  localization: TLocalizations;
  setLocalization: Dispatch<SetStateAction<TLocalizations | null>>;
  reQuery: boolean;
  setReQuery: Dispatch<SetStateAction<boolean>>;
  selectedPage: TPage | null;
  setSelectedPage: Dispatch<SetStateAction<TPage | null>>;
};
export interface IPage {
  nextPageID?: number;
  steps_text: string;
  is_submit: boolean;
}
const initialValue: IPage = {
  nextPageID: 0,
  steps_text: '',
  is_submit: false
};
export const validationSchema = Yup.object({});
const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle | undefined) => ({
  boxShadow: isDragging ? '0 0 10px rgba(0, 0, 0, 0.15)' : 'none',
  ...draggableStyle
});
export const PageContent: FC<TPageContent> = ({
  localization,
  setLocalization,
  reQuery,
  setReQuery,
  setSelectedPage,
  selectedPage
}) => {
  const {
    companyId,
    formId
  } = useParams();
  const [initialValues, setInitialValues] = useState<IPage>();
  const [targetPage, setTargetPage] = useState<TPage>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [formPages, setFormPages] = useState<TPage[]>([]);
  const [fields, setFields] = useState<ApiCompanyField[]>([]);
  const onDragEnd = (result: DropResult) => {
    const {
      destination,
      source
    } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    if (!targetPage) {
      return;
    }
    const updatedFields = Array.from(fields);
    const [movedField] = updatedFields.splice(source.index, 1);
    updatedFields.splice(destination.index, 0, movedField);
    pagesApi.deletePageField(targetPage.id, movedField.position!).then(res => {
      if (res.statusCode === 200) {
        pagesApi.addFieldToPage(targetPage.id, {
          fieldID: movedField.id!,
          position: destination.index + 1
        }).then(res => {
          if (res.statusCode === 201) {
            toast.success('Поле змінено', {
              autoClose: 10
            });
            setReQuery(!reQuery);
          }
        });
      }
    });
    setFields(updatedFields);
  };
  const handleDelete = (field: ApiCompanyField) => {
    if (targetPage) {
      pagesApi.deletePageField(targetPage.id, field.position!).then(res => {
        if (res.statusCode === 200) {
          setReQuery(!reQuery);
          toast.success('Поле видалено', {
            autoClose: 10
          });
        }
      });
    }
  };
  const handleTransitTo = (answer: TAnswer, pageID: number) => {
    if (targetPage) {
      if (answer.transitTo) {
        pagesApi.deleteOptionTransition(targetPage.id, answer.id).then(res => {
          if (res.statusCode === 200) {
            pagesApi.addOptionTransition(targetPage.id, {
              optionID: answer.id,
              transitTo: pageID
            }).then(res => {
              if (res.statusCode === 201) {
                setReQuery(!reQuery);
                toast.success('Перехід оновлено', {
                  autoClose: 10
                });
              }
            });
          }
        });
      } else {
        pagesApi.addOptionTransition(targetPage.id, {
          optionID: answer.id,
          transitTo: pageID
        }).then(res => {
          if (res.statusCode === 201) {
            setReQuery(!reQuery);
            toast.success('Перехід додано', {
              autoClose: 10
            });
          }
        });
      }
    }
  };
  const onSubmit = async (values: IPage, {
    setSubmitting
  }: FormikHelpers<IPage>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    const styles: TAdditionalParams[] = [];
    if (targetPage) {
      options = targetPage.options ? [...targetPage.options] : [];
      addAdditionalParam(values.steps_text, 'steps_text', options);
      addAdditionalParam(values.is_submit, 'is_submit', options);
      const changedPage: TPage = {
        ...targetPage,
        options
      };
      if (values.nextPageID) {
        changedPage.nextPageID = values.nextPageID;
      } else {
        delete changedPage.nextPageID;
      }
      pagesApi.updatePage(targetPage.id, changedPage).then(res => {
        if (res.statusCode === 200) {
          setReQuery(!reQuery);
          toast.success('Сторінку оновлено', {
            autoClose: 10
          });
        }
      });
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<IPage>) => <Form>
      <InputField extraBlockStyles={{
      margin: '25px 0'
    }} name="steps_text" onChange={setFieldValue} onKeyUp={() => handleKeyUp('steps_text', setErrorMessage, errorMessage)} placeholder="Введіть підпис кроку" value={values.steps_text} error={typeof errorMessage === 'object' ? getErrorMessage('steps_text', errorMessage) : undefined} />

      <div className="utils-info">
        <p>
          Ідентифікатор поточйної сторінки: <b>{targetPage?.id}</b>
        </p>
        <p>
          Ідентифікатор наступної сторінки: <b>{values.nextPageID}</b>
        </p>
      </div>

      <div className="utils-row">
        <CustomSelect extraStyles={{
        maxWidth: '300px'
      }} containerStyle={{
        marginTop: '0px'
      }} name="nextPageID" options={formPages || []} valueField="id" labelField="id" placeholder="Оберіть ІД наступної сторінки" selected={formPages.find(page => page.id === values.nextPageID)} onChange={(value: any) => {
        setFieldValue('nextPageID', value.id);
      }} search />

        {values.nextPageID && <button type="button" onClick={e => {
        setFieldValue('nextPageID', undefined);
      }}>
            <DeleteIcon color="#C4C4C4" />
          </button>}
      </div>

      <Checkbox name="is_submit" value={values.is_submit!} onChange={handleChange} extraBlockStyles={{
      marginTop: '20px'
    }}>
        <span className="checkboxValue">Сторінка відправки форми</span>
      </Checkbox>

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '5px 25px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    if (selectedPage) {
      pagesApi.getPage(selectedPage.id).then(res => {
        if (res.statusCode === 200) {
          const steps_text = res.data.options ? res.data.options.find(option => option.key === 'steps_text')?.value || '' : '';
          const is_submit = res.data.options ? res.data.options.find(option => option.key === 'is_submit')?.value === 'true' : false;
          const pageUtils: IPage = {
            steps_text,
            is_submit
          };
          if (res.data.nextPageID) {
            pageUtils.nextPageID = res.data.nextPageID;
          }
          setInitialValues(pageUtils);
          setTargetPage(res.data);
          setFields(res.data.fields ? res.data.fields.sort((a, b) => a.position! - b.position!) : []);
        }
      });
    }
  }, [selectedPage, reQuery]);
  useEffect(() => {
    if (targetPage) {
      localizationsApi.getLocalizationPages(localization.id).then(res => {
        setFormPages(res.data.sort((a, b) => a.id - b.id).filter(e => e.id !== targetPage?.id));
      });
    }
  }, [localization, reQuery, targetPage]);
  return <PageContentStyles>
      <div className="page">
        <div className="header">
          <h6>Налаштування додаткових парамтерів</h6>
        </div>
        <div className="utils">
          {initialValues && <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
              {renderForm}
            </Formik>}
        </div>
        <div className="header">
          <h6>Редагування полів сторінки</h6>
        </div>
        <DragDropContext onDragEnd={result => onDragEnd(result)}>
          <div className="settings">
            <Droppable droppableId="droppable">
              {(provided, snapshot) => <div {...provided.droppableProps} className="settings-list" ref={provided.innerRef}>
                  {fields.map((field, index) => <Draggable key={field.position?.toString()!} draggableId={field.position?.toString()!} index={index}>
                      {(provided, snapshot) => <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={field.id} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                          <div className="settings-item">
                            <div className="settings-item-header">
                              <FieldIcon type={field.type as FieldType} />
                              <p>{field.question}</p>

                              <button type="button" className="settings-item-del" onClick={e => {
                        e.stopPropagation();
                        handleDelete(field);
                      }}>
                                <DeleteIcon color="#C4C4C4" />
                              </button>
                            </div>
                            {!!field.answers && <div className="settings-item-answers">
                                <h6 className="settings-item-subtitle">
                                  Розгалудження форми:
                                </h6>
                                {field.answers.map(el => <div key={el.id} className="settings-item-answer">
                                    <p>{el.text}</p>
                                    <CustomSelect extraStyles={{
                          maxWidth: '120px'
                        }} containerStyle={{
                          marginTop: '0'
                        }} name="transitTo" options={formPages || []} valueField="id" labelField="id" placeholder="Перехід" selected={formPages.find(page => page.id === el.transitTo)} onChange={(value: any) => {
                          handleTransitTo(el, value.id);
                        }} search />
                                  </div>)}
                              </div>}
                          </div>
                        </div>}
                    </Draggable>)}
                  {provided.placeholder}
                </div>}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
      <FieldPicker fieldsCount={fields.length} selectedPage={selectedPage} reQuery={reQuery} setReQuery={setReQuery} />
    </PageContentStyles>;
};