/* eslint-disable class-methods-use-this */

import baseApi from '../baseApi';
import { ApiCompanyNode, ApiNode, ApiResponse } from '../entities';
import INodesApi from './INodesApi';

class NodesApi implements INodesApi {
  public async getNodesByCompany(id: number, parentID?: number): Promise<ApiResponse<ApiCompanyNode[]>> {
    return baseApi.getData(`/companies/${id}/nodes/children${parentID ? `?parentID=${parentID}` : ''}`);
  }

  public async getNodeByID(id: number): Promise<ApiResponse<ApiNode>> {
    return baseApi.getData(`/nodes/${id}`);
  }

  public async getChainNodes(id: number, signal: AbortSignal): Promise<ApiResponse<ApiCompanyNode[]>> {
    return baseApi.getData(`/nodes/${id}/chain`, false, true, signal);
  }

  public async getFilteredNodesByCompany(id: number, q: string, signal: AbortSignal, offset?: number, limit?: number): Promise<ApiResponse<ApiNode[]>> {
    const res = await baseApi.getData(`/companies/${id}/nodes/filtered?q=${q}&offset=${offset || 0}&limit=${limit || 10}`, false, true, signal);

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }
}

export const nodesApi = new NodesApi();
