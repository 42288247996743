import styled from 'styled-components';

export const GeneralFormInfoStyles = styled.div`
   max-width: 600px;
   padding: 15px;
   flex: 1;
   box-sizing: border-box;
   position: relative;
   overflow: auto;

   form {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 25px;
   }

   .radio {
      &-wrapper {
         display: flex;
         align-items: center;
         gap: 15px;
      }

      &-item {
         cursor: pointer;
         position: relative;
         display: flex;
         justify-content: center;
         align-items: center;
         background-color: rgb(255, 255, 255);
      }
   }

   .img {
      width: 100%;

      &-wrapper {
         margin-top: 10px;
         display: flex;
         align-items: center;
         gap: 16px;
      }

      &-container {
         display: flex;
         flex-direction: column;
      }

      &-target {
         height: 80px;
         width: 160px;
         background: rgb(255, 255, 255);
         box-sizing: border-box;
         overflow: hidden;
      }

      &-selected {
         width: 100%;
         height: 100%;
         object-fit: contain;
      }

      &-default {
         display: flex;
         align-items: center;
         justify-content: center;
         background: rgb(240, 240, 240);
         border: 1px solid rgb(229, 229, 229);
         border-radius: 4px;
      }

      &-upload {
         display: flex;
         flex-direction: column;
         gap: 10px;
         max-width: 300px;
         width: 100%;

         p {
            font-size: 12px;
            line-height: 120%;
            color: rgb(85, 85, 85);
         }
      }

      &-optionwrapper {
         display: flex;
         align-items: center;
         gap: 15px;
         width: 100%;
         padding: 5px 10px;
      }

      &-optionimg {
         max-width: 70px;

         img {
               width: 100%;
         }
      }
   }
`;
