import React, { useEffect, useRef, useState } from 'react';
import { CompanyFieldsStyles } from './styles';
import { ApiCompanyField, FieldType, fieldsApi } from '../../../api';
import { FieldSelector } from '../../molecules';
import { SimplePlusIcon } from '../../../assets';
import { TFieldConstruct, typeNames } from './utils';
import { useParams } from 'react-router-dom';
import { FieldConstructor } from '../fieldConstructor';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../atoms';
import { useDebounce } from '../../../hooks';
export const CompanyFields = () => {
  const {
    id
  } = useParams();
  const [field, setField] = useState<TFieldConstruct | null>(null);
  const [fields, setFields] = useState<ApiCompanyField[]>([]);
  const [showSelectedTypes, setShowSelectedTypes] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMoreFields, setHasMoreFields] = useState(true);
  const debouncedSearch = useDebounce(searchParams, 1000);
  const countFields = useRef<number>(0);
  const infiniteScrollRef = useRef<HTMLDivElement>(null);
  const fieldsContainerRef = useRef<HTMLDivElement>(null);
  const checkIfMoreFields = () => {
    if (infiniteScrollRef.current && fieldsContainerRef.current && fields.length) {
      const infiniteScrollHeight = infiniteScrollRef.current.clientHeight;
      const sidebarListHeight = fieldsContainerRef.current.clientHeight;
      if (infiniteScrollHeight >= sidebarListHeight && fields.length < countFields.current) {
        setPageNumber(prev => prev + 1);
      }
    }
  };
  useEffect(() => {
    checkIfMoreFields();
  }, [fields, countFields.current]);
  useEffect(() => {
    if (id) {
      setLoading(true);
      fieldsApi.getCompanyFields(+id, (pageNumber ? +pageNumber - 1 : 0) * 10, 10, searchParams).then(res => {
        setFields(prev => [...prev, ...res.data]);
        countFields.current = res.count || 0;
        if (fields.length + res.data.length >= (res.count || 0)) {
          setHasMoreFields(false);
        }
        setLoading(false);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [pageNumber, debouncedSearch]);
  useEffect(() => {
    setPageNumber(1);
    setFields([]);
    setHasMoreFields(true);
  }, [searchParams]);
  return <CompanyFieldsStyles>
      <div className="fields">
        {showSelectedTypes && <div className="fields-select">
            <div className="fields-select-title">
              <h3>Обрати тип поля форми</h3>
              <button type="button" onClick={event => {
            event.preventDefault();
            setField(null);
            setShowSelectedTypes(false);
          }}>
                <SimplePlusIcon width={24} height={24} color="#bababa" />
              </button>
            </div>
            <div className="fields-list">
              {Object.values(FieldType).map(type => <FieldSelector key={type} type={type} previewText={typeNames[type]} handleClick={() => {
            setField({
              type: type as FieldType
            });
            setShowSelectedTypes(false);
          }} />)}
            </div>
          </div>}

        {field && <FieldConstructor field={field} setFields={setFields} setField={setField} />}

        {!showSelectedTypes && !field && <h1>Поле не обрано</h1>}
      </div>
      <div className="sidebar">
        <div className="sidebar-title">
          <h4>Список полів суб’єкту моніторингу</h4>
          <button type="button" onClick={event => {
          event.preventDefault();
          setShowSelectedTypes(true);
          setField(null);
        }}>
            <SimplePlusIcon width={16} height={16} color="#000" />
          </button>
        </div>
        <div className="sidebar-search">
          <input type="text" value={searchParams} onChange={event => {
          setSearchParams(event.target.value);
        }} placeholder="Пошук..." />
        </div>
        <div className="sidebar-fields" ref={infiniteScrollRef} id="scrollableContainer">
          <InfiniteScroll dataLength={fields.length} next={() => {
          setPageNumber(prev => prev + 1);
        }} loader={loading ? <Loader margin={10} /> : null} hasMore={hasMoreFields} scrollableTarget="scrollableContainer">
            <div className="sidebar-list" ref={fieldsContainerRef}>
              {fields.filter(item => {
              const type = item.type as FieldType;
              return Object.values(FieldType).includes(type);
            }).map((el, index) => <FieldSelector key={`${el.type}-${index}`} type={el.type as FieldType} previewText={el.question} handleClick={() => {
              setField({
                type: el.type as FieldType,
                id: el.id
              });
              setShowSelectedTypes(false);
            }} />)}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </CompanyFieldsStyles>;
};