import { ConstructorTabKeys, TTabConstructor } from './TConstructor';
export const tabsConstructor: (lang: string) => TTabConstructor[] = lang => [{
  key: ConstructorTabKeys.general_info,
  text: 'Загальна інформація'
}, {
  key: ConstructorTabKeys.form_content,
  text: 'Контент форми'
}, {
  key: ConstructorTabKeys.form_btn,
  text: 'Кнопка відправки'
}, {
  key: ConstructorTabKeys.form_locations,
  text: 'Підʼєднані локації'
}];