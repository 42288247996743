import { getTranslationByLangOrEng } from '../../../i18n';
import { EditTabKeys, TTabsEdit } from './TEditCompany';

export const tabsEdit = (lang: string): TTabsEdit[] => [
  {
    key: EditTabKeys.info,
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_base_info'),
  },
  {
    key: EditTabKeys.design,
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_design'),
  },
  {
    key: EditTabKeys.documents,
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_documents'),
  },
  {
    key: EditTabKeys.issuesStatusesAndTypes,
    text: getTranslationByLangOrEng(
      lang,
      'companies_info_tab_issues_statuses_and_types',
    ),
  },
  {
    key: EditTabKeys.templates,
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_templates'),
  },
  {
    key: EditTabKeys.sources,
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_sources'),
  },
  {
    key: EditTabKeys.reviewTableColumnSettings,
    text: getTranslationByLangOrEng(
      lang,
      'companies_info_tab_review_table_columns_settings',
    ),
  },
  {
    key: EditTabKeys.settings,
    text: 'Налаштування',
  },
  {
    key: EditTabKeys.tags,
    text: 'Теги та категорії відгуків',
  },
  {
    key: EditTabKeys.qrStyles,
    text: 'Стилі QR',
  },
  {
    key: EditTabKeys.posters,
    text: 'Постери',
  },
];
