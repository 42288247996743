import { FC, useEffect, useState } from 'react';
import { JustPlusIcon } from '../../../../assets';
import { ApiCategory, categoryApi } from '../../../../api';
import { TChip } from '../types';
export const Chip: FC<TChip> = ({
  category,
  handleRemove
}) => {
  const [targetCategories, setTargetCategories] = useState<ApiCategory[]>([]);
  useEffect(() => {
    categoryApi.getChainCategories(category.id).then(res => {
      setTargetCategories(res.data.reverse());
    });
  }, []);
  return <div className="chip">
      <div className="chipText">
        {targetCategories.map(el => el.name).join(' / ')}
      </div>
      <div className="chipDel" onClick={() => handleRemove(category)}>
        <JustPlusIcon color="#999" width={20} height={20} />
      </div>
    </div>;
};