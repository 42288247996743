import React, { FC, useEffect } from 'react';
import { PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { Annotation, Connector, Label } from '@visx/annotation';
import { DataItem } from '../../../../../pages';
type TPieDataAnnotation = {
  title: string;
  path: any;
  arc: PieArcDatum<DataItem>;
  subtitle?: string;
  index: number;
};
const useDataAnnotationPosition = ({
  arc,
  path,
  index
}: {
  arc: PieArcDatum<DataItem>;
  path: any;
  index: number;
}): {
  labelX: number;
  labelY: number;
  surfaceX: number;
  surfaceY: number;
} => {
  const middleAngle = Math.PI / 2 - (arc.startAngle + (arc.endAngle - arc.startAngle) / 2);
  const outerRadius: number = path.outerRadius()(arc);
  const normalX = Math.cos(middleAngle);
  const normalY = Math.sin(-middleAngle);
  const labelX = normalX * outerRadius * (0.1 + 0.1 * (index % 2)) * (middleAngle < Math.PI ? 1 : -1);
  const labelY = normalY * outerRadius * (0.1 + 0.1 * (index % 2));
  const surfaceX = normalX * outerRadius;
  const surfaceY = normalY * outerRadius;
  return {
    labelX,
    labelY,
    surfaceX,
    surfaceY
  };
};
export const PieDataAnnotation: FC<TPieDataAnnotation> = ({
  title,
  path,
  arc,
  subtitle,
  index
}) => {
  const {
    surfaceX,
    surfaceY,
    labelX,
    labelY
  } = useDataAnnotationPosition({
    arc,
    path,
    index
  });
  useEffect(() => {}, []);
  return <Annotation x={surfaceX} y={surfaceY} dx={labelX} dy={labelY}>
      <Connector type="elbow" stroke="#000" className="csi-width" />
      <Label showBackground={false} showAnchorLine={false} title={subtitle}
    // subtitle={subtitle}
    titleFontSize={10} subtitleFontSize={8} />
    </Annotation>;
};