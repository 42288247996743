import styled from 'styled-components';

export const PostersDropdownStyles = styled.div`
  position: relative;

  width: 20px;
  height: 20px;
  
  .postersDropdown {
    position: absolute;
    z-index: 1;
    left: 0;
    transform: translateX(-50%);
    
    width: fit-content;
    padding: 8px;
    max-height: 300px;

    overflow: auto;
    
    background-color: #eef3f7;
    border: 1px solid #d2cbd7;
    border-radius: 4px;
    
    .downloadAllButton {
      text-align: center;
      border-bottom: 1px solid #d2cbd7;

      padding-bottom: 4px;
      margin-bottom: 6px;
    }
    
    .downloadPostersButtonsWrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
      
      .splitter {
        background-color: #d2cbd7;
        width: 100%;
        height: 1px;
        
        &:last-child {
          display: none;
        }
      }
    }
    
    button {
      white-space: nowrap;
      
      font-size: 16px;
      line-height: 120%;
      font-weight: 500;
    }
  }
`;
