/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import {
  ApiGoogleAccounts,
  ApiGoogleLocations,
  ApiResponse,
} from '../entities';
import IGoogleApi from './IGoogleApi';

class GoogleApi implements IGoogleApi {
  public async authGoogle(
    id: number,
  ): Promise<ApiResponse<{ authURL: string }>> {
    return baseApi.postData(`/companies/${id}/google-auth/login`);
  }

  public async getBusinessGoogle(
    id: number,
  ): Promise<ApiResponse<ApiGoogleAccounts[]>> {
    return baseApi.getData(`/companies/${id}/google-business/accounts`);
  }

  public async getTargetBusinessGoogle(
    id: number,
  ): Promise<ApiResponse<ApiGoogleAccounts>> {
    return baseApi.getData(`/companies/${id}/google-business/accounts/synced`);
  }

  public async syncGoogleAccount(
    id: number,
    accountID: string,
  ): Promise<ApiResponse<{ message: string }>> {
    return baseApi.postData(
      `/companies/${id}/google-business/accounts/${accountID}`,
    );
  }

  public async logoutGoogle(
    id: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return baseApi.postData(`/companies/${id}/google-auth/logout`);
  }

  public async getGoogleLocations(
    id: number,
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<ApiGoogleLocations[]>> {
    const res = await baseApi.getData(
      `/companies/${id}/google-business/locations?offset=${offset || 0}&limit=${
        limit || 10
      }`,
    );

    return Promise.resolve({
      data: res.data.locations,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }
}

export const googleApi = new GoogleApi();
