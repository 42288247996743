import styled from 'styled-components';

export const DeleteModalStyles = styled.div`
  .modalBody {
    background-color: #fff;

    .modalDescription {
      text-align: center;
      margin-bottom: 8px;
    }

    .modalFooter {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding: 10px 16px;
      background: #ffffff;
      border-radius: 0 0 4px 4px;
      box-sizing: border-box;

      button {
        padding: 0 10px;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        transition: 0.3s all;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .confirmButton {
        background: #000;
        border: 1px solid #000;
        color: #ffffff;
        transition: all 0.3s;

        &:hover {
          background: #ffffff;
          color: #000;
        }
      }

      .declineButton {
        background: #ffffff;
        border: 1px solid #c4c4c4;
        color: #000000;

        :hover {
          color: #fff;
          background: #c4c4c4;
        }
      }
    }
  }
`;
