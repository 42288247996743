import React, { FC, useEffect } from 'react';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom, AxisRight } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { DataItem } from '../../../../pages';
type TBarChart = {
  width: number;
  height: number;
  data: DataItem[];
};
export const BarChart: FC<TBarChart> = ({
  width,
  height,
  data
}) => {
  const margin = {
    top: 20,
    right: 20,
    bottom: 40,
    left: 20
  };
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const yScale = scaleLinear<number>({
    domain: [0, 100]
  }).range([yMax, 0]);
  const xScale = scaleBand<string>({
    domain: data.map(d => d.label),
    padding: 0.5
  }).rangeRound([0, xMax]);
  useEffect(() => {}, []);
  return <svg width={width} height={height}>
      <Group top={20}>
        <GridRows scale={yScale} stroke="#000" width={xMax - 25} height={yMax} left={25} tickValues={[25, 50, 75]} />
        <AxisLeft scale={yScale} tickValues={[25, 50, 75]} left={25} tickLabelProps={() => ({
        fontSize: 12,
        fill: 'black',
        textAnchor: 'end'
      })} />
        {data.map((d, i) => {
        const barHeight = yMax - yScale(d.value);
        return <Group key={`bar-${i}`}>
              <Bar x={xScale(d.label)} y={yMax - barHeight} height={barHeight} width={xScale.bandwidth()} fill={d.fill ? d.fill : '#FFDB4D'} />
              <text x={xScale(d.label)! + xScale.bandwidth() / 2} y={yMax - barHeight - 5} fontSize={12} fill="black" textAnchor="middle">
                {d.value.toFixed(2)}
              </text>
            </Group>;
      })}
        <AxisBottom top={yMax} scale={xScale} tickLabelProps={() => ({
        fontSize: 12,
        dy: '2.5em',
        fill: 'black',
        textAnchor: 'middle',
        width: 200
      })} />
      </Group>
    </svg>;
};