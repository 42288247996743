import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { CsiDashboardStyles } from './styles';
import { BarChart, PieChart } from '../../analyticsCharts';
import { analyticsApi, ApiCsi, fieldsApi, TPostCsi } from '../../../../api';
import { DataItem } from '../../../../pages';
import { Loader } from '../../../atoms';
type PieData = {
  label: string;
  data: DataItem[];
};
type TCsiDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[];
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const colors: string[] = ['#FFDB4D', '#89DB33', '#5B5AFF', '#CC2E4F'];
export const CsiDashboard: FC<TCsiDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const [csiData, setCsiData] = useState<ApiCsi[]>([]);
  const [barData, setBarData] = useState<DataItem[]>([]);
  const [pieData, setPieData] = useState<PieData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostCsi = {
      from: from as string,
      to: to as string,
      filters: {
        regions: regions as number[],
        relatedByOrgans: relatedByOrgans as number[],
        cnapTypes: cnapTypes as string[],
        organTypes: organTypes as string[],
        idfs: idfs as string[]
      }
    };
    setLoading(true);
    analyticsApi.getCsiDashboardData(+companyID, filteredData).then(res => {
      setCsiData(res.data);
      setLoading(false);
    });
  }, [reQuery]);
  useEffect(() => {
    if (csiData) {
      const loadAdditionalData = async () => {
        const results = await Promise.all(csiData.filter(csi => csi.fieldId).map(async item => {
          const res = await fieldsApi.getTargetField(item.fieldId);
          const fieldLabel = res.data.options.find(el => el.key === 'analytics_title')?.value || res.data.question;
          const barItem: DataItem = {
            label: fieldLabel,
            value: item.satisfied,
            fill: '#FFDB4D'
          };
          const pieItem: PieData = {
            label: fieldLabel,
            data: item.pie.map((el, index) => ({
              label: el.label,
              value: el.percent,
              fill: colors[index],
              count: el.count
            }))
          };
          return {
            barItem,
            pieItem
          };
        }));
        const barData = results.map(result => result.barItem);
        const pieData = results.map(result => result.pieItem);
        setBarData(barData);
        setPieData(pieData);
      };
      loadAdditionalData();
    }
  }, [csiData]);
  if (loading) {
    return <CsiDashboardStyles>
        <Loader />
      </CsiDashboardStyles>;
  }
  return <CsiDashboardStyles>
      <h2>Показник рівня задоволеності (CSI)</h2>
      <div className="csi">
        <div className="csi-bar">
          {!!barData.length && <BarChart width={dimensions.width - 50} height={400} data={barData} />}
        </div>
        <div className="csi-pie" style={{
        maxWidth: `${dimensions.width - 50}px`
      }}>
          {pieData.map((d, i) => <div key={i} className="csi-pieitem" style={{
          width: `${(dimensions.width - 50) / 5 - 25}px`
        }}>
              <h4>{d.label}</h4>
              <PieChart width={(dimensions.width - 50) / 5.5} height={(dimensions.height - 50) / 5.5} data={d.data} label={d.label} legend />
            </div>)}
        </div>
      </div>
    </CsiDashboardStyles>;
};