import styled from 'styled-components';

export const QRPublishingPieDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1;
  width: 100%;
  align-items: center;
  overflow: auto;
  box-sizing: border-box;

  @media screen and (max-width: 1400px) {
    gap: 20px;
  }

  h2 {
    width: 80%;
    text-align: center;
    font-size: 24px;

    @media screen and (max-width: 1400px) {
      font-size: 18px;
    }
  }

  .stats {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      gap: 8px;
      background: rgb(172, 193, 207);
      box-shadow: 0 4px 8px rgba(172, 193, 207, 0.6);
      width: 40%;
      height: 150px;

      @media screen and (max-width: 1400px) {
        height: 100px;
      }

      p {
        font-size: 18px;
        text-align: center;

        @media screen and (max-width: 1400px) {
          font-size: 14px;
        }
      }

      h6 {
        font-size: 24px;
        text-align: center;
        font-weight: 700;

        @media screen and (max-width: 1400px) {
          font-size: 18px;
        }
      }
    }
  }

  .pies {
    display: flex;
    justify-content: space-around;
    gap: 50px;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: calc(33% - 30px);
      padding: 20px;
      background: #fff;
      border-radius: 13px;

      p {
        font-size: 16px;
        height: 60px;

        @media screen and (max-width: 1400px) {
          font-size: 14px;
        }
      }
    }
  }
`;
