import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { getTranslationByLangOrEng } from '../../i18n';
import { useAppDispatch, useAppSelector, setInterfaceLanguage } from '../../state';
import { getInterfaceLanguage, getErrorMessage, handleKeyUp } from '../../utils';
import { RecoveryStyles } from './styles';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { authApi } from '../../api';
import { InputField } from '../../components';
interface FormValues {
  email: string;
}
const initialValues: FormValues = {
  email: ''
};
export const Recovery = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  useEffect(() => {
    dispatch(setInterfaceLanguage(getInterfaceLanguage() || 'EN'));
  }, []);
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setIsLoading(true);
    authApi.forgotPassword(values.email, ['EN', 'UA'].includes(interfaceLanguage) ? interfaceLanguage : 'EN').then(res => {
      if (res.statusCode === 200) {
        setComplete(true);
        setIsLoading(false);
        setTimeout(() => {
          setComplete(false);
          navigate('/auth/login');
        }, 3000);
      } else {
        setErrorMessage('User not found');
        setIsLoading(false);
      }
    });
    setSubmitting(false);
  }
  const validationSchema = Yup.object({
    email: Yup.string().email(getTranslationByLangOrEng(interfaceLanguage, 'validation_invalid_email')).required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required'))
  });
  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
      <InputField name="email" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'email_placeholder')} value={values.email} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'email')} />

      <Link to="/auth/login" className="form__link">
        {getTranslationByLangOrEng(interfaceLanguage, 'login_recovery_auth')}
      </Link>

      <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>
        {getTranslationByLangOrEng(interfaceLanguage, 'enter_forgot_button')}
      </SubmitButton>
    </Form>;
  return <RecoveryStyles>
      <Helmet>
        <title>Recovery Password</title>
      </Helmet>

      <div className="formWrapper">
        <div className="formContainer">
          <h2>
            {getTranslationByLangOrEng(interfaceLanguage, 'login_recovery_password_title')}
          </h2>

          {complete ? <h3 className="completeMessage">
              {getTranslationByLangOrEng(interfaceLanguage, 'login_recovery_complete')}
            </h3> : <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
              {renderForm}
            </Formik>}
        </div>
      </div>
    </RecoveryStyles>;
};