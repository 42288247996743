import styled from 'styled-components';

export const DropdownSelectorStyles = styled.div`
  .container {
    position: relative;
    .controls {
      cursor: pointer;
      display: flex;
      background-color: #eef3f7;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;

      &.opened {
        background-color: #fff4d7;
      }
      .value {
      }
    }
    .controls:hover {
      background-color: #d2dbe1;
    }

    .items {
      width: 100%;
      display: flex;
      bottom: 36px;
      background-color: grey;
      position: absolute;
      flex-direction: column;
      transition: 0.4s all;
      transform: scale(1, 0);
      transform-origin: bottom;
      &.opened {
        transform: scale(1, 1);
        display: flex;
        bottom: 36px;
        flex-direction: column;
      }
      .item {
        cursor: pointer;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: #eef3f7;
        &.selected {
          background-color: #fff4d7;
        }
      }
      .item:hover {
        background-color: #d2dbe1;
      }
    }
  }
`;
