import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ApiChatTemplates, chatTemplatesApi } from '../../api';
import { CompleteIcon, DeleteIcon, PencilIcon } from '../../assets';
type TCompanyTemplate = {
  id: string;
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  template: ApiChatTemplates;
};
const CompanyTemplate_1 = _compiledBlock(_props => <div className="templateContainer">
      <div className="templateKey">{_props.v0}</div>
      <div className="templateContent">
        {_props.v1}
      </div>
      <div className="buttons">
        {_props.v2}

        <button type="button" onClick={_props.v3}>
          {_props.v4}
        </button>
      </div>
    </div>, {
  name: "CompanyTemplate_1",
  portals: ["v0", "v1", "v2", "v4"]
});
export const CompanyTemplate: FC<TCompanyTemplate> = ({
  id,
  setReQuery,
  reQuery,
  template
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [content, setContent] = useState<string>(template.content);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const handleDelete = (templateID: number) => {
    chatTemplatesApi.deleteChatTemplate(parseInt(id!, 10), templateID).then(res => {
      if (res.statusCode === 200) {
        setReQuery(!reQuery);
      } else {
        // console.log(res.data.message);
      }
    });
  };
  const handleUpdate = (templateID: number, data: {
    key: string;
    content: string;
  }) => {
    chatTemplatesApi.updateChatTemplate(parseInt(id!, 10), templateID, data).then(res => {
      if (res.statusCode === 200) {
        setReQuery(!reQuery);
      } else {
        // console.log(res.data);
      }
    });
  };
  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };
  useEffect(() => {
    resizeTextarea();
  }, []);
  return /*@million jsx-skip*/<CompanyTemplate_1 v0={template.key} v1={<textarea ref={textareaRef} disabled={!edit} value={content} onChange={e => setContent(e.target.value)} />} v2={edit ? <button type="button" onClick={() => {
    setEdit(false);
    handleUpdate(template.id, {
      key: template.key,
      content
    });
  }}>
            <CompleteIcon />
          </button> : <button type="button" onClick={() => setEdit(true)}>
            <PencilIcon />
          </button>} v3={() => {
    handleDelete(template.id);
  }} v4={<DeleteIcon />} />;
};