import React, { FC, useEffect, useState } from 'react';
import { ResponsibleDropdownStyles } from './ResponsibleDropdownStyles';
import { Loader } from '../../atoms';
import { usersApi, formApi } from '../../../api';
type TResponsibleDropdown = {
  nodeID: number;
};
export const ResponsibleDropdown: FC<TResponsibleDropdown> = ({
  nodeID
}) => {
  const [users, setUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const getUsers = async () => {
    const responsibleIDs: number[] = await formApi.getNodeById(nodeID).then(res => res.data.responsibleIDs);
    const responsibleRequest = responsibleIDs.map(el => usersApi.getUser(el));
    const responsibleResponses = (await Promise.all(responsibleRequest)).map(user => user.data.name);
    setUsers(responsibleResponses);
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    getUsers();
  }, [nodeID]);
  return <ResponsibleDropdownStyles>
      {loading && <Loader margin={10} />}
      {users.map(name => <div key={name} className="userName">
          {name}
        </div>)}
    </ResponsibleDropdownStyles>;
};