import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../state';
import { SentimentFilterStyles } from './styles';
import { TransparentButton } from '../../../atoms';
import { translations } from '../../../../i18n';
import { resetFilterButtonStyles } from '../reviewsTableFiltersUtils';
import { sentimentIcon } from '../../../../constants';
const sentiments: string[] = ['positive', 'negative', 'neutral'];
interface SentimentValues {
  value: boolean;
  name: string;
  key: string;
  icon: any;
}
interface ISentimentFilter {
  filterParams: {
    [key: string]: number | string | any;
  };
  setReQuery: () => void;
  setApply: (params: {
    [key: string]: number | string;
  }) => void;
  setFilterParams: (params: {
    [key: string]: number | string;
  }) => void;
  onClose: () => void;
}
export const SentimentFilter: FC<ISentimentFilter> = React.memo(({
  filterParams,
  setReQuery,
  setApply,
  setFilterParams,
  onClose
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [initialValues, setInitialValues] = useState<SentimentValues[]>([]);
  const additionalResetHandlers = () => {
    if (filterParams.sentiment) {
      delete filterParams.sentiment;
    }
    setFilterParams({
      ...filterParams
    });
    setApply({
      ...filterParams
    });
    setReQuery();
    onClose();
  };
  const handleSelect = (sentiment: string) => {
    filterParams.sentiment = sentiment;
    setFilterParams(filterParams);
    setApply(filterParams);
    setReQuery();
  };
  useEffect(() => {
    const sentimentData = filterParams?.sentiment ? sentiments.map(sentiments => ({
      key: sentiments,
      name: sentiments,
      value: filterParams.sentiment === sentiments,
      icon: sentimentIcon(sentiments)
    })) : sentiments.map(sentiments => ({
      key: sentiments,
      name: sentiments,
      value: false,
      icon: sentimentIcon(sentiments)
    }));
    setInitialValues(sentimentData);
  }, []);
  return <SentimentFilterStyles>
        <div>
          <TransparentButton handleClick={() => {
        additionalResetHandlers();
      }} text={translations[interfaceLanguage].reset_reviews_filter} extraStyles={{
        ...resetFilterButtonStyles,
        marginTop: '0'
      }} extraButtonTextStyles={{
        color: '#ffffff'
      }} />
          <div className="list-wrapper">
            {initialValues.map(el => {
          const NetworkIcon = el.icon;
          return <div key={el.key} className={el.value ? 'list-item list-active' : 'list-item'} onClick={() => handleSelect(el.key)}>
                  <NetworkIcon width={20} height={20} />
                  <span>{el.name}</span>
                </div>;
        })}
          </div>
        </div>
      </SentimentFilterStyles>;
});