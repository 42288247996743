import styled from 'styled-components';

export const ResponsibleDropdownStyles = styled.div`
  position: absolute;
  left: 50%;
  width: 250px;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(196, 196, 196);

  .userName {
    color: rgb(110, 110, 110);
    padding: 0px 10px;
    width: calc(100% - 20px);
    height: 32px;
    display: flex;
    align-items: center;

    &:not(:first-child) {
      border-top: 1px solid rgb(196, 196, 196);
    }
  }
`;
