import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import SubmitButton from '../form/submitButton/SubmitButton';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../state';
import { getTranslationByLangOrEng } from '../../i18n';
import { CompanyTemplatesStyles } from './CompanyTemplatesStyles';
import TextAreaAutoResize from '../form/textAreaAutoResize/TextAreaAutoResize';
import { ApiChatTemplates, chatTemplatesApi } from '../../api';
import { CompanyTemplate } from './CompanyTemplate';
import { Loader } from '../atoms';
import { getErrorMessage, handleKeyUp } from '../../utils';
import { InputField } from '../molecules';
interface FormChatTemplates {
  key: string;
  content: string;
}
const initialValues: FormChatTemplates = {
  key: '',
  content: ''
};
export default function CompanyTemplates() {
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [chatTemplates, setChatTemplates] = useState<ApiChatTemplates[]>();
  const [reQuery, setReQuery] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    chatTemplatesApi.getChatTemplates(parseInt(id!, 10)).then(res => {
      if (res.statusCode === 200) {
        // console.log(res.data);
        setChatTemplates(res.data);
        setIsLoading(false);
      } else {
        // console.log(res.data);
      }
    });
  }, [reQuery]);
  const validationSchema = Yup.object({
    content: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
    key: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')).matches(/^\/.*$/, 'Строка повинна починатися з символу /').max(50, 'Строка не може бути більшою за 50 символів')
  });
  async function onSubmit(values: FormChatTemplates, {
    setSubmitting
  }: FormikHelpers<FormChatTemplates>) {
    chatTemplatesApi.createNewChatTemplate(parseInt(id!, 10), {
      key: values.key,
      content: values.content
    }).then(res => {
      if (res.statusCode === 201) {
        setReQuery(!reQuery);
      } else {
        // console.log(res.data);
      }
    });
    setSubmitting(false);
  }
  const templatesInChatForm = ({
    values,
    setFieldValue
  }: FormikProps<FormChatTemplates>) => <Form>
      <div className="blockHead">
        <span className="caption">
          Шаблони відповідей для чату із суб’єктом звернення
        </span>
      </div>

      {chatTemplates && <div className="arrayWrapper">
          {chatTemplates.map(template => <CompanyTemplate template={template} id={id!} setReQuery={setReQuery} reQuery={reQuery} />)}
        </div>}

      <InputField value={values.key} placeholder="Назва шаблону" onChange={setFieldValue} onKeyUp={() => handleKeyUp('key', setErrorMessage, errorMessage)} name="key" error={typeof errorMessage === 'object' ? getErrorMessage('key', errorMessage) : undefined} />
      <TextAreaAutoResize placeholder="Введіть текст шаблону" value={values.content} onChange={event => setFieldValue('content', event.target.value)} name="content" onKeyUp={() => handleKeyUp('content', setErrorMessage, errorMessage)} error={typeof errorMessage === 'object' ? getErrorMessage('content', errorMessage) : undefined} minRows={10} />

      <SubmitButton isLoading={isLoading}>
        {getTranslationByLangOrEng(interfaceLanguage, 'add_button')}
      </SubmitButton>

      {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
    </Form>;
  return <CompanyTemplatesStyles>
      {!chatTemplates || isLoading ? <Loader /> : <div className="formContainer">
          <Formik validationSchema={validationSchema} onSubmit={onSubmit} initialValues={initialValues}>
            {templatesInChatForm}
          </Formik>
        </div>}
    </CompanyTemplatesStyles>;
}