import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import * as React from 'react';
import { TIcon } from './TIcon';
const NeutralIcon_1_1 = _compiledBlock(_props => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width={_props.v0} height={_props.v1} fill={_props.v2}>
      <path d="M620-520q25 0 42.5-17.5T680-580q0-25-17.5-42.5T620-640q-25 0-42.5 17.5T560-580q0 25 17.5 42.5T620-520Zm-280 0q25 0 42.5-17.5T400-580q0-25-17.5-42.5T340-640q-25 0-42.5 17.5T280-580q0 25 17.5 42.5T340-520Zm20 180h240v-60H360v60ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Z" />
    </svg>, {
  name: "NeutralIcon_1_1",
  svg: true
});
const NeutralIcon_1 = ({
  width = 640,
  height = 512,
  color = '#FFC619'
}) => /*@million jsx-skip*/<NeutralIcon_1_1 v0={width} v1={height} v2={color} />;
export const NeutralIcon: React.FC<TIcon> = React.memo(NeutralIcon_1);