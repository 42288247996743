import { FieldType } from '../../../api';
import { FieldIcon } from '../../atoms';
import { FieldSelectorStyles } from './styles';
export interface FieldSelectorProps {
  type: FieldType;
  previewText: string;
  handleClick?: () => void;
}
export const FieldSelector = ({
  type,
  previewText,
  handleClick
}: FieldSelectorProps) => <FieldSelectorStyles onClick={event => {
  if (handleClick) {
    event.preventDefault();
    event.stopPropagation();
    handleClick();
  }
}}>
    <FieldIcon type={type} />

    <p>{previewText}</p>
  </FieldSelectorStyles>;