import React, { useEffect, useRef, useState } from 'react';
import { AnalyticStyles } from './styles';
import { Helmet } from 'react-helmet-async';
import { CompaniesMainInfo } from '../../entities';
import { companyApi } from '../../api';
import { AnalyticsFilters, AnalyticsStartPage, CompanyFilter, ComplexGeneralDashboard, ComplexRatingDashboard, CsiDashboard, CsiDetailedDashboard, CsiRegionRatingsDashboard, CustomTabsMenu, DissatisfactionDashboard, LocationsOutOfRating, LocationsWithoutReviews, QRPublishingDashboard, QRPublishingPieDashboard, RatingByPopulationDashboard, RatingByReviewsDashboard, SubHeader, TransparentButton } from '../../components';
import { TabKeys, tabs } from './utils';
import { setSidebarFullWidth, useAppDispatch } from '../../state';
import { FullScreen, JustPlusIcon, TableFilterIcon } from '../../assets';
export const Analytic = React.memo(() => {
  const dispatch = useAppDispatch();
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [filters, setFilters] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0
  });
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const blockRef = useRef(null);
  function getCompanies(data: CompaniesMainInfo[]) {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      acc[`${item.id}`] = item;
      return acc;
    }, {} as {
      [key: string]: CompaniesMainInfo;
    });
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const compID = Object.values(apiCompanies)[0].id;
      filterParamsRef.current = {
        companyID: compID
      };
      setReQuery(!reQuery);
    }
  }
  const handleRefreshFilter = () => {
    filterParamsRef.current = {
      companyID: filterParamsRef.current.companyID
    };
    setReQuery(!reQuery);
  };
  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };
  const toggleFilters = () => {
    setFilters(!filters);
  };
  useEffect(() => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
        dispatch(setSidebarFullWidth(false));
      }
    });
  }, []);
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height
        });
      }
    });
    if (blockRef.current) {
      observer.observe(blockRef.current);
    }
    return () => {
      if (blockRef.current) {
        observer.unobserve(blockRef.current);
      }
    };
  }, [reQuery, fullScreen]);
  return <AnalyticStyles className="analytics">
      <Helmet>
        <title>Analytics Voicer</title>
      </Helmet>

      <SubHeader strechChild title="Аналітика">
        <div className="analytics-subheader">
          {companies && <CompanyFilter filterParams={filterParamsRef.current} companies={companies} setFilterParams={params => {
          filterParamsRef.current = params;
        }} setReQuery={() => setReQuery(!reQuery)} />}
          <div className="analytics-btns">
            {selectedTab !== '' && <TransparentButton text="Повернутися до меню" handleClick={() => {
            setSelectedTab('');
            setFilters(false);
          }} filled />}
            <TransparentButton text="Очистити фільтри" handleClick={handleRefreshFilter} extraStyles={{
            backgroundColor: '#767676',
            border: 'none'
          }} extraButtonTextStyles={{
            color: '#ffffff'
          }} />
          </div>
        </div>
      </SubHeader>

      <div className={`analytics-wrapper ${fullScreen ? 'full-screen' : ''}`}>
        {filterParamsRef.current.companyID && !filters && selectedTab && <div className="analytics-filters" onClick={toggleFilters} title="Обрати фільтри">
            <TableFilterIcon height={30} width={30} color="#999" />
          </div>}
        {filterParamsRef.current.companyID && <AnalyticsFilters companyId={+filterParamsRef.current.companyID} filterParams={filterParamsRef} reQuery={reQuery} filters={filters} setFilters={setFilters} setReQuery={setReQuery} setSelectedTab={setSelectedTab} selectedTab={selectedTab} handleRefreshFilter={handleRefreshFilter} />}
        {filterParamsRef.current.companyID && <div className="analytics-charts" ref={blockRef}>
            {selectedTab === TabKeys.csi && <CsiDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.csiDetailed && <CsiDetailedDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.csiRegionRatings && <CsiRegionRatingsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.ratingPopulationOne && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingPopulationOne)?.text!} ascCategories="I категорія (100 тис. осіб і більше)" />}
            {selectedTab === TabKeys.ratingPopulationTwo && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingPopulationTwo)?.text!} ascCategories="II категорія (від 50 до 100 тис.осіб)" />}
            {selectedTab === TabKeys.ratingPopulationThree && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingPopulationThree)?.text!} ascCategories="III категорія (від 20 до 50 тис.осіб)" />}
            {selectedTab === TabKeys.ratingPopulationFour && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingPopulationFour)?.text!} ascCategories="IV категорія (від 10 до 20 тис.осіб)" />}
            {selectedTab === TabKeys.ratingPopulationFive && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingPopulationFive)?.text!} ascCategories="V категорія (від 5 до 10 тис.осіб)" />}
            {selectedTab === TabKeys.ratingPopulationSix && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingPopulationSix)?.text!} ascCategories="VI категорія (менше 5 тис.осіб)" />}
            {selectedTab === TabKeys.ratingReviewsOne && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingReviewsOne)?.text!} ascCategories="I категорія (100 тис. осіб і більше)" />}
            {selectedTab === TabKeys.ratingReviewsTwo && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingReviewsTwo)?.text!} ascCategories="II категорія (від 50 до 100 тис.осіб)" />}
            {selectedTab === TabKeys.ratingReviewsThree && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingReviewsThree)?.text!} ascCategories="III категорія (від 20 до 50 тис.осіб)" />}
            {selectedTab === TabKeys.ratingReviewsFour && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingReviewsFour)?.text!} ascCategories="IV категорія (від 10 до 20 тис.осіб)" />}
            {selectedTab === TabKeys.ratingReviewsFive && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingReviewsFive)?.text!} ascCategories="V категорія (від 5 до 10 тис.осіб)" />}
            {selectedTab === TabKeys.ratingReviewsSix && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingReviewsSix)?.text!} ascCategories="V категорія (від 5 до 10 тис.осіб)" />}
            {selectedTab === TabKeys.ratingVrmReviews && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} title={tabs.find(tab => tab.key === TabKeys.ratingVrmReviews)?.text!} ascTypes="ВРМ" />}
            {selectedTab === TabKeys.complexRating && <ComplexRatingDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.locationOutOfRating && <LocationsOutOfRating filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.locationWithoutReviews && <LocationsWithoutReviews filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.locationDissatisfaction && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="location-dissatisfaction" divisionPie="location-dissatisfaction-pie" title={tabs.find(tab => tab.key === TabKeys.locationDissatisfaction)?.text!} />}
            {selectedTab === TabKeys.generalProcessDissatisfaction && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="general-process-dissatisfaction" divisionPie="general-process-dissatisfaction-pie" title={tabs.find(tab => tab.key === TabKeys.generalProcessDissatisfaction)?.text!} />}
            {selectedTab === TabKeys.staffDissatisfaction && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="staff-dissatisfaction" divisionPie="staff-dissatisfaction-pie" title={tabs.find(tab => tab.key === TabKeys.staffDissatisfaction)?.text!} />}
            {selectedTab === TabKeys.servicesList && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="services-list-dissatisfaction" divisionPie="services-list-dissatisfaction-pie" title={tabs.find(tab => tab.key === TabKeys.servicesList)?.text!} />}
            {selectedTab === TabKeys.complexGeneral && <ComplexGeneralDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.qrPublishing && <QRPublishingDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.qrPublishingPie && <QRPublishingPieDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {!selectedTab && <AnalyticsStartPage setSelectedTab={setSelectedTab} tabs={tabs} />}
            {/* <div className="analytics-menu">
              <CustomTabsMenu
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={(key: any) => {
                  setSelectedTab(key as TabKeys)
                }}
                withContainer
              />
             </div> */}
          </div>}
        {filterParamsRef.current.companyID && <div className={`analytics-fullscreen ${fullScreen ? 'active' : ''}`} onClick={toggleFullScreen} title="Повноекранний режим">
            {fullScreen ? <JustPlusIcon color="#999" width={30} height={30} /> : <FullScreen height={30} width={30} color="#999" />}
          </div>}
      </div>
    </AnalyticStyles>;
});