import React, { Fragment, useRef, useState } from 'react';
import { ApiForm, ApiNode, ApiPosterTemplate } from '../../../api';
import { toast } from 'react-toastify';
import { companyPostersApi } from '../../../api/companyPosters/CompanyPostersApi';
import { PostersDropdownStyles } from './PostersDropdownStyles';
import { useClickOutside } from '../../../hooks';
import { Loader } from '../../atoms';
import { PrintIcon } from '../../../assets/icons/PrintIcon';
import { ICreatePoster } from '../../../entities/ICreatePosters';
interface PostersDropdownProps {
  node: ApiNode;
  form: ApiForm;
  posters: ApiPosterTemplate[];
}
const printPDF = async (blobPoster: Blob) => {
  try {
    const blobUrl = URL.createObjectURL(blobPoster);
    const newWindow = window.open(blobUrl, '_blank');
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.focus();
        newWindow.print();
        URL.revokeObjectURL(blobUrl);
      };
    } else {
      toast.error('Не вдалося відкрити PDF файл для друку.');
    }
  } catch (error: any) {
    toast.error(`Виникла помилка при друку PDF: ${error.message}`);
  }
};
function PostersDropdown({
  node,
  form,
  posters
}: PostersDropdownProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const postersDropdownRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(postersDropdownRef, () => setIsOpen(false));
  const createPoster = async (poster: ApiPosterTemplate) => {
    try {
      setIsLoading(true);
      const textItem = poster.items.find(posterItem => posterItem.type === 'text');
      const dataToPost: ICreatePoster = {
        form_id: form.id!,
        node_id: node.id,
        params: {
          templateID: poster.id,
          content: []
        }
      };
      if (textItem) {
        dataToPost.params.content.push({
          content: node.code,
          templateItemID: textItem.id
        });
      }
      await companyPostersApi.createQRPoster(dataToPost).then(res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          printPDF(res.data);
          setIsLoading(false);
        } else {
          throw new Error('Виникла помилка при генерації постеру');
        }
      });
    } catch (e) {
      toast.error('Виникла помилка при генерації постеру');
    } finally {
      setIsLoading(false);
    }
  };
  return <PostersDropdownStyles ref={postersDropdownRef}>
      <button type="button" className="openerButton" onClick={event => {
      event.stopPropagation();
      setIsOpen(!isOpen);
    }}>
        <PrintIcon width={20} height={20} />
      </button>

      {isOpen && <div className="postersDropdown">
          {isLoading ? <Loader /> : <div className="downloadPostersButtonsWrapper">
              {posters.map(poster => <Fragment key={poster.id}>
                  <button type="button" onClick={event => {
            event.stopPropagation();
            createPoster(poster);
          }}>
                    {poster.name}
                  </button>

                  <div className="splitter" />
                </Fragment>)}
            </div>}
        </div>}
    </PostersDropdownStyles>;
}
export default PostersDropdown;