import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiChangesStatuses, sourcesApi } from '../../../../api';
import { ISource } from '../../../../entities';
import { StatusesSettings } from '../../../molecules';
type TChangingStatuses = {
  apiSourcesSettings: ApiChangesStatuses[];
};
export const ChangingStatuses: FC<TChangingStatuses> = React.memo(({
  apiSourcesSettings
}) => {
  const {
    id
  } = useParams();
  const [selectedSource, setSelectedSource] = useState<number>();
  const [sourcesSettings, setSourcesSettings] = useState<ApiChangesStatuses[]>(apiSourcesSettings);
  const [sources, setSources] = useState<ISource[]>([]);
  useEffect(() => {
    if (id) {
      sourcesApi.getSourcesByCompanyId(+id).then(res => {
        setSources(res.data);
      });
    }
  }, []);
  return <div className="statusesChanging">
        {!!sourcesSettings.length && <>
            <h3>Changing statuses</h3>
            <div className="sourcesWrapper">
              {sources && sources.map(source => <button onClick={() => setSelectedSource(source.id)} className={source.id === selectedSource ? 'selected' : ''} key={source.id} type="button">
                    {source.name}
                  </button>)}
            </div>

            <div className="horizontalLine" />

            <div className="statusesContainer">
              {selectedSource && sourcesSettings && <>
                  {Object.entries(sourcesSettings.find(sett => sett.sourceID === selectedSource)!.changes).map(([key, value]) => <StatusesSettings setSourcesSettings={setSourcesSettings} sourcesSettings={sourcesSettings} key={key} statId={key} selected={value} selectedSource={selectedSource} />)}

                  <div className="horizontalLine" />
                </>}
            </div>
          </>}
      </div>;
});