import React from 'react';
import { TIcon } from './TIcon';
export const PrintIcon: React.FC<TIcon> = React.memo(({
  width = 20,
  height = 20,
  color = 'black'
}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} version="1.1" viewBox="0 0 64 64" xmlSpace="preserve">
      <g>
        <path d="M57.788 14.031H52.5V5.97a4 4 0 00-4-4h-33a4 4 0 00-4 4v8.062H6.212A6.22 6.22 0 000 20.243v26.27a6.22 6.22 0 006.212 6.213h2.38a1 1 0 100-2h-2.38A4.217 4.217 0 012 46.514v-26.27a4.217 4.217 0 014.212-4.213h51.576A4.217 4.217 0 0162 20.243v26.27a4.217 4.217 0 01-4.212 4.213H56a1 1 0 100 2h1.788A6.22 6.22 0 0064 46.514v-26.27a6.22 6.22 0 00-6.212-6.213zM13.5 5.97c0-1.103.897-2 2-2h33c1.103 0 2 .897 2 2v8h-37v-8z" />
        <path d="M44 45.032H20a.999.999 0 100 1.998h24a.999.999 0 100-1.998zM44 52.032H20a.999.999 0 100 1.998h24a.999.999 0 100-1.998z" />
        <circle cx="7.959" cy="21.841" r="2" />
        <circle cx="14.286" cy="21.841" r="2" />
        <circle cx="20.612" cy="21.841" r="2" />
        <path d="M11 62.031h42v-26H11v26zm2.404-23.596h37.192v21.193H13.404V38.435z" />
      </g>
    </svg>);