import { FC, useState } from 'react';
import { translations } from '../../../i18n';
import { LazyCollapsible } from '../../atoms/lazyCollapsible';
import CustomSelectWithMultipleCheckboxes from '../../form/customSelect/CustomSelectWithMultipleCheckboxes';
import { extraOneOptionStyles } from '../reviewsTableFilters/reviewsTableFiltersUtils';
import { fieldsApi, TAnswer } from '../../../api';
import { useAppSelector } from '../../../state';
import { TQuestion } from '../dropDownFilter/TDropdownFilter';
import { IAnswer } from '../../../entities';
import { TOption } from '../reviewsTableFilters/filterSelectInput/initialValues';
export const AnswearFilterItem: FC<{
  index: number;
  id: number;
  question: TQuestion;
  onChange: (e: any) => void;
  values: any;
  setQuestionsAnswer: (question_id: number, answers: any) => void;
  filterQuestions: {
    [key: string]: string[];
  };
}> = ({
  index,
  id,
  onChange,
  values,
  question,
  setQuestionsAnswer,
  filterQuestions
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const answers = question.answers;
  const onOpenAnswersList = async (id: number) => {
    if (!answers?.length) {
      setIsLoading(true);
      const res = await fieldsApi.getTargetField(id);
      setQuestionsAnswer(id, (res.data.answers as TAnswer[]).map(e => ({
        id: e.id.toString(),
        value: !!filterQuestions[id]?.includes(String(e.id)),
        name: e.text
      })));
      setIsLoading(false);
    }
  };
  return <LazyCollapsible containerStyles={{
    width: '100%'
  }} onOpen={() => {
    onOpenAnswersList(id);
  }} isLoading={isLoading || !answers?.length} title={question.name}>
      <CustomSelectWithMultipleCheckboxes disableScroll name={`questions[${index}]answers`} handleChange={onChange}
    // @ts-ignore
    options={values.questions[index].answers || []} extraOneOptionStyles={extraOneOptionStyles} optionsContainerStyles={{
      width: '100%',
      marginTop: 0,
      marginBottom: 5
    }} placeholder={translations[interfaceLanguage].select_multiple_Answerss} placeholderStyles={{
      color: '#6c6c6c'
    }} oneOptionValueStyles={{
      marginLeft: '4px',
      display: 'inline-block',
      height: 24,
      width: '250px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }} isLoading={false} alwaysOpened />
    </LazyCollapsible>;
};