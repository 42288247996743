import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ImportStyles } from './ImportStyles';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { UploadFileButton } from '../../form/uploadFileButton/UploadFileButton';
import CustomSelect from '../../form/customSelect/CustomSelect';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { DeleteIcon } from '../../../assets';
import { CompaniesMainInfo } from '../../../entities/ICompany';
import { ApiImportStatusData } from '../../../api/entities/ApiImportData';
import { FormValues, ImportUsersData, ImportNodesData, ImportReviewsData, validationSchema } from './formValuesAndValidation';
import { Loader } from '../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { InputField } from '../form';
import { importApi, companyApi } from '../../../api';
export const Import = () => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [maxSizeError, setMaxSizeError] = useState<string>('');
  const [submittingError, setSubmittingError] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [importing, setImporting] = useState<boolean>(false);
  const [interruptImportError, setInterruptImportError] = useState<string>();
  const [showInterruptImportButton, setShowInterruptImportButton] = useState<boolean>(false);
  const [showImportProgressBar, setShowImportProgressBar] = useState<boolean>(false);
  const [isRecursiveTimeout, setIsRecursiveTimeout] = useState<boolean>(false);
  const [currentImportStatusError, setCurrentImportStatusError] = useState<string>();
  const [isCurrentImportStatusErrorShown, setIsCurrentImportStatusErrorShown] = useState<boolean>(false);
  const [apiRequestTimeout, setApiRequestTimeout] = useState<any>();
  const [isActualProgressBarShown, setIsActualProgressBarShown] = useState<boolean>(false);
  const importEntitiesRef = useRef<{
    name: string;
  }[]>([{
    name: 'Users'
  }, {
    name: 'Nodes'
  }, {
    name: 'Reviews'
  }]);
  const companiesRef = useRef<CompaniesMainInfo[]>([]);
  const currentImportStatusRef = useRef<ApiImportStatusData>();
  function recursiveTimeout() {
    const interval = setTimeout(() => {
      recursiveTimeout();
      importApi.getCurrentImportStatus().then(res => {
        if (res.statusCode === 200) {
          currentImportStatusRef.current = res.data;
          setIsActualProgressBarShown(true);
          if (res.data.error) {
            setIsRecursiveTimeout(false);
            setShowInterruptImportButton(false);
            setIsCurrentImportStatusErrorShown(true);
          }
          if (!res.data.running) {
            setIsRecursiveTimeout(false);
            setShowInterruptImportButton(false);
          }
        } else {
          setCurrentImportStatusError('Updating import status error');
        }
      });
      setApiRequestTimeout(interval);
    }, 30000);
  }
  const documentSizeExamination = (document: File) => {
    if (document.size >= 100000000) {
      return setMaxSizeError(getTranslationByLangOrEng(interfaceLanguage, 'max_size_error'));
    }
    setMaxSizeError('');
  };
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setImporting(true);
    setSubmitting(false);
    if (values.entity && values.company && values.entity.name === 'Nodes') {
      const data: ImportNodesData = {
        file: values.file!,
        companyID: `${values.company.id}`,
        sheet: values.sheet
      };
      const res = await importApi.importNodes(data);
      if (res.statusCode === 200) {
        setShowInterruptImportButton(true);
        importApi.getCurrentImportStatus().then(res => {
          if (res.statusCode === 200) {
            currentImportStatusRef.current = res.data;
            if (!res.data.error) {
              setShowImportProgressBar(true);
              setIsRecursiveTimeout(true);
            } else {
              setIsCurrentImportStatusErrorShown(true);
            }
          } else {
            setCurrentImportStatusError('Updating import status error');
          }
        });
      } else {
        setSubmittingError(res.data.message);
      }
    }
    if (values.entity && values.entity.name === 'Users') {
      const data: ImportUsersData = {
        file: values.file!,
        sheet: values.sheet
      };
      const res = await importApi.importUsers(data);
      if (res.statusCode === 200) {
        setShowInterruptImportButton(true);
        importApi.getCurrentImportStatus().then(res => {
          if (res.statusCode === 200) {
            currentImportStatusRef.current = res.data;
            if (!res.data.error) {
              setShowImportProgressBar(true);
              setIsRecursiveTimeout(true);
            } else {
              setIsCurrentImportStatusErrorShown(true);
            }
          } else {
            setCurrentImportStatusError('Updating import status error');
          }
        });
      } else {
        setSubmittingError(res.data.message);
      }
    }
    if (values.entity && values.entity.name === 'Reviews') {
      const data: ImportReviewsData = {
        file: values.file!,
        sheet: values.sheet
      };
      const res = await importApi.importReviews(data);
      if (res.statusCode === 200) {
        setShowInterruptImportButton(true);
        importApi.getCurrentImportStatus().then(res => {
          if (res.statusCode === 200) {
            currentImportStatusRef.current = res.data;
            if (!res.data.error) {
              setShowImportProgressBar(true);
              setIsRecursiveTimeout(true);
            } else {
              setIsCurrentImportStatusErrorShown(true);
            }
          } else {
            setCurrentImportStatusError('Updating import status error');
          }
        });
      } else {
        setSubmittingError(res.data.message);
      }
    }
    setImporting(false);
  }
  function interruptImportHandler() {
    setIsLoaded(false);
    importApi.stopCurrentImport().then(res => {
      if (res.statusCode === 200) {
        importApi.getCurrentImportStatus().then(res => {
          if (res.statusCode === 200) {
            currentImportStatusRef.current = res.data;
            if (res.data.error) {
              setIsCurrentImportStatusErrorShown(true);
            }
          } else {
            setCurrentImportStatusError('Updating import status error');
          }
        });
        if (interruptImportError) {
          setInterruptImportError('');
        }
        setShowInterruptImportButton(false);
        setIsRecursiveTimeout(false);
      } else {
        setInterruptImportError(res.data.message);
      }
    }).catch(e => {
      console.log('e', e);
    }).finally(() => {
      setIsLoaded(true);
    });
  }
  const disabledUploadButtonStyles = {
    backgroundColor: '#808080',
    borderColor: '#808080'
  };
  useEffect(() => {
    importApi.getCurrentImportStatus().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        currentImportStatusRef.current = res.data;
        if (res.data.running) {
          setShowInterruptImportButton(true);
          setIsRecursiveTimeout(true);
          setShowImportProgressBar(true);
        }
      } else {
        setCurrentImportStatusError('Updating import status error');
      }
      setIsLoaded(true);
    });
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        companiesRef.current = res.data;
      }
    });
    setInitialValues({
      entity: undefined,
      file: undefined,
      company: undefined,
      sheet: ''
    });
  }, []);
  useEffect(() => {
    if (isRecursiveTimeout) {
      recursiveTimeout();
      return () => {
        clearTimeout(apiRequestTimeout);
      };
    }
    if (!isRecursiveTimeout) {
      const highestId = window.setTimeout(() => {
        for (let i = highestId; i >= 0; i--) {
          window.clearInterval(i);
        }
      }, 0);
    }
  }, [isRecursiveTimeout]);
  const renderForm = ({
    values,
    errors,
    setFieldValue
  }: FormikProps<FormValues>) => <Form>
      <div className="importPageWrapper">
        <div className="formSection">
          <CustomSelect label={getTranslationByLangOrEng(interfaceLanguage, 'import_entity_selection_title')} name="entity" options={importEntitiesRef.current} selectKey="name" placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_import_entity')} value={values.entity} handleSelect={value => {
          setFieldValue('entity', value);
        }} search emptyOption emptyList={getTranslationByLangOrEng(interfaceLanguage, 'no_options_available')} formGroupStyles={{
          maxWidth: 300,
          width: '100%'
        }} required />
          {values.entity && values.entity.name === 'Nodes' && <CustomSelect label={getTranslationByLangOrEng(interfaceLanguage, 'company')} name="company" options={companiesRef.current} selectKey="name" placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_company')} value={values.company} handleSelect={value => {
          setFieldValue('company', value);
        }} search emptyOption emptyList={getTranslationByLangOrEng(interfaceLanguage, 'no_options_avaliable')} formGroupStyles={{
          maxWidth: 300,
          width: '100%'
        }} />}
          <div className="fileForImportContainer">
            <div className="helperWrapper">
              {values.file && <button className="deleteFileButton" type="button" onClick={() => setFieldValue('file', undefined)}>
                  <DeleteIcon width={16} height={16} />
                </button>}
              <span>
                {values.file && values.file instanceof File ? values.file.name : getTranslationByLangOrEng(interfaceLanguage, 'choose_file_for_import')}
              </span>
            </div>
            <div>
              <UploadFileButton onChange={file => {
              setFieldValue('file', file);
              if (file) {
                documentSizeExamination(file);
              }
            }} formats=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" name={getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_file_save_button')} extraButtonStyles={{
              maxWidth: '272px',
              width: '100%',
              height: '40px',
              ...(values.file ? disabledUploadButtonStyles : {
                backgroundColor: '#000',
                borderColor: '#000'
              })
            }} extraButtonTextStyles={{
              color: '#ffffff'
            }} disabled={!!values.file} />
            </div>
          </div>
          <div>
            {maxSizeError && <p className="fileError">{maxSizeError}</p>}
            {errors && errors.file && <p className="fileError">{errors.file}</p>}
          </div>
          <div className="fileFormatTextConatiner">
            <span>
              {`${getTranslationByLangOrEng(interfaceLanguage, 'import_file_format_text')} .xlsx`}
            </span>
          </div>

          <InputField name="sheet" onChange={setFieldValue} onKeyUp={() => handleKeyUp('sheet', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, '')} value={values.sheet} error={typeof errorMessage === 'object' ? getErrorMessage('sheet', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'import_file_sheet_name_label')} extraBlockStyles={{
          width: '400px',
          marginBottom: '10px'
        }} required />

          <div>
            <SubmitButton isLoading={importing} extraBlockStyles={{
            maxWidth: 400,
            width: '100%'
          }}>
              {getTranslationByLangOrEng(interfaceLanguage, 'start_import_button_text')}
            </SubmitButton>
            {submittingError && <p className="submitError">{submittingError}</p>}
          </div>
          {showInterruptImportButton && <div className="interruptImportButtonContainer">
              <button onClick={interruptImportHandler} type="button" className="interruptImportButton">
                <span>
                  {getTranslationByLangOrEng(interfaceLanguage, 'interrupt_import_script_button_text')}
                </span>
              </button>
              {interruptImportError && <p className="interruptError">{interruptImportError}</p>}
            </div>}

          {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
        </div>
        <div>
          {showImportProgressBar && <div className="progressBarContainer">
              {isActualProgressBarShown && currentImportStatusRef.current?.length && currentImportStatusRef.current?.current ? <label className="progressBarLabel" htmlFor="import">
                  {getTranslationByLangOrEng(interfaceLanguage, 'import_progress_bar_title')}
                  <div>
                    <progress id="import" max={currentImportStatusRef.current.length} value={currentImportStatusRef.current.current} />
                    <span className="progressBarValues">{`${currentImportStatusRef.current?.current}/${currentImportStatusRef.current.length}`}</span>
                  </div>
                </label> : <label className="progressBarLabel" htmlFor="import">
                    {getTranslationByLangOrEng(interfaceLanguage, 'import_progress_bar_title')}
                    <div>
                      <progress id="import" max="100" value="0" />
                    </div>
                  </label>}
            </div>}
          {currentImportStatusError && <p className="currentImportStatusError">
              {`${getTranslationByLangOrEng(interfaceLanguage, 'error_title')}: ${currentImportStatusError}`}
            </p>}
          {isCurrentImportStatusErrorShown && currentImportStatusRef && currentImportStatusRef.current?.error && <div className="importErrorContainer">
                <span className="importErrorTitle">Error</span>
                <div className="importErrorTextContainer">
                  <span>{currentImportStatusRef.current?.error}</span>
                </div>
              </div>}
        </div>
      </div>
    </Form>;
  return <ImportStyles>
      {!isLoaded || !initialValues ? <Loader /> : <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema(interfaceLanguage)}>
          {renderForm}
        </Formik>}
    </ImportStyles>;
};