import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { TFieldConstruct } from '../../companyFields';
import { ApiCompanyField, ApiCreatedField, ApiUpdatedField, FieldType, TAdditionalParams, fieldsApi } from '../../../../api';
import { useParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { addAdditionalParam } from '../utils';
import { Checkbox, InputField } from '../../../molecules';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import { SubmitButton } from '../../../atoms';
type TPhone = {
  field: TFieldConstruct;
  setFields: Dispatch<SetStateAction<ApiCompanyField[]>>;
  setField: Dispatch<SetStateAction<TFieldConstruct | null>>;
};
export interface IPhoneField {
  question: string;
  placeholder: string;
  required: boolean;
  isComment: boolean;
  country_code_not_editable: boolean;
}
const initialValue: IPhoneField = {
  question: '',
  placeholder: '',
  required: false,
  isComment: false,
  country_code_not_editable: false
};
export const validationSchema = Yup.object({
  question: Yup.string().required('* Required')
});
export const Phone: FC<TPhone> = ({
  field,
  setFields,
  setField
}) => {
  const {
    id
  } = useParams();
  const [initialValues, setInitialValues] = useState<IPhoneField>();
  const [apiField, setApiField] = useState<ApiCompanyField | null>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const onSubmit = async (values: IPhoneField, {
    setSubmitting
  }: FormikHelpers<IPhoneField>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    const styles: TAdditionalParams[] = [];
    if (id) {
      if (apiField) {
        options = [...apiField.options];
        addAdditionalParam(values.placeholder, 'placeholder', options);
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.isComment, 'isComment', options);
        addAdditionalParam(values.country_code_not_editable, 'country_code_not_editable', options);
        const data: ApiUpdatedField<FieldType.Phone> = {
          question: values.question,
          options,
          styles
        };
        fieldsApi.updateFormField(apiField.id!, data).then(res => {
          if (res.statusCode === 200) {
            setFields(prev => prev.map(el => {
              if (el.id === apiField.id) {
                return {
                  ...el,
                  ...data
                };
              }
              return el;
            }));
            toast.success('Поле успішно оновлено');
          }
        });
      } else {
        addAdditionalParam(values.placeholder, 'placeholder', options);
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.isComment, 'isComment', options);
        addAdditionalParam(values.country_code_not_editable, 'country_code_not_editable', options);
        const data: ApiCreatedField = {
          field: {
            [FieldType.Phone]: {
              question: values.question,
              options,
              styles
            }
          }
        };
        fieldsApi.createCompanyFields(+id, data).then(res => {
          if (res.statusCode === 201) {
            setFields(prev => [res.data, ...prev]);
            setField({
              id: res.data.id,
              type: field.type
            });
            toast.success('Поле успішно додано');
          }
        });
      }
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<IPhoneField>) => <Form>
      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="question" onChange={setFieldValue} onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)} placeholder="Введіть запитання для поля" value={values.question} error={typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined} />
      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="placeholder" onChange={setFieldValue} onKeyUp={() => handleKeyUp('placeholder', setErrorMessage, errorMessage)} placeholder="Введіть пояснення запитання" value={values.placeholder} error={typeof errorMessage === 'object' ? getErrorMessage('placeholder', errorMessage) : undefined} />
      <Checkbox name="required" value={values.required!} onChange={handleChange}>
        <span className="checkboxValue">
          Обовʼязкове поле
        </span>
      </Checkbox>

      <Checkbox name="isComment" value={values.isComment!} onChange={handleChange}>
        <span className="checkboxValue">
          Коментар для сповіщень
        </span>
      </Checkbox>

      <Checkbox name="country_code_not_editable" value={values.country_code_not_editable!} onChange={handleChange}>
        <span className="checkboxValue">
          Код країни не редагується
        </span>
      </Checkbox>

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '15px 45px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    if (field.id) {
      fieldsApi.getTargetField(field.id).then(res => {
        const required = res.data.options.find(option => option.key === 'required')?.value === 'true';
        const isComment = res.data.options.find(option => option.key === 'isComment')?.value === 'true';
        const country_code_not_editable = res.data.options.find(option => option.key === 'country_code_not_editable')?.value === 'true';
        const placeholder = res.data.options.find(option => option.key === 'placeholder')?.value || '';
        setApiField(res.data);
        setInitialValues({
          question: res.data.question,
          placeholder,
          isComment,
          required,
          country_code_not_editable
        });
      });
    } else {
      setInitialValues(initialValue);
    }
  }, [field]);
  return <div className="field-body">
      {initialValues && <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
          {renderForm}
        </Formik>}
    </div>;
};