import React, { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { NodeStyles } from './styles';
import { ApiCompanyNode, formApi, nodesApi } from '../../../api';
import { ArrowDownIcon, DeleteIcon, FeedbacksListIcon, FormsListIcon, HorizontalDashedLineIcon, PencilIcon, ProfileIcon, VerticalDashedLineIcon } from '../../../assets';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { Modal } from '../../organisms';
import { Loader } from '../../atoms';
import { TNode } from './types';
import { ResponsibleDropdown } from '../responsibleDropdown';
export const Node: FC<TNode> = ({
  node,
  deep = 0,
  isLast,
  setSelectedNode,
  setEditModal,
  setRootNodes,
  searchExpanded,
  isSearchLast
}) => {
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [childNodes, setChildNodes] = useState<ApiCompanyNode[]>([]);
  const [open, setOpen] = useState<boolean>(!!searchExpanded);
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);
  const [responsible, serResponsible] = useState(false);
  const [expand, setExpand] = useState(!!searchExpanded);
  const handleDelete = (id: number) => {
    setRootNodes(prev => prev.filter(el => el.id !== id));
  };
  useEffect(() => {
    if (!searchExpanded) {
      if (id && node.id && open && !node.isLeaf) {
        nodesApi.getNodesByCompany(+id, node.id).then(res => {
          setChildNodes(res.data);
          setExpand(true);
        });
      }
      if (!open) {
        setExpand(false);
      }
    }
  }, [open]);
  return <>
      <NodeStyles onClick={() => setOpen(!open)} style={{
      paddingLeft: `${deep * 33 + 16}px`
    }}>
        <div className="nodeInfo">
          <div className="nodeMark">
            {!node.isLeaf && <>
                <div className={expand ? 'arrowWrapper active' : 'arrowWrapper'}>
                  <ArrowDownIcon width={10} color="#555" />
                </div>
                {expand && !isSearchLast && <div className="arrowVerticalLine">
                    <VerticalDashedLineIcon />
                  </div>}
              </>}
            {!node.isLeaf && !!deep && <>
                <div className="horizontalLineNone">
                  <HorizontalDashedLineIcon />
                </div>
                <div className={isLast || searchExpanded ? 'verticalLineNone last' : 'verticalLineNone'}>
                  <VerticalDashedLineIcon />
                </div>
              </>}
            {node.isLeaf && !!deep && <>
                <div className="horizontalLineLeaf">
                  <HorizontalDashedLineIcon />
                </div>
                <div className={isLast ? 'verticalLineLeaf last' : 'verticalLineLeaf'}>
                  <VerticalDashedLineIcon />
                </div>
              </>}
          </div>
          <div className="nodeName">{node.name}</div>
          {!!node.responsibleIDs && <div className="responsibleWrapper" onMouseLeave={() => serResponsible(false)}>
              <div className="userIcon" onMouseEnter={() => serResponsible(true)}>
                <ProfileIcon />
                {responsible && <ResponsibleDropdown nodeID={node.id} />}
              </div>
            </div>}
        </div>
        <div className="nodeUtils">
          <div className="editButtons">
            <div className="buttonContainer">
              <button type="button" onClick={e => {
              e.stopPropagation();
              setSelectedNode(`${node.id}`);
              setEditModal(true);
            }}>
                <PencilIcon />
              </button>
            </div>
            <div className="buttonContainer">
              <button type="button" onClick={e => {
              e.stopPropagation();
              setModal(true);
            }} disabled={!node.isLeaf}>
                <DeleteIcon color={!node.isLeaf ? '#dedede' : '#FF0101'} />
              </button>
            </div>
            <div className="splitter">
              <div />
            </div>
            <div className="buttonContainer">
              <Link className="openButton" title={getTranslationByLangOrEng(interfaceLanguage, 'go_to_forms_page_button_title')} to={`/companies/${id}/nodes/${node.id}/forms`}>
                <FormsListIcon />
              </Link>
            </div>
            <div className="buttonContainer">
              <Link className="openButton" title={getTranslationByLangOrEng(interfaceLanguage, 'go_to_reviews_page_button_title')} to={`/reviews/page/1?companyID=${id}&nodes=${node.id}`}>
                <FeedbacksListIcon />
              </Link>
            </div>
          </div>
        </div>

        {modal && <Modal title={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_table_delete_title')} onClose={() => setModal(false)} extraStyles={{
        width: '544px'
      }}>
            <div className="modalButtonsWrap">
              <p className="modalButtonsCaption">
                {`${getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_table_delete_description_1')} "${node.name}" ${getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_table_delete_description_3')}!`}
              </p>
              {loading && <Loader margin={10} />}
              {!loading && <div>
                  <button type="button" onClick={() => setModal(false)}>
                    {getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
                  </button>
                  <button type="button" onClick={() => {
              setLoading(true);
              formApi.deleteNode(node.id).then(res => {
                if (res.statusCode === 200) {
                  handleDelete(node.id);
                  setLoading(false);
                  setModal(false);
                } else {
                  setDeleteError(true);
                }
              });
            }}>
                    {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
                  </button>
                </div>}
              {deleteError && <p className="modalError">
                  {`* ${getTranslationByLangOrEng(interfaceLanguage, 'delete_error')}`}
                </p>}
            </div>
          </Modal>}
      </NodeStyles>

      {open && childNodes.map((el, index, array) => <Node isLast={index === array.length - 1} key={el.id} node={el} deep={deep + 1} setSelectedNode={setSelectedNode} setEditModal={setEditModal} setRootNodes={setChildNodes} />)}
    </>;
};