import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { CsiDetailedDashboardStyles } from './styles';
import { useWindowSize } from 'usehooks-ts';
import { useAppSelector } from '../../../../state';
import { analyticsApi, ApiCsi, ApiGroupedCsi, fieldsApi, TPostGroupedCsi } from '../../../../api';
import { BarChart, HorizontalBar } from '../../analyticsCharts';
import { DataItem } from '../../../../pages';
const colors: string[] = ['#FF3800', '#FF831A', '#5B5AFF', '#D7F64D', '#FFDB4D', '#89DB33', '#CC2E4F', '#FFDB4D'];
type TCsiDetailedDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[];
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
export const CsiDetailedDashboard: FC<TCsiDetailedDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const [csiData, setCsiData] = useState<ApiGroupedCsi | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostGroupedCsi = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      positiveAnswers: ['Чудово', 'Добре'],
      fieldIDs: [858718, 858809]
    };
    setLoading(true);
    analyticsApi.getGroupedCsi(+companyID, filteredData).then(res => {
      console.log(res);
      setCsiData(res.data);
      setLoading(false);
    });
  }, [reQuery]);
  return <CsiDetailedDashboardStyles>
      <h2>Статистика по показнику рівня задоволеності (CSI)</h2>
      {csiData && <div className="charts">
          {/* <div className="charts-one">
            <BarChart
              width={dimensions.width / 9}
              height={(dimensions.height - 200) / 2}
              data={[{
                label: 'CSI',
                value: csiData.csi,
                fill: '#FFDB4D',
              }]}
            />
           </div> */}
          {/* <div className="charts-two">
            <BarChart
              width={(dimensions.width / 9) * 2}
              height={(dimensions.height - 200) / 2}
              data={[
                {
                  label: 'Жінка',
                  value: csiData.csiW,
                  fill: '#CC2E4F',
                },
                {
                  label: 'Чоловік',
                  value: csiData.csiM,
                  fill: 'rgb(208, 220, 229)',
                },
              ]}
            />
           </div> */}
          {/* <div className="charts-three">
            <BarChart
              width={(dimensions.width / 9) * 3}
              height={(dimensions.height - 200) / 2}
              data={[
                {
                  label: '14-18',
                  value: csiData.csi1418,
                  fill: '#FFDB4D',
                },
                {
                  label: '19-25',
                  value: csiData.csi1925,
                  fill: '#FFDB4D',
                },
                {
                  label: '26-35',
                  value: csiData.csi2635,
                  fill: '#FFDB4D',
                },
                {
                  label: '36-55',
                  value: csiData.csi3645,
                  fill: '#FFDB4D',
                },
                {
                  label: '46-60',
                  value: csiData.csi4660,
                  fill: '#FFDB4D',
                },
                {
                  label: 'Більше 60',
                  value: csiData.csiUp60,
                  fill: '#FFDB4D',
                },
              ]}
            />
           </div> */}
          {/* <div className="charts-four">
            <BarChart
              width={(dimensions.width / 9) * 2}
              height={(dimensions.height - 200) / 2}
              data={[
                {
                  label: 'ФОП',
                  value: csiData.csiSoleProprietorship,
                  fill: '#FFDB4D',
                },
                {
                  label: 'Юр. особа',
                  value: csiData.csiLegalEntity,
                  fill: '#FFDB4D',
                },
                {
                  label: 'Фізична особа',
                  value: csiData.csiIndividual,
                  fill: '#FFDB4D',
                },
              ]}
            />
           </div> */}
          {/* <div className="charts-five">
            <HorizontalBar
              width={(dimensions.width / 9) * 5}
              height={(dimensions.height - 200) / 2}
              data={csiData.csiOrganTypes.map((el) => ({ label: el.name, value: el.value }))}
            />
           </div> */}
          {/* <div className="charts-six">
            <HorizontalBar
              width={(dimensions.width / 9) * 3}
              height={(dimensions.height - 200) / 2}
              data={csiData.csiCnapTypes.map((el) => ({ label: el.name, value: el.value }))}
            />
           </div> */}
        </div>}
    </CsiDetailedDashboardStyles>;
};