import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams, createSearchParams, useLocation } from 'react-router-dom';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import { ReviewsTableStyles, SearchContainer, DropDownContainer, IconContainer, StyledSelect, Separator } from './styles';
import { ApiResponse, companyApi, companySettingsApi, reviewApi, authApi, formApi, exportApi } from '../../../api';
import { getNodesDictionary, getLanguages, addKeyQuestionValueToAnswer } from '../../../utils';
import { INode, IReviewForm, CompaniesMainInfo, IContact, IReviewStatus, ISource } from '../../../entities';
import 'react-calendar/dist/Calendar.css';
import { ConnectionChannelExtraField, KeyAnswerExtraField, LanguagesExtraField, NameExtraField, StatusExtraField, SourceExtraField, EmailExtraField, UserNameExtraField, PhoneNumberExtraField, CommentExtraField, KeyQuestionAnswerField, TagsField, CategoryField, AttachmentField, SentimentField } from './ReviewsTableFields';
import { useAppDispatch, useAppSelector, setCompanyMetadata, setUser } from '../../../state';
import { availablePageSizes, hiddenReviewFilterStatuses, reviewsStatusIconsAndText } from '../../../constants';
import { useWindowSize } from 'usehooks-ts';
import { Helmet } from 'react-helmet-async';
import { extraFieldsSettings, mapTableSettingsKeyNames, options } from './utils';
import { CompanyFilter, CustomSelect, FilterSelectInput, Loader, StatusesCounter, SubHeader, Table, TransparentButton } from '../../../components';
import { useDebounce } from '../../../hooks';
import { SearchIcon } from '../../../assets';
import { DropDownKeys, TDropDownValue } from './TReviewsTable';
import { parseSearchString } from '../../../utils/parseSearchString';
import { Expandable } from '../../../components/atoms/expandable/index';
import { TQuestion } from '../../../components/molecules/reviewsTableFilters/scoreFilter/TScoreFilter';
import download from 'downloadjs';
export const ReviewsTable = () => {
  const {
    pageNumber
  } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const code = search.get('companyID');
  const {
    width
  } = useWindowSize();
  const {
    languages: languagesState,
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    data
  } = useAppSelector(state => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingReviews, setLoadingReviews] = useState<boolean>(false);
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(availablePageSizes[1]);
  const [selectedCategoryRow, setSelectedCategoryRow] = useState<string | undefined>(undefined);
  const [selectedTagRow, setSelectedTagRow] = useState<string | undefined>(undefined);
  const [searchField, setSearchField] = useState<string>('');
  const [selectedType, setSelectedType] = useState<TDropDownValue>(options[0]);
  const [headers, setHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [clientStatuses, setClientStatuses] = useState<number[]>([]);
  const nodesRef = useRef<{
    [key: string]: Partial<INode>;
  }>({});
  const formsRef = useRef<{
    [key: string]: {
      lang: number;
      companyId: number;
      nodeId: number;
    };
  }>({});
  const reviewsRef = useRef<IReviewForm[]>();
  const reviewsQuantityRef = useRef<number>(0);
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const tableColumnsSettingsRef = useRef<{
    [key: string]: boolean;
  }>({});
  const sourcesRef = useRef<{
    reviewId: number;
    value: string | null | undefined;
  }[]>([]);
  const reviewContactsRef = useRef<Array<IContact[]>>([]);
  const debouncedSearchField = useDebounce(searchField, 1500);
  const reviewStatuses = Object.values(reviewsStatusIconsAndText(interfaceLanguage))?.filter(item => !hiddenReviewFilterStatuses.includes(item?.value));
  const onClickToRow = async (data: any) => {
    window.open(`/reviews/company/${code}/review/${data.id}`, '_blank');
  };
  const getCompanies = (data: CompaniesMainInfo[]) => {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      // @ts-ignore
      acc[`${item.id}`] = item;
      return acc;
    }, {});
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const compID = Object.values(apiCompanies)[0].id;
      filterParamsRef.current = {
        companyID: compID
      };
      const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
      navigate(`/reviews/page/1?${path}`);
      setReQuery(!reQuery);
    }
  };
  const getCompanyNodes = async (id: number) => {
    const res = await formApi.getCompanyNodes(id);
    if (res.statusCode >= 200 && res.statusCode < 300) {
      const newNodesDictionary: {
        [key: number]: Partial<INode>;
      } = {};

      // @ts-ignore
      getNodesDictionary(newNodesDictionary, res.data);
      const nodesDictionary = nodesRef.current ? {
        ...nodesRef.current,
        ...newNodesDictionary
      } : newNodesDictionary;
      if (Object.keys(nodesDictionary).length > 0) {
        // @ts-ignore
        nodesRef.current = nodesDictionary;
      }
    }
  };
  function getReviewsAdditionalData(item: IReviewForm, source: ISource) {
    return {
      reviewId: item.id,
      value: source ? source.name : ''
    };
  }
  function transformReviewAnswers(review: any) {
    const reviewAnswersWithType = review.answers.map((item: any) => ({
      ...item,
      type: item.field.type,
      keyQuestion: addKeyQuestionValueToAnswer(item)
    }));
    return {
      ...review,
      answers: reviewAnswersWithType
    };
  }
  async function workWithResponse(response: ApiResponse<IReviewForm[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const reviewsModified = response.data.map(transformReviewAnswers);
      const data = [...(reviewsRef.current || []), ...(Array.isArray(reviewsModified) ? reviewsModified : [])];
      reviewsRef.current = data;
      reviewsQuantityRef.current = response.count!;
      if (tableColumnsSettingsRef.current?.sources) {
        sourcesRef.current = response.data.map(item => getReviewsAdditionalData(item, item.source as ISource));
      }
      if (tableColumnsSettingsRef.current?.communication_channel) {
        reviewContactsRef.current = response.data.map(item => item.contacts);
      }
      const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
      setSearchParams(path);
      setLoadingReviews(false);
    }
  }
  const filterQuestions = useRef<TQuestion[]>();
  const resetFilterQuestions = () => {
    filterQuestions.current = undefined;
  };
  const applyFilters = (params: any) => {
    filterParamsRef.current = params;
    if (params.questions) {
      filterQuestions.current = params.questions;
      delete params.questions;
    }
    const path = createSearchParams(params as Record<string, string | string[]>);
    navigate(`/reviews/page/1?${path}`);
  };
  const refreshFilter = () => {
    filterParamsRef.current = {
      companyID: filterParamsRef.current?.companyID
    };
    filterQuestions.current = undefined;
    const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
    setSearchParams(path);
    setReQuery(!reQuery);
  };
  const setFilterParams = (params: any) => {
    filterParamsRef.current = params;
  };
  const downloadExcel = () => {
    const newFilterParams = parseSearchString(searchParams);
    if (newFilterParams.companyID) {
      exportApi.exportReviews({
        ...newFilterParams,
        search: {
          [selectedType.name]: debouncedSearchField
        } as {
          clientID: string;
          phone: string;
          comment: string;
          [key: string]: any;
        }
      }).then(res => {
        const filename = 'review.xlsx';
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        download(res.data as Blob, filename, mimeType);
      });
    }
  };
  useEffect(() => {
    if (!languagesState) {
      getLanguages(dispatch);
    }
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });
  }, []);
  useEffect(() => {
    if (filterParamsRef.current.companyID) {
      getCompanyNodes(+filterParamsRef.current.companyID);
      companySettingsApi.getCompanySettings(+filterParamsRef.current.companyID).then(sett => {
        setClientStatuses(sett.data['client-statuses'] || []);
      });
    }
    const interval = setInterval(() => {
      if (filterParamsRef.current.companyID === 110) {
        setReQuery(!reQuery);
      }
    }, 180000);
    return () => clearInterval(interval);
  }, [filterParamsRef.current.companyID, reQuery]);
  useEffect(() => {
    if (data?.id) {
      authApi.getMyAccount(data.id).then(res => {
        dispatch(setUser(res.data));
      });
    }
  }, []);
  useEffect(() => {
    if (formsRef.current && reviewsRef.current && tableColumnsSettingsRef.current) {
      const data = [...(tableColumnsSettingsRef.current?.reviewId ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_review_id_column_title'),
        accessor: 'reviewId',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <div>{row.original.id}</div>
      }] : []), ...(tableColumnsSettingsRef.current?.created_at ? [{
        Header: <FilterSelectInput filtersOpenerStyles={{
          minWidth: 220
        }} columnName="createdAt" title={getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_create')} filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'createAt'
      }] : []), ...(tableColumnsSettingsRef.current?.statuses ? [{
        Header: <FilterSelectInput columnName="statuses" title={getTranslationByLangOrEng(interfaceLanguage, 'table_columns_settings_status_option')} filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} status={reviewStatuses as IReviewStatus[] || []} />,
        accessor: 'statuses',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <StatusExtraField status={row.original.status} lang={interfaceLanguage} />
      }] : []), ...(tableColumnsSettingsRef.current?.nodes ? [{
        Header: <FilterSelectInput title={getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_node_column_title')} columnName="nodes" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} containerExtraStyles="extraFilterStyles" extraFilterFormStyles="extraFilterFormStyles" optionsExtraStyles="optionsExtraStyles" />,
        accessor: 'nodeName',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <NameExtraField nodeName={row.original.node.name || ''} />
      }] : []), ...(tableColumnsSettingsRef.current?.sources ? [{
        Header: <FilterSelectInput title={getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_source')} columnName="sources" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'sources',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <SourceExtraField reviewId={row.original.id} sources={sourcesRef.current} />
      }] : []), ...(tableColumnsSettingsRef.current?.keyAnswer ? [{
        Header: <FilterSelectInput title={`${getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_estimate')}`} columnName="keyAnswer" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'keyAnswer',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <KeyAnswerExtraField answers={row.original.answers} />
      }] : []), ...(tableColumnsSettingsRef.current?.keyQuestion ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_key_question_column_title'),
        accessor: 'keyQuestion',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <KeyQuestionAnswerField answers={row.original.answers} />
      }] : []), ...(tableColumnsSettingsRef.current?.communication_channel ? [{
        Header: <FilterSelectInput columnName="contacts" title={getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_contact_type')} filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'connectionChannel',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => {
          const contactsData = reviewContactsRef.current.filter((arr: IContact[]) => arr?.some((obj: IContact) => obj.clientID === row.original.id));
          return <ConnectionChannelExtraField answers={row.original.answers} contacts={contactsData.flat()} />;
        }
      }] : []), ...(tableColumnsSettingsRef.current?.comment ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_comment_column_title'),
        accessor: 'comment',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <CommentExtraField comment={row.original.comment} />
      }] : []), ...(tableColumnsSettingsRef.current?.name ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_user_name_column_title'),
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <UserNameExtraField answers={row.original.answers} />
      }] : []), ...(tableColumnsSettingsRef.current?.phone ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_phone_column_title'),
        accessor: 'phone',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <PhoneNumberExtraField answers={row.original.answers} />
      }] : []), ...(tableColumnsSettingsRef.current?.email ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_email_column_title'),
        accessor: 'email',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <EmailExtraField answers={row.original.answers} />
      }] : []), ...(languagesState?.length && tableColumnsSettingsRef.current?.language ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_language'),
        accessor: 'language',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <LanguagesExtraField lang={row.original.language} />
      }] : []), ...(tableColumnsSettingsRef.current?.categories ? [{
        Header: <FilterSelectInput title={getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_categories_column_title')} columnName="categories" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} containerExtraStyles="extraFilterStyles" extraFilterFormStyles="extraFilterFormStyles" optionsExtraStyles="optionsExtraStyles" />,
        accessor: 'categories',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <CategoryField index={row.id}
        // eslint-disable-next-line eqeqeq
        isOpened={String(row.id) == String(selectedCategoryRow)} setSelectedRow={setSelectedCategoryRow} categories={row.original.categories} />
      }] : []), ...(tableColumnsSettingsRef.current?.tags ? [{
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput filtersOpenerStyles={{
          minWidth: 200
        }} columnName="tag" title="Теги" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'tags',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <TagsField index={row.id}
        // eslint-disable-next-line eqeqeq
        isOpened={String(row.id) == String(selectedTagRow)} setSelectedRow={setSelectedTagRow} tags={row.original.tags ?? []} />
      }] : []), ...(tableColumnsSettingsRef.current?.attachments ? [{
        Header: <FilterSelectInput columnName="attachmentTypes" title={getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_attachments_column_title')} filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'attachments',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <AttachmentField answers={row.original.answers.filter((el: any) => el.type === 'file_upload')} />
      }] : [])
      // ...(tableColumnsSettingsRef.current?.sentiment
      //   ? [
      //     {
      //       Header: (
      //         <FilterSelectInput
      //           columnName="sentiment"
      //           title="Sentiment"
      //           filterParams={filterParamsRef.current}
      //           setFilterParams={setFilterParams}
      //           setReQuery={() => setReQuery(!reQuery)}
      //           setApply={applyFilters}
      //         />
      //       ),
      //       accessor: 'sentiment',
      //       // eslint-disable-next-line react/no-unstable-nested-components
      //       Cell: ({ row }: any) => (
      //         <SentimentField sentiment={row.original.sentiment} />
      //       ),
      //     },
      //   ]
      //   : []),
      ];
      setHeaders(data);
    }
  }, [formsRef.current, sourcesRef.current, reviewsRef.current, interfaceLanguage, tableColumnsSettingsRef.current, selectedCategoryRow, selectedTagRow]);
  useEffect(() => {
    if (!loadingReviews) {
      const newFilterParams = filterParamsRef.current; // parseSearchString(searchParams);
      if (newFilterParams.companyID) {
        setLoadingReviews(true);
        reviewsRef.current = undefined;
        reviewApi.postDataToGetFilteredReviews({
          ...filterParamsRef.current,
          // questionAnswers: mapAnswers(filterQuestions.current ?? []),
          search: {
            [selectedType.name]: debouncedSearchField
          } as {
            clientID: string;
            phone: string;
            comment: string;
            [key: string]: any;
          }
        }, (pageNumber ? +pageNumber - 1 : 0) * pageSize, pageSize).then(formsResult => {
          workWithResponse(formsResult);
        });
      }
    }
  }, [pageNumber, reQuery, pageSize, debouncedSearchField]);
  useEffect(() => {
    if (filterParamsRef.current.companyID) {
      companyApi.getCompanyMetadataByCompanyId(+filterParamsRef.current.companyID).then(res => {
        if (res.statusCode === 200) {
          dispatch(setCompanyMetadata(res.data));
          const tableSettings: {
            [key: string]: boolean;
          } = {};
          const tableColumnSettings = res.data?.reviewTableSettings;
          tableColumnSettings?.forEach(item => {
            tableSettings[mapTableSettingsKeyNames(item.name)] = true;
          });
          tableColumnsSettingsRef.current = tableSettings;
        }
      }).catch(e => {
        console.log('e', e);
      });
    }
  }, [filterParamsRef.current.companyID]);
  const shouldShowTable = !!formsRef.current && !!reviewsRef.current && !!headers && !loadingReviews && filterParamsRef.current?.companyID;
  const shouldShowSearch = !!formsRef.current && !!reviewsRef.current && !!headers && !loadingReviews && filterParamsRef.current?.companyID;
  const shouldShowStatuses = !!formsRef.current && !!reviewsRef.current && !loadingReviews && clientStatuses;
  const shouldShowTranspButtons = !!formsRef.current && !!reviewsRef.current && !!headers && !loadingReviews && !!filterParamsRef.current?.companyID;
  const [isOpen, setIsOpen] = useState(false);
  return <ReviewsTableStyles tableVersion={width < 1020} mobileVersion={width < 500}>
      <Helmet>
        <title>Reviews Voicer</title>
      </Helmet>
      <div style={{
      height: 15
    }} />
      <div className="headerWrapper">
        <SubHeader companyID={+filterParamsRef.current.companyID} title={`${getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_title')} (${reviewsQuantityRef.current})`} />
        {shouldShowStatuses && <StatusesCounter clientStatuses={clientStatuses} />}
      </div>
      <div className="tableFiltersWrapper">
        {companies && <CompanyFilter filterParams={filterParamsRef.current} companies={companies} setFilterParams={applyFilters} setReQuery={() => setReQuery(!reQuery)} />}
        <div className="tableFiltersWrapperBtns">
          {shouldShowTranspButtons && <TransparentButton filled handleClick={() => {
          setIsOpen(e => !e);
        }} text="Додаткові фільтри" />}
          {shouldShowTranspButtons && <TransparentButton filled handleClick={downloadExcel} text="Вивантажити відгуки" />}
          {shouldShowTranspButtons && <TransparentButton text={getTranslationByLangOrEng(interfaceLanguage, 'refresh_all_fitlers')} handleClick={() => refreshFilter()} extraStyles={{
          backgroundColor: '#767676',
          border: 'none'
        }} extraButtonTextStyles={{
          color: '#ffffff'
        }} />}
        </div>
      </div>
      {shouldShowTable && <Expandable isOpen={isOpen}>
          <FilterSelectInput extraStyles={{
        marginBottom: 8
      }} filtersOpenerStyles={{
        width: 270
      }} columnName="cnap" title="ЦНАП/СНАП" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />
          <FilterSelectInput extraStyles={{
        marginBottom: 8
      }} filtersOpenerStyles={{
        width: 270
      }} columnName="types" title="тип ЦНАП" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />
          <FilterSelectInput extraStyles={{
        marginBottom: 8
      }} filtersOpenerStyles={{
        width: 270
      }} columnName="years" title="Рік" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />
          <FilterSelectInput filtersOpenerStyles={{
        width: 400
      }} extraStyles={{
        marginBottom: 8
      }} columnName="answers" title="Відповіді на запитання"
      // @ts-ignore
      filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} filterQuestions={filterQuestions.current} resetFilterQuestions={resetFilterQuestions} />

          {/* <FilterSelectInput
            filtersOpenerStyles={{ width: 270 }}
            columnName="age"
            title="Вік"
            filterParams={filterParamsRef.current}
            setFilterParams={setFilterParams}
            setReQuery={() => setReQuery(!reQuery)}
            setApply={applyFilters}
           />
           <FilterSelectInput
            filtersOpenerStyles={{ width: 270 }}
            columnName="gender"
            title="Стать"
            filterParams={filterParamsRef.current}
            setFilterParams={setFilterParams}
            setReQuery={() => setReQuery(!reQuery)}
            setApply={applyFilters}
           /> */}

          <FilterSelectInput filtersOpenerStyles={{
        minWidth: 270
      }} extraStyles={{
        marginBottom: 8
      }} columnName="services" title="Послуги якими скористались" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />
        </Expandable>}
      <div style={{
      height: 15
    }} />
      {shouldShowSearch && <SearchContainer>
          <DropDownContainer>
            <IconContainer>
              <SearchIcon />
            </IconContainer>
            <StyledSelect>
              <CustomSelect containerStyle={{
            borderWidth: 0,
            backgroundColor: '#eef3f7',
            marginTop: 0
          }} options={options} valueField={selectedType.name} selected={selectedType} labelField="label" name="name" onChange={e => {
            setSelectedType(e);
            setSearchField('');
          }} placeholder="Enter type" />
            </StyledSelect>
          </DropDownContainer>
          {/* <Separator /> */}
          <input placeholder={selectedType.placeholder} type={selectedType.name === DropDownKeys.comment ? 'text' : 'number'} value={searchField} onChange={({
        target: {
          value
        }
      }) => {
        setSearchField(value);
      }} />
        </SearchContainer>}
      {loadingReviews && <Loader />}
      {!loadingReviews && !filterParamsRef.current.companyID && <div className="chooseCompanyTitleContainer">
          <div className="chooseCompanyTitle">
            {translations[interfaceLanguage].reviews_page_choose_company_title}
          </div>
        </div>}
      <div style={{
      height: 15
    }} />
      {shouldShowTable && <Table pagination columns={headers} data={reviewsRef.current || []} hideFieldsSplitters extraFieldsSettings={extraFieldsSettings} onClickToRow={onClickToRow} rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_review_title')} pageSize={pageSize} setPageSize={setPageSize} currentPage={pageNumber ? +pageNumber : 1} rowsQuantity={reviewsQuantityRef.current} pageNumberHandler={page => {
      if (page) {
        const params = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
        navigate(`/reviews/page/${page}?${params}`);
      }
    }} />}
    </ReviewsTableStyles>;
};