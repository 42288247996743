import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { SubHeaderStyles } from './SubHeaderStyles';
import { useWindowSize } from 'usehooks-ts';
import { GoogleReviewCounter } from '../../molecules';
type TPath = {
  name: string;
  route?: string;
  callback?: (path?: string) => void;
};
interface SubHeaderProps {
  title: string;
  pathArray?: TPath[];
  hideBorderBottom?: boolean;
  companyID?: number;
  strechChild?: boolean;
}
export const SubHeader: React.FC<SubHeaderProps> = ({
  title,
  pathArray,
  hideBorderBottom,
  children,
  companyID = 0,
  strechChild
}) => {
  const {
    width
  } = useWindowSize();
  const [targetPath, setTargetPath] = useState<TPath[]>([]);
  useEffect(() => {
    setTargetPath(pathArray || []);
  }, [pathArray]);
  return <SubHeaderStyles strechChild={strechChild} tableVersion={width < 768} className={hideBorderBottom ? 'titleWrapper withoutBorder' : 'titleWrapper'}>
      <div className="titleContainer">
        <h1>{title}</h1>
        {!!targetPath.length && <div className="path">
            {targetPath.map((item, index) => item.route ? <div key={item.route + index}>
                <Link to={item.route} onClick={event => {
            if (item.callback) {
              event.preventDefault();
              item.callback?.(item.route);
            }
          }}>
                  {item.name}
                </Link>
                {targetPath[index + 1] && <span key={`arrow${index}`} className="arrowRight">
                  {' > '}
                </span>}
              </div> : <div key={item.name + index}>
                <span>{item.name}</span>
                {targetPath[index + 1] && <span key={`arrow${index}`} className="arrowRight">
                  {' > '}
                </span>}
              </div>)}
          </div>}
      </div>
      <div className="childrenContainer">{children}</div>
    </SubHeaderStyles>;
};