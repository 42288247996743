import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InterfaceState {
  fullWidth: boolean;
  grayMode: boolean;
  fontScale: number;
  activeTab: string | undefined;
}

const initialState: InterfaceState = {
  fullWidth: true,
  grayMode: false,
  fontScale: 0.6,
  activeTab: undefined,
};

export const interfaceSlice = createSlice({
  name: 'interface',
  initialState,
  reducers: {
    setSidebarFullWidth: (state, action: PayloadAction<boolean>) => ({
      ...state,
      fullWidth: action.payload,
    }),
    increaseFont: (state) => {
      if (state.fontScale < 0.8) {
        state.fontScale += 0.1
      }
    },
    decreaseFont: (state) => {
      if (state.fontScale > 0.4) {
        state.fontScale -= 0.1
      }
    },
    setGrayMode: (state, action: PayloadAction<boolean>) => ({
      ...state,
      grayMode: action.payload,
    }),
    setSidebarActiveTab: (
      state,
      action: PayloadAction<string | undefined>,
    ) => ({ ...state, activeTab: action.payload }),
  },
  extraReducers: (builder) => {},
});

export const { actions: interfaceActions, reducer: interfaceReducer } = interfaceSlice;
// Extract and export each action creator by name
export const {
  setSidebarActiveTab,
  setSidebarFullWidth,
  setGrayMode,
  increaseFont,
  decreaseFont,
} = interfaceActions;
