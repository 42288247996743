import React from 'react';
import { InfoAlertStyles } from './styles';
import { InfoCharIcon } from '../../../assets';
import { useParams } from 'react-router-dom';
interface InfoAlertProps {
  signature: string;
}
export const InfoAlert = React.memo(({
  signature
}: InfoAlertProps) => {
  const {
    id
  } = useParams();
  return <InfoAlertStyles>
        <div className="infoIcon">
          <InfoCharIcon />
        </div>
        <div className="infoText">
          <p>{signature}</p>
        </div>
      </InfoAlertStyles>;
});