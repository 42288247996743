import React, { useCallback, useEffect, useState } from 'react';
import { DocumentsStyles } from './DocumentsStyles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { documentsApi, companyApi, filesApi } from '../../../api';
import { DeleteIcon, PencilIcon } from '../../../assets';
import { useAppDispatch, useAppSelector, setDocument } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { ICompanyDocument } from '../../../entities/ICompanyDocument';
import { sort } from 'fast-sort';
import { Loader, Modal, Table, TransparentButton } from '../../../components';
function EditButtons({
  id,
  companyId,
  document,
  filterDocumentsAfterDelete
}: {
  id: number;
  companyId: number;
  document: ICompanyDocument;
  filterDocumentsAfterDelete: (id: number) => void;
}) {
  const [modal, setModal] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <>
      {modal && <Modal title={getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_table_delete_title')} onClose={() => setModal(false)} extraStyles={{
      width: '544px'
    }}>
          <div className="modalButtonsWrap">
            <p className="modalButtonsCaption">
              {getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_table_delete_text')}
            </p>
            <div>
              <button type="button" onClick={() => setModal(false)}>
                {getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
              </button>
              <button type="button" onClick={() => {
            documentsApi.deleteDocument(+id).then(res => {
              if (res.statusCode === 200) {
                filterDocumentsAfterDelete(+id);
                // setModal(false);
              } else {
                setDeleteError(true);
              }
            });
          }}>
                {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
              </button>
            </div>
            {deleteError && <p className="modalError">
                {getTranslationByLangOrEng(interfaceLanguage, 'delete_error')}
              </p>}
          </div>
        </Modal>}
      <div className="editButtonsContainer">
        <div className="editButtons">
          <Link onClick={() => dispatch(setDocument(document))} to={`/companies/${companyId}/documents/${id}/edit`}>
            <PencilIcon />
          </Link>
          <button type="button" onClick={() => {
          setModal(true);
        }}>
            <DeleteIcon />
          </button>
        </div>
      </div>
    </>;
}
export default function Documents() {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<ICompanyDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  useEffect(() => {
    setLoading(true);
    if (id) {
      filesApi.getCompanyDocuments(+id).then(res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          const documents = sort([...(res.data || [])]).desc(item => item.created_at ? new Date(item.created_at).getTime() : 0);
          setDocuments(documents);
        }
      }).finally(() => {
        setLoading(false);
      });
    } else {
      navigate('/companies');
    }
  }, []);
  const filterDocumentsAfterDelete = useCallback((id: number) => {
    setDocuments(documents.filter(document => document.id !== id));
  }, [documents]);
  const companiesTableHeaders = React.useMemo(() => [{
    Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_table_header_name'),
    accessor: 'description'
  }, {
    Header: 'edit buttons',
    accessor: 'editButtons',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: ({
      row
    }: any) => <EditButtons id={row.original.id} companyId={+id!} document={row.original} filterDocumentsAfterDelete={filterDocumentsAfterDelete} />
  }], [documents, interfaceLanguage]);
  return <DocumentsStyles>
      <div className="documentsWrapper">
        <div className="tableTitleWrapper">
          <TransparentButton text={getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_table_add_document_button')} handleClick={() => navigate(`/companies/${id}/documents/create`)} />
        </div>
        <div className="documentsTableContainer">
          {loading ? <Loader /> : <Table pagination columns={companiesTableHeaders} data={documents.length ? documents.sort() : []} hideFieldsSplitters hiddenHeaders={['editButtons']} />}
        </div>
      </div>
    </DocumentsStyles>;
}