import React, { useEffect, useState } from 'react';
import { NumericScaleStyles } from './styles';
interface ColorScaleProps {
  numbersColor: string;
  selectedButtonColor: string;
  maxValue: number;
  defaultValue: number | undefined;
  showDefaultValue: boolean;
  signatures: [string | undefined, string | undefined];
  value?: number;
  handleChange?: (value: number | undefined) => void;
  signatureColor?: string;
}
export const NumericScale = ({
  numbersColor,
  selectedButtonColor,
  maxValue,
  defaultValue,
  showDefaultValue,
  signatures,
  value,
  handleChange,
  signatureColor
}: ColorScaleProps) => {
  const [assessment, setAssessment] = useState<number>();
  const numbers = new Array(maxValue).fill(1);
  useEffect(() => {
    if (showDefaultValue) {
      if (handleChange) {
        handleChange(defaultValue ? defaultValue > maxValue ? maxValue : defaultValue : defaultValue);
      } else {
        setAssessment(defaultValue ? defaultValue > maxValue ? maxValue : defaultValue : defaultValue);
      }
    }
  }, [defaultValue]);
  return <NumericScaleStyles signatureColor={signatureColor}>
      <div className="buttons" style={{
      borderColor: selectedButtonColor
    }}>
        {numbers.map((number, index) => <button
      // eslint-disable-next-line react/no-array-index-key
      key={number + index} type="button" style={(value ? value === number + index : assessment ? +assessment === number + index : false) ? {
        backgroundColor: selectedButtonColor,
        borderColor: selectedButtonColor
      } : {
        borderColor: selectedButtonColor
      }} onClick={handleChange ? () => handleChange(number + index) : () => setAssessment(number + index)}>
            <span style={{
          color: numbersColor
        }}>{number + index}</span>
          </button>)}
      </div>

      {signatures[0] && signatures[1] && <div className="signatures">
          <span>{signatures[0]}</span>
          <span>{signatures[1]}</span>
        </div>}
    </NumericScaleStyles>;
};