import React from 'react';
import { ArcElement, Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import { chartColors } from '../../../constants';
import { ChartStyles } from './ChartStyles';
ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const
    }
  }
};
interface ChartProps {
  data: any[] | Object;
  labelKey: string;
  dataKeys?: string[];
  circle?: boolean;
}
interface ChartData {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
}
interface CircleChartData {
  data: number[];
  borderColor: string[];
  backgroundColor: string[];
  borderWidth: number;
  hoverOffset: number;
}
export const Chart: React.FC<ChartProps> = React.memo(({
  labelKey,
  dataKeys,
  data,
  circle
}) => {
  const datasets: {
    labels: string[];
    datasets: (ChartData | CircleChartData)[];
  } = {
    labels: [],
    datasets: []
  };
  if (circle) {
    datasets.labels = Object.keys(data);
    datasets.datasets = [{
      data: [],
      borderColor: [...chartColors.slice(1, datasets.labels.length + 1)],
      backgroundColor: [...chartColors.slice(1, datasets.labels.length + 1)],
      borderWidth: 1,
      hoverOffset: 8
    }];
    datasets.datasets[0].data = Object.values(data);
  } else {
    // @ts-ignore
    datasets.labels = [...new Set(data.map(item => item[labelKey]))];
    const datasetDict: {
      [key: string]: ChartData;
    } = {};
    for (let i = 0; i < dataKeys!.length; i++) {
      datasetDict[dataKeys![i]] = {
        label: dataKeys![i],
        data: [],
        borderColor: chartColors[i],
        backgroundColor: chartColors[i]
      };
    }
    (data as any[]).forEach(item => {
      for (let i = 0; i < dataKeys!.length; i++) {
        datasetDict[dataKeys![i]].data.push(item[dataKeys![i]]);
      }
    });
    datasets.datasets = Object.values(datasetDict);
  }
  return <ChartStyles>
        {circle ? <Doughnut options={{
      ...options,
      scales: {
        // @ts-ignore
        xAxes: [{
          display: false
        }]
      }
    }} data={datasets} height="300px" width="300px" /> : <Line options={options} data={datasets as {
      labels: string[];
      datasets: ChartData[];
    }} height="300px" />}
      </ChartStyles>;
});