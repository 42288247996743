import { getTranslationByLangOrEng } from '../../i18n';

export enum TabKeys {
  import = 'import',
  export = 'export',
}

export type TTab = {
  key: TabKeys;
  text: string;
};

export const tabs = (lang: string): TTab[] => [
  {
    key: TabKeys.import,
    text: getTranslationByLangOrEng(lang, 'impex_import_tab'),
  },
  {
    key: TabKeys.export,
    text: getTranslationByLangOrEng(lang, 'impex_export_tab'),
  },
];
