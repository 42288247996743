export enum ConstructorTabKeys {
  general_info = 'general-info',
  form_content = 'form-content',
  form_btn = 'form-btn',
  form_locations = 'form-locations',
}

export type TTabConstructor = {
  key: ConstructorTabKeys;
  text: string;
};
