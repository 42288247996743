/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import { ApiResponse, ApiTag, ApiCopyForm } from '../entities';
import ITagsApi from './ITagsApi';

class TagsApi implements ITagsApi {
  public async getCompanyTags(id: number): Promise<ApiResponse<ApiTag[]>> {
    const res = await baseApi.getData(`/companies/${id}/tags`);
    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async createCompanyTag(
    id: number,
    name: string,
  ): Promise<ApiResponse<ApiTag>> {
    return baseApi.postData(`/companies/${id}/tags`, { name });
  }

  public async getTag(id: number): Promise<ApiResponse<ApiTag>> {
    return baseApi.getData(`/tags/${id}`);
  }

  public async changeTag(
    id: number,
    name: string,
  ): Promise<ApiResponse<ApiTag>> {
    return baseApi.putData(`/tags/${id}`, { name });
  }

  public async deleteTag(id: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/tags/${id}`);
  }

  public async setTagsToReview(
    id: number,
    tagIDs: number[],
  ): Promise<ApiResponse<any>> {
    return baseApi.postData(`/reviews/${id}/tags`, { tagIDs });
  }

  public async deleteReviewTag(
    id: number,
    tagID: number,
  ): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/reviews/${id}/tags/${tagID}`);
  }
}

export const tagsApi = new TagsApi();
