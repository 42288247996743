import React, { useState } from 'react';
import { CollapsibleProps } from './types';
import { CollapsibleStyles } from './styles';
import { ExpandMore } from '../../../assets/material-icons';
import { Loader } from '../loader';
export const LazyCollapsible: React.FC<CollapsibleProps> = ({
  isLoading,
  onOpen,
  title,
  children,
  containerStyles
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return <CollapsibleStyles>
      <div style={containerStyles} className={`collapsible ${isOpen ? 'open' : ''}`}>
        <div onClick={() => {
        setIsOpen(prev => !prev);
        onOpen();
      }} className="collapsible-header">
          <div className="row">
            <p className="title">{title}</p>
          </div>
          <div className={`collapse-button ${isOpen ? 'opened' : ''}`}>
            <ExpandMore />
          </div>
        </div>
        <div className={`collapsible-content ${isOpen ? 'open' : ''}`}>
          {isLoading ? <Loader margin={10} /> : children}
        </div>
      </div>
    </CollapsibleStyles>;
};