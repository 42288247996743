import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Link, useLocation, useNavigate, useParams, useRoutes, useSearchParams } from 'react-router-dom';
import { LoginStyles } from './styles';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { useAppDispatch, useAppSelector } from '../../state';
import { getTranslationByLangOrEng } from '../../i18n';
import { Helmet } from 'react-helmet-async';
import { getErrorMessage, handleKeyUp } from '../../utils';
import { DiiaAuthApi } from '../../api/diiaAuthApi';
import { Dropdown } from '../../components/molecules/dropdown';
import { authApi } from '../../api';
interface FormValues {
  position: any | undefined;
  type: any | undefined;
  organisation: any | undefined;
}
const initialValues: FormValues = {
  position: undefined,
  type: undefined,
  organisation: undefined
};

// Керівник.
// .
// Працівник ВРМ

const initialExecPositionValue = [{
  id: 'Executive',
  value: 'Керівник'
}];
const initialWorkersPositionValue = [{
  id: 'Employee_tsnap',
  value: 'Працівник ЦНАП'
}, {
  id: 'Employee_VRM',
  value: 'Працівник ВРМ'
}];
const initialTypeValue = [{
  id: '1_tsnap',
  value: 'ЦНАП'
}, {
  id: '2_snap',
  value: 'СНАП'
}];
const initialOrganisationValue = {
  '1_tsnap': [{
    id: 'DTministery',
    value: 'Мінцифри'
  }, {
    id: 'DP_Diia',
    value: 'ДП Дія'
  }, {
    id: 'ODA',
    value: 'Обласна Державна адміністрація'
  }, {
    id: 'OMS',
    value: 'ОМС'
  }, {
    id: 'TSNAP',
    value: 'ЦНАП'
  }],
  '2_snap': [{
    id: 'TSNAP',
    value: 'ЦОВВ'
  }, {
    id: 'organisation',
    value: 'структурний підрозділ ЦОВВ/ОДА/ДРАЦС/іншої установи чи організації'
  }, {
    id: 'ZOZ',
    value: 'ЗОЗ'
  }]
};
const initialPositionValue = {
  DTministery: initialExecPositionValue,
  ODA: initialExecPositionValue,
  DP_Diia: initialExecPositionValue,
  default: [...initialExecPositionValue, ...initialWorkersPositionValue]
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const SelectRole = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useQuery();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    data,
    status,
    statusCode
  } = useAppSelector(state => state.user);
  // console.log(params.forEach((e) => console.log(e)));
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  useEffect(() => {
    if (status === 'failed') {
      setErrorMessage('Email or password is wrong!');
    }
    setIsLoading(false);
  }, [status, statusCode, data]);

  // const validationSchema = Yup.object({
  //   position: Yup.string()
  //     .email(
  //       getTranslationByLangOrEng(
  //         interfaceLanguage,
  //         'validation_invalid_email',
  //       ),
  //     )
  //     .required(
  //       getTranslationByLangOrEng(interfaceLanguage, 'validation_required'),
  //     ),
  //   phone: Yup.string().required(
  //     getTranslationByLangOrEng(interfaceLanguage, 'validation_required'),
  //   ),
  // });

  function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    navigate(`/auth/select_node/${values.type.value}/${values.organisation.value}/${values.position.value}`);
  }

  // useEffect(() => {}, [])

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue
  }: FormikProps<FormValues>) => {
    console.log(values);
    return <Form autoComplete="off">
        <Dropdown extraStyles={{
        marginBottom: 20
      }} id="2739h3rh973r2" placeholder="ЦНАП/СНАП" value={values.type} variants={initialTypeValue} setValue={val => {
        setFieldValue('type', val);
        setFieldValue('organisation', undefined);
        setFieldValue('position', undefined);
      }} />
        {/* @ts-ignore */}
        {values?.type?.id && <Dropdown extraStyles={{
        marginBottom: 20
      }} id="2739h3r38712h973r2" placeholder="Організація" value={values.organisation}
      // @ts-ignore
      variants={initialOrganisationValue[values?.type?.id]} setValue={e => {
        setFieldValue('organisation', e);
        // @ts-ignore
        if (initialPositionValue[e?.id]?.length) {
          setFieldValue('position',
          // @ts-ignore
          initialPositionValue[e.id][0]);
        } else {
          setFieldValue('position',
          // @ts-ignore
          null);
        }
      }} />}
        {/* @ts-ignore */}
        {values?.organisation?.id && <Dropdown extraStyles={{
        marginBottom: 20
      }} id="r37hgbf9g374hf34" placeholder="Посада" value={values.position} variants={
      // @ts-ignore
      initialPositionValue[values.organisation.id] ?? initialPositionValue.default} setValue={e => setFieldValue('position', e)} />}
        <div className="row">
          <SubmitButton type="button" onClick={() => navigate(-1)} extraButtonStyles={{
          width: 220
        }} extraBlockStyles={{
          display: 'flex',
          alignSelf: 'center'
        }}>
            Назад
          </SubmitButton>
          <SubmitButton extraButtonStyles={{
          width: 220
        }} extraBlockStyles={{
          display: 'flex',
          alignSelf: 'center'
        }}>
            Підтвердити дані
          </SubmitButton>
        </div>
      </Form>;
  };
  return <LoginStyles>
      <Helmet>
        <title>Authorization Voicer</title>
      </Helmet>

      <div className="formWrapper">
        <div className="formContainer">
          <h2>Оберіть</h2>
          <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}
        // validationSchema={validationSchema}
        >
            {renderForm}
          </Formik>
        </div>
      </div>
    </LoginStyles>;
};