import { MutableRefObject } from 'react';
import { formApi } from '../../../api';
import { DeepArrayItem } from '../../../entities/DeepArrayItem';
import { getNodeNamesArray, getNodesDeepArray } from '../../../utils';
import { NodeFilterItem } from '../../molecules/reviewsTableFilters/nodeFilter/additionalSettings';
import { getParentId } from '../../molecules/reviewsTableFilters/nodeFilter/utils';

export function expandAllSelectedItemsAndTheirParents(
  obj: DeepArrayItem[],
  addToExpandedItemsList: (id: number) => void,
) {
  obj.forEach((item) => {
    if (!item.allChildrenAreSelected) {
      if (item.selected || item.hasSelectedChildren) {
        addToExpandedItemsList(item.id);
      }

      if (item.hasSelectedChildren) {
        expandAllSelectedItemsAndTheirParents(
          item.children,
          addToExpandedItemsList,
        );
      }
    }
  });
}

export function expandSearchedItemsAndTheirParents(
  obj: DeepArrayItem[],
  addToExpandedItemsList: (id: number) => void,
) {
  obj.forEach((item) => {
    addToExpandedItemsList(item.id);

    if (item.children.length) {
      expandSearchedItemsAndTheirParents(item.children, addToExpandedItemsList);
    }
  });
}

export function findParentNode(array: NodeFilterItem[], item: NodeFilterItem) {
  const parentNodeIndex = array.findIndex((node) => node.id === item.parentId);
  if (parentNodeIndex !== undefined) {
    array[parentNodeIndex].hasSelectedChildren = true;
    if (
      array[parentNodeIndex].deep !== 0
      && !array[parentNodeIndex - 1].hasSelectedChildren
    ) {
      findParentNode(array, array[parentNodeIndex]);
    }
  }
}

export async function getNodesBySelectedCompany(
  companyId: number,
  nodesRef: MutableRefObject<DeepArrayItem[]>,
  nodeNamesRef: MutableRefObject<NodeFilterItem[]>,
) {
  const nodes = await formApi.getCompanyNodes(companyId);

  if (nodes.statusCode >= 200 && nodes.statusCode < 300) {
    const nodeNamesArray: {
      id: number;
      name: string;
      deep: number;
      parentId: number | undefined;
    }[] = [];

    getNodeNamesArray(nodeNamesArray, nodes.data || []);

    nodeNamesArray.map((item, index) => {
      if (item.deep > 0) {
        item.parentId = getParentId(nodeNamesArray, item.deep, index);
      }

      return item;
    });

    nodeNamesRef.current = nodeNamesArray.map((item) => ({
      ...item,
      selected: false,
      hasSelectedChildren: false,
      allChildrenAreSelected: false,
    }));
    nodesRef.current = getNodesDeepArray(nodeNamesArray);
  }
}
