import styled from 'styled-components';

export const CompaniesInfoModalStyles = styled.div`
  .modalContainer {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
    border-radius: 4px 4px 0px 0px;

    p {
      text-align: center;
      margin-bottom: 20px;
    }

    .buttonsContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      button {
        padding: 0 10px;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        transition: 0.3s all;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .confirmButton {
        background: #000;
        border: 1px solid #000;
        color: #ffffff;
        transition: all 0.3s;

        &:hover {
          background: #ffffff;
          color: #000;
        }
      }

      .declineButton {
        background: #ffffff;
        border: 1px solid #c4c4c4;
        color: #000000;

        :hover {
          color: #fff;
          background: #c4c4c4;
        }
      }
    }
  }
`;
