import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import * as React from 'react';
import { TIcon } from './TIcon';
const DropdownIcon_1 = _compiledBlock(_props => <svg viewBox="0 0 1024 1024" width={_props.v0} height={_props.v1} fill={_props.v2} version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path d="M556.01602 769.767264l453.883943-454.93226c18.798868-18.797098 18.798868-49.373591 0.008854-68.167148-9.057669-9.054127-21.159352-14.042485-34.080917-14.042485s-25.023249 4.988358-34.082688 14.044256L511.467873 687.601901 82.146769 246.561608c-8.95142-8.94965-21.054874-13.938008-33.972898-13.938008-12.919795 0-25.023249 4.988358-34.082688 14.044256-18.786473 18.791785-18.786473 49.368279 0 68.156523l452.562922 454.652473c10.723996 9.19225 25.28887 21.563095 38.55043 21.559553 1.156336 0 2.30913-0.093853 3.424737-0.279787l2.103717-0.348849 2.078925 0.462181c1.514038 0.336453 3.102451 0.504679 4.720967 0.504679 10.879827 0.001771 24.546902-7.672899 38.483139-21.607365z" />
  </svg>, {
  name: "DropdownIcon_1",
  svg: true
});
export const DropdownIcon: React.FC<TIcon> = ({
  width = 448,
  height = 512,
  color = '#000'
}) => /*@million jsx-skip*/<DropdownIcon_1 v0={width} v1={height} v2={color} />;