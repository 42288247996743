import React, { useEffect, useRef, useState } from 'react';
import { QuantityStyles } from './styles';
import { IColor } from '../../../entities';
import { mapIColorToString } from '../../../mappers';
interface QuantityProps {
  minValue: number | undefined;
  maxValue: number | undefined;
  defaultValue: number;
  step: number;
  buttonsBackgroundColor: IColor;
  buttonsSignsColor: IColor;
}
export const Quantity = React.memo(({
  minValue = 0,
  maxValue,
  defaultValue,
  step = 1,
  buttonsSignsColor,
  buttonsBackgroundColor
}: QuantityProps) => {
  const [value, setValue] = useState<number>(0);
  const [width, setWidth] = useState<number>(14);
  const inputRef = useRef<HTMLInputElement>(null);
  function focusOutEventListener() {
    if (!inputRef.current?.value) {
      setValue(minValue);
    }
  }
  useEffect(() => {
    setValue(defaultValue);
    setWidth(defaultValue !== undefined ? `${defaultValue}`.length * 14 : 14);
  }, []);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('focusout', focusOutEventListener);
      return () => {
        inputRef.current?.removeEventListener('focusout', focusOutEventListener);
      };
    }
  }, [inputRef.current]);
  useEffect(() => {
    setWidth(value !== undefined ? `${value}`.length * 14 : 14);
  }, [value]);
  function handleMinusOne() {
    if (minValue !== undefined && value - step >= minValue || minValue === undefined) {
      setValue(prev => prev - step);
    }
  }
  function handlePlusOne() {
    if (maxValue !== undefined && value + step <= maxValue || maxValue === undefined) {
      setValue(prev => prev + step);
    }
  }
  function changeListener(newValue: number | string) {
    const newValueInt: number = newValue && newValue !== '-' ? +newValue : newValue as number;
    if (minValue !== undefined && newValueInt && newValueInt < minValue) {
      setValue(minValue);
    } else if (maxValue !== undefined && newValueInt && newValueInt > maxValue) {
      setValue(maxValue);
    } else {
      setValue(newValueInt);
    }
  }
  return <QuantityStyles>
        <button type="button" style={{
      backgroundColor: mapIColorToString(buttonsBackgroundColor)
    }} onClick={handleMinusOne}>
          <span style={{
        color: mapIColorToString(buttonsSignsColor)
      }}>-</span>
        </button>
        <span className="valueContainer">
          <input ref={inputRef} type="text" value={value} onChange={e => {
        const re = /^-?\d*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
          changeListener(e.target.value);
        }
      }} style={{
        width
      }} inputMode="numeric" />
        </span>
        <button type="button" style={{
      backgroundColor: mapIColorToString(buttonsBackgroundColor)
    }} onClick={handlePlusOne}>
          <span style={{
        color: mapIColorToString(buttonsSignsColor)
      }}>+</span>
        </button>
      </QuantityStyles>;
});