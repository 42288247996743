const rgbaParts = ['r', 'g', 'b', 'a'];

export function getColorFromString(
  str: string,
): string | { r: number; g: number; b: number; a: number } {
  // @ts-ignore
  return str && str.startsWith('rgba')
    ? str
      .substring(5, str.length - 1)
      .split(', ')
      .reduce((acc, item, index) => {
        // @ts-ignore
        acc[rgbaParts[index]] = item;
        return acc;
      }, {})
    : str;
}
