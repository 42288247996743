import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ClientButtonStyles } from './styles';
import { toast } from 'react-toastify';
import { ApiConstructorForm, TAdditionalParams, companyApi, constructorApi } from '../../../api';
import { useParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { SubmitButton } from '../../atoms';
import { InputField } from '../../molecules';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { IFieldColor, addAdditionalParam, mapApiColorToFieldColor, mapFieldColorToApiColor } from '../fieldConstructor';
import CustomColorsSelect from '../../form/customSelect/CustomColorsSelect';
const defaultText: IFieldColor = {
  name: 'Text color',
  color: '#fff'
};
const defaultDisabledText: IFieldColor = {
  name: 'Disabled text color',
  color: '#fff'
};
const defaultBg: IFieldColor = {
  name: 'Bg color',
  color: '#000'
};
const defaultDisabledBg: IFieldColor = {
  name: 'Diia bg',
  color: '#ccc'
};
export interface IClientButton {
  text: string;
  send_button_background_color: IFieldColor;
  send_button_text_color: IFieldColor;
  send_button_disabled_background_color: IFieldColor;
  send_button_disabled_text_color: IFieldColor;
}
const initialValue: IClientButton = {
  text: 'Надіслати',
  send_button_background_color: defaultBg,
  send_button_disabled_text_color: defaultDisabledText,
  send_button_text_color: defaultText,
  send_button_disabled_background_color: defaultDisabledBg
};
const validationSchema = Yup.object({
  text: Yup.string().required('* Required')
});
export const ClientButton = () => {
  const {
    companyId,
    formId
  } = useParams();
  const [initialValues, setInitialValues] = useState<IClientButton>();
  const [apiForm, setApiForm] = useState<ApiConstructorForm>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [companyColors, setCompanyColors] = useState<IFieldColor[]>([]);
  async function getCompanyMetadata() {
    if (companyId) {
      const res = await companyApi.getCompanyMetadata(+companyId!);
      if (res.statusCode >= 200 && res.statusCode < 300) {
        setCompanyColors(res.data.filter(item => item.key.startsWith('color_')).map(item => {
          const colorParts = item.value.split('|||');
          return {
            name: colorParts[0],
            color: colorParts[1]
          };
        }));
      }
    }
  }
  const onSubmit = async (values: IClientButton, {
    setSubmitting
  }: FormikHelpers<IClientButton>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    let styles: TAdditionalParams[] = [];
    if (formId && apiForm) {
      options = [...apiForm.options];
      styles = [...apiForm.styles];
      addAdditionalParam(values.text, 'text', options);
      addAdditionalParam(mapFieldColorToApiColor(values.send_button_background_color), 'send_button_background_color', styles);
      addAdditionalParam(mapFieldColorToApiColor(values.send_button_disabled_background_color), 'send_button_disabled_background_color', styles);
      addAdditionalParam(mapFieldColorToApiColor(values.send_button_disabled_text_color), 'send_button_disabled_text_color', styles);
      addAdditionalParam(mapFieldColorToApiColor(values.send_button_text_color), 'send_button_text_color', styles);
      constructorApi.updateForm(+formId, {
        ...apiForm,
        styles,
        options
      }).then(res => {
        console.log(res);
        toast.success('Форма успішно оновлена');
      });
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<IClientButton>) => <Form>
      <InputField name="text" onChange={setFieldValue} onKeyUp={() => handleKeyUp('text', setErrorMessage, errorMessage)} placeholder="Підпис кнопки" value={values.text} error={typeof errorMessage === 'object' ? getErrorMessage('text', errorMessage) : undefined} />
      <div className="constructor-line" />

      <div className="button-wrapper">
        <button type="button" className="button-item" style={{
        backgroundColor: values.send_button_background_color.color as string,
        color: values.send_button_text_color.color as string
      }}>
          {values.text || 'Send'}
        </button>
        <button type="button" className="button-item" style={{
        backgroundColor: values.send_button_disabled_background_color.color as string,
        color: values.send_button_disabled_text_color.color as string
      }}>
          {values.text || 'Send'}
        </button>
      </div>

      <div className="constructor-line" />

      <div className="color-wrapper">
        <h6>Колір тексту кнопки</h6>

        <div className="color-selector">
          <CustomColorsSelect name="send_button_text_color" placeholder="Виберіть колір" options={companyColors} value={values.send_button_text_color} handleSelect={color => {
          setFieldValue('send_button_text_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('send_button_text_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <div className="color-wrapper">
        <h6>Колір тексту вимкненої кнопки</h6>

        <div className="color-selector">
          <CustomColorsSelect name="send_button_disabled_text_color" placeholder="Виберіть колір" options={companyColors} value={values.send_button_disabled_text_color} handleSelect={color => {
          setFieldValue('send_button_disabled_text_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('send_button_disabled_text_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <div className="color-wrapper">
        <h6>Колір фону кнопки</h6>

        <div className="color-selector">
          <CustomColorsSelect name="send_button_background_color" placeholder="Виберіть колір" options={companyColors} value={values.send_button_background_color} handleSelect={color => {
          setFieldValue('send_button_background_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('send_button_background_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <div className="color-wrapper">
        <h6>Колір фону вимкненої кнопки</h6>

        <div className="color-selector">
          <CustomColorsSelect name="send_button_disabled_background_color" placeholder="Виберіть колір" options={companyColors} value={values.send_button_disabled_background_color} handleSelect={color => {
          setFieldValue('send_button_disabled_background_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('send_button_disabled_background_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <div className="constructor-line" />

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '15px 45px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    getCompanyMetadata();
    if (formId) {
      constructorApi.getForm(+formId).then(res => {
        const text = res.data.options.find(option => option.key === 'text')?.value || 'Надіслати';
        const send_button_background_color = res.data.styles.find(option => option.key === 'send_button_background_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'send_button_background_color')?.value!) : defaultBg;
        const send_button_text_color = res.data.styles.find(option => option.key === 'send_button_text_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'send_button_text_color')?.value!) : defaultText;
        const send_button_disabled_background_color = res.data.styles.find(option => option.key === 'send_button_disabled_background_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'send_button_disabled_background_color')?.value!) : defaultDisabledBg;
        const send_button_disabled_text_color = res.data.styles.find(option => option.key === 'send_button_disabled_text_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'send_button_disabled_text_color')?.value!) : defaultDisabledText;
        setApiForm(res.data);
        setInitialValues({
          text,
          send_button_background_color,
          send_button_text_color,
          send_button_disabled_background_color,
          send_button_disabled_text_color
        });
      });
    }
  }, []);
  return <ClientButtonStyles>
      <div className="constructor">
        {initialValues && <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {renderForm}
          </Formik>}
      </div>
    </ClientButtonStyles>;
};