import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FilterSelectStyles } from './styles';
import { useClickOutside } from '../../../hooks';
import { ArrowDownIcon, CheckMark, SearchIcon } from '../../../assets';
import { TFilterSelect } from './utils';
export const FilterSelect: FC<TFilterSelect<any>> = ({
  options,
  valueField,
  labelField,
  label,
  search,
  filterParams,
  setReQuery,
  reQuery,
  accessor
}) => {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const filteredParams = useMemo(() => {
    if (search) {
      return options.filter(option => (labelField ? `${option[labelField]}` : `${option}`).toLowerCase().includes(searchParams.toLowerCase()));
    }
    return options;
  }, [searchParams, options, reQuery]);
  const valueText = useMemo(() => {
    const accessorValue = filterParams.current?.[accessor];
    if (Array.isArray(accessorValue) && accessorValue && !!accessorValue.length) {
      if (accessorValue.length === 1) {
        const firstItem = accessorValue[0];
        if (labelField && valueField) {
          return options.find(el => el[valueField] === firstItem) ? options.find(el => el[valueField] === firstItem)[labelField] : '';
        }
        return firstItem;
      }
      return 'Вибір кількох варіантів';
    }
    return 'Усі';
  }, [filterParams.current, reQuery]);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchParams(e.target.value);
  };
  const handleSelect = (option: any) => {
    const accessorValue = filterParams.current?.[accessor];
    const selectedValue = valueField ? option[valueField] : option;
    if (filterParams.current) {
      if (Array.isArray(accessorValue)) {
        if (typeof accessorValue[0] === 'string') {
          const updatedArray: string[] = accessorValue as string[];
          if (typeof selectedValue === 'string') {
            if (updatedArray.includes(selectedValue)) {
              filterParams.current[accessor] = updatedArray.filter(item => item !== selectedValue);
            } else {
              filterParams.current[accessor] = [...updatedArray, selectedValue];
            }
          } else {
            filterParams.current[accessor] = [selectedValue];
          }
        } else if (typeof accessorValue[0] === 'number') {
          const updatedArray: number[] = accessorValue as number[];
          if (typeof selectedValue === 'number') {
            if (updatedArray.includes(selectedValue)) {
              filterParams.current[accessor] = updatedArray.filter(item => item !== selectedValue);
            } else {
              filterParams.current[accessor] = [...updatedArray, selectedValue];
            }
          } else {
            filterParams.current[accessor] = [selectedValue];
          }
        } else {
          filterParams.current[accessor] = [selectedValue];
        }
      } else {
        filterParams.current[accessor] = [selectedValue];
      }
      setReQuery(!reQuery);
    }
  };
  const checkSelected = useCallback((option: any): boolean => {
    const accessorValue = filterParams.current?.[accessor];
    if (Array.isArray(accessorValue) && accessorValue) {
      const searchValue = valueField ? option[valueField] : option;
      if ((typeof searchValue === 'string' || typeof searchValue === 'number') && (accessorValue as Array<string | number>).includes(searchValue)) {
        return true;
      }
    }
    return false;
  }, [filterParams.current]);
  useEffect(() => {
    setSearchParams('');
  }, [open]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  return <FilterSelectStyles>
      <h4>{label}:</h4>
      <div className="select-wrapper">
        <div className="select-value" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
          <span className="select-value">{valueText}</span>
          <div style={{
          height: 36
        }} className={open ? 'select-arrow' : 'select-arrow select-arrow-active'}>
            <ArrowDownIcon />
          </div>
        </div>

        {open && <div className="select-options" id="optionsList" ref={optionsListRef}>
            {search && <div className="select-search">
                <SearchIcon />
                <input type="text" value={searchParams} onChange={handleChange} placeholder="Шукати..." />
              </div>}
            {!!filteredParams.length && filteredParams.map((option, index) => <div key={`${valueField ? option[valueField] : option}-${index}`} className="select-option">
                  <button type="button" onClick={() => handleSelect(option)}>
                    <div className={checkSelected(option) ? 'select-default select-selected' : 'select-default'}>
                      {checkSelected(option) && <CheckMark />}
                    </div>
                    <div className="select-content">
                      <span>
                        {labelField ? option[labelField] : option}
                      </span>
                    </div>
                  </button>
                </div>)}
            {!filteredParams.length && <div className="select-option">
                <div className="select-noitem">-</div>
              </div>}
          </div>}
      </div>
    </FilterSelectStyles>;
};