import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { LoginStyles } from './styles';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { useAppDispatch, useAppSelector } from '../../state';
import { getTranslationByLangOrEng } from '../../i18n';
import { Helmet } from 'react-helmet-async';
import { getErrorMessage, handleKeyUp } from '../../utils';
import { DiiaAuthApi } from '../../api/diiaAuthApi';
import { InputField, ResponsibleDropdown } from '../../components';
import { loginUser } from '../../state/thunk/authThunk';
interface FormValues {
  email: string;
  password: string;
}
const initialValues: FormValues = {
  email: '',
  password: ''
};
export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    data,
    status,
    statusCode
  } = useAppSelector(state => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  useEffect(() => {
    if (status === 'failed') {
      setErrorMessage('Email or password is wrong!');
    }
    setIsLoading(false);
  }, [status, statusCode, data]);
  const validationSchema = Yup.object({
    email: Yup.string().email(getTranslationByLangOrEng(interfaceLanguage, 'validation_invalid_email')).required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
    password: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required'))
  });
  function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    if (!isLoading) {
      setSubmitting(false);
      setIsLoading(true);
      dispatch(loginUser(values));
    }
  }
  const authViaGovId = () => {
    DiiaAuthApi.idGovUaAuth();
  };
  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue
  }: FormikProps<FormValues>) => <Form autoComplete="off">
      {/* <ResponsibleDropdown nodeID={23131} /> */}
      <div className="login-form">
        <InputField name="email" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'email_placeholder')} autocomplete value={values.email} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
        <InputField name="password" type="password" onChange={setFieldValue} onKeyUp={() => handleKeyUp('password', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'password_placeholder')} autocomplete value={values.password} error={typeof errorMessage === 'object' ? getErrorMessage('password', errorMessage) : undefined} />
      </div>
      <Link to="/auth/recovery" className="form__link">
        Забули пароль?
      </Link>
      <SubmitButton extraBlockStyles={{
      display: 'flex',
      alignSelf: 'center'
    }}>
        {getTranslationByLangOrEng(interfaceLanguage, 'enter_button')}
      </SubmitButton>

      <p style={{
      display: 'flex',
      alignSelf: 'center'
    }}>або</p>
      <SubmitButton type="button" onClick={authViaGovId} // TODO id gov ua auth here
    extraBlockStyles={{
      display: 'flex',
      alignSelf: 'center'
    }}>
        Увійти через id.gov.ua
      </SubmitButton>
    </Form>;
  return <LoginStyles>
      <Helmet>
        <title>Authorization Voicer</title>
      </Helmet>

      <div className="formWrapper">
        <div className="formContainer">
          <h2>Авторизація на платформі</h2>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {renderForm}
          </Formik>
        </div>
      </div>
    </LoginStyles>;
};