import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import * as React from 'react';
import { TIcon } from './TIcon';
const TitleIcon_1_1 = _compiledBlock(_props => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" role="img" width={_props.v0} height={_props.v1}>
      <path fill={_props.v2} d="M254 52.8C249.3 40.3 237.3 32 224 32s-25.3 8.3-30 20.8L57.8 416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32h-1.8l18-48h159.6l18 48H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32h-25.8L254 52.8zM279.8 304H168.2L224 155.1 279.8 304z" />
    </svg>, {
  name: "TitleIcon_1_1",
  svg: true
});
const TitleIcon_1 = ({
  width = 448,
  height = 512,
  color = '#000'
}) => /*@million jsx-skip*/<TitleIcon_1_1 v0={width} v1={height} v2={color} />;
export const TitleIcon: React.FC<TIcon> = React.memo(TitleIcon_1);