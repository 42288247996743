import styled from 'styled-components';

export const TemplatersStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 600px;
  min-height: 180px;
  @media screen and (max-width: 768px) {
    width: 500px;
  }

  @media screen and (max-width: 468px) {
    width: 300px;
  }

  .preview {
    width: 100%;
    margin-bottom: 20px;
    p {
      font-family: 'e-Ukraine', sans-serif;
      -webkit-font-smoothing: antialiased;
    }

    .nicer {
      color: 'red';
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: rgb(240, 245, 245);

    .tab {
      width: 33.333%;
      text-align: center;
      padding: 15px 0;
      cursor: pointer;
    }

    .active {
      background: rgb(133, 173, 173);
    }
  }

  .tools {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .addNewTemplate {
      width: 20px;
      height: 20px;
      margin-left: 16px;
      background-color: #000;
      border-radius: 10px;
      display: grid;
      place-items: center;

      svg {
        width: auto;
        height: 12px;
      }
    }
  }

  .form {
    position: relative;
    /* background-color: red; */
    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &:hover {
      .editTemplate,
      .deleteTemplate {
        display: block;
        cursor: pointer;
        z-index: 1000;
      }
    }

    .template {
      margin-bottom: 24px;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid rgb(229, 229, 229);
      font-size: 14px;
      background-color: rgb(250, 250, 250);

      span {
        color: #e41212;
        font-weight: 900;
      }
    }

    .deleteTemplate {
      position: absolute;
      right: 10px;
      top: 0%;
      display: none;
    }

    .editTemplate {
      width: 20px;
      height: 20px;
      margin-left: 16px;
      background-color: #000;
      border-radius: 10px;
      display: none;
      place-items: center;
      position: absolute;
      right: 35px;
      top: 0%;

      svg {
        width: auto;
        height: 12px;
      }
    }
  }
`;
