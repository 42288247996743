import React, { Dispatch, FC, SetStateAction, useEffect, RefObject, useState } from 'react';
import { AnalyticsFiltersStyles } from './styles';
import { FilterSelect, YearMonths } from '../../molecules';
import { analyticsApi, ApiAnalyticFilter, ApiPostAnalyticFilter } from '../../../api';
import { JustPlusIcon } from '../../../assets';
import { TransparentButton } from '../../atoms';
type TAnalyticsFilters = {
  companyId: number;
  filterParams: RefObject<{
    [key: string]: number | string | string[] | number[];
  }>;
  setReQuery: Dispatch<SetStateAction<boolean>>;
  setFilters: Dispatch<SetStateAction<boolean>>;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  reQuery: boolean;
  filters: boolean;
  selectedTab: string;
  handleRefreshFilter: () => void;
};
const initialFilter: ApiAnalyticFilter = {
  cnapTypes: [],
  idfs: [],
  organTypes: [],
  regions: [],
  relatedByOrgans: []
};
export const AnalyticsFilters: FC<TAnalyticsFilters> = ({
  companyId,
  filterParams,
  setReQuery,
  reQuery,
  selectedTab,
  filters,
  setFilters,
  setSelectedTab,
  handleRefreshFilter
}) => {
  const [data, setData] = useState<ApiAnalyticFilter>(initialFilter);
  const handleClose = () => {
    setFilters(false);
  };
  useEffect(() => {
    if (filterParams.current) {
      const {
        companyID,
        regions,
        relatedByOrgans,
        ...restFilterParams
      } = filterParams.current;
      const filteredData: ApiPostAnalyticFilter = {
        regions: regions as number[],
        relatedByOrgans: relatedByOrgans as number[]
      };
      analyticsApi.analyticsFilters(companyID as number, filteredData).then(res => {
        if (res.statusCode === 200) {
          setData(res.data);
        }
      });
    }
  }, [reQuery]);
  return <AnalyticsFiltersStyles filters={filters}>
      <div className="close-filters" onClick={handleClose} title="Закрити фільтри">
        <JustPlusIcon color="#999" width={25} height={25} />
      </div>
      <TransparentButton text="Очистити фільтри" handleClick={handleRefreshFilter} extraStyles={{
      backgroundColor: '#767676',
      border: 'none'
    }} extraButtonTextStyles={{
      color: '#ffffff'
    }} />
      <div className="filter-wrapper">
        <YearMonths filterParams={filterParams} reQuery={reQuery} setReQuery={setReQuery} />
        {selectedTab !== 'csi' && <>
            <FilterSelect options={data?.regions || []} accessor="regions" filterParams={filterParams} reQuery={reQuery} valueField="id" labelField="name" setReQuery={setReQuery} label="Область" search />
            <FilterSelect options={data.organTypes ? data.organTypes.map(el => el.name) : []} accessor="organTypes" filterParams={filterParams} reQuery={reQuery} setReQuery={setReQuery} label="Тип органу що утворив" search />
            <FilterSelect options={data.cnapTypes ? data?.cnapTypes.map(el => el.name) : []} accessor="cnapTypes" filterParams={filterParams} reQuery={reQuery} setReQuery={setReQuery} label="Тип ЦНАП" />
            <FilterSelect options={data?.relatedByOrgans || []} accessor="relatedByOrgans" filterParams={filterParams} reQuery={reQuery} valueField="id" labelField="name" setReQuery={setReQuery} label="Назва органу що утворив" search />
            <FilterSelect options={data?.idfs || []} accessor="idfs" filterParams={filterParams} reQuery={reQuery} setReQuery={setReQuery} label="IDF" search />
          </>}
      </div>
    </AnalyticsFiltersStyles>;
};