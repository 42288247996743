import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import * as React from 'react';
import { TIcon } from './TIcon';
const RadioButtonIcon_1_1 = _compiledBlock(_props => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" width={_props.v0} height={_props.v1}>
      <path fill={_props.v2} d="M160 256C160 202.1 202.1 160 256 160C309 160 352 202.1 352 256C352 309 309 352 256 352C202.1 352 160 309 160 256zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
    </svg>, {
  name: "RadioButtonIcon_1_1",
  svg: true
});
const RadioButtonIcon_1 = ({
  width = 512,
  height = 512,
  color = '#000'
}) => /*@million jsx-skip*/<RadioButtonIcon_1_1 v0={width} v1={height} v2={color} />;
export const RadioButtonIcon: React.FC<TIcon> = React.memo(RadioButtonIcon_1);