import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { TFieldConstruct } from '../../companyFields';
import { toast } from 'react-toastify';
import { ApiCompanyField, ApiCreatedField, ApiUpdatedField, FieldType, TAdditionalParams, fieldsApi } from '../../../../api';
import { addAdditionalParam } from '../utils';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Checkbox, InputField } from '../../../molecules';
import { SubmitButton } from '../../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
type TButton = {
  field: TFieldConstruct;
  setFields: Dispatch<SetStateAction<ApiCompanyField[]>>;
  setField: Dispatch<SetStateAction<TFieldConstruct | null>>;
};
export interface IButtonField {
  question: string;
  link: string;
  blank: boolean;
}
const initialValue: IButtonField = {
  question: '',
  link: '',
  blank: false
};
export const validationSchema = Yup.object({
  question: Yup.string().required('* Required')
});
export const Button: FC<TButton> = ({
  field,
  setFields,
  setField
}) => {
  const {
    id
  } = useParams();
  const [initialValues, setInitialValues] = useState<IButtonField>();
  const [apiField, setApiField] = useState<ApiCompanyField | null>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const onSubmit = async (values: IButtonField, {
    setSubmitting
  }: FormikHelpers<IButtonField>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    const styles: TAdditionalParams[] = [];
    if (id) {
      if (apiField) {
        options = [...apiField.options];
        addAdditionalParam(values.link, 'link', options);
        addAdditionalParam(values.blank, 'blank', options);
        const data: ApiUpdatedField<FieldType.Button> = {
          question: values.question,
          options,
          styles
        };
        fieldsApi.updateFormField(apiField.id!, data).then(res => {
          if (res.statusCode === 200) {
            setFields(prev => prev.map(el => {
              if (el.id === apiField.id) {
                return {
                  ...el,
                  ...data
                };
              }
              return el;
            }));
            toast.success('Поле успішно оновлено');
          }
        });
      } else {
        addAdditionalParam(values.link, 'link', options);
        addAdditionalParam(values.blank, 'blank', options);
        const data: ApiCreatedField = {
          field: {
            [FieldType.Button]: {
              question: values.question,
              options,
              styles
            }
          }
        };
        fieldsApi.createCompanyFields(+id, data).then(res => {
          if (res.statusCode === 201) {
            setFields(prev => [res.data, ...prev]);
            setField({
              id: res.data.id,
              type: field.type
            });
            toast.success('Поле успішно додано');
          }
        });
      }
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<IButtonField>) => <Form>
      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="question" onChange={setFieldValue} onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)} placeholder="Введіть підпис кнопки" value={values.question} error={typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined} />
      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="link" onChange={setFieldValue} onKeyUp={() => handleKeyUp('link', setErrorMessage, errorMessage)} placeholder="Посилання куди має вести кнопка" value={values.link} error={typeof errorMessage === 'object' ? getErrorMessage('link', errorMessage) : undefined} />
      <Checkbox name="blank" value={values.blank!} onChange={handleChange}>
        <span className="checkboxValue">
          Має вести на окрему сторінку
        </span>
      </Checkbox>

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '15px 45px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    if (field.id) {
      fieldsApi.getTargetField(field.id).then(res => {
        const blank = res.data.options.find(option => option.key === 'blank')?.value === 'true';
        const link = res.data.options.find(option => option.key === 'link')?.value || '';
        setApiField(res.data);
        setInitialValues({
          question: res.data.question,
          link,
          blank
        });
      });
    } else {
      setInitialValues(initialValue);
    }
  }, [field]);
  return <div className="field-body">
      {initialValues && <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
          {renderForm}
        </Formik>}
    </div>;
};