import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './state';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { pdfjs } from 'react-pdf';
const persistor = persistStore(store);
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
ReactDOM.render(<React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>, document.getElementById('root'));
reportWebVitals();