import { isArray } from 'lodash';

export const handleCreateSearchParams = (params: {
  [key: string]: number | string | string[] | number[];
}): string => Object.entries(params)
// eslint-disable-next-line array-callback-return
  .map(([key, value]) => {
    if (
      (key === 'statuses' && isArray(value))
        || (key === 'nodes' && isArray(value))
        || (key === 'contacts' && isArray(value))
        || (key === 'sources' && isArray(value))
    ) {
      return `${key}=${value.join(',')}`;
    }
    if (key === 'from' || key === 'to' || key === 'companyID') {
      return `${key}=${value}`;
    }
  })
  .join('&');
