import styled from 'styled-components';

export const CsiDetailedDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  gap: 50px;

  @media screen and (max-width: 1400px) {
    gap: 30px;
  }

  h2 {
    width: 80%;
    text-align: center;
    font-size: 24px;

    @media screen and (max-width: 1400px) {
      font-size: 18px;
    }
  }

  .charts {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      'one two two three three three four four'
      'one two two three three three four four'
      'five five five five five six six six'
      'five five five five five six six six';

    &-one {
      grid-area: one;
    }

    &-two {
      grid-area: two;
    }

    &-three {
      grid-area: three;
    }

    &-four {
      grid-area: four;
    }

    &-five {
      grid-area: five;
    }

    &-six {
      grid-area: six;
    }
  }
`;
