import styled from 'styled-components';

export const RegistrationStyles = styled.div`
  flex-grow: 1;
  display: grid;
  place-items: center;
  height: 100%;
  
  .formContainer {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.14));
    background-color: #fff;
    border-radius: 4px;
    width: 100%;
    max-width: 672px;
    
    .titleContainer {
      height: 55px;
      display: flex;
      align-items: center;
      padding-left: 32px;
      border-bottom: 1px solid #F0F0F0;

      h1 {
        font-weight: 500;
        font-size: 18px;
        color: #000000;
      }
    }

    form {
      max-width: calc(100% - 32px);
      margin-bottom: 24px;

        .inputs {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0 24px;
        }
      }
    }

    .form__wrapper {
      padding: 24px 32px 23px;
    }

    .linkContainer {
      display: flex;
      justify-content: center;

      .form__link {
        margin-left: 5px;
        color: #000;
      }
    }

    .extraErrorMessage {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
    }
`;
