/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import { ApiAuthResponse, ApiResponse, ApiUser } from '../entities';
import IAccessesApi, { AccessesParams } from './IAccessesApi';

export type AccessRequest = {
  companyID: number;
  createdAt: string;
  id: number;
  managerID: number;
  nodesIDs: number[];
  processedBy: number;
  status: 'pending' | 'rejected' | 'approved';
  updatedAt: string;
  workerID: number;
};

class AccessesApi implements IAccessesApi {
  async accessReviewList({
    isManager,
    status,
    limit,
    offset,
  }: AccessesParams): Promise<ApiResponse<AccessRequest[]>> {
    const response = await baseApi.getData(
      `/view-access?limit=${limit}&offset=${offset}&isManager=${isManager}${
        status?.length ? `&statuses=${status.join('&statuses=')}` : ''
      }`,
    );
    return {
      data: response.data.requests,
      statusCode: 200,
    };
  }

  async createAccessReview({
    userID,
    company,
    organization,
    userType,
    companyID,
    position,
    nodesIDs,
  }: {
    company: string;
    organization: string;
    userType: string;
    position: string; // посада
    companyID: number;
    nodesIDs: number[];
    userID: number;
  }): Promise<ApiResponse<null>> {
    const response = await baseApi.postData('/view-access', {
      company,
      organization,
      userType,
      companyID,
      position,
      nodesIDs,
    });
    return {
      data: response.data.requests,
      statusCode: 200,
    };
  }

  async sendPosition({
    userID,
    position,
  }: {
    position: string;
    userID: number; // посада
  }): Promise<ApiResponse<null>> {
    const response = await baseApi.putData(`/users/${userID}/position`, {
      position,
    });
    return {
      data: response.data.requests,
      statusCode: 200,
    };
  }

  async rejectAccessRequest(request_id: number): Promise<ApiResponse<null>> {
    const response = await baseApi.postData(
      `/view-access/${request_id}/reject`,
    );
    return { statusCode: 200, data: null };
  }

  async approveAccessRequest(request_id: number): Promise<ApiResponse<null>> {
    const response = await baseApi.postData(
      `/view-access/${request_id}/approve`,
    );
    return { statusCode: 200, data: null };
  }
}

export const accessesApi = new AccessesApi();
