import { TQuestion } from '../../../components/molecules/reviewsTableFilters/scoreFilter/TScoreFilter';
import { DropDownKeys, TDropDownValue } from './TReviewsTable';

export const extraFieldsSettings = {
  connectionChannelExtraField: {
    justifyContent: 'center',
    width: '100px',
  },
  createAt: {
    width: '100px',
    justifyContent: 'center',
    margin: 'auto',
    textColor: '#6c6c6c',
  },
  nodeName: {
    justifyContent: 'center',
  },
  reviewExtraField: {
    justifyContent: 'center',
  },
  language: {
    textColor: '#6c6c6c',
  },
  statuses: {
    justifyContent: 'center',
  },
  keyAnswer: {
    justifyContent: 'center',
  },
  sources: {
    justifyContent: 'center',
    width: '50px',
  },
  email: {
    justifyContent: 'center',
    width: '120px',
    margin: 'auto',
  },
  name: {
    justifyContent: 'center',
  },
  phone: {
    justifyContent: 'center',
  },
  parentNode: {
    justifyContent: 'center',
  },
  comment: {
    justifyContent: 'center',
  },
  keyQuestion: {
    justifyContent: 'center',
  },
  attachments: {
    justifyContent: 'center',
    width: '220px',
  },
  sentiment: {
    justifyContent: 'center',
    width: '220px',
  },
};

export const mapAnswers = (questions: TQuestion[]) => {
  const questionAnswers: { [key: number]: string[] } = {};
  questions.forEach((question) => {
    const selectedAnswers = question.answers
      ?.filter((answer) => !!answer.value)
      .map((e) => e.name);
    if (selectedAnswers?.length) {
      questionAnswers[question.id] = selectedAnswers;
    }
  });
  return questionAnswers;
};

export const options: Array<TDropDownValue> = [
  {
    name: DropDownKeys.reviewID,
    label: 'ID відгука',
    value: true,
    placeholder: 'Пошук за ідентифікатором відгуку',
  },
  {
    name: DropDownKeys.comment,
    label: 'Коментар',
    value: false,
    placeholder: 'Шукати за коментарем',
  },
  {
    name: DropDownKeys.phone,
    label: 'Номер телефону',
    value: false,
    placeholder: 'Пошук за номером телефону',
  },
];

export function mapTableSettingsKeyNames(name: string) {
  switch (name) {
    case 'source':
      return 'sources';
    case 'node':
      return 'nodes';
    case 'status':
      return 'statuses';
    default:
      return name;
  }
}
