/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import {
  ApiEmailCampaigns,
  ApiMassMailing,
  ApiMassSmsMailingResponse,
  ApiResponse,
  ApiSmsCampaigns,
  ApiSmsHistoryItem,
  ApiViberHistoryItem,
} from '../entities';
import IMassMailingApi from './IMassMailing';

class MassMailingApi implements IMassMailingApi {
  public async createMassSmsMailing(
    data: ApiMassMailing,
  ): Promise<ApiResponse<ApiMassSmsMailingResponse>> {
    return baseApi.postData('/sms/campaign', data);
  }

  public async createMassViberMailing(
    data: ApiMassMailing,
  ): Promise<ApiResponse<{ message: string }>> {
    return baseApi.postData('/viber/campaign', data);
  }

  public async createMassEmailMailing(
    data: ApiMassMailing,
  ): Promise<ApiResponse<{ message: string }>> {
    return baseApi.postData('/email/campaign', data);
  }

  public async massMailingViberHistory(
    companyID: number,
  ): Promise<ApiResponse<ApiViberHistoryItem[]>> {
    return baseApi.getData(`/viber-history?companyID=${companyID}`);
  }

  public async massMailingSmsHistory(
    companyID: number,
  ): Promise<ApiResponse<ApiSmsHistoryItem[]>> {
    const res = await baseApi.getData(`/sms-history?companyID=${companyID}`);

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async massMailingSmsCampaignList(
    companyID: number,
  ): Promise<ApiResponse<ApiSmsCampaigns[]>> {
    return baseApi.getData(`/sms-campaigns?companyID=${companyID}`);
  }

  public async massMailingViberCampaignList(
    companyID: number,
  ): Promise<ApiResponse<ApiSmsCampaigns[]>> {
    return baseApi.getData(`/viber-campaigns?companyID=${companyID}`);
  }

  public async massMailingEmailCampaignList(
    companyID: number,
  ): Promise<ApiResponse<{ campaigns: ApiEmailCampaigns[] }>> {
    return baseApi.getData(`/email-campaigns?companyID=${companyID}`);
  }

  public async massMailingSmsCampaign(
    id: number,
  ): Promise<ApiResponse<ApiSmsCampaigns>> {
    return baseApi.getData(`/sms-campaigns/${id}`);
  }

  public async massMailingViberCampaign(
    id: number,
  ): Promise<ApiResponse<ApiSmsCampaigns>> {
    return baseApi.getData(`/viber-campaigns/${id}`);
  }

  public async deleteMassSmsMailingCampaign(
    id: number,
  ): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/sms-campaigns/${id}`);
  }
}

export const massMailingApi = new MassMailingApi();
