/* eslint-disable class-methods-use-this */
import { CompaniesMainInfo, ICompany } from '../../entities';
import {
  mapApiMetadataToICompany,
  mapFromApiCompaniesToICompanies,
  mapFromApiCompanyToICompany,
} from '../Mappers';
import baseApi from '../baseApi';
import { ApiCompany, ApiMetadata, ApiResponse } from '../entities';
import ICompanyApi from './ICompanyApi';

class CompanyApi implements ICompanyApi {
  public async createCompany(data: {
    name: string;
    alias: string;
  }): Promise<ApiResponse<ApiCompany>> {
    return baseApi.postData('/companies', data);
  }

  public async deleteCompany(id: number): Promise<ApiResponse<ApiCompany>> {
    return baseApi.deleteData(`/companies/${id}`);
  }

  public async editCompanyMainInfo(data: {
    name: string;
    alias: string;
    id: number;
  }): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.putData(`/companies/${data.id}`, {
      name: data.name,
      alias: data.alias,
    });
  }

  public async getCompanies(): Promise<ApiResponse<CompaniesMainInfo[]>> {
    const companies = await baseApi.getData('/companies');
    return {
      data: mapFromApiCompaniesToICompanies(companies.data),
      statusCode: companies.statusCode,
    };
  }

  public async getCompanyById(
    id: number,
  ): Promise<ApiResponse<CompaniesMainInfo>> {
    const company = await baseApi.getData(`/companies/${id}`);

    return {
      data: mapFromApiCompanyToICompany(company.data),
      statusCode: company.statusCode,
    };
  }

  public async createCompaniesMetadata(
    data: { key: string; value: string },
    parentId: number,
  ): Promise<ApiResponse<ApiMetadata>> {
    return baseApi.postData('/metadata/companies', {
      ...data,
      parentID: parentId,
    });
  }

  public async updateCompanyMetadata(
    id: number,
    data: string,
  ): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.putData(`/metadata/companies/${id}`, { value: data });
  }

  public async deleteCompanyMetadata(
    id: number,
  ): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.deleteData(`/metadata/companies/${id}`);
  }

  public async getCompanyMetadata(
    id: number,
  ): Promise<ApiResponse<ApiMetadata[]>> {
    return baseApi.getData(`/companies/${id}/metadata`);
  }

  public async getCompanyMetadataByCompanyId(
    id: number,
  ): Promise<ApiResponse<Partial<ICompany>>> {
    const metaDataFromServer = await baseApi.getData(
      `/companies/${id}/metadata`,
    );

    return {
      data: mapApiMetadataToICompany(metaDataFromServer.data),
      statusCode: metaDataFromServer.statusCode,
    };
  }
}

export const companyApi = new CompanyApi();
