import React, { FC } from 'react';
import { useAppSelector } from '../../../state';
import { CategoryChipsStyles } from './styles';
import { TCategoryChips } from './types';
import { Chip } from './chip';
export const CategoryChips: FC<TCategoryChips> = ({
  categories,
  handleRemove
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <CategoryChipsStyles>
      {categories.map(el => <Chip key={el.id} category={el} handleRemove={handleRemove} />)}
    </CategoryChipsStyles>;
};