import styled from 'styled-components';
export const CompanyReviewTableColumnsSettingsStyles = styled.div`
  padding: 26px 32px 80px 16px;

  .tableSettingsWrapper {
    max-width: 540px;
    width: 100%;
    border: 1px solid #f0f0f0;
    margin-bottom: 25px;
    .headWrapper {
      padding: 9px 11px 9px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f0f5f5;
      span {
        font-weight: 500;
        font-size: 15px;
        color: #000000;
      }
    }

    .tableColumnsSettingsList {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .switcherOptionWrapper {
        height: 46px;
        padding: 18px 16px 0px;
        display: grid;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;

        &:nth-child(2n + 1) {
          border-right: 1px solid #f0f0f0;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .maxAppliedSettingsWarn {
    font-size: 12px;
    color: #ff0000;
    margin-bottom: 8px;
  }
`;