import { AvailableQREyeStyle, AvailableQRStyle } from '../../api';

export const qrStyleOptions: { name: string; value: AvailableQRStyle }[] = [
  {
    name: 'Квадрат',
    value: 'square',
  },
  {
    name: 'Округлий',
    value: 'rounded',
  },
  {
    name: 'Квадрат із зазором',
    value: 'gapped_square',
  },
  {
    name: 'Коло',
    value: 'circle',
  },
  {
    name: 'Вертикальні смуги',
    value: 'vertical_bars',
  },
  {
    name: 'Горизонтальні смуги',
    value: 'horizontal_bars',
  },
];

export const qrEyesStyleOptions: { name: string; value: AvailableQREyeStyle }[] = [
  {
    name: 'Квадрат',
    value: 'square',
  },
  {
    name: 'Округлий',
    value: 'rounded',
  },
  {
    name: 'Квадрат із зазором',
    value: 'gapped_square',
  },
  {
    name: 'Коло',
    value: 'circle',
  },
  {
    name: 'Вертикальні смуги',
    value: 'vertical_bars',
  },
  {
    name: 'Горизонтальні смуги',
    value: 'horizontal_bars',
  },
];
