/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import { ApiChatTemplates, ApiResponse } from '../entities';
import IChatTemplatesApi from './IChatTemplatesApi';

class ChatTemplatesApi implements IChatTemplatesApi {
  public async getChatTemplates(
    companyID: number,
  ): Promise<ApiResponse<ApiChatTemplates[]>> {
    return baseApi.getData(`/companies/${companyID}/chat-templates`);
  }

  public async deleteChatTemplate(
    companyID: number,
    templateID: number,
  ): Promise<ApiResponse<any>> {
    return baseApi.deleteData(
      `/companies/${companyID}/chat-templates/${templateID}`,
    );
  }

  public async updateChatTemplate(
    companyID: number,
    templateID: number,
    data: { key: string; content: string },
  ): Promise<ApiResponse<ApiChatTemplates>> {
    return baseApi.putData(
      `/companies/${companyID}/chat-templates/${templateID}`,
      data,
    );
  }

  public async createNewChatTemplate(
    companyID: number,
    data: { key: string; content: string },
  ): Promise<ApiResponse<ApiChatTemplates>> {
    return baseApi.postData(`/companies/${companyID}/chat-templates`, data);
  }
}

export const chatTemplatesApi = new ChatTemplatesApi();
