import styled from 'styled-components';

export const AnalyticStyles = styled.div`
  height: 100%;
  width: 100%;

  .analytics {
    width: 100%;

    &-subheader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
    }

    &-btns {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    &-fullscreen {
      position: absolute;
      right: 25px;
      top: 15px;
      cursor: pointer;
      z-index: 10;
      transition: all 0.3s ease-in-out;

      &.active {
        transform: rotate(45deg);
      }
    }

    &-filters {
      position: absolute;
      left: 25px;
      top: 15px;
      cursor: pointer;
      z-index: 10;
    }

    &-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      max-height: calc(-126px + 100vh);
      position: relative;
      transition: all 0.3s ease-in-out;

      &.full-screen {
        background: rgb(238, 243, 247);
        position: fixed;
        max-height: inherit;
        left: 0;
        top: 0;
        width: 100wv;
        height: 100vh;
        z-index: 100;
      }
    }

    &-charts {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 15px;
      position: relative;
      overflow: auto;
      gap: 20px;
    }
  }

  .tabsContainer {
    position: relative;
    overflow: auto;
    flex-wrap: nowrap !important;

    button {
      white-space: nowrap !important;
      flex-shrink: 0;
      height: 32px;
    }
  }
`;
