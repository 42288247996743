/* eslint-disable class-methods-use-this */
import { IMultiTenancy } from '../../entities/IMultiTenancy';
import baseApi from '../baseApi';
import { ApiMultiTenancy, ApiResponse } from '../entities';
import IMultiTenancyApi from './IMultiTenancyApi';

class MultiTenancyApi implements IMultiTenancyApi {
  public async getMultiTenancy(
    id: number,
  ): Promise<ApiResponse<IMultiTenancy>> {
    return baseApi.getData(`/users/${id}/view`);
  }

  public async putMultiTenancy(
    id: number,
    data: ApiMultiTenancy,
  ): Promise<ApiResponse<any>> {
    return baseApi.putData(`/users/${id}/view`, data);
  }
}

export const multiTenancyApi = new MultiTenancyApi();
