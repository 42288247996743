/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import { ApiCreateSettings, ApiResponse, ApiSettings } from '../entities';
import ICompanySettingsApi from './ICompanySettingsApi';

class CompanySettingsApi implements ICompanySettingsApi {
  public async getCompanySettings(
    id: number,
  ): Promise<ApiResponse<ApiSettings>> {
    return baseApi.getData(`/companies/${id}/settings`);
  }

  public async createCompanySettings(
    id: number,
    data: ApiCreateSettings,
  ): Promise<ApiResponse<any>> {
    return baseApi.postData(`/companies/${id}/settings`, data);
  }

  public async updateCompanySettingsKey(
    id: number,
    key: string,
    data: { value: any },
  ): Promise<ApiResponse<any>> {
    return baseApi.putData(`/companies/${id}/settings/${key}`, data);
  }

  public async deleteCompanySettingsKey(
    id: number,
    key: string,
  ): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/companies/${id}/settings/${key}`);
  }
}

export const companySettingsApi = new CompanySettingsApi();
