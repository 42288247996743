import { QRColor } from '../../../api';
import { ColorPickerColor } from '../../molecules';

export function transformQRColorToObjectRGBColor(color: QRColor): { r: number; g: number; b: number; } {
  return { r: color[0], g: color[1], b: color[2] }
}

export function transformQRColorToHex(color: QRColor): string {
  return `#${color.map((channel) => {
    const hex = channel.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }).join('')}`;
}

export function transformQRColorToColorPickerColor(color: QRColor): ColorPickerColor {
  return {
    rgb: transformQRColorToObjectRGBColor(color),
    hex: transformQRColorToHex(color),
  }
}

export function transformColorPickerValueToQrColor(color: ColorPickerColor): QRColor {
  return [color.rgb.r, color.rgb.g, color.rgb.b]
}
