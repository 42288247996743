import React, { FC, useEffect, useRef, useState } from 'react';
import { setNodeNames, useAppDispatch, useAppSelector } from '../../../state';
import { DeepArrayItem } from '../../../entities';
import { useNavigate } from 'react-router-dom';
import { useClickOutside } from '../../../hooks';
import { ApiConstructorForm, exportApi, formApi } from '../../../api';
import { getErrorMessage, getNodeNamesArray, getNodesDeepArray, handleKeyUp } from '../../../utils';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { getTranslationByLangOrEng } from '../../../i18n';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { InputField } from '../form';
import CustomSelectTiedNode from '../../form/customSelect/CustomSelectTiedNode';
import { Modal } from '../../organisms';
import { Loader } from '../../atoms';
import { fieldsValidation } from './utils';
import { CopyIcon, DeleteIcon, DocumentIcon, PencilIcon, ThreeDots } from '../../../assets';
import { QRCode } from 'react-qr-svg';
import download from 'downloadjs';
export interface ICopyForm {
  nodesList: DeepArrayItem[];
  nodeId: number;
  newFormName: string;
}
type TCustomMenuField = {
  formId: number;
  companyId: number;
  handleDelete: (id: number) => void;
  allFeedbackForms: ApiConstructorForm[];
  formName?: string;
};
export const CustomMenuField: FC<TCustomMenuField> = ({
  formId,
  companyId,
  handleDelete,
  allFeedbackForms,
  formName
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    nodeNames,
    selectedNode: node,
    selectedCompany: company
  } = useAppSelector(state => state.company);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const [modalCopyVisible, setModalCopyVisible] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);
  const [copyError, setCopyError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitError, setSubmitError] = useState<string>('');
  const [initialValues, setInitialValues] = useState<ICopyForm>();
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<any>();
  const downloadQRCode = () => {
    const svgElement = document.getElementById('qrCode');
    if (svgElement instanceof SVGElement && (node || formName)) {
      const svgString = new XMLSerializer().serializeToString(svgElement);
      const encodedData = window.btoa(svgString);
      const base64svg = `data:image/svg+xml;base64,${encodedData}`.replace('image/svg+xml', 'image/octet-stream');
      const aEl = document.createElement('a');
      aEl.href = base64svg;
      aEl.download = `${(formName || node?.name)?.split(' ').join('_')}_QR_Code.svg`;
      aEl.click();
    } else {
      console.error('Unable to find the SVG element or node is not defined.');
    }
  };
  const downloadJSON = () => {
    exportApi.exportForm(formId).then(res => {
      const filename = `${res.data.name}.json`;
      const mimeType = 'application/json';
      download(JSON.stringify(res.data.features), filename, mimeType);
    });
  };
  const deleteForm = () => {
    formApi.deleteFeedbackForm(formId).then(res => {
      if (res.statusCode === 200) {
        handleDelete(formId);
        setModalDeleteVisible(false);
        setMenuVisible(false);
      } else {
        setDeleteError(true);
      }
    });
  };
  useEffect(() => {
    if (!nodeNames?.length) {
      formApi.getCompanyNodes(+companyId!).then(res => {
        const nodeNamesArray: {
          id: number;
          name: string;
          deep: number;
        }[] = [];
        getNodeNamesArray(nodeNamesArray, res.data || []);
        dispatch(setNodeNames(nodeNamesArray));
      });
    }
  }, []);
  useEffect(() => {
    if (nodeNames?.length && node) {
      setInitialValues({
        nodesList: getNodesDeepArray(nodeNames),
        nodeId: node.id!,
        newFormName: allFeedbackForms.find(form => form.id === formId)!.name
      });
    }
  }, [nodeNames, node]);
  useClickOutside(ref, () => {
    setMenuVisible(false);
  });
  function onSubmit(values: ICopyForm, {
    setSubmitting
  }: FormikHelpers<ICopyForm>) {
    setSubmitting(false);
    setIsLoading(true);
    try {
      formApi.copyForm({
        companyID: companyId,
        formID: formId,
        name: values.newFormName,
        nodeID: values.nodeId
      }).then(res => {
        if (res.data.id) {
          navigate(`/companies/${companyId}/forms/${res.data.id}`);
          setModalCopyVisible(false);
          setMenuVisible(false);
        } else {
          setCopyError(true);
        }
      });
    } catch (e) {
      setCopyError(true);
    } finally {
      setIsLoading(false);
    }
  }
  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange
  }: FormikProps<ICopyForm>) {
    return <Form>
        <div className="modalButtonsWrap">
          {nodeNames && values.nodeId && <CustomSelectTiedNode label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_node_to_copy')} name="nodeId" options={values.nodesList} selectKey="name" placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_form_placeholder')} value={nodeNames.find(node => node.id === values.nodeId)} handleSelect={node => setFieldValue('nodeId', node.id)} formGroupStyles={{
          width: '100%'
        }} search expandable />}

          <InputField label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_new_form_name_title')} name="newFormName" onChange={setFieldValue} onKeyUp={() => handleKeyUp('newFormName', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_new_form_name_placeholder')} value={values.newFormName} error={typeof errorMessage === 'object' ? getErrorMessage('newFormName', errorMessage) : undefined} extraBlockStyles={{
          width: '100%'
        }} required />

          <SubmitButton isLoading={isLoading} isError={touched && !!submitError}>
            {getTranslationByLangOrEng(interfaceLanguage, 'save_form_without_changes_button')}
          </SubmitButton>
          <div className="formErrorContainer">
            {submitError && <span>{submitError}</span>}
          </div>

          <button type="button" onClick={() => setModalCopyVisible(false)} className="declineButton">
            {getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
          </button>

          {copyError && <p className="modalError">
              {getTranslationByLangOrEng(interfaceLanguage, 'copy_error')}
            </p>}
        </div>
      </Form>;
  }
  return <div ref={ref} className="menu">
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button style={{
      width: '50px',
      display: 'flex',
      justifyContent: 'center'
    }} onClick={() => setMenuVisible(!menuVisible)} type="button">
        <ThreeDots />
      </button>
      {menuVisible && <ul className="menuList">
          <li>
            <button type="button" onClick={() => {
          navigate(`/companies/${companyId}/forms/${formId}`);
        }}>
              <PencilIcon width={20} height={20} />
              <span>
                {getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_edit_button')}
              </span>
            </button>
          </li>
          {/* <li>
            <button type="button" onClick={() => setModalCopyVisible(true)}>
              <CopyIcon width={20} height={20} />
              <span>
                {getTranslationByLangOrEng(interfaceLanguage, 'copy_button')}
              </span>
            </button>
           </li> */}
          <li>
            <button type="button" onClick={downloadJSON}>
              <DocumentIcon width={20} height={20} />
              <span>Викачати JSON</span>
            </button>
          </li>
          <li>
            <button type="button" onClick={() => setModalDeleteVisible(true)}>
              <DeleteIcon />
              <span>
                {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
              </span>
            </button>
          </li>
        </ul>}

      {modalDeleteVisible && <Modal onClose={() => setModalDeleteVisible(false)} extraStyles={{
      width: 300
    }} title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_delete_button')}>
          <div className="modalButtonsWrap">
            <p className="modalButtonsCaption">
              {getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_delete_confirm_text')}
            </p>

            <button type="button" onClick={() => setModalDeleteVisible(false)} className="declineButton">
              {getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
            </button>
            <button className="deleteButton" type="button" onClick={deleteForm}>
              {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
            </button>

            {deleteError && <p className="modalError">
                {getTranslationByLangOrEng(interfaceLanguage, 'delete_error')}
              </p>}
          </div>
        </Modal>}

      {modalCopyVisible && <Modal onClose={() => setModalCopyVisible(false)} extraStyles={{
      width: 300
    }} title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_copy_button')}>
          {!initialValues ? <Loader /> : <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={() => fieldsValidation(interfaceLanguage)}>
              {renderForm}
            </Formik>}
        </Modal>}
    </div>;
};