import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import * as React from 'react';
import { TIcon } from './TIcon';
const EmailCompanyIcon_1_1 = _compiledBlock(_props => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" width={_props.v0} height={_props.v1}>
      <path fill={_props.v2} d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
    </svg>, {
  name: "EmailCompanyIcon_1_1",
  svg: true
});
const EmailCompanyIcon_1 = ({
  width = 512,
  height = 512,
  color = '#000'
}) => /*@million jsx-skip*/<EmailCompanyIcon_1_1 v0={width} v1={height} v2={color} />;
export const EmailCompanyIcon: React.FC<TIcon> = React.memo(EmailCompanyIcon_1);