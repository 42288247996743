import styled from 'styled-components';

export const FilterSelectInputStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  .filterRow {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding-right: 15px;
    svg {
      margin-left: 0px;
    }
  }
  .filterRow:hover {
    background-color: #dbe2e7;
  }
  .entityTitle {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 8px;
    }

    svg {
      transition: all 0.3s ease-in-out;
      width: 16px;
      height: 16px;
    }
  }

  .filterTitle {
    font-weight: 400;
    margin-right: 10px;
  }

  .filtersOpener {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    min-width: 150px;
    align-items: center;
    padding: 4px 10px;
    /* border: 1px solid #e5e5e5; */
    &-active {
      svg {
        transition: all 0.3s ease-in-out;
        transform: rotate(180deg);
      }
    }
  }
`;
