import styled from 'styled-components';

export const DissatisfactionDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
  overflow: auto;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 1400px) {
    gap: 10px;
  }

  h2 {
    width: 80%;
    text-align: center;
    font-size: 24px;

    @media screen and (max-width: 1400px) {
      font-size: 18px;
    }
  }

  .rating {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box !important;

    &-col {
      box-sizing: border-box;
      padding: 10px 15px;
      font-size: 14px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1400px) {
        padding: 0;
        font-size: 12px;
      }
    }

    &-item {
      font-size: 14px;

      @media screen and (max-width: 1400px) {
        font-size: 12px;
      }
    }
  }

  .chart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0 50px;

    @media screen and (max-width: 1400px) {
      padding: 10px 0 20px;
    }

    &-legend {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 600px;

      @media screen and (max-width: 1400px) {
        gap: 5px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        @media screen and (max-width: 1400px) {
            font-size: 10px;
        }
      }

      div {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        flex-shrink: 0;
      }
    }
  }
`;
