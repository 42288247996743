import styled from 'styled-components';
export const NotifiableCompanyStyles = styled.div`
  width: 100%;

  .companyName {
    width: calc(100% - 30px);
    padding: 10px 15px;
    background: rgb(240, 245, 245);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .open {
      transform: rotate(-90deg);
      transition: all 0.3s;
    }
  }

  .sourceContainer {
    border-bottom: 1px solid rgb(240, 240, 240);
    border-left: 1px solid rgb(240, 240, 240);
    border-right: 1px solid rgb(240, 240, 240);
  }
`;