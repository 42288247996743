import { ApiResponse, sourcesApi } from '../../api';
import { ISource } from '../../entities/ISource';

async function manipulateData(
  actionType: 'create' | 'update' | 'delete',
  items: any[],
  companyId: number,
) {
  if (items.length > 0) {
    const promises: Promise<ApiResponse<ISource | null>>[] = [];

    items.forEach((item) => {
      let action;

      switch (actionType) {
        case 'create':
          action = sourcesApi.createSource({
            companyID: companyId,
            name: item.name,
          });
          break;
        case 'update':
          action = sourcesApi.updateSource(item.id, {
            companyID: companyId,
            name: item.name,
          });
          break;
        case 'delete':
          action = sourcesApi.deleteSource(item);
          break;
      }

      if (action) {
        promises.push(action);
      }
    });

    await Promise.all(promises);
  }
}

export async function updateSourcesList(
  companyId: number,
  initialSources: any[],
  items: any[],
) {
  const initialSourcesNames = initialSources.reduce(
    (acc: { [key: number]: string }, item) => {
      if (item.id) {
        acc[item.id] = item.name;
      }

      return acc;
    },
    {},
  );

  const initialSourcesIds = initialSources.map((item) => item.id!);
  const currentSourcesIds = items.map((item) => item.id);
  const sourcesToDelete: number[] = initialSourcesIds.filter(
    (x) => !currentSourcesIds.includes(x),
  );
  const sourcesToUpdate: any[] = items.filter((item) => (item.id ? item.name !== initialSourcesNames[item.id] : false));
  const sourcesToCreate: any[] = items.filter((item) => !item.id);

  await manipulateData('delete', sourcesToDelete, companyId);
  await manipulateData('update', sourcesToUpdate, companyId);
  await manipulateData('create', sourcesToCreate, companyId);
}
