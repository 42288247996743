import React from 'react';
import { ArrowDownIcon } from '../../../../assets';
import { hasNonEmptyChildren } from '../../../../utils';
import { CheckboxItem } from '../../../atoms';
import { MultiSelectItemStyles } from './MultiSelectItemStyles';
import { SelectItemProps } from './types';
import { DeepArrayItem } from '../../../../entities/DeepArrayItem';
export default function MultiSelectItem({
  path,
  option,
  selectKey,
  inputName,
  handleSelect,
  imageProperty,
  expandable,
  handleExpandRow,
  expandedRows,
  childrenKey,
  setOpen,
  selectable,
  checkboxSelected,
  checkboxHasSelectedChildren,
  checkboxAllChildrenSelected,
  showLastElement
}: SelectItemProps) {
  return <MultiSelectItemStyles
  // @ts-ignore
  key={`selectOption:${option.id}:${option[selectKey]}`} selectable={!!selectable}>
      <div className="row">
        <input autoComplete="off" type="radio" name={inputName}
      // @ts-ignore
      value={option[selectKey]}
      // @ts-ignore
      id={`${option[selectKey]}:${option.id}`} />
        <div className={imageProperty ? 'withImage' : ''}>
          <div className="labelContent" style={{
          paddingLeft: 16 + option.deep * 16,
          textAlign: option.name === '-' ? 'center' : 'left'
        }}>
            {selectable && <button onClick={() => {
            handleSelect(option, path);
          }} type="button" style={{
            marginRight: 8
          }}>
                <CheckboxItem selected={!!checkboxSelected} hasSelectedChildren={checkboxHasSelectedChildren} allChildrenAreSelected={checkboxAllChildrenSelected} handleOnClick={() => null} />
              </button>}
            {imageProperty && <button onClick={() => handleExpandRow && handleExpandRow(option.id)} type="button" className="emoji">
                {/* @ts-ignore */}
                {option[imageProperty]}
              </button>}

            <button style={{
            textAlign: 'left'
          }} onClick={() => handleExpandRow && handleExpandRow(option.id)} type="button">
              {/* @ts-ignore */}
              <p>{option[selectKey]}</p>
            </button>
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, max-len */}
        {expandable && option.children.length > 0 && <button style={{
        marginRight: '5px'
      }} className="expandButton" type="button" onClick={() => handleExpandRow && handleExpandRow(option.id)}>
            <ArrowDownIcon />
          </button>}
      </div>
      {/* .filter((node) => node.children.length) */}
      {expandable && expandedRows![option.id] && !!childrenKey && option.children.map((subItem: DeepArrayItem, index) => {
      if (!(subItem.children && subItem.children.length) && !showLastElement) {
        return null;
      }
      const visibleArrow = showLastElement || hasNonEmptyChildren(subItem.children);
      return <MultiSelectItem showLastElement={showLastElement} key={`itemKey:${[index]}`} path={[...path, index]} option={subItem} selectKey={selectKey} childrenKey={childrenKey} inputName={`${inputName}:${subItem.id}`} expandedRows={expandedRows} expandable={visibleArrow} handleExpandRow={handleExpandRow} imageProperty={imageProperty} handleSelect={handleSelect} setOpen={setOpen} selectable={selectable} checkboxSelected={selectable ? subItem.selected : undefined} checkboxHasSelectedChildren={selectable ? subItem.hasSelectedChildren : undefined} checkboxAllChildrenSelected={selectable ? subItem.allChildrenAreSelected : undefined} />;
    }).filter(el => el !== null)}
    </MultiSelectItemStyles>;
}