import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { FC } from 'react';
import { TIcon } from './TIcon';
const ChatIcon_1 = _compiledBlock(_props => <svg width={_props.v0} height={_props.v1} fill="none" viewBox="0 0 16 15">
    <path fill={_props.v2} fillRule="evenodd" d="M3.178 13.07v-2.473H1.766a.706.706 0 01-.707-.706V4.594c0-.39.317-.706.707-.706h9.181c.39 0 .707.316.707.706V9.89c0 .39-.316.706-.707.706H5.65L3.178 13.07zm2.911-1.413h4.858c.976 0 1.766-.79 1.766-1.766V4.594c0-.975-.79-1.766-1.766-1.766H1.766C.79 2.828 0 3.618 0 4.594V9.89c0 .975.79 1.766 1.766 1.766h.353v1.412a1.06 1.06 0 001.808.75l2.162-2.162z" clipRule="evenodd" />
    <path fill="#FF8C19" fillRule="evenodd" d="M2.825.53a.53.53 0 01.53-.53h9.181a2.649 2.649 0 012.649 2.649v2.825a.53.53 0 11-1.06 0V2.649a1.59 1.59 0 00-1.588-1.59H3.354a.53.53 0 01-.53-.53z" clipRule="evenodd" />
  </svg>, {
  name: "ChatIcon_1",
  svg: true
});
export const ChatIcon: FC<TIcon> = ({
  width = 512,
  height = 512,
  color = '#000'
}) => /*@million jsx-skip*/<ChatIcon_1 v0={width} v1={height} v2={color} />;