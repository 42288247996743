import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { TFieldConstruct } from '../../companyFields';
import { toast } from 'react-toastify';
import { ApiCompanyField, ApiCreatedField, ApiUpdatedField, FieldType, TAdditionalParams, fieldsApi } from '../../../../api';
import { addAdditionalParam } from '../utils';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Checkbox, InputField } from '../../../molecules';
import { SubmitButton } from '../../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import { EmailIcon, MessageIcon, MuteIcon, PhoneIcon, TelegramIcon, ViberIcon, WhatsAppIcon } from '../../../../assets';
import { FieldFilesStyles } from '../styles';
import SquareCheckbox from '../../../form/squareCheckbox/SquareCheckbox';
type TConnectionChannel = {
  field: TFieldConstruct;
  setFields: Dispatch<SetStateAction<ApiCompanyField[]>>;
  setField: Dispatch<SetStateAction<TFieldConstruct | null>>;
};
type TChannel = 'email' | 'sms' | 'call' | 'telegram' | 'viber' | 'whatsapp' | 'without answer';
export interface IConnectionChannelField {
  question: string;
  required: boolean;
  options: {
    key: string;
    selected: boolean;
  }[];
}
const initialValue: IConnectionChannelField = {
  question: '',
  required: false,
  options: [{
    key: 'email',
    selected: false
  }, {
    key: 'sms',
    selected: false
  }, {
    key: 'call',
    selected: false
  }, {
    key: 'telegram',
    selected: false
  }, {
    key: 'viber',
    selected: false
  }, {
    key: 'whatsapp',
    selected: false
  }, {
    key: 'without answer',
    selected: false
  }]
};
export const validationSchema = Yup.object({
  question: Yup.string().required('* Required')
});
export const ConnectionChannel: FC<TConnectionChannel> = ({
  field,
  setFields,
  setField
}) => {
  const {
    id
  } = useParams();
  const [initialValues, setInitialValues] = useState<IConnectionChannelField>();
  const [apiField, setApiField] = useState<ApiCompanyField | null>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  function getChannelTypeImage(type: TChannel) {
    const types = {
      email: <EmailIcon color="#000" />,
      sms: <MessageIcon color="#000" />,
      call: <PhoneIcon color="#000" />,
      telegram: <TelegramIcon color="#000" />,
      viber: <ViberIcon color="#000" />,
      whatsapp: <WhatsAppIcon color="#000" />,
      'without answer': <MuteIcon color="#8D8D8D" />
    };
    return types[type];
  }
  function getChannelTypeName(type: TChannel) {
    const types = {
      email: 'Email',
      sms: 'SMS',
      call: 'Дзвінок',
      telegram: 'Telegram',
      viber: 'Viber',
      whatsapp: 'WhatsApp',
      'without answer': 'Без відповіді'
    };
    return types[type];
  }
  const onSubmit = async (values: IConnectionChannelField, {
    setSubmitting
  }: FormikHelpers<IConnectionChannelField>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    const styles: TAdditionalParams[] = [];
    if (id) {
      if (apiField) {
        options = [...apiField.options];
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.options.filter(el => el.selected).map(e => e.key).join('|||'), 'options', options);
        const data: ApiUpdatedField<FieldType.ConnectionChannel> = {
          question: values.question,
          options,
          styles
        };
        fieldsApi.updateFormField(apiField.id!, data).then(res => {
          if (res.statusCode === 200) {
            setFields(prev => prev.map(el => {
              if (el.id === apiField.id) {
                return {
                  ...el,
                  ...data
                };
              }
              return el;
            }));
            toast.success('Поле успішно оновлено');
          }
        });
      } else {
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.options.filter(el => el.selected).map(e => e.key).join('|||'), 'options', options);
        const data: ApiCreatedField = {
          field: {
            [FieldType.ConnectionChannel]: {
              question: values.question,
              options,
              styles
            }
          }
        };
        fieldsApi.createCompanyFields(+id, data).then(res => {
          if (res.statusCode === 201) {
            setFields(prev => [res.data, ...prev]);
            setField({
              id: res.data.id,
              type: field.type
            });
            toast.success('Поле успішно додано');
          }
        });
      }
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<IConnectionChannelField>) => <Form>
      <InputField name="question" onChange={setFieldValue} onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)} placeholder="Введіть запитання для поля" value={values.question} error={typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined} />
      <div className="files">
        <h4>Виберіть які канали зв`язку будуть доступні на сторінці форми</h4>
        {values.options.map((field: {
        key: string;
        selected: boolean;
      }, index) => <div className="files-container" key={field.key}>
            <SquareCheckbox name={`options[${index}].selected`} value={values.options![index].selected!} onChange={handleChange} checkboxWrapperStyles={{
          alignItems: 'center'
        }}>
              <div className="files-checkbox">
                <div className="files-icon">
                  {getChannelTypeImage(field.key as TChannel)}
                </div>
                <span>{getChannelTypeName(field.key as TChannel)}</span>
              </div>
            </SquareCheckbox>
          </div>)}
      </div>

      <Checkbox name="required" value={values.required!} onChange={handleChange}>
        <span className="checkboxValue">
          Обовʼязкове поле
        </span>
      </Checkbox>

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '15px 45px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    if (field.id) {
      fieldsApi.getTargetField(field.id).then(res => {
        const required = res.data.options.find(option => option.key === 'required')?.value === 'true';
        const options = res.data.options.find(option => option.key === 'options')?.value.split('|||') || [];
        setApiField(res.data);
        setInitialValues({
          question: res.data.question,
          required,
          options: initialValue.options.map(el => ({
            ...el,
            selected: options.includes(el.key)
          }))
        });
      });
    } else {
      setInitialValues(initialValue);
    }
  }, [field]);
  return <FieldFilesStyles>
      <div className="field-body">
        {initialValues && <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {renderForm}
          </Formik>}
      </div>
    </FieldFilesStyles>;
};