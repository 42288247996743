import styled from 'styled-components';

export const ErrorPageStyles = styled.div`
  min-height: calc(100vh - 104px);
  display: flex;
  flex-direction: column;

  .headContainer {
    box-sizing: border-box;
    padding: 33px 48px;

    @media (max-width: 781px) {
      padding: 33px 24px;
    }

    .logoContainer {
      width: 160px;
      cursor: pointer;
    }
  }

  .contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 0 48px;
    box-sizing: border-box;

    @media (max-width: 781px) {
      padding: 0 24px;
    }

    .errorContainer {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: 25px;

      max-width: 944px;
      width: 100%;

      @media (max-width: 781px) {
        grid-template-columns: auto;
        place-items: center;
      }

      .errorImageContainer {
        img {
          @media (max-width: 781px) {
            width: 100%;
          }
        }
      }

      .errorTextContainer {
        display: flex;
        flex-direction: column;
        font-family: "Poppins", sans-serif;

        span:nth-child(1) {
          font-weight: 300;
          font-size: 108px;
          line-height: 123.6%;
          color: #9bb1af;

          @media (max-width: 406px) {
            font-size: 78px;
          }
        }

        span:nth-child(2) {
          margin-bottom: 7px;
          font-weight: 500;
          font-size: 34px;
          line-height: 123.6%;
          color: #000000;

          @media (max-width: 406px) {
            font-size: 24px;
          }
        }

        span:nth-child(3) {
          font-weight: 400;
          font-size: 20px;
          line-height: 123.6%;
          color: #b6b6b6;

          @media (max-width: 406px) {
            font-size: 12px;
          }
        }

        @media (max-width: 781px) {
          text-align: center;
        }
      }
    }
  }
`;
