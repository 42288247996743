import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { NodeBranchStyles } from './styles';
import { ApiCompanyNode, nodesApi } from '../../../api';
import { Node } from '../node/Node';
type TNodeBranch = {
  node: ApiCompanyNode;
  setSelectedNode: Dispatch<SetStateAction<string>>;
  setEditModal: Dispatch<SetStateAction<boolean>>;
  isLast: boolean;
  signal: AbortSignal;
};
export const NodeBranch: FC<TNodeBranch> = ({
  setSelectedNode,
  setEditModal,
  isLast,
  node,
  signal
}) => {
  const [chain, setChain] = useState<ApiCompanyNode[]>([]);
  useEffect(() => {
    nodesApi.getChainNodes(node.id, signal).then(res => {
      setChain([...res.data.reverse().slice(0, res.data.length - 1), node]);
    });
  }, [node]);
  return <NodeBranchStyles>
      {chain.map((el, index, array) => <Node key={el.id} isLast={isLast} node={el} deep={index} setSelectedNode={setSelectedNode} setEditModal={setEditModal} setRootNodes={setChain} searchExpanded isSearchLast={index === array.length - 1} />)}
    </NodeBranchStyles>;
};