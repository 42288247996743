import styled from 'styled-components';

export const HeaderStyles = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #E7EEF3;

  @media screen and (max-width: 768px) {
    padding: 8px 10px;
  }

  &::after {
    position: absolute;
    content: "";
    height: 2px;
    width: calc(100% - 40px);
    bottom: 0;
    background: #000;
  }

  .logo {
    display: flex;
    align-items: center;
    gap: 2px;

    @media screen and (max-width: 768px) {
      gap: 5px;

      svg {
        width: 32px;
      }
    }
  }

  .leftPart,
  .leftPart > button {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 768px) {
      gap: 10px;
    }
  }

  .user {
    position: relative;

    &-select {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      span {
        font-size: 13px;
        font-weight: 400;
        text-decoration: underline;
        line-height: 120%;

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }

      &-arrow {
        position: relative;
        transition: all 0.3s ease-in-out;

        &.active {
          transform: rotate(-180deg);
        }
      }
    }

    &-contrast {
      margin-left: 15px;
      cursor: pointer;
    }
    
    &-interface {
      margin-left: 10px;
      cursor: pointer;
    }

    &-list {
      position: absolute;
      left: 0%;
      transform: translate(-50%);
      top: 105%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-radius: 4px;
      z-index: 5;
    }

    &-link {
      background: #fff;
      cursor: pointer;
      color: rgb(0, 0, 0);
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.02em;
      text-decoration: none;
      padding: 8px 16px;
      box-sizing: border-box;
      white-space: nowrap;

      &:hover {
        background-color: #ccc;
      }
    }
  }
`;
