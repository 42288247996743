import styled from 'styled-components';
export const FormLocalizationStyles = styled.div`
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 30%;

    .modalContent {
      width: 100%;
    }

    .languages {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px 5px;

      &-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 3px 10px;
        border-radius: 25px;
        border: 1px solid #000;
        cursor: pointer;

        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
  }
`;