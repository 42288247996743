/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import { ApiCategory, ApiPostCategory, ApiResponse } from '../entities';
import ICategoryApi from './ICategoryApi';

class CategoryApi implements ICategoryApi {
  public async getCategoryById(id: number): Promise<ApiResponse<ApiCategory>> {
    return baseApi.getData(`/categories/${id}`);
  }

  public async delCategoryById(
    id: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return baseApi.deleteData(`/categories/${id}`);
  }

  public async changeCategoryById(
    id: number,
    data: ApiPostCategory,
  ): Promise<ApiResponse<ApiCategory>> {
    return baseApi.putData(`/categories/${id}`, data);
  }

  public async getCategoriesByCompany(
    id: number,
    parentID?: number,
  ): Promise<ApiResponse<ApiCategory[]>> {
    return baseApi.getData(
      `/companies/${id}/categories${parentID ? `?parentID=${parentID}` : ''}`,
    );
  }

  public async getFlatCategoriesByCompany(
    id: number,
  ): Promise<ApiResponse<ApiCategory[]>> {
    return baseApi.getData(`/companies/${id}/categories/flat`);
  }

  public async getChainCategories(
    id: number,
  ): Promise<ApiResponse<ApiCategory[]>> {
    return baseApi.getData(`/categories/${id}/chain`);
  }

  public async createCategory(
    id: number,
    data: ApiPostCategory,
  ): Promise<ApiResponse<ApiCategory>> {
    return baseApi.postData(`/companies/${id}/categories`, data);
  }

  public async addCategoryToReview(
    id: number,
    categoryID: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return baseApi.postData(`/reviews/${id}/categories`, {
      categoryIDs: [categoryID],
    });
  }

  public async delCategoryFromReview(
    id: number,
    categoryID: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return baseApi.deleteData(`/reviews/${id}/categories/${categoryID}`);
  }
}

export const categoryApi = new CategoryApi();
