import styled from 'styled-components';

export const DropdownContainer = styled.div`
  .container {
    display: flex;
    position: relative;
    width: 100%;
    height: 35px;
    padding-bottom: 2px;
    line-height: 27.2px;
    border-bottom: 2px solid #000;
    &.opened {
      border-bottom: 2px solid transparent;
    }
    transition: 0.5s;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 10px; */
    /* background-color: blue; */
    .placeholder {
      /* background-color: green; */
      position: absolute;
      left: 0;
      color: #8c8e91;
      transition: 0.3s;
      &.selected {
        top: 0px;
        font-size: 12px;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
      }
    }

    .icon {
      width: 10px;
      height: 5px;
      transition: 0.5s;
      -webkit-transform-origin: center;
      transform-origin: center;
      position: absolute;
      /* top: -10px; */
      right: 0;
      color: #8c8e91;
      transition: 0.3s;
      &.opened {
        transform: rotate(3.141292rad);
      }
    }
  }
  .dropdown {
  }

  .value {
    width: 100%;
    p {
      white-space: nowrap;
      overflow: hidden;
      height: 35px;
      width: 95%;
      text-overflow: ellipsis;
    }
  }

  .dropdown-menu {
    /* opacity: 0.1; */
    display: none;
    position: absolute;
    /* top: 34px; */
    left: 0;
    z-index: 10;
    width: 100%;
    max-height: 230px;
    padding: 16px 20px;
    overflow-y: scroll;
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
    transition: 0.5s;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    flex-direction: column;
    &.fade-in-out {
      display: flex;
      opacity: 0;
      &.opened {
        opacity: 1;
      }
    }
    .dropdown-menu-item {
      margin-bottom: 9px;
    }
  }
`;
