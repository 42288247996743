import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../state';
import * as Yup from 'yup';
import { Modal } from '../Modal';
import { CopyPermissionsModalStyles } from './CopyPermissionsModalStyles';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { getTranslationByLangOrEng } from '../../../../i18n';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import { permissionApi } from '../../../../api';
import { IUserPermission } from '../../../../entities';
import { InputField } from '../../../molecules';
type TCopyPermissionsModal = {
  onClose: () => void;
  permissionsId: number;
};
export const CopyPermissionsModal: FC<TCopyPermissionsModal> = React.memo(({
  onClose,
  permissionsId
}) => {
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [targetPermissions, setTargetPermissions] = useState<IUserPermission>();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const fieldsValidation = (lang: string) => Yup.object({
    name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')).notOneOf([targetPermissions?.name], getTranslationByLangOrEng(lang, 'enter_uniq_name'))
  });
  function onSubmit(values: {
    name: string;
  }, {
    setSubmitting
  }: FormikHelpers<{
    name: string;
  }>) {
    setSubmitting(false);
    if (targetPermissions) {
      const data: IUserPermission = {
        name: values.name,
        resource: targetPermissions?.resource,
        permissions: targetPermissions.permissions
      };
      permissionApi.createPermission(data).then(res => {
        onClose();
      });
    }
  }
  function renderForm({
    values,
    setFieldValue
  }: FormikProps<{
    name: string;
  }>) {
    return <Form>
          <div className="fieldWrap">
            <InputField label={getTranslationByLangOrEng(interfaceLanguage, 'permissions_name')} name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'enter_permissions_name')} value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} extraBlockStyles={{
          width: '100%'
        }} required />
          </div>
          <div className="modalButtonsWrap">
            <SubmitButton>
              {getTranslationByLangOrEng(interfaceLanguage, 'save_button')}
            </SubmitButton>
          </div>
        </Form>;
  }
  useEffect(() => {
    permissionApi.getPermission(+permissionsId).then(res => {
      setTargetPermissions(res.data);
    });
  }, []);
  return <CopyPermissionsModalStyles>
        <Modal title={getTranslationByLangOrEng(interfaceLanguage, 'copy_permissions')} onClose={onClose}>
          <Formik initialValues={{
        name: ''
      }} onSubmit={onSubmit} validationSchema={() => fieldsValidation(interfaceLanguage)}>
            {renderForm}
          </Formik>
        </Modal>
      </CopyPermissionsModalStyles>;
});