import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const LogoFillIcon: FC<TIcon> = React.memo(({
  width = 49,
  height = 48
}) => <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M24.0079 48C10.5647 48 6.943 47.3199 3.81153 44.1885C0.664251 41.0412 0 37.4353 0 24.0079C0 10.5647 0.680066 6.95881 3.81153 3.81153C6.943 0.680066 10.5647 0 24.0079 0C37.4511 0 41.0728 0.680066 44.2043 3.81153C47.3516 6.95881 48.0158 10.5647 48.0158 24.0079C48.0158 37.4511 47.3516 41.0412 44.2043 44.2043C41.057 47.3199 37.4353 48 24.0079 48Z" fill="black" />
        <path d="M33.4181 23.9762C32.5008 23.9762 31.8998 23.3752 31.8998 22.4738C31.8998 21.6039 32.5008 21.0029 33.4181 21.0029H35.8062V23.9762H33.4181ZM37.8148 19.279H33.1809C31.2198 19.279 29.8754 20.5601 29.8754 22.4105C29.8754 23.8655 30.7137 24.9726 32.0422 25.368L29.5117 29.2112H31.7417L34.0191 25.7159H35.8062V29.2112H37.8148V19.279ZM12.7631 21.8728V18.8836H16.6537V27.3766H11.7667C12.2886 26.4751 12.7631 24.3558 12.7631 21.8728ZM18.7097 16.9542H10.7703V21.9518C10.7703 24.4981 10.3275 26.4909 9.72652 27.3766H8.77759V31.9156H10.7387V29.227H18.172V31.9156H20.1331V27.3766H18.7097V16.9542ZM24.7038 27.5189L24.5298 27.4082L26.8389 19.279H22.1575L21.6514 21.082L24.3717 21.0662L22.6003 27.2659C22.284 28.3888 23.0432 29.4484 24.2926 29.4484C24.688 29.4484 25.0675 29.3535 25.4946 29.0846L28.6893 26.9179L27.7245 25.4787L24.7038 27.5189ZM26.0639 17.4128C27.0603 17.4128 27.4715 17.049 27.4715 16.1634C27.4715 15.2461 27.0445 14.8823 26.0639 14.8823C25.0992 14.8823 24.6563 15.2461 24.6563 16.1634C24.6563 17.0332 25.0675 17.4128 26.0639 17.4128Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>);