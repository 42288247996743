import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { TFieldConstruct } from '../../companyFields';
import { ApiCompanyField, ApiCreatedField, ApiUpdatedField, FieldType, TAdditionalParams, companyApi, fieldsApi } from '../../../../api';
import { IFieldColor, addAdditionalParam, mapApiColorToFieldColor, mapFieldColorToApiColor } from '../utils';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Checkbox, InputField, Quantity } from '../../../molecules';
import { SubmitButton } from '../../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import CustomColorsSelect from '../../../form/customSelect/CustomColorsSelect';
import { FieldQuantityStyles } from '../styles';
const defaultBtnBgColor: IFieldColor = {
  name: 'Bg color',
  color: '#000'
};
const defaultBtnSignColor: IFieldColor = {
  name: 'Signature color',
  color: '#fff'
};
type TQuantity = {
  field: TFieldConstruct;
  setFields: Dispatch<SetStateAction<ApiCompanyField[]>>;
  setField: Dispatch<SetStateAction<TFieldConstruct | null>>;
};
export interface IQuantityField {
  question: string;
  required: boolean;
  isSentiment: boolean;
  key_question: boolean;
  default_quantity_value: number;
  min: number;
  max: number;
  quantity_buttons_background_color: IFieldColor;
  quantity_buttons_signs_color: IFieldColor;
}
const initialValue: IQuantityField = {
  question: '',
  required: false,
  isSentiment: false,
  key_question: false,
  max: 0,
  min: 0,
  default_quantity_value: 0,
  quantity_buttons_background_color: defaultBtnBgColor,
  quantity_buttons_signs_color: defaultBtnSignColor
};
export const validationSchema = Yup.object({
  question: Yup.string().required('* Required')
});
export const QuantityField: FC<TQuantity> = ({
  field,
  setFields,
  setField
}) => {
  const {
    id
  } = useParams();
  const [initialValues, setInitialValues] = useState<IQuantityField>();
  const [apiField, setApiField] = useState<ApiCompanyField | null>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [companyColors, setCompanyColors] = useState<IFieldColor[]>([]);
  async function getCompanyMetadata() {
    const res = await companyApi.getCompanyMetadata(+id!);
    if (res.statusCode >= 200 && res.statusCode < 300) {
      setCompanyColors(res.data.filter(item => item.key.startsWith('color_')).map(item => {
        const colorParts = item.value.split('|||');
        return {
          name: colorParts[0],
          color: colorParts[1]
        };
      }));
    }
  }
  const onSubmit = async (values: IQuantityField, {
    setSubmitting
  }: FormikHelpers<IQuantityField>) => {
    setSubmitting(false);
    let options: TAdditionalParams[] = [];
    const styles: TAdditionalParams[] = [];
    if (id) {
      if (apiField) {
        options = [...apiField.options];
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.isSentiment, 'isSentiment', options);
        addAdditionalParam(values.key_question, 'key_question', options);
        addAdditionalParam(values.min, 'min', options);
        addAdditionalParam(values.max, 'max', options);
        addAdditionalParam(values.default_quantity_value, 'default_quantity_value', options);
        addAdditionalParam(mapFieldColorToApiColor(values.quantity_buttons_background_color), 'quantity_buttons_background_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.quantity_buttons_signs_color), 'quantity_buttons_signs_color', styles);
        const data: ApiUpdatedField<FieldType.Quantity> = {
          min: +values.min,
          max: +values.max,
          question: values.question,
          options,
          styles
        };
        fieldsApi.updateFormField(apiField.id!, data).then(res => {
          if (res.statusCode === 200) {
            setFields(prev => prev.map(el => {
              if (el.id === apiField.id) {
                return {
                  ...el,
                  ...data
                };
              }
              return el;
            }));
            toast.success('Поле успішно оновлено');
          }
        });
      } else {
        addAdditionalParam(values.required, 'required', options);
        addAdditionalParam(values.isSentiment, 'isSentiment', options);
        addAdditionalParam(values.key_question, 'key_question', options);
        addAdditionalParam(values.min, 'min', options);
        addAdditionalParam(values.max, 'max', options);
        addAdditionalParam(values.default_quantity_value, 'default_quantity_value', options);
        addAdditionalParam(mapFieldColorToApiColor(values.quantity_buttons_background_color), 'quantity_buttons_background_color', styles);
        addAdditionalParam(mapFieldColorToApiColor(values.quantity_buttons_signs_color), 'quantity_buttons_signs_color', styles);
        const data: ApiCreatedField = {
          field: {
            [FieldType.Quantity]: {
              min: +values.min,
              max: +values.max,
              question: values.question,
              options,
              styles
            }
          }
        };
        fieldsApi.createCompanyFields(+id, data).then(res => {
          if (res.statusCode === 201) {
            setFields(prev => [res.data, ...prev]);
            setField({
              id: res.data.id,
              type: field.type
            });
            toast.success('Поле успішно додано');
          }
        });
      }
    }
  };
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<IQuantityField>) => <Form>
      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="question" onChange={setFieldValue} onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)} placeholder="Введіть запитання для поля" value={values.question} error={typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined} />

      <div className="quantity">
        <Quantity defaultValue={values.default_quantity_value || 0} minValue={values.min || 0} maxValue={values.max} step={1} buttonsBackgroundColor={values.quantity_buttons_background_color} buttonsSignsColor={values.quantity_buttons_signs_color} />
      </div>

      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="default_quantity_value" type="number" min={0} max={values.max} onChange={setFieldValue} onKeyUp={() => handleKeyUp('default_quantity_value', setErrorMessage, errorMessage)} placeholder="Значення за замовчуванням" value={values.default_quantity_value} error={typeof errorMessage === 'object' ? getErrorMessage('default_quantity_value', errorMessage) : undefined} />
      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="min" type="number" min={0} max={values.max} onChange={setFieldValue} onKeyUp={() => handleKeyUp('min', setErrorMessage, errorMessage)} placeholder="Мінімальне значення" value={values.min} error={typeof errorMessage === 'object' ? getErrorMessage('min', errorMessage) : undefined} />
      <InputField extraBlockStyles={{
      marginBottom: '25px'
    }} name="max" type="number" min={values.min} max={9999} onChange={setFieldValue} onKeyUp={() => handleKeyUp('max', setErrorMessage, errorMessage)} placeholder="Максимальне значення" value={values.max} error={typeof errorMessage === 'object' ? getErrorMessage('max', errorMessage) : undefined} />
      <div className="color-wrapper">
        <h6>Колір фону кнопок</h6>

        <div className="color-selector">
          <CustomColorsSelect name="quantity_buttons_background_color" placeholder="Виберіть колір" options={companyColors} value={values.quantity_buttons_background_color} handleSelect={color => {
          setFieldValue('quantity_buttons_background_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('quantity_buttons_background_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <div className="color-wrapper">
        <h6>Колір підписів кнопок</h6>

        <div className="color-selector">
          <CustomColorsSelect name="quantity_buttons_signs_color" placeholder="Виберіть колір" options={companyColors} value={values.quantity_buttons_signs_color} handleSelect={color => {
          setFieldValue('quantity_buttons_signs_color', {
            color: color.color,
            name: color.name
          });
        }} extraComponentPosition="left" extraStyles={{
          flexGrow: 1
        }} handleAddColor={color => {
          setFieldValue('quantity_buttons_signs_color', {
            color: color.color,
            name: color.name
          });
        }} />
        </div>
      </div>

      <Checkbox name="required" value={values.required!} onChange={handleChange}>
        <span className="checkboxValue">
          Обовʼязкове поле
        </span>
      </Checkbox>

      <Checkbox name="isSentiment" value={values.isSentiment!} onChange={handleChange}>
        <span className="checkboxValue">
          Застосувати сентимент аналіз до цього поля
        </span>
      </Checkbox>

      <Checkbox name="key_question" value={values.key_question!} onChange={handleChange}>
        <span className="checkboxValue">
          Ключове питання
        </span>
      </Checkbox>

      <SubmitButton extraBlockStyles={{
      marginTop: '20px'
    }} extraButtonStyles={{
      padding: '15px 45px'
    }}>
        Зберегти
      </SubmitButton>
    </Form>;
  useEffect(() => {
    getCompanyMetadata();
    if (field.id) {
      fieldsApi.getTargetField(field.id).then(res => {
        const required = res.data.options.find(option => option.key === 'required')?.value === 'true';
        const isSentiment = res.data.options.find(option => option.key === 'isSentiment')?.value === 'true';
        const key_question = res.data.options.find(option => option.key === 'key_question')?.value === 'true';
        const default_quantity_value = res.data.options.find(option => option.key === 'default_quantity_value')?.value || 0;
        const max = res.data.options.find(option => option.key === 'max')?.value || 0;
        const min = res.data.options.find(option => option.key === 'min')?.value || 0;
        const quantity_buttons_background_color = res.data.styles.find(option => option.key === 'quantity_buttons_background_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'quantity_buttons_background_color')?.value!) : defaultBtnBgColor;
        const quantity_buttons_signs_color = res.data.styles.find(option => option.key === 'quantity_buttons_signs_color')?.value ? mapApiColorToFieldColor(res.data.styles.find(option => option.key === 'quantity_buttons_signs_color')?.value!) : defaultBtnSignColor;
        setApiField(res.data);
        setInitialValues({
          question: res.data.question,
          isSentiment,
          required,
          key_question,
          default_quantity_value: +default_quantity_value,
          max: +max,
          min: +min,
          quantity_buttons_background_color,
          quantity_buttons_signs_color
        });
      });
    } else {
      setInitialValues(initialValue);
    }
  }, [field]);
  return <FieldQuantityStyles>
      <div className="field-body">
        {initialValues && <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {renderForm}
          </Formik>}
      </div>
    </FieldQuantityStyles>;
};