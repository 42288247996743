import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotifySettingsStyles } from './NotifySettingsStyles';
import { TransparentButton } from '../../../atoms';
import { useAppSelector } from '../../../../state';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { companySettingsApi } from '../../../../api';
import { useClickOutside } from '../../../../hooks';
import { ArrowDownIcon, CheckMark } from '../../../../assets';
import { weeklyDays } from '../../../../constants';
type TNotifySettings = {
  apiStart: string | undefined;
  apiEnd: string | undefined;
  apiDays: number[] | undefined;
};
export const NotifySettings: FC<TNotifySettings> = React.memo(({
  apiStart,
  apiEnd,
  apiDays
}) => {
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [start, setStart] = useState<string>('08:00');
  const [end, setEnd] = useState<string>('21:00');
  const [notifyDays, setNotifyDays] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const handleSave = useCallback(() => {
    const requests = [];
    if (id) {
      requests.push(companySettingsApi.updateCompanySettingsKey(+id, 'notify-time-begin', {
        value: `${start}:00`
      }));
      requests.push(companySettingsApi.updateCompanySettingsKey(+id, 'notify-time-end', {
        value: `${end}:00`
      }));
      requests.push(companySettingsApi.updateCompanySettingsKey(+id, 'notify-days', {
        value: notifyDays
      }));
      Promise.all(requests).then(res => {
        console.log(res);
      });
    }
  }, [start, end, notifyDays]);
  const handleSelect = useCallback((index: number) => {
    setNotifyDays(prev => {
      const numberExists = prev.includes(index);
      if (numberExists) {
        return prev.filter(num => num !== index);
      }
      return [...prev, index];
    });
  }, [notifyDays]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  useEffect(() => {
    setStart(apiStart?.split(':').slice(0, -1).join(':') || '08:00');
    setEnd(apiEnd?.split(':').slice(0, -1).join(':') || '21:00');
    setNotifyDays(apiDays || []);
  }, [apiEnd, apiStart, apiDays]);
  return <NotifySettingsStyles>
        <h3>Відправка сповіщень</h3>

        <div className="notifyWrapper">
          <input type="time" value={start} onChange={e => setStart(e.target.value)} />
          <input type="time" value={end} onChange={e => setEnd(e.target.value)} />
          <div className="selectField">
            <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
              <span className="placeholder">Оберіть день тижня</span>
              <div className={open ? 'arrowDownActive' : ''}>
                <ArrowDownIcon />
              </div>
            </div>

            {open && <div className="options" ref={optionsListRef}>
                {weeklyDays.map((value, index) => <div key={value} className="option">
                    <div className="rowSel">
                      <button type="button" onClick={() => handleSelect(+index)}>
                        <div className="labelContent">
                          <div className="default">
                            {notifyDays.includes(+index) && <CheckMark />}
                          </div>
                          <span>
                            {getTranslationByLangOrEng(interfaceLanguage, value)}
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>)}
              </div>}
          </div>
        </div>

        <TransparentButton filled text={getTranslationByLangOrEng(interfaceLanguage, 'save_button')} handleClick={handleSave} />
        <div className="horizontalLine" />
      </NotifySettingsStyles>;
});