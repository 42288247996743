import React, { CSSProperties, ReactNode } from 'react';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import { Modal } from '../Modal';
import { ModalConfirmDeclineStyles } from './ModalConfirmDeclineStyles';
type Props = {
  extraStyles?: CSSProperties;
  onDecline?: (event: any) => void;
  onConfirm?: (event: any) => void;
  onClose: () => void;
  title?: string;
  buttonsLabel?: string;
  confirmText?: string;
  declineText?: string;
  firstButtonStyles?: CSSProperties;
  secondButtonStyles?: CSSProperties;
  error?: boolean;
  errorText?: string;
  children?: any;
  isConfirmButtonShown?: boolean;
  buttonsWrapperStyles?: CSSProperties;
  confirmButton?: (text: string, handleClick?: (event: any) => void) => ReactNode;
  declineButton?: (text: string, handleClick?: (event: any) => void) => ReactNode;
};
export const ModalConfirmDecline = React.memo(({
  onDecline,
  extraStyles,
  onConfirm,
  onClose,
  title,
  confirmText,
  declineText,
  buttonsLabel,
  error,
  errorText,
  firstButtonStyles,
  secondButtonStyles,
  children,
  isConfirmButtonShown = true,
  buttonsWrapperStyles,
  confirmButton,
  declineButton
}: Props) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <ModalConfirmDeclineStyles>
        <Modal title={title} onClose={onClose} extraStyles={extraStyles}>
          {children}

          <div className="modalButtonsWrap">
            {buttonsLabel && <p className="modalButtonsCaption">{buttonsLabel}</p>}

            <div className={`${isConfirmButtonShown ? 'modalButtonsInnerWrap' : 'modalButtonsInnerWrap divNoGap'}`} style={buttonsWrapperStyles || {}}>
              {declineButton ? declineButton(declineText || getTranslationByLangOrEng(interfaceLanguage, 'close_editing_button'), onDecline) : <button style={firstButtonStyles} type="button" className="declineButton" onClick={onDecline}>
                  {declineText || getTranslationByLangOrEng(interfaceLanguage, 'close_editing_button')}
                </button>}

              {isConfirmButtonShown && onConfirm && (confirmButton ? confirmButton(confirmText || getTranslationByLangOrEng(interfaceLanguage, 'continue_editing_button'), onConfirm) : <button style={secondButtonStyles} type="button" className="confirmButton" onClick={event => onConfirm(event)}>
                    {confirmText || getTranslationByLangOrEng(interfaceLanguage, 'continue_editing_button')}
                  </button>)}
            </div>
            {error && <p className="modalError">
                {errorText || getTranslationByLangOrEng(interfaceLanguage, 'error')}
              </p>}
          </div>
        </Modal>
      </ModalConfirmDeclineStyles>;
});