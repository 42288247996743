import styled from 'styled-components';

export const StructureStyles = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex: 1;
  position: relative;
  overflow: auto;

  .searchWrapper {
    background-color: #e7eef3;
    border: 4px solid white;
    border-radius: 5px;
    input {
      background-color: transparent;
    }
  }
`;
