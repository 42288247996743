import styled from 'styled-components';

export const FieldPickerStyles = styled.div`
   display: flex;
   flex-direction: column;
   max-width: 250px;
   background: rgb(231, 238, 243);
   width: 100%;
   height: 100%;
   flex-shrink: 0;

   .infinite-scroll-component__outerdiv {
      height: 100%;
      width: 100%;
   }

   .search {
      flex-shrink: 0;
      height: 50px;
      padding: 0px 8px;
      border-bottom: 1px solid rgb(229, 229, 229);
      background-color: rgb(252, 252, 252);

      input {
         width: 100%;
         background: none;
         height: 100%;
         outline: none;
         border: none;
      }
   }

   .fields {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      position: relative;
      overflow: auto;

      &-list {
         padding: 12px;
         box-sizing: border-box;
         display: flex;
         flex-direction: column;
         position: relative;
         gap: 8px;
      }
   }
`;
