import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ApiSmsHistoryItem, massMailingApi } from '../../../api';
import { CampaignStyles, StatusesCounterStyles } from './CampaignStyles';
import { format, isWithinInterval, parse, parseISO } from 'date-fns';
import { ArrowDownIcon, DeleteIcon, SolvedIcon } from '../../../assets';
import { NameCustomField, TransparentButton } from '../../atoms';
import { Table } from '../table';
import { downloadSheet, extraFieldsSettings, options } from './utils';
import { useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ChartCampaignsSms, TCampaign } from './TCampaign';
import { FilterSelectInput } from '../../molecules';
import { Modal } from '../modal';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const coloredStats = {
  itemsTotal: '#acacac',
  itemsDelivered: '#60a518',
  formOpened: '#be65e1',
  itemsFailed: '#fc6b5a',
  formSubmitted: '#3ea1ee'
};
const keysToInclude: Array<keyof ChartCampaignsSms> = ['itemsTotal',
// 'itemsDelivered',
'formOpened', 'formSubmitted'
// 'itemsFailed',
];
const config = {
  itemsTotal: {
    color: '#acacac',
    label: 'Всього'
  },
  itemsDelivered: {
    color: '#60a518',
    label: 'Успішно'
  },
  formOpened: {
    color: '#be65e1',
    label: 'Відкрили форму'
  },
  itemsFailed: {
    color: '#fc6b5a',
    label: 'Не вдалося'
  },
  formSubmitted: {
    color: '#3ea1ee',
    label: 'Відправили форму'
  }
};
const filterOptions = {
  opened: [{
    value: false,
    name: 'Not opened',
    key: 0
  }, {
    value: false,
    name: 'Opened',
    key: 1
  }],
  submitted: [{
    value: false,
    name: 'Not submitted',
    key: 0
  }, {
    value: false,
    name: 'Submitted',
    key: 1
  }]
};
export const CampaignSms: FC<TCampaign> = React.memo(({
  campaign,
  selectedCampaign,
  setSelectedCampaign,
  type,
  companyID,
  handleDelete
}) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isInfoVisible, setInfoVisible] = useState<boolean>(false);
  const [items, setItems] = useState<ApiSmsHistoryItem[]>([]);
  const [headers, setHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [filterParams, setFilterParams] = useState<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const formData = useMemo(() => {
    const formOpened: number = items.filter(el => el.formOpened).length;
    const formSubmitted: number = items.filter(el => el.formSubmitted).length;
    return {
      formOpened,
      formSubmitted
    };
  }, [campaign, items]);
  const data = useMemo(() => ({
    labels: ['Count'],
    datasets: keysToInclude.map((key, index) => {
      if (key === 'formOpened') {
        return {
          label: getTranslationByLangOrEng(interfaceLanguage, key),
          data: [formData.formOpened],
          borderColor: coloredStats[key],
          backgroundColor: coloredStats[key]
        };
      }
      if (key === 'formSubmitted') {
        return {
          label: getTranslationByLangOrEng(interfaceLanguage, key),
          data: [formData.formSubmitted],
          borderColor: coloredStats[key],
          backgroundColor: coloredStats[key]
        };
      }
      return {
        label: getTranslationByLangOrEng(interfaceLanguage, key),
        data: [campaign[key]],
        borderColor: coloredStats[key],
        backgroundColor: coloredStats[key]
      };
    })
  }), [campaign, items]);
  const description: string = useMemo(() => keysToInclude.filter(el => el !== 'formOpened' && el !== 'formSubmitted').map(e => {
    console.log(e);
    console.log(interfaceLanguage);
    return e;
  }).map(el => `${getTranslationByLangOrEng(interfaceLanguage, el)
  // .slice(0, 1)
  .toLocaleUpperCase()}: ${campaign[el as keyof typeof campaign]}`).join(' | '), [campaign, keysToInclude, interfaceLanguage]);
  const handleToggleInfo = () => {
    setInfoVisible(!isInfoVisible);
    setSelectedCampaign(campaign);
  };
  useEffect(() => {
    setInfoVisible(selectedCampaign ? selectedCampaign.id === campaign.id : false);
  }, [selectedCampaign]);
  console.log(`fetch${isInfoVisible}`);
  useEffect(() => {
    if (type === 'SMS' && isInfoVisible) {
      console.log('fetch');
      massMailingApi.massMailingSmsCampaign(campaign.id).then(res => {
        console.log(res);
        setItems(res.data.items);
        setFilterParams({});
      });
    }
    if (type === 'Viber' && isInfoVisible) {
      console.log('fetch');
      massMailingApi.massMailingViberCampaign(campaign.id).then(res => {
        console.log(res);
        setItems(res.data.items);
        setFilterParams({});
      });
    }
  }, [type, isInfoVisible]);
  useEffect(() => {
    setHeaders([{
      Header: 'ID',
      accessor: 'id',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        row
      }: any) => <NameCustomField name={row.original.messageID} />
    }, {
      Header: 'Phone',
      accessor: 'phone',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        row
      }: any) => <NameCustomField name={row.original.phone} />
    }, {
      Header: 'State',
      accessor: 'state',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        row
      }: any) => <NameCustomField name={row.original.stateDescription} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput
      // icon
      columnName="sendDate" title="Sent date" filterParams={filterParams} options={filterOptions} setFilterParams={(params: any) => {
        setFilterParams(params);
      }} setReQuery={() => {}} setApply={(params: any) => {
        setFilterParams(params);
      }}
      // status={(reviewStatuses as IReviewStatus[]) || []}
      />,
      accessor: 'date',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        row
      }: any) => <NameCustomField name={format(new Date(row.original.sentAt), 'dd.MM.yyyy')} />
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput icon columnName="opened" title="Opened" filterParams={filterParams} setFilterParams={(params: any) => {
        setFilterParams(params);
      }} options={filterOptions} setReQuery={() => {}} setApply={(params: any) => {
        setFilterParams(params);
      }}
      // status={(reviewStatuses as IReviewStatus[]) || []}
      />,
      accessor: 'opened',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        row
      }: any) => <div className="icon-container">
              {row.original.formOpened ? <SolvedIcon /> : <DeleteIcon width={15} height={15} color="#eee" />}
            </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput icon columnName="submitted" title="Submitted" filterParams={filterParams} setFilterParams={(params: any) => {
        setFilterParams(params);
      }} options={filterOptions} setReQuery={() => {}} setApply={(params: any) => {
        setFilterParams(params);
      }}
      // status={(reviewStatuses as IReviewStatus[]) || []}
      />,
      accessor: 'submitted',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        row
      }: any) => <div className="icon-container">
              {row.original.formSubmitted ? <SolvedIcon width={20} height={20} /> : <DeleteIcon width={15} height={15} color="#eee" />}
            </div>
    }]);
  }, [items, filterParams]);
  const filteredItems = useMemo(() => items.filter(item => {
    const createdAt = parseISO(item.createdAt);
    const from = parseISO(filterParams.from as string);
    const to = parseISO(filterParams.to as string);
    if (filterParams.from && filterParams.to) {
      if (!isWithinInterval(createdAt, {
        start: from,
        end: to
      })) {
        return false;
      }
    }
    if (filterParams.opened && filterParams.submitted) {
      return (filterParams.opened as any[]).includes(+item.formOpened) && (filterParams.submitted as any[]).includes(+item.formSubmitted);
    }
    if (filterParams.opened && !filterParams.submitted) {
      return (filterParams.opened as any[]).includes(+item.formOpened) && item.formSubmitted === false;
    }
    if (!filterParams.opened && filterParams.submitted) {
      return (filterParams.submitted as any[]).includes(+item.formSubmitted) && item.formOpened === true;
    }
    return true;
  }), [filterParams, items]);
  return <CampaignStyles>
        <div onClick={handleToggleInfo} className="campaign-header">
          <div className="campaign-title">
            <h3>{campaign.name}</h3>
            <p>{description}</p>
          </div>
          <div className="campaign-date">
            Date of creation:{' '}
            {format(new Date(campaign.createdAt), 'dd.MM.yyyy')}
          </div>
          <div className={isInfoVisible ? 'arrow' : 'arrow active'}>
            <ArrowDownIcon />
          </div>
        </div>
        <div className={`campaign-info-content ${isInfoVisible ? 'show' : ''}`}>
          <StatusesCounterStyles>
            <div className="wrapper">
              {keysToInclude.map(item => <div key={item} className="status">
                  <div className="statusHead">
                    <div className="statusCount">
                      <p style={{
                  color: config[item].color
                }}>
                        {/* @ts-ignore */}
                        {campaign[item] ?? formData[item]}
                      </p>
                    </div>
                  </div>
                  <div className="statusContent">
                    <div className="statusName">{config[item].label}</div>
                  </div>
                </div>)}
            </div>
          </StatusesCounterStyles>
          <Bar height={40} options={options} data={data} />
          <div className="btns-containe">
            <TransparentButton filled text="Download Failed" handleClick={() => downloadSheet(items.filter(el => el.stateDescription !== 'Delivered' && el.stateDescription !== 'Доставлено'))} />
            <TransparentButton filled text="Download All" handleClick={() => downloadSheet(items)} />
            <TransparentButton filled extraStyles={{
          backgroundColor: 'red',
          borderColor: 'red'
        }} text="Delete Campaign" handleClick={() => setIsModalVisible(true)} />
          </div>
          {!!headers && !!items && <Table columns={headers} data={filteredItems} hideFieldsSplitters sortableColumns={['id']} extraFieldsSettings={extraFieldsSettings} minHeight="600px" />}
        </div>
        {isModalVisible && <Modal title="Are you sure?" onClose={() => setIsModalVisible(false)} extraStyles={{
      width: 400
    }}>
            <div className="delete_modal">
              <p className="subtitle">
                Are you sure you want to delete the mailling ({campaign.name})
                and that the changes will be irreversible?
              </p>
              <div className="row">
                <TransparentButton filled extraStyles={{
            backgroundColor: '#ccc',
            borderColor: '#ccc'
          }} text="Cancell" handleClick={() => setIsModalVisible(true)} />
                <TransparentButton filled text="Delete" extraStyles={{
            backgroundColor: 'red',
            borderColor: 'red'
          }} handleClick={() => handleDelete(campaign.id)} />
              </div>
            </div>
          </Modal>}
      </CampaignStyles>;
});