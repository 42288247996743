import React, { FC } from 'react';
import { TIcon } from './TIcon';
export const ProfileIcon: FC<TIcon> = React.memo(({
  color = '#000',
  height = 26,
  width = 26
}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26">
    <circle cx="13" cy="13" r="12" fill="white" stroke="#0C0005" strokeWidth="2" />
    <mask id="path-2-inside-1_32_3617" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.07046 20.0416C4.38499 16.4565 8.41534 14.0833 13 14.0833C17.5847 14.0833 21.6151 16.4566 23.9296 20.0417C23.5398 20.6454 23.1013 21.2148 22.6198 21.7443C20.7418 18.3678 17.1377 16.0833 13 16.0833C8.86236 16.0833 5.25829 18.3678 3.3803 21.7442C2.89872 21.2147 2.46025 20.6453 2.07046 20.0416Z" />
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.07046 20.0416C4.38499 16.4565 8.41534 14.0833 13 14.0833C17.5847 14.0833 21.6151 16.4566 23.9296 20.0417C23.5398 20.6454 23.1013 21.2148 22.6198 21.7443C20.7418 18.3678 17.1377 16.0833 13 16.0833C8.86236 16.0833 5.25829 18.3678 3.3803 21.7442C2.89872 21.2147 2.46025 20.6453 2.07046 20.0416Z" fill="#D9D9D9" />
    <path d="M2.07046 20.0416L0.39021 18.9568L-0.310137 20.0415L0.390199 21.1263L2.07046 20.0416ZM23.9296 20.0417L25.6099 21.1264L26.3102 20.0417L25.6099 18.9569L23.9296 20.0417ZM22.6198 21.7443L20.8719 22.7164L22.2255 25.1502L24.0993 23.09L22.6198 21.7443ZM3.3803 21.7442L1.90074 23.0899L3.77451 25.1501L5.12814 22.7164L3.3803 21.7442ZM13 12.0833C7.70793 12.0833 3.05751 14.8253 0.39021 18.9568L3.7507 21.1263C5.71247 18.0877 9.12275 16.0833 13 16.0833V12.0833ZM25.6099 18.9569C22.9426 14.8254 18.2921 12.0833 13 12.0833V16.0833C16.8773 16.0833 20.2876 18.0878 22.2494 21.1264L25.6099 18.9569ZM22.2494 18.9569C21.9194 19.4679 21.5482 19.9501 21.1402 20.3986L24.0993 23.09C24.6545 22.4796 25.1602 21.823 25.6099 21.1264L22.2494 18.9569ZM24.3676 20.7722C22.1511 16.7869 17.8926 14.0833 13 14.0833V18.0833C16.3827 18.0833 19.3325 19.9487 20.8719 22.7164L24.3676 20.7722ZM13 14.0833C8.10741 14.0833 3.84903 16.7869 1.63246 20.7721L5.12814 22.7164C6.66755 19.9486 9.6173 18.0833 13 18.0833V14.0833ZM4.85986 20.3985C4.4519 19.95 4.08063 19.4678 3.75072 18.9568L0.390199 21.1263C0.839881 21.8229 1.34554 22.4795 1.90074 23.0899L4.85986 20.3985Z" fill="#0C0005" mask="url(#path-2-inside-1_32_3617)" />
    <rect x="9.47827" y="4.25" width="6.66667" height="7.75" rx="3.33333" stroke="black" strokeWidth="2" />
  </svg>);