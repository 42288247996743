/* eslint-disable class-methods-use-this */
import { env } from 'process';
import baseApi from '../baseApi';
import { ApiResponse } from '../entities';

export class DiiaAuthApi {
  static baseUrl: string = process.env.REACT_APP_API_HOST!;

  static async idGovUaAuth(): Promise<void> {
    // const { statusCode, data: userData } = await baseApi.postData('/login');
    const jsonResponse = await baseApi.fetchData('/oauth2/idgovua/login', {
      method: 'POST',
      body: JSON.stringify({
        userType: 'Працівник',
      }),
    });
    window.open(jsonResponse.data.authCodeURL, '_self', 'noreferrer');
  }
}
