import styled from 'styled-components';

export const SidebarStyles = styled.div`
  padding: 25px 0;
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  z-index: 99;
  align-items: center;
  transition: width 0.3s;
  background: #e7eef3;

  @media screen and (max-width: 768px) {
    position: absolute;
    left: 0;
    height: 100%;
  }

  button {
    text-decoration: underline;
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    color: #000000;
    max-width: 130px;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 150px;
  }

  svg {
    transition: all 0.2s;
  }

  svg:hover {
    transition: all 0.2s;
  }

  li {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.active {
      span {
        text-decoration: underline;
      }

      .fill {
        fill: #0c0005 !important;
        /* stroke: #0c0005 !important; */
      }
      .unfill {
        fill: #e7eef3 !important;
        /* stroke: #e7eef3 !important; */
      }
    }

    &:hover {
      span {
        text-decoration: underline;
      }

      .fill {
        fill: #0c0005 !important;
        /* stroke: #0c0005 !important; */
      }
      .unfill {
        fill: #e7eef3 !important;
        /* stroke: #e7eef3 !important; */
      }
    }

    a {
      height: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      gap: 10px;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      color: #0c0005;
    }
  }

  .count {
    font-size: 12px;
    font-weight: 700;
    background-color: #000;
    color: #fff;
    padding: 0px 7px;
    border-radius: 20px;
  }

  .count-abs {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%);
  }
`;
