import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiPosterTemplate } from '../../../api';
import { DeleteIcon } from '../../../assets';
import { useAppSelector } from '../../../state';
import { CompanyPostersStyles } from './CompanyPostersStyles';
import { Loader, TransparentButton } from '../../atoms';
import { companyPostersApi } from '../../../api/companyPosters/CompanyPostersApi';
import PosterModal from '../modal/posterModal/PosterModal';
import { ModalConfirmDecline } from '../modal';
import { toast } from 'react-toastify';
import { mapApiPosterDataToPreviewPosterData } from './utils';
export const CompanyPosters = React.memo(() => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    id
  } = useParams();
  const [posters, setPosters] = useState<ApiPosterTemplate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [posterIDToDelete, setPosterIDToDelete] = useState<number | undefined>(undefined);
  const [posterModalOpen, setPosterModalOpen] = useState<boolean>(false);
  const [previewCreatingInProgress, setPreviewCreatingInProgress] = useState<boolean>(false);
  useEffect(() => {
    if (id) {
      setLoading(true);
      companyPostersApi.getCompanyPosterTemplates(+id).then(response => {
        setPosters(response.data);
        setLoading(false);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, []);
  const previewPoster = async (poster: ApiPosterTemplate) => {
    let fileURL: string | null = null;
    try {
      setPreviewCreatingInProgress(true);
      toast.info('Зачекайте триває підготовка постеру для попереднього перегляду');
      const posterDataToPreview = mapApiPosterDataToPreviewPosterData(poster);
      const generatedPoster = await companyPostersApi.previewPoster(posterDataToPreview).then(res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          return res.data;
        }
      });
      if (generatedPoster) {
        fileURL = URL.createObjectURL(generatedPoster);
        setPreviewCreatingInProgress(false);
        window.open(fileURL, '_blank');
      }
    } catch (e) {
      toast.error('Виникла помилка під час підготовки попереднього перегляду постеру');
    } finally {
      setPreviewCreatingInProgress(false);
      if (fileURL) {
        URL.revokeObjectURL(fileURL);
      }
    }
  };
  const onPosterModalClose = useCallback(() => {
    setPosterModalOpen(false);
  }, []);
  const onPosterCreated = useCallback((createdPoster: ApiPosterTemplate) => {
    setPosters(posters => [...posters, createdPoster]);
  }, []);
  const deletePosterTemplate = async () => {
    if (posterIDToDelete) {
      try {
        setLoading(true);
        companyPostersApi.deletePosterTemplate(posterIDToDelete).then(response => {
          if (response.statusCode >= 200 && response.statusCode < 300) {
            setPosters(prev => prev.filter(poster => poster.id !== posterIDToDelete));
            setLoading(false);
            toast.success('Постер видалено');
            setPosterIDToDelete(undefined);
          } else {
            throw new Error('Виникла помилка при видаленні постеру');
          }
        });
      } catch (e) {
        toast.error('Виникла помилка при видаленні постеру');
      } finally {
        setLoading(false);
      }
    }
  };
  return <CompanyPostersStyles>
      {posterIDToDelete && <ModalConfirmDecline confirmText="Видалити" declineText="Відмінити" onConfirm={deletePosterTemplate} title="Підвердіть видалення постеру" firstButtonStyles={{
      background: '#000'
    }} secondButtonStyles={{
      background: 'red'
    }} onDecline={() => setPosterIDToDelete(undefined)} onClose={() => setPosterIDToDelete(undefined)} />}

      {posterModalOpen && <PosterModal onClose={onPosterModalClose} onPosterCreated={onPosterCreated} />}

      {loading ? <Loader /> : posters.length > 0 ? <div className="postersContent">
              <div className="postersHead">
                <h3>Список постерів</h3>

                <TransparentButton text="Додати +" handleClick={() => setPosterModalOpen(true)} />
              </div>

              <div className="postersWrapper">
                {posters.map(poster => <Fragment key={poster.id}>
                    <div className="poster">
                      <button disabled={previewCreatingInProgress} type="button" onClick={() => previewPoster(poster)} className="posterName">{poster.name}</button>

                      <div className="posterButtons">
                        <button type="button" onClick={() => {
                setPosterIDToDelete(poster.id);
              }}>
                          <DeleteIcon width={16} height={16} />
                        </button>
                      </div>
                    </div>

                    <div className="splitter" />
                  </Fragment>)}
              </div>
            </div> : <div className="noDataWrapper">
              <span className="noDataText">Постерів не знайдено</span>

              <TransparentButton text="Додати +" handleClick={() => setPosterModalOpen(true)} />
            </div>}
    </CompanyPostersStyles>;
});