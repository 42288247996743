import React, { FC } from 'react';
import { NameCustomFieldStyles } from './NameCustomFieldStyles';
type TNameCustomField = {
  name: string;
  logo?: string;
  textColor?: string;
};
export const NameCustomField: FC<TNameCustomField> = React.memo(({
  name,
  logo,
  textColor
}) => <NameCustomFieldStyles>
      <div className="customNameWrapper">
        {logo && <div className={logo ? 'imageContainer' : 'imageContainer default'}>
            <div className="image">{logo && <img src={logo} alt="logo" />}</div>
          </div>}
        <span style={{
      color: textColor || '#000'
    }}>{name}</span>
      </div>
    </NameCustomFieldStyles>);