import styled from 'styled-components';
export const AuditModalStyles = styled.div`
  .modalBody {
    width: 80%;
    background: #fff;
    max-height: 100vh;

    h3 {
      margin-bottom: 15px;
      font-size: 22px;
    }

    .cellWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`;