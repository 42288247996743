export enum TabKeys {
  csi = 'csi',
  csiDetailed = 'csiDetailed',
  csiRegionRatings = 'csiRegionRatings',
  ratingPopulationOne = 'ratingPopulationOne',
  ratingPopulationTwo = 'ratingPopulationTwo',
  ratingPopulationThree = 'ratingPopulationThree',
  ratingPopulationFour = 'ratingPopulationFour',
  ratingPopulationFive = 'ratingPopulationFive',
  ratingPopulationSix = 'ratingPopulationSix',
  ratingReviewsOne = 'ratingReviewsOne',
  ratingReviewsTwo = 'ratingReviewsTwo',
  ratingReviewsThree = 'ratingReviewsThree',
  ratingReviewsFour = 'ratingReviewsFour',
  ratingReviewsFive = 'ratingReviewsFive',
  ratingReviewsSix = 'ratingReviewsSix',
  ratingVrmReviews = 'ratingVrmReviews',
  complexRating = 'complexRating',
  locationDissatisfaction = 'locationDissatisfaction',
  locationOutOfRating = 'locationOutOfRating',
  locationWithoutReviews = 'locationWithoutReviews',
  generalProcessDissatisfaction = 'generalProcessDissatisfaction',
  staffDissatisfaction = 'staffDissatisfaction',
  complexGeneral = 'complexGeneral',
  servicesList = 'servicesList',
  qrPublishing = 'qrPublishing',
  qrPublishingPie = 'qrPublishingPie',
}

export type DataItem = {
  label: string;
  value: number;
  fill?: string;
  count?: number;
};

export type TTab = {
  key: TabKeys;
  text: string;
};

export const tabs: TTab[] = [
  {
    key: TabKeys.csi,
    text: 'Показник рівня задоволеності (CSI)',
  },
  {
    key: TabKeys.locationDissatisfaction,
    text: 'Основні причини незадоволеності місцем надання послуг',
  },
  {
    key: TabKeys.generalProcessDissatisfaction,
    text: 'Основні причини незадоволеності загальним процесом надання послуг',
  },
  {
    key: TabKeys.staffDissatisfaction,
    text: 'Основні причини незадоволеності персоналом',
  },
  {
    key: TabKeys.servicesList,
    text: 'Основні причини незадоволеності повнотою переліку послуг',
  },
  {
    key: TabKeys.csiDetailed,
    text: 'Статистика по показнику рівня задоволеності (CSI)',
  },
  {
    key: TabKeys.csiRegionRatings,
    text: 'Рейтинг регіонів за регультатами оцінки рівня задоволеності',
  },
  {
    key: TabKeys.ratingPopulationOne,
    text: 'Рейтинг ЦНАП відповідно до кількості населення (офлайн-фронт-офіс І категорії)',
  },
  {
    key: TabKeys.ratingPopulationTwo,
    text: 'Рейтинг ЦНАП відповідно до кількості населення (офлайн-фронт-офіс ІI категорії)',
  },
  {
    key: TabKeys.ratingPopulationThree,
    text: 'Рейтинг ЦНАП відповідно до кількості населення (офлайн-фронт-офіс ІII категорії)',
  },
  {
    key: TabKeys.ratingPopulationFour,
    text: 'Рейтинг ЦНАП відповідно до кількості населення (офлайн-фронт-офіс ІV категорії)',
  },
  {
    key: TabKeys.ratingPopulationFive,
    text: 'Рейтинг ЦНАП відповідно до кількості населення (офлайн-фронт-офіс V категорії)',
  },
  {
    key: TabKeys.ratingPopulationSix,
    text: 'Рейтинг ЦНАП відповідно до кількості населення (офлайн-фронт-офіс VI категорії)',
  },
  {
    key: TabKeys.ratingReviewsOne,
    text: 'Рейтинг ЦНАП відповідно до кількості відгуків (офлайн-фронт-офіс I категорії)',
  },
  {
    key: TabKeys.ratingReviewsTwo,
    text: 'Рейтинг ЦНАП відповідно до кількості відгуків (офлайн-фронт-офіс II категорії)',
  },
  {
    key: TabKeys.ratingReviewsThree,
    text: 'Рейтинг ЦНАП відповідно до кількості відгуків (офлайн-фронт-офіс III категорії)',
  },
  {
    key: TabKeys.ratingReviewsFour,
    text: 'Рейтинг ЦНАП відповідно до кількості відгуків (офлайн-фронт-офіс IV категорії)',
  },
  {
    key: TabKeys.ratingReviewsFive,
    text: 'Рейтинг ЦНАП відповідно до кількості відгуків (офлайн-фронт-офіс V категорії)',
  },
  {
    key: TabKeys.ratingReviewsSix,
    text: 'Рейтинг ЦНАП відповідно до кількості відгуків (офлайн-фронт-офіс VI категорії)',
  },
  {
    key: TabKeys.ratingVrmReviews,
    text: 'Рейтинг ВРМ відповідно до кількості відгуків',
  },
  {
    key: TabKeys.complexRating,
    text: 'Рейтинг ВРМ за результатами оцінки рівня задоволеності',
  },
  {
    key: TabKeys.locationOutOfRating,
    text: 'ЦНАП, які не увійшли до рейтингу за результатами оцінки рівня задоволеності',
  },
  {
    key: TabKeys.locationWithoutReviews,
    text: 'ЦНАП у яких відсутні відгуки',
  },
  {
    key: TabKeys.complexGeneral,
    text: 'Результат оцінки рівня задоволеності у ЦНАП',
  },
  {
    key: TabKeys.qrPublishing,
    text: 'Результат опитування розміщення QR-кодів на опитування щодо оцінки рівня задоволеності в ЦНАП',
  },
  {
    key: TabKeys.qrPublishingPie,
    text: 'Аналітика розміщення QR-кодів в ЦНАП',
  },
];
