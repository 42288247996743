import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { FC, useMemo } from 'react';
import { TTagsOption } from '../types';
import { CheckMark } from '../../../../assets';
const SelectOption_1 = _compiledBlock(_props => <div className="selectOption">
      <div className="selectRow" style={_props.v0}>
        <div className="selectCheckbox">
          <div className={_props.v1} onClick={_props.v2}>
            {_props.v3}
          </div>
          <span>{_props.v4}</span>
        </div>
      </div>
    </div>, {
  name: "SelectOption_1",
  portals: ["v3", "v4"]
});
export const SelectOption: FC<TTagsOption> = ({
  tag,
  handleSelect,
  offset,
  tags
}) => {
  const isSelected = useMemo(() => tags.some(el => el.id === tag.id), [tags]);
  return /*@million jsx-skip*/<SelectOption_1 v0={{
    paddingLeft: `${offset * 16}px`
  }} v1={isSelected ? 'selectMarker selectActive' : 'selectMarker'} v2={() => handleSelect(tag)} v3={isSelected && <CheckMark />} v4={tag.name} />;
};