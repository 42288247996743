/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import {
  ApiAddOptionTransition,
  ApiAddPage,
  ApiAddPageField,
  ApiResponse,
  TPage,
} from '../entities';
import IPagesApi from './IPagesApi';

class PagesApi implements IPagesApi {
  public async addFormPage(id: number, data: ApiAddPage): Promise<ApiResponse<TPage>> {
    return baseApi.postData(`/localizations/${id}/pages`, data);
  }

  public async addFieldToPage(id: number, data: ApiAddPageField): Promise<ApiResponse<any>> {
    return baseApi.postData(`/pages/${id}/fields`, data);
  }

  public async addOptionTransition(id: number, data: ApiAddOptionTransition): Promise<ApiResponse<any>> {
    return baseApi.postData(`/pages/${id}/transitions`, data);
  }

  public async getPage(id: number): Promise<ApiResponse<TPage>> {
    return baseApi.getData(`/pages/${id}`);
  }

  public async deletePage(id: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/pages/${id}`);
  }

  public async deleteOptionTransition(id: number, option: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/pages/${id}/transitions/${option}`);
  }

  public async deletePageField(id: number, pos: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/pages/${id}/fields/${pos}`);
  }

  public async updatePage(id: number, data: ApiAddPage): Promise<ApiResponse<TPage>> {
    return baseApi.putData(`/pages/${id}`, data);
  }
}

export const pagesApi = new PagesApi();
