import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { companySettingsApi } from '../../../../api';
import { Checkbox } from '../../../molecules';
type TEnabledSettings = {
  apiEnabled: boolean | undefined;
  settings: string;
  label: string;
};
export const EnabledSettings: FC<TEnabledSettings> = React.memo(({
  apiEnabled,
  settings,
  label
}) => {
  const {
    id
  } = useParams();
  const [enabled, setEnabled] = useState<boolean>(false);
  const handleToggleEnabled = useCallback(() => {
    if (id) {
      companySettingsApi.updateCompanySettingsKey(+id, settings, {
        value: !enabled
      }).then(res => {
        setEnabled(!enabled);
      });
    }
  }, [enabled]);
  useEffect(() => {
    setEnabled(!!apiEnabled);
  }, [apiEnabled]);
  return <div className="enabled">
        <div className="fields">
          <h3>{label}</h3>
          <Checkbox extraBlockStyles={{
        width: 52
      }} hideError name={settings} value={enabled} onChange={handleToggleEnabled} />
        </div>
      </div>;
});