import { EditStyles } from './styles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CompanyInfoForm from '../../../components/companyInfoForm/CompanyInfoForm';
import CompanyDesignForm from '../../../components/companyDesignForm/CompanyDesignForm';
import Documents from '../documents/Documents';
import { companyApi } from '../../../api';
import { useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import CompanyTemplates from '../../../components/companyTamplates/CompanyTemplates';
import CompanySources from '../../../components/companySources/CompanySources';
import CompanyReviewTableColumnsSettings from '../../../components/companyReviewTableColumnsSettings/CompanyReviewTableColumnsSettings';
import { tabsEdit } from './utils';
import { Helmet } from 'react-helmet-async';
import { CompanyPosters, CompanySettings, CompanyTagsAndCategories, CustomTabsMenu, SubHeader, TransparentButton } from '../../../components';
import CompanyIssueStatusesAndTypes from '../../../components/companyIssueStatusesAndTypes/CompanyIssueStatusesAndTypes';
import { EditTabKeys } from './TEditCompany';
import CompanyQrStyles from '../../../components/companyQRStylesForm/CompanyQRStylesForm';
export const tabComponents: {
  [key: string]: any;
} = {
  [EditTabKeys.info]: () => <CompanyInfoForm />,
  [EditTabKeys.design]: () => <CompanyDesignForm />,
  [EditTabKeys.documents]: () => <Documents />,
  [EditTabKeys.issuesStatusesAndTypes]: () => <CompanyIssueStatusesAndTypes />,
  [EditTabKeys.templates]: () => <CompanyTemplates />,
  [EditTabKeys.sources]: () => <CompanySources />,
  [EditTabKeys.reviewTableColumnSettings]: () => <CompanyReviewTableColumnsSettings />,
  [EditTabKeys.settings]: () => <CompanySettings />,
  [EditTabKeys.tags]: () => <CompanyTagsAndCategories />,
  [EditTabKeys.qrStyles]: () => <CompanyQrStyles />,
  [EditTabKeys.posters]: () => <CompanyPosters />
};
export default function EditCompany() {
  const {
    id
  } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selectedCompany
  } = useAppSelector(state => state.company);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [companyName, setCompanyName] = useState('');
  const [selectedTab, setSelectedTab] = useState(tabsEdit(interfaceLanguage)[0].key);
  function handleCancel() {
    navigate('/companies');
  }
  useEffect(() => {
    if (location.search.includes('google-auth-redirect')) {
      navigate(`/companies/${id}/edit`);
      setSelectedTab(EditTabKeys.integrations);
    }
  }, [location]);
  useEffect(() => {
    if (selectedCompany) {
      setCompanyName(selectedCompany.name!);
    } else if (id) {
      companyApi.getCompanyById(+id).then(res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setCompanyName(res.data.name!);
        }
      });
    }
  }, []);
  return <EditStyles>
      <Helmet>
        <title>{`Editing settings ${companyName} Voicer`}</title>
      </Helmet>

      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_title_edit')} pathArray={[{
      name: companyName,
      route: `/companies/${id}/nodes`
    }]} hideBorderBottom>
        <TransparentButton handleClick={() => navigate(`/companies/${id}/nodes`)} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure')} filled />
        <TransparentButton handleClick={() => navigate('/companies')} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} />
      </SubHeader>

      <CustomTabsMenu tabs={tabsEdit(interfaceLanguage)} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {tabComponents[selectedTab]()}
    </EditStyles>;
}