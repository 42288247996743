/* eslint-disable react/jsx-indent */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { СlientStatusesEmptyIssuesStyles } from './СlientStatusesEmptyIssuesStyles';
import { useClickOutside } from '../../../../hooks';
import { ArrowDownIcon, CheckMark } from '../../../../assets';
import { reviewsStatusIconsAndText } from '../../../../constants';
import { useAppSelector } from '../../../../state';
import { companySettingsApi } from '../../../../api';
type TClientStatusesEmptyIssues = {
  apiClientStatusesEmptyIssues: number[];
};
export const ClientStatusesEmptyIssues: FC<TClientStatusesEmptyIssues> = React.memo(({
  apiClientStatusesEmptyIssues
}) => {
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [open, setOpen] = useState(false);
  const [clientStatusesEmptyIssues, setClientStatusesEmptyIssues] = useState<number[]>([]);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const handleUpdate = useCallback((statusID: number) => {
    const isNumberInArray = clientStatusesEmptyIssues.some(item => item === statusID);
    let statuses: number[] = [];
    if (isNumberInArray) {
      statuses = clientStatusesEmptyIssues.filter(item => item !== statusID);
    } else {
      statuses = [...clientStatusesEmptyIssues, statusID].sort((a, b) => a - b);
    }
    if (id) {
      companySettingsApi.updateCompanySettingsKey(+id, 'client-statuses-when-issues-is-empty', {
        value: statuses
      }).then(res => {
        setClientStatusesEmptyIssues(statuses);
      });
    }
  }, [clientStatusesEmptyIssues]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  useEffect(() => {
    if (apiClientStatusesEmptyIssues) {
      setClientStatusesEmptyIssues(apiClientStatusesEmptyIssues);
    }
  }, [apiClientStatusesEmptyIssues]);
  return <СlientStatusesEmptyIssuesStyles>
        <div className="clientStatusesEmptyIssues">
          <h3>
            Якщо не створене завдання то можна перейти тільки в ці статуси
          </h3>

          <div className="selectField">
            <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
              <span className="placeholder">Оберіть статуси</span>
              <div className={open ? 'arrowDownActive' : ''}>
                <ArrowDownIcon />
              </div>
            </div>

            {open && <div className="options" ref={optionsListRef}>
                {Object.entries(reviewsStatusIconsAndText(interfaceLanguage)).map(([key, value]) => {
            const Icon = value.icon;
            return <div key={key} className="option">
                      <div className="rowSel">
                        <button type="button" onClick={() => handleUpdate(+key)}>
                          <div className="labelContent">
                            <div className="default">
                              {clientStatusesEmptyIssues.includes(+key) && <CheckMark />}
                            </div>
                            <Icon width={25} height={25} />
                            <span>
                              {value.text.length > 20 ? `${value.text.slice(0, 20)}...` : value.text}
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>;
          })}
              </div>}
          </div>
        </div>
      </СlientStatusesEmptyIssuesStyles>;
});