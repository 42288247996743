export const translations: { [key: string]: { [key: string]: string } } = {
  EN: {
    itemsFailed: 'Failed',
    itemsProcessed: 'Processed',
    itemsOpened: 'Opened',
    itemsUnsubscribed: 'Unsubscribed',
    itemsSent: 'Sent',
    itemsTotal: 'Total',
    itemAccepted: 'Accepted',
    formOpened: 'Form Opened',
    formSubmitted: 'Form Submitted',
    itemsDelivered: 'Delivered',

    responsible_in_review: 'Review responsible',
    sun: 'Sunday',
    mon: 'Monday',
    tue: 'Tuesdayy',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    not_activate_chat: 'Chat not activated',
    tasks: 'Tasks',
    feedback_processing: 'Feedback processing',
    questions_and_answers: 'Questions and answers',
    detailed_feedback_information: 'Detailed review information',
    companies_form_constructor_title_hidden: 'Hidden key',
    companies_form_constructor_title_hidden_placeholder: 'Hidden key',
    companies_form_constructor_title_hidden_info:
      'Enter the key for the input type hidden',
    search_by_comment: 'Search by comment',
    enter_role_name: 'Enter new role name',
    role_name: 'New role name',
    enter_uniq_name: 'Enter uniq name',
    enter_permissions_name: 'Enter new permission name',
    permissions_name: 'New permission name',
    copy_permissions: 'Copy permission',
    pass_changed_success: 'Password changed successfully',
    connect_tg: 'Connect Telegram bot',
    connect_vb: 'Connect Viber bot',
    reset_pass: 'Reset password',
    notifiable_sources: 'Notifiable Sources',
    notifications: 'Notifications',
    audit: 'History audit',
    CREATE: 'Create',
    UPDATE: 'Update',
    DELETE: 'Delete',
    review_audit: 'Review processing history',
    USER: 'User',
    SYSTEM: 'System',
    actor: 'Actor',
    action: 'Action',
    search_by_phone: 'Search by phone number',
    search_by_reviewID: 'Search by review ID',
    chat_error_no_user:
      'The chat with the user was not found, maybe he did not connect or deleted the bot',
    chat_label: 'Chat with the client',
    comment_internal: 'Internal comment',
    changes_not_saved_modal:
      'Changes are not saved. Are you sure you want to leave the page?',
    go_back: 'Go back',
    all_items_uploaded: 'All items uploaded',
    login_title: 'Sign in',
    login_recovery_password: 'Password recovery',
    add_comment: 'Add a comment',
    add_button: 'Add',
    add_option: 'Add option',
    add_option_button: 'Add Variant',
    apply_filters_button: 'Apply',
    apply_table_settings: 'Apply table settings',
    are_you_sure: 'Are you sure?',
    attached_files: 'Attached files',
    bot_is_not_connected: 'Bot is not connected',
    cancel_button: 'Cancel',
    cant_decided: "Can't solve",
    change_new_form_button: 'Change new form',
    changes_saved_successfully: 'Changes saved successfully',
    channels_for_notifications: 'Channels for notification',
    channels_of_connections: 'Channels of connection',
    chat_error_description: 'An error occurred while sending a message',
    chat_history_is_empty: 'Chat history is empty',
    chat_is_finished: 'Chat closed',
    choose_file_button: 'Select a file',
    choose_period: 'Choose period',
    client_form_scale_field_h3: 'Your impression of our company',
    close_button: 'Close',
    close_editing_button: 'Close editing',
    closed_auto_no_replay: 'Closed automatically due to no client’s answer',
    color_picker_add_button: 'Add color',
    comment_placeholder: 'Enter comment',
    companies_columns_settings_confirmation_before_save_modal_title:
      'Are you sure you want to edit the column settings for reviews page?',
    companies_design_add_color_button: 'Add color',
    companies_design_add_color_placeholder: 'Enter color name',
    companies_design_add_font_button: 'Add font',
    companies_design_add_font_placeholder: 'Enter font name',
    companies_design_colors_text: 'Colors',
    companies_design_confirmation_before_save_modal_title:
      'Are you sure that you want to edit company information?',
    companies_design_fonts_text: 'Fonts',
    companies_design_logos_add_button: 'Upload file',
    companies_design_logos_add_placeholder: 'Enter logo name',
    companies_design_logos_text: 'Images',
    companies_documents_add_localization: 'Localization',
    companies_documents_add_name_label: 'Name',
    companies_documents_add_name_placeholder: 'Enter document name',
    companies_documents_add_title: 'Adding a document',
    companies_documents_add_title_editing: 'Document editing',
    companies_documents_file_caption: 'File',
    companies_documents_file_format: 'Format',
    companies_documents_file_max_size: 'Maximum file size',
    companies_documents_file_not_loaded: 'not loaded',
    companies_documents_file_save_button: 'Upload file',
    companies_documents_file_text_editor_label: 'Document text',
    companies_documents_file_text_editor_placeholder: 'Enter document text',
    companies_documents_table_add_document_button: 'Add Document',
    companies_documents_table_delete_text:
      'Are you sure you want to delete the document?',
    companies_documents_table_delete_title: 'Deleting a document',
    companies_documents_table_header_edit_date: 'Date of change',
    companies_documents_table_header_edit_languages: 'Languages',
    companies_documents_table_header_name: 'Name',
    companies_form_add_anonymous_checkbox_no: 'No answers cannot be anonymous.',
    companies_form_add_anonymous_checkbox_yes: 'Yes answers can be anonymous',
    companies_form_add_anonymous_label:
      'Possibility to leave anonymous reviews',
    companies_form_add_create_title: 'Add a feedback form',
    companies_form_add_edit_title: 'Editing a feedback form',
    companies_form_add_link_label: 'Form link',
    companies_form_add_link_placeholder:
      'Enter the link to the application form',
    companies_form_add_main_language_label: 'Primary language',
    companies_form_add_main_language_placeholder: 'Not selected',
    companies_form_add_multi_languages_label: 'Multilingual',
    companies_form_add_multi_languages_placeholder: 'Choice of languages',
    companies_form_add_name_label: 'Name of the application form',
    companies_form_add_name_placeholder:
      'Enter the name of the application form',
    companies_form_add_status_not_published: 'Not published',
    companies_form_add_status_published: 'Published',
    companies_form_add_status_title: 'Status',
    companies_form_add_tied_node_label: 'Tied node',
    companies_form_add_tied_node_label_second:
      'The second is responsible in the node',
    companies_form_add_tied_node_placeholder: 'Node',
    companies_form_constructor_add_an_option: 'Add an option',
    companies_form_constructor_add_document_button: 'Add a document',
    companies_form_constructor_add_field_to_series: 'Add to series of fields',
    companies_form_constructor_add_question_button: 'Add a question',
    companies_form_constructor_background_color: 'Background',
    companies_form_constructor_background_color_for_text_title:
      'Background color for text',
    companies_form_constructor_background_color_title: 'Background color',
    companies_form_constructor_caption_checkbox_label: 'Select document',
    companies_form_constructor_caption_placeholder:
      'Select the document you need',
    companies_form_constructor_caption_title: 'Privacy policy',
    companies_form_constructor_checked_elements_border_radius_title:
      'Checked radio buttons border radius',
    companies_form_constructor_checked_elements_checked_size_title:
      'Radio button checked size',
    companies_form_constructor_choice_description_info:
      'You can display a text field if you want user extend response when this option is selected',
    companies_form_constructor_choice_description_placeholder:
      'Choice description placeholder...',
    companies_form_constructor_choice_description_required:
      'Choice description required',
    companies_form_constructor_choice_description_title:
      'Choice description caption...',
    companies_form_constructor_choose_form_font_placeholder:
      'Select the questionnaire font',
    companies_form_constructor_choose_logo_button: 'Choose a logo',
    companies_form_constructor_choose_logo_label: 'Logo',
    companies_form_constructor_choose_logo_title:
      'Choose a logo from the uploaded company images',
    companies_form_constructor_choose_main_mascot_button: 'Choose a mascot',
    companies_form_constructor_choose_main_mascot_label: 'Main mascot',
    companies_form_constructor_choose_main_mascot_title:
      'Choose a mascot from the uploaded company images',
    companies_form_constructor_chose_field_placeholder: 'Select field',
    companies_form_constructor_communication_channels_call: 'Call',
    companies_form_constructor_communication_channels_checkbox_auto_answer_label:
      'Auto answer',
    companies_form_constructor_communication_channels_checkbox_auto_answer_placeholder:
      'Enter auto answer text',
    companies_form_constructor_communication_channels_email: 'Email',
    companies_form_constructor_communication_channels_label:
      'Choose what the communication channels will be available on the form page',
    companies_form_constructor_communication_channels_placeholder:
      'Explanation that you need to choose a communication channel for feedback',
    companies_form_constructor_communication_channels_sms: 'SMS',
    companies_form_constructor_communication_channels_sub_label:
      'Select messengers used for replies',
    companies_form_constructor_communication_channels_telegram: 'Telegram',
    companies_form_constructor_communication_channels_whatsapp: 'WhatsApp',
    companies_form_constructor_communication_channels_title:
      'Channels of connection',
    companies_form_constructor_communication_channels_viber: 'Viber',
    companies_form_constructor_communication_channels_without_answer:
      'Without answer',
    companies_form_constructor_country_code_not_editable:
      'Country code not editable',
    companies_form_constructor_disabled_send_button_background_color:
      "Disabled button's background color",
    companies_form_constructor_display_node_address: 'Display node address ',
    companies_form_constructor_display_scale_background_color:
      'Display the background color of the main scale',
    companies_form_constructor_display_scale_background_color_info:
      'Background color must depend of the main scale selected value',
    companies_form_constructor_display_scale_mascot:
      'Display the mascot of the main scale',
    companies_form_constructor_display_scale_mascot_info:
      'Mascot must depend of the main scale selected value',
    companies_form_constructor_document_description:
      'Select the document you need',
    companies_form_constructor_document_description_placeholder:
      'Document description',
    companies_form_constructor_document_empty_list:
      "Company don't have documents",
    companies_form_constructor_document_placeholder: 'Select document',
    companies_form_constructor_document_title: 'Document',
    companies_form_constructor_elements_border_width_title:
      'Elements border width',
    companies_form_constructor_elements_color_title: 'Color of elements',
    companies_form_constructor_email_placeholder: 'Enter your email',
    companies_form_constructor_email_title: 'E-mail',
    companies_form_constructor_form_font_title: 'Questionnaire font',
    companies_form_constructor_form_name_label: 'Name of form',
    companies_form_constructor_form_name_placeholder: 'Enter form name',
    companies_form_constructor_full_answer_placeholder:
      'We would be happy if you left an e-mail for communication',
    companies_form_constructor_full_answer_title: 'Detailed response',
    companies_form_constructor_info_additional_field_placeholder:
      'In this field, enter the placeholder for additional field of the question you want to ask the respondents.',
    companies_form_constructor_info_placeholder:
      'In this field enter the placeholder of the question you want to ask the respondents.',
    companies_form_constructor_info_question:
      'In this field enter the question you want to ask the respondents.',
    companies_form_constructor_info_scale_max:
      'In this field enter a short description for the maximum scale value.',
    companies_form_constructor_info_scale_min:
      'In this field enter a short description for the minimum scale value.',
    companies_form_constructor_info_step_text:
      'In this field, enter the text you want to show as a step text.',
    companies_form_constructor_info_thank_you_page_text:
      'In this field enter the text you want to display on Thank you page.',
    companies_form_constructor_info_thank_you_page_title:
      'In this field enter the title you want to display on Thank you page.',
    companies_form_constructor_key_question: 'Key question',
    companies_form_constructor_language_label: 'Form language',
    companies_form_constructor_language_placeholder: 'Language',
    companies_form_constructor_localization_label:
      'Localization for the whole form',
    companies_form_constructor_logo_place_center: 'Centered',
    companies_form_constructor_logo_place_label: 'Logo location',
    companies_form_constructor_logo_place_left: 'Left',
    companies_form_constructor_logo_place_right: 'On right',
    companies_form_constructor_main_question:
      'Leave your rating of the company',
    companies_form_constructor_main_scale: 'Main scale',
    companies_form_constructor_mascot: 'Mascot',
    companies_form_constructor_max_placeholder: 'Extremely incredible',
    companies_form_constructor_min_placeholder: 'Unlikely',
    companies_form_constructor_name_field_required: 'Name input field required',
    companies_form_constructor_name_placeholder: 'What is your name',
    companies_form_constructor_name_title: 'Name',
    companies_form_constructor_next_field: 'Next field',
    companies_form_constructor_not_checked_elements_color_title:
      'Color of not checked elements elements',
    companies_form_constructor_one_from_list_placeholder:
      'How long have you been visiting our establishment?',
    companies_form_constructor_one_from_list_title: 'One of the list',
    companies_form_constructor_phone_placeholder: 'Enter your phone number',
    companies_form_constructor_phone_title: 'Phone number',
    companies_form_constructor_prev_field: 'Prev field',
    companies_form_constructor_privacy_policy_label:
      'Select the document you need',
    companies_form_constructor_privacy_policy_placeholder: 'Select document',
    companies_form_constructor_privacy_policy_title: 'Privacy policy',
    companies_form_constructor_quantity_button_signs_color:
      'Button signs color',
    companies_form_constructor_quantity_button_signs_color_placeholder:
      'Select button sings color',
    companies_form_constructor_quantity_buttons_background_color:
      'Buttons background color',
    companies_form_constructor_quantity_buttons_background_color_placeholder:
      'Select buttons background color',
    companies_form_constructor_quantity_default_value_title: 'Default value',
    companies_form_constructor_quantity_max_value_title: 'Max value',
    companies_form_constructor_quantity_min_value_title: 'Min value',
    companies_form_constructor_quantity_placeholder:
      'How much times did you come to our place?',
    companies_form_constructor_quantity_title: 'Quantity',
    companies_form_constructor_required_question: 'Mandatory question',
    companies_form_constructor_scale_change_design_button:
      'Change the design of the scale',
    companies_form_constructor_scale_title: 'Scale',
    companies_form_constructor_select_field: 'Select field',
    companies_form_constructor_send_button: 'Button',
    companies_form_constructor_send_button_background_color:
      "Button's background color",
    companies_form_constructor_send_button_link: "Button's link",
    companies_form_constructor_send_button_text: "Button's text",
    companies_form_constructor_send_button_text_color: "Button's text color",
    companies_form_constructor_send_button_title: 'Send button',
    companies_form_constructor_send_disabled_button_text_color:
      "Disabled button's text color",
    companies_form_constructor_set_default_agreed: 'Set default agreed',
    companies_form_constructor_several_from_list_placeholder:
      'How long have you been visiting our establishment?',
    companies_form_constructor_several_from_list_title: 'A few from the list',
    companies_form_constructor_show_additional_field: 'Show additional field',
    companies_form_constructor_show_name_input_field: 'Show name input field',
    companies_form_constructor_show_text_in_two_rows: 'Show text in two rows',
    companies_form_constructor_signature_label: 'Signature',
    companies_form_constructor_signature_max_label: 'max',
    companies_form_constructor_signature_min_label: 'min',
    companies_form_constructor_start_page_button_width:
      'Start page button width',
    companies_form_constructor_status_label: 'Status',
    companies_form_constructor_step_text: 'Step text',
    companies_form_constructor_step_text_color: 'Step text color',
    companies_form_constructor_step_text_placeholder: 'Write step text',
    companies_form_constructor_step_text_underline_color:
      'Step text underline color',
    companies_form_constructor_step_text_underline_color_placeholder:
      'Select step text underline color',
    companies_form_constructor_submit_button: 'Save form',
    companies_form_constructor_tab_diagram: 'Diagram',
    companies_form_constructor_tab_field: 'Field',
    companies_form_constructor_tab_fields: 'Fields',
    companies_form_constructor_tab_settings: 'Settings',
    companies_form_constructor_text: 'Text...',
    companies_form_constructor_thank_you_page_field_title: 'Thank you page',
    companies_form_constructor_thank_you_page_text: 'Thank you page text',
    companies_form_constructor_thank_you_page_text_first_line:
      'Thank you page text first line',
    companies_form_constructor_thank_you_page_text_second_line:
      'Thank you page text second line',
    companies_form_constructor_thank_you_page_title: 'Thank you page title',
    companies_form_constructor_title: 'Form constructor',
    companies_form_constructor_title_additional_field_placeholder: 'Another',
    companies_form_constructor_title_additional_field_placeholder_title:
      'Additional field placeholder',
    companies_form_constructor_title_placeholder:
      'We would be happy if you leave a review',
    companies_form_constructor_title_placeholder_title: 'Placeholder',
    companies_form_constructor_title_title: 'Caption',
    companies_form_constructor_upload_files_label:
      'Choose what types of file uploads will be available on the form page',
    companies_form_constructor_upload_files_placeholder:
      'Explanation that it is possible to attach files to a form',
    companies_form_constructor_upload_files_title: 'File upload',
    companies_form_download_qr: 'Download QR',
    companies_form_scale_constructor_change_background_checkbox_label:
      'Change background when rating changes',
    companies_form_scale_constructor_change_background_select_label:
      'Background color',
    companies_form_scale_constructor_change_background_select_placeholder:
      'Choose a color',
    companies_form_scale_constructor_change_mascot_checkbox_label:
      'Change mascot when rating changes',
    companies_form_scale_constructor_change_mascot_select_label: 'Mascots',
    companies_form_scale_constructor_change_mascot_select_placeholder:
      'Choose a mascot',
    companies_form_scale_constructor_choose_color_placeholder: 'Choose a color',
    companies_form_scale_constructor_file_not_uploaded: 'file not uploaded',
    companies_form_scale_constructor_main_gradient_colors_label:
      'Basic gradient colors',
    companies_form_scale_constructor_maximum_value_label: 'Maximum value',
    companies_form_scale_constructor_maximum_value_placeholder:
      'Enter the maximum value',
    companies_form_scale_constructor_minimum_value_label:
      'Enter a default value',
    companies_form_scale_constructor_minimum_value_placeholder: 'Default value',
    companies_form_scale_constructor_numbers_chosen_colors_label:
      'The color of the selected answer',
    companies_form_scale_constructor_numbers_colors_label: 'Color of numbers',
    companies_form_scale_constructor_pointer_color_label: 'Pointer color',
    companies_form_scale_constructor_show_default_value: 'Show default value',
    companies_form_scale_constructor_signature_text_color_label:
      'Signature text color',
    companies_form_scale_constructor_star_chosen_color_label:
      'The color of the selected star',
    companies_form_scale_constructor_star_not_chosen_color_label:
      'The fill color of the unselected star',
    companies_form_scale_constructor_star_not_chosen_contour_color_label:
      'Outline star outline color',
    companies_form_scale_constructor_tab_title: 'Scale',
    companies_form_scale_constructor_title: 'Scale constructor',
    companies_form_select_color_placeholder: 'Select color',
    companies_form_show_node_code: 'Show node code',
    companies_form_table_add_form_button: 'Add form',
    companies_form_table_constructor_button: 'Form constructor',
    companies_form_table_copy_button: 'Copying a Form',
    companies_form_table_copy_confirm_text:
      'Are you sure you want to copy the form?',
    companies_form_table_delete_button: 'Deleting a Form',
    companies_form_table_delete_confirm_text:
      'Are you sure you want to delete the form?',
    companies_form_table_edit_button: 'Edit',
    companies_form_table_header_feedbacks: 'Reviews',
    companies_form_table_header_last_edit: 'Last modified',
    companies_form_table_header_link: 'Link',
    companies_form_table_header_name: 'Form name',
    companies_form_table_header_status: 'Status',
    companies_form_table_new_form_language_placeholder:
      'Select new form language',
    companies_form_table_new_form_language_title: 'New form language',
    companies_form_table_new_form_name_placeholder: 'Enter new form name',
    companies_form_table_new_form_name_title: 'New form name',
    companies_form_table_new_node_language_placeholder:
      'Select new node language',
    companies_form_table_new_node_language_title: 'New node language',
    companies_form_table_new_node_name_placeholder: 'Enter new node name',
    companies_form_table_new_node_name_title: 'New node name',
    companies_form_table_node_to_copy: 'Where do you want to copy the form?',
    companies_form_table_thank_button: 'Thank-you-page',
    companies_form_table_title: 'Company node feedback forms',
    companies_form_use_as_default_form: 'Use as default form',
    companies_form_with_steps: 'Form with steps',
    companies_info_add_field_button: 'Add field',
    companies_info_after_save_modal_confirm_button: 'Edit company data',
    companies_info_after_save_modal_title:
      'The company was successfully established',
    companies_info_choose_file_button: 'Select a file',
    companies_info_company_domain_label: 'Company domain',
    companies_info_company_domain_placeholder: 'Enter company domain',
    companies_info_company_extra_field_name_placeholder: 'Enter field name',
    companies_info_company_extra_field_value_placeholder: 'Enter field value',
    companies_info_company_name_label: 'The name of the company',
    companies_info_company_name_placeholder: 'Enter company name',
    companies_info_confirmation_before_save_modal_title:
      'Are you sure that you want to edit company information?',
    companies_info_file_max_size: 'JPG GIF or PNG. Maximum size 800K',
    companies_info_file_not_selected: 'File not selected',
    companies_info_submit_create_button: 'Save the company',
    companies_info_submit_edit_button: 'Save changes',
    companies_info_tab_base_info: 'Basic information',
    companies_info_tab_design: 'Design',
    companies_info_tab_documents: 'Documents',
    companies_info_tab_issues_statuses_and_types: 'Task statuses and types',
    companies_info_tab_review_table_columns_settings: 'Columns settings',
    companies_info_tab_sources: 'Sources',
    companies_info_tab_templates: 'Templates',
    companies_info_title_add: 'Add company',
    companies_info_title_edit: 'Edit company',
    companies_issue_statuses_and_types_add_status_button: 'Add status',
    companies_issue_statuses_and_types_add_status_placeholder:
      'Enter status name',
    companies_issue_statuses_and_types_statuses_text: 'Statuses',
    companies_issue_statuses_and_types_type_add_button: 'Add type',
    companies_issue_statuses_and_types_type_add_placeholder: 'Enter type name',
    companies_issue_statuses_and_types_types_text: 'Types',
    companies_issues_and_types_confirmation_before_save_modal_title:
      'Are you sure you want to edit tasks statuses and types?',
    companies_main_add_company_button: 'Add company',
    companies_main_delete_description:
      'Are you sure you want to delete the company?',
    companies_main_delete_title: 'Deleting a company',
    companies_main_forms_button: 'Contact forms',
    companies_main_structure_button: 'Structure',
    companies_main_table_company_name_header: 'Company',
    companies_main_title: 'Companies',
    companies_review_table_columns_settings_title: 'Columns',
    companies_review_title: 'Review',
    companies_reviews_table_header_button_details: 'Details',
    companies_reviews_table_header_contact: 'Channel',
    companies_reviews_table_header_contact_type: 'Communication type',
    companies_reviews_table_header_create: 'Date of creation',
    companies_reviews_table_header_estimate: 'Score',
    companies_reviews_table_header_files: 'Attached files',
    companies_reviews_table_header_key_answer: 'Key answer',
    companies_reviews_table_header_language: 'Language',
    companies_reviews_table_header_location: 'Locations',
    companies_reviews_table_header_source: 'Source',
    companies_reviews_table_header_status: 'Status',
    companies_reviews_table_title: 'Reviews',
    companies_source_must_be_unique_text: 'must be unique',
    companies_source_name_label: 'Name of the source',
    companies_source_name_max_length_error:
      'The source name must be less than 100 characters',
    companies_sources_add_source_button: 'Add source',
    companies_sources_confirmation_before_save_modal_title:
      'Are you sure you want to edit sources?',
    companies_sources_create_new_source_title: 'Create new source',
    companies_sources_create_source_text: 'Create source',
    companies_sources_placeholder: 'Enter source name',
    companies_sources_title: 'Sources',
    companies_structure: 'Structure',
    companies_structure_create_accountable: 'Responsible(s)',
    companies_structure_create_address_label: 'The address',
    companies_structure_create_address_placeholder: 'Enter address',
    companies_structure_create_advanced_settings: 'Advanced settings',
    companies_structure_create_edit_title: 'Edit node',
    companies_structure_create_email: 'E-mail',
    companies_structure_create_google_place_id_label: 'Google Place ID',
    companies_structure_create_integration_alpha_name_label: 'Alpha-name',
    companies_structure_create_integration_key_label: 'Key',
    companies_structure_create_integration_title: 'Integration settings',
    companies_structure_create_modal_title:
      'Are you sure what you want to create node?',
    companies_structure_create_node_code_label: 'Node code',
    companies_structure_create_node_code_placeholder: 'Enter node code',
    companies_structure_create_node_name_label: 'Node name',
    companies_structure_create_node_name_placeholder: 'Enter node name',
    companies_structure_create_sms: 'SMS',
    companies_structure_create_telegram: 'Telegram',
    companies_structure_create_tied_form_placeholder: 'Form',
    companies_structure_create_tied_node_label: 'Tied node',
    companies_structure_create_tied_node_placeholder: 'Node',
    companies_structure_create_title: 'Create node',
    companies_structure_create_viber: 'Viber',
    companies_structure_lang_delay: 'Use an expiration timer',
    companies_structure_lang_detection: 'Use automatic form language detection',
    companies_structure_table_accountable: 'Responsible',
    companies_structure_table_add_node_button: 'Add node',
    companies_structure_table_delete_description_1:
      'Are you sure you want to delete the node',
    companies_structure_table_delete_description_2: 'company structures',
    companies_structure_table_delete_description_3:
      'This will remove all nested nodes',
    companies_structure_table_delete_title: 'Deleting a company structure node',
    companies_structure_table_header_name: 'Nodes',
    companies_structure_title: 'Company structure',
    companies_structure_update_modal_title:
      'Are you sure what you want to edit node?',
    company: 'Company',
    company_main_info_delete_title: 'Removal of the company',
    company_main_info_edit_title:
      'Edit basic information about the company \ncompany designcompany documents',
    company_main_info_go_to_company_structure: 'Go to company structure',
    company_structure_delete_button_title: 'Deleting a node',
    company_structure_edit_button_title: 'Node editing',
    confirm_button: 'Confirm',
    connect_button: 'To plug',
    continue_editing_button: 'Continue editing',
    continue_review_dialog_button: 'Continue the dialogue',
    copy_button: 'Copy',
    copy_error: '* An error occurred while copying',
    create_button: 'Create',
    data_save_error: '* An error occurred while saving data',
    delete_button: 'Delete',
    delete_error: '* An error occurred while deleting',
    document: 'Document',
    done_button: 'Done',
    edit_button: 'Edit',
    email: 'Email',
    email_label: 'Email',
    email_placeholder: 'Enter email',
    employee_profile_title: 'Employee profile',
    enter_button: 'Enter',
    enter_forgot_button: 'Recover password',
    enter_task_name: 'Enter task name',
    error: '* An error occurred',
    error_text: 'Error text',
    error_title: 'Error',
    expecting_reply_from_client: 'Waiting for client’s answer',
    filter_title_sources: 'Sources',
    filter_title_status: 'Statuses',
    filters: 'Filters',
    finish_review_dialog_button: 'Finish the dialogue',
    finish_review_dialog_modal_description:
      'Are you sure you want to finish the dialogue?',
    finish_review_dialog_modal_title: 'Finish the dialogue',
    form: 'Form',
    form_constructor_connection_case_label: 'Option',
    form_constructor_connection_case_placeholder: 'Select option',
    form_constructor_connection_go_to: 'go to',
    form_constructor_connection_render_minifield_connection_always: 'Always',
    form_constructor_connection_render_minifield_connection_type_texts_always:
      'All other cases',
    form_constructor_connection_render_minifield_connection_type_texts_if:
      'If is',
    form_constructor_connection_select_field: 'Select field',
    form_constructor_connection_select_with_any_items_label: 'Next field',
    form_constructor_connection_select_with_any_items_options_1_question:
      'Not selected',
    form_constructor_connection_select_with_any_items_placeholder:
      'Select field',
    form_constructor_connection_statement_label: 'Rule',
    form_constructor_connection_statement_placeholder: 'Select rule',
    form_constructor_delete_field_modal_description:
      'Are you sure you want to delete this field?',
    form_constructor_delete_field_modal_title: 'Warning!',
    form_constructor_diagram_fields_list_is_empty: 'Fields list is empty',
    form_constructor_fields_form_content: 'Content',
    form_constructor_fields_form_documents: 'Documents',
    form_constructor_fields_form_no_fields_selected: 'No selected field',
    form_constructor_fields_form_send_button: 'Send button',
    form_constructor_fields_form_thank_you_page: 'Thank you page',
    form_copy_save_error:
      '* A form with the same name already exists on the selected node',
    form_language_error: '* You already have form with selected language',
    form_name_error: '* Form name should be unique',
    form_save_error: '* An error occurred while saving the form',
    from: 'From',
    full_name_label: 'Full name of the employee',
    full_name_placeholder: 'Enter employee name',
    generate_button: 'Generate',
    go_to_forms_page_button_title: 'Go to feedback forms',
    go_to_issues_create: 'Go to task create',
    go_to_reviews_page_button_title: 'Go to reviews',
    go_to_task_create: 'Go to task create',
    in_progress: 'In progress',
    issues_add_issue: 'Add an task',
    issues_form_company_label: 'Company',
    issues_form_company_placeholder: 'Select a company',
    issues_form_connect_issue_label: 'Connect task',
    issues_form_connect_issue_placeholder: 'Select a task',
    issues_form_create_new_issue_status: 'Create new task status',
    issues_form_create_new_issue_status_title: 'Create status',
    issues_form_create_new_issue_type: 'Create new task type',
    issues_form_create_new_issue_type_title: 'Create type',
    issues_form_must_be_unique: 'must be unique',
    issues_form_name_label: 'Name of task',
    issues_form_name_placeholder: 'Enter form name',
    issues_form_node_label: 'Node',
    issues_form_node_placeholder: 'Select a node',
    issues_form_priority_label: 'Priority of task',
    issues_form_priority_placeholder: 'Select a priority',
    issues_form_responsible_label: 'Responsible',
    issues_form_responsible_placeholder: 'Select a responsible',
    issues_form_review_label: 'Review',
    issues_form_review_placeholder: 'Select a review',
    issues_form_status_label: 'Status of task',
    issues_form_status_name_label: 'Name of task status',
    issues_form_status_placeholder: 'Select a status',
    issues_form_type_label: 'Type of task',
    issues_form_type_name_label: 'Name of task type',
    issues_form_type_placeholder: 'Select a type of task',
    issues_save_modal_create_title:
      'Are you sure what you want to create task?',
    issues_save_modal_edit_title: 'Are you sure what you want to edit task?',
    issues_search_by_name: 'Search task by name',
    issues_sub_header_create_issue_title: 'Create task',
    issues_sub_header_create_task_title: 'Creating a task',
    issues_sub_header_edit_issue_title: 'Edit task',
    issues_sub_header_title: 'Tasks',
    issues_table_empty: 'Select company to display tasks',
    issues_table_header_company: 'Company',
    issues_table_header_date: 'Date of creation',
    issues_table_header_name: 'Name',
    issues_table_header_node: 'Node',
    issues_table_header_priority: 'Priority',
    issues_table_header_status: 'Status',
    issues_table_header_type: 'Task type',
    language: 'Language',
    languages_add_name_placeholder: 'Enter name',
    languages_add_short_name_label: 'Short name',
    languages_add_short_name_placeholder: 'Enter short name',
    languages_add_title: 'Adding a language',
    languages_table_add_language_button: 'Add language',
    languages_table_header_full_name: 'Full name',
    languages_table_header_short_name: 'Short name',
    languages_table_modal_delete_buttons_label_end: 'language?',
    languages_table_modal_delete_buttons_label_start:
      'Are you sure you want to delete',
    languages_table_modal_delete_title: 'Deleting language',
    languages_table_title: 'Languages',
    leave_the_page: 'Leave the page',
    loading_error: '* An error occurred while loading data',
    login_recovery_auth: 'Return',
    login_recovery_complete:
      'A message with instructions on how to reset your password will be sent to your email address shortly',
    mass_mailing_type_by_addresses: 'Address mailing',
    mass_mailing_type_by_company: 'By company',
    mass_mailing_confirmation_modal_subtitle1:
      'Not all possible data found in the table: ',
    mass_mailing_confirmation_modal_subtitle2:
      '. Are you sure you want to continue the mailing?',
    mass_mailing_confirmation_modal_title: 'Are you sure?',
    max_size_error: '* Maximum allowed file size exceeded',
    max_size_error_25: '* Maximum allowed file size is 25Mb',
    max_table_column_settings_apply_warning: 'Max 6 active settings',
    more_detailed: 'More detailed',
    name: 'Name',
    new_task_name: 'New task name',
    next_button: 'Next',
    no_options_available: 'No options available',
    node: 'Node',
    non_submit_description: 'Please return and save changes before closing,',
    non_submit_title: 'You have not saved your changes',
    not_decided: 'Not solved',
    not_viewed: 'Not viewed',
    nothing_find: 'Not found',
    login_recovery_password_title: 'Recovery of a lost password',
    open_issue_title: 'Open task',
    open_language_title: 'Open language',
    open_permission_title: 'Open permission',
    open_review_title: 'Open review',
    open_role_title: 'Open role',
    open_user_title: 'Open user',
    other: 'Other',
    overdue_status_text: 'Прострочені відгуки',
    pagination_page_number_placeholder: 'Page number',
    pagination_title: 'Lines per page',
    password: 'Password',
    password_confirmation_label: 'Repeat password',
    password_confirmation_placeholder: 'Enter password again',
    password_label: 'Password',
    password_placeholder: 'Enter password',
    permission_create_title: 'Create permission',
    permission_edit_title: 'Edit permission',
    permission_name_placeholder: 'Enter permission name',
    permission_name_title: 'Permission name',
    permission_resource_placeholder: 'Enter permission resource',
    permission_resource_title: 'Resource',
    permissions_main_add_permission_button: 'Add permission',
    permissions_main_delete_description:
      'Are you sure you want to delete the permission?',
    permissions_main_delete_title: 'Deleting a permission',
    permissions_main_permissions_button: 'Permissions',
    permissions_main_table_create_header: 'Create',
    permissions_main_table_delete_header: 'Delete',
    permissions_main_table_permission_header: 'Permission name',
    permissions_main_table_read_header: 'Read',
    permissions_main_table_resource_header: 'Resource',
    permissions_main_table_update_header: 'Update',
    permissions_main_title: 'Permissions',
    phone: 'Phone',
    phone_number_label: 'Phone number',
    phone_number_placeholder: 'Enter phone number',
    photo: 'Photo',
    preview_button: 'Preview',
    privacy_policy: 'user agreement',
    privacy_policy_confirm: 'I agree with',
    record: 'Audio',
    refresh_all_fitlers: 'Clear all filters',
    registration_profile_completion: 'Profile completion',
    reopened: 'Opened again',
    reset_reviews_filter: 'Reset',
    reset_table_settings: 'Reset table settings',
    review_choose_template: 'Choose a response template',
    review_created_at: 'Review date and time',
    review_dialog_finished_text: 'The dialogue was finished',
    review_form_name: 'Name of form',
    review_form_source: 'Form source',
    review_handle_continue_dialog_error_message:
      'Something is wrong. Please, try again later.',
    review_message_input_placeholder: 'Write your message',
    review_no_asscociated_issues: 'No associated tasks',
    review_no_associated_tasks: 'No associated tasks',
    review_node_code: "Node's code",
    review_node_name: "Node's name",
    review_on_submit_3_error_message:
      'The client already has a dialogue with another manager. Please, try again later.,',
    review_open_task_button_text: 'Open task',
    review_page_type_of_task: 'Type of task',
    review_review_id: 'Review id',
    review_save_modal_title: 'Are you sure that you want to edit review?',
    review_updated_at: 'Processing date,',
    reviews_page_choose_company_title:
      'Select a company from the drop-down list',
    reviews_table_comment_column_title: 'Comment',
    reviews_table_email_column_title: 'Email',
    reviews_table_filter_title: 'Filter',
    reviews_table_key_question_column_title: 'Key question',
    reviews_table_tags_column_title: 'Tags',
    reviews_table_categories_column_title: 'Categories',
    reviews_table_node_column_title: 'Node',
    reviews_table_parent_node_column_title: 'Parent node',
    reviews_table_phone_column_title: 'Phone number',
    reviews_table_review_id_column_title: 'Review ID',
    reviews_table_ukrposhta_settings_button: 'Unprocesed reviews',
    reviews_table_user_name_column_title: 'Name',
    reviewType: 'Review type',
    role_create_title: 'Create role',
    role_edit_title: 'Edit role',
    role_name_placeholder: 'Enter role name',
    role_name_title: 'Role name',
    role_page_create_title: 'Create role',
    role_permissions: 'Permissions',
    role_select_permissions_placeholder: 'Select permissions',
    roles_main_add_role_button: 'Add role',
    roles_main_delete_description: 'Are you sure you want to delete the role?',
    roles_main_delete_title: 'Deleting a role',
    roles_main_permissions_button: 'Permissions',
    roles_main_table_user_role_header: 'Role name',
    roles_main_title: 'Roles',
    roles_part_analytic: 'Analytic',
    roles_part_companies: 'Companies',
    roles_part_documents: 'Documents',
    roles_part_forms: 'Forms',
    roles_part_languages: 'Languages',
    roles_part_mass_mailings: 'Масові розсилки',
    roles_part_nodes: 'Nodes',
    roles_part_reviews: 'Reviews',
    roles_part_users: 'Users',
    roles_sub_permission_create: 'Create',
    roles_sub_permission_delete: 'Delete',
    roles_sub_permission_read: 'Read',
    roles_sub_permission_update: 'Update',
    save_button: 'Save',
    save_changes_button: 'Save Changes',
    save_document_button: 'Save',
    save_draft_button: 'Save draft',
    save_field_button: 'Save field',
    save_form_button: 'Save form',
    save_form_without_changes_button: 'Save form without changes',
    search: 'Search...',
    search_for_name: 'Search by name',
    search_for_email: 'Search by email',
    select_company: 'Select company',
    select_form: 'Select form',
    select_language: 'Select language',
    select_multiple_sources: 'Select sources',
    select_multiple_statuses: 'Select statuses',
    select_node: 'Select node',
    select_status: 'Select status',
    select_type: 'Select type',
    send_button: 'Send',
    sidebar_analytic: 'Analytics',
    sidebar_companies: 'Companies',
    sidebar_exit: 'Quit profile',
    sidebar_issues: 'Tasks',
    sidebar_languages: 'Languages',
    sidebar_profile: 'Profile',
    sidebar_reviews: 'Reviews',
    sidebar_tasks: 'Tasks',
    sidebar_users: 'Users',
    sms: 'SMS',
    social_networks_module_text:
      'Choose a convenient way for you to communicate with us',
    solution_not_required: 'Solution \n not required',
    solved: 'Solved',
    source: 'Source',
    status: 'Status of review',
    status_code: 'Status code',
    table_columns_settings_comment_option: 'Comment',
    table_columns_settings_communication_channel_option: 'Communication type',
    table_columns_settings_company_option: 'Company',
    table_columns_settings_created_at_option: 'Created at',
    table_columns_settings_email_option: 'Email',
    table_columns_settings_estimation_option: 'Score',
    table_columns_settings_key_question_option: 'Key question',
    table_columns_settings_categories_option: 'Categories',
    table_columns_settings_tags_option: 'Tags',
    table_columns_settings_language_option: 'Language',
    table_columns_settings_name_option: 'Name',
    table_columns_settings_node_option: 'Node',
    table_columns_settings_parent_node_option: 'Parent node',
    table_columns_settings_phone_option: 'Phone number',
    table_columns_settings_reviewId_option: 'Review ID',
    table_columns_settings_source_option: 'Source',
    table_columns_settings_status_option: 'Status',
    table_settings: 'Table settings',
    task_form_category_label: 'Category of task',
    task_form_create_task: 'Create task',
    task_form_list_category_label: 'Category',
    task_form_list_subcategory_label: 'Subcategory',
    task_form_list_type_label: 'Type',
    task_form_name_label: 'Name of the task',
    task_form_priority_label: 'Task priority',
    task_form_status_label: 'Task status',
    task_form_subcategory_label: 'Subcategory of task',
    task_form_type_label: 'Type of task',
    telegram: 'Telegram',
    to: 'To',
    to_plug_button: 'To plug',
    turn_off_button: 'Disable',
    upload: 'Upload',
    upload_color_button: 'Upload color',
    uploading_files: 'Uploading files',
    user_email_placeholder: 'Enter email',
    user_email_title: 'Email',
    user_name_placeholder: 'Enter name',
    user_name_title: 'First and last name',
    user_new_password_placeholder: 'Enter new password',
    user_new_password_title: 'New password',
    user_old_password_placeholder: 'Enter old password',
    user_old_password_title: 'Old password',
    user_page_create: 'Create user',
    user_page_edit: 'Edit user',
    user_password_confirmation_placeholder: 'Repeat password',
    user_password_confirmation_title: 'Password confirmation',
    user_password_placeholder: 'Enter password',
    user_password_title: 'Password',
    user_role_placeholder: 'Select user roles',
    user_role_title: 'Roles',
    users_main_add_user_button: 'Add user',
    users_main_delete_description: 'Are you sure you want to delete the user?',
    users_main_delete_title: 'Deleting a user',
    users_main_roles_button: 'Roles',
    users_main_table_user_company_header: 'Company',
    users_main_table_user_email_header: 'Email',
    users_main_table_user_name_header: 'Name',
    users_main_table_user_notifications_header: 'Notifications',
    users_main_table_user_role_header: 'Role',
    users_main_title: 'Users',
    users_main_upload_users_button: 'Upload users',
    validation_constructor_at_least_2_possible_answers:
      '* Question must have at least 2 possible answers',
    validation_constructor_should_have_at_least_1_document:
      '* Form should have min one document',
    validation_constructor_should_select_file_types:
      '* You must select file types allowed for upload or remove this field from the form',
    validation_domain:
      "The domain can contain only English characters numbers '.' and '-'",
    validation_form_should_have_minimum_1:
      '* Form should have minimum one field',
    validation_full_type_required: '* You must select each parts of type',
    validation_invalid_email: '* Invalid email format',
    validation_invalid_link: '* Invalid link format',
    validation_more_one_accountable:
      '* You must select at least 1 node manager',
    validation_more_one_option: '* You must select at least 1 option',
    validation_must_fill_all_fields_for: '* You must complete all fields for',
    validation_must_fill_this_field: '* You must complete this field',
    validation_name_must_be_longer_4:
      '* The name is too short - must be at least 4 characters long.',
    validation_required: '* Required',
    viber: 'Viber',
    video: 'Video',
    viewed: 'Viewed',
    your_changes_lose:
      'You need to save the changes. Otherwise they will be lost.',
    send_feedback: 'Send feedback',
    send_feedback_title: 'Feedback',
    send_feedback_label: 'Your wishes or comments',
    send_feedback_complete: 'Thank you for your feedback',
    sidebar_impex: 'Import/Export',
    impex_page_title: 'Import/Export',
    impex_import_tab: 'Import',
    impex_export_tab: 'Export',
    select_import_entity: 'What do you want to import?',
    start_import_button_text: 'Start import',
    choose_file_for_import: 'Choose file for import',
    import_entity_selection_title: 'To import',
    import_file_sheet_name_label: 'The name of the sheet in the import file',
    import_file_sheet_name_input_placeholder: 'Enter name of the sheet',
    interrupt_import_script_button_text: 'Interrupt import',
    import_file_format_text: 'Format must be:',
    import_progress_bar_title: 'Import progress:',
    import_users_entity_name: 'Users',
    import_nodes_entity_name: 'Nodes',
    qr_title: 'Scan the QR to further customize notifications',
    mass_mailings_channels_of_connections: 'Channels of connection',
    mass_mailings_send_button: 'Send',
    yes_button_text: 'Yes',
    no_button_text: 'No',
    confirm_send_mass_mailings_modal_title:
      'Are you sure you want to make mass mailings?',
    modal_mass_mailings_sent_success_title: 'Messages sent successfully',
    ok_button_text: 'Ok',
    mass_mailings_error_text: 'Error sending messages',
    mass_mailings_recipient_label: 'Recipient',
    mass_mailings_recipients_list_title: 'Recipients',
    add_at_least_one_recipient_error: 'At least one recipient must be added',
    sidebar_mass_mailings: 'Масові розсилки',
    create_mass_mailings_page_title: 'Create mass mailings',
    create_mass_mailings_first_step_subheader_title:
      'Mass mailing - Creating - Step 1 of 3',
    choose_mass_mailings_communication_channel: 'Choose communication channel',
    mass_mailings_add_recipient_button_text: 'Add recipient',
    download_recipients_file_label: 'Download a file with recipients',
    format: 'Format',
    choose_file: 'Choose a file',
    file_data_downloading_text: 'File data downloading',
    mass_mailings_mails_count: 'The number of mails that will be sent',
    create_sms_mass_mailings_second_step_subheader_title:
      'Creating SMS-mailing - Step 2 of 3',
    create_viber_mass_mailings_second_step_subheader_title:
      'Creating Viber-mailing - Step 2 of 3',
    create_sms_mass_mailings_third_step_subheader_title:
      'Creating SMS-mailing - Step 3 of 3',
    create_viber_mass_mailings_third_step_subheader_title:
      'Creating Viber-mailing - Step 3 of 3',
    mass_mailings_sender_account: 'Sender account',
    sms_text: 'SMS text',
    message: 'Message',
    message_text_placeholder: 'Message text',
    responsible: 'Responsible',
    responsible_id: 'Responsible ID',
    companies_form_constructor_dividing_decorative_line:
      'Form dividing decorative line parameters',
    companies_form_constructor_show_form_dividing_decorative_line:
      "Show form's dividing decorative line",
    companies_form_constructor_dividing_decorative_line_width_in_percent:
      'Length of the decorative line in percent',
    companies_form_constructor_dividing_decorative_line_height:
      'Height of the decorative line in pixels',
    companies_form_constructor_dividing_decorative_line_height_placeholder:
      'Enter height of the decorative line',
    companies_form_constructor_dividing_decorative_line_color:
      'Decorative line color',
    companies_form_constructor_dividing_decorative_line_color_placeholder:
      'Choose decorative line color',
    export_entity_selection_title: 'To export',
    select_export_entity: 'What do you want to export?',
    export_button_text: 'To export',
    export_nodes_error_text: 'Nodes export error',
    export_users_error_text: 'Users export error',
    target: 'Current review',
    all: 'All history',
    quarter: 'Last 3 months',
    months: 'Last 1 months',
    days7: 'Last 7 days',
    telegram_network: 'Telegram',
    viber_network: 'Viber',
    phone_network: 'Phone',
    email_network: 'Email',
    whatsapp_network: 'WhatsApp',

    campaign_table_header_state: 'State',
    campaign_table_header_node: 'Node',
    campaign_table_header_district: 'Region',
    campaign_table_header_visited_at: 'Visit date',
    campaign_table_header_service_name: 'Service name',
    campaign_table_header_service_status: 'Service status',
    campaign_table_header_service_result: 'Service result',
    campaign_table_header_service_in_time: 'Performance result',
    campaign_table_header_client_gender: 'Gender',
  },
  UA: {
    itemsFailed: 'Не вдалося',
    itemsProcessed: 'Processed',
    itemsOpened: 'Відкрито',
    itemsUnsubscribed: 'Unsubscribed',
    itemsSent: 'Відправлено',
    itemsTotal: 'Всього',
    itemAccepted: 'Прийнято',
    itemsFormOpened: 'Відкрили форму',
    itemsFormSubmitted: 'Відправили форму',
    itemsDelivered: 'Доставлено',

    try_again: 'Спробувати знову',
    invalid_succeed_function: 'Код відновлення не коректний або не дійсний',
    min_len_password: 'Мінімальна кількість символів 6',

    responsible_in_review: 'Відповідальний за відгук',
    sun: 'Неділя',
    mon: 'Понеділок',
    tue: 'Вівторок',
    wed: 'Середа',
    thu: 'Четвер',
    fri: "П'ятниця",
    sat: 'Cубота',
    not_activate_chat: 'Чат не активовано',
    tasks: 'Делегування',
    feedback_processing: 'Обробка відгуків',
    questions_and_answers: 'Питання та відповіді',
    detailed_feedback_information: 'Інформація про відгук',
    companies_form_constructor_title_hidden: 'Прихований ключ',
    companies_form_constructor_title_hidden_placeholder: 'Прихований ключ',
    companies_form_constructor_title_hidden_info:
      'Введіть ключ для прихованого типу введення',
    search_by_comment: 'Шукати за коментарем',
    enter_role_name: 'Введіть нову назву ролі',
    role_name: 'Нова назва ролі',
    enter_uniq_name: 'Введіть унікальне імя',
    enter_permissions_name: 'Введіть назву нового дозволу',
    permissions_name: 'Нова назва дозволу',
    copy_permissions: 'Копіювати дозвіл',
    pass_changed_success: 'Пароль змінено успішно',
    connect_tg: 'Підключити бот Telegram',
    connect_vb: 'Підключити бот Viber',
    reset_pass: 'Скинути пароль',
    notifiable_sources: 'Джерела сповіщень',
    notifications: 'Сповіщення',
    audit: 'Історія опрацювання',
    CREATE: 'Створити',
    UPDATE: 'Оновити',
    DELETE: 'Видалити',
    review_audit: 'Історія обробки відгуків',
    USER: 'Користувач',
    SYSTEM: 'Система',
    actor: 'Автор',
    action: 'Дія',
    search_by_phone: 'Пошук за номером телефону',
    search_by_reviewID: 'Пошук за ідентифікатором відгуку',
    chat_error_no_user:
      'Чат з користувачем не знайдено можливо він не підключився або видалив бота',
    chat_label: 'Чат із суб’єктом звернення',
    comment_internal: 'Нотатки',
    changes_not_saved_modal:
      'Зміни не збережено. Ви впевнені що хочете покинути сторінку?',
    go_back: 'Повернутися',
    all_items_uploaded: 'Всі елементи завантажено',
    login_title: 'Увійти',
    login_recovery_password: 'Відновлення пароля',
    add_comment: 'Додати коментар',
    add_button: 'Додати',
    add_option: 'Додати опцію',
    add_option_button: 'Додати варіант',
    apply_filters_button: 'Застосувати',
    apply_table_settings: 'Застосувати налаштування таблиці',
    are_you_sure: 'Ви впевнені?',
    attached_files: 'Прикріплені файли',
    bot_is_not_connected: 'Бот не підключений',
    cancel_button: 'Закрити',
    cant_decided: 'Вирішення неможливе',
    change_new_form_button: 'Змінити нову форму',
    changes_saved_successfully: 'Зміни успішно збережено',
    channels_for_notifications: 'Канали для сповіщень',
    channels_of_connections: 'Канали зєднання',
    chat_error_description: 'Під час надсилання повідомлення сталася помилка',
    chat_history_is_empty: 'Історія чату порожня',
    chat_is_finished: 'Чат завершено',
    choose_file_button: 'Вибрати файл',
    choose_period: 'Виберіть період',
    client_form_scale_field_h3: 'Ваше враження про наш суб’єкт моніторингу',
    close_button: 'Закрити',
    close_editing_button: 'Закрити редагування',
    closed_auto_no_replay: 'Закрито автоматично через відсутність відповіді',
    color_picker_add_button: 'Додати колір',
    comment_placeholder: 'Введіть коментар',
    companies_columns_settings_confirmation_before_save_modal_title:
      'Ви впевнені що хочете змінити налаштування колонок на сторінці відгуків?',
    companies_design_add_color_button: 'Додати колір',
    companies_design_add_color_placeholder: 'Введіть назву кольору',
    companies_design_add_font_button: 'Додати шрифт',
    companies_design_add_font_placeholder: 'Введіть назву шрифту',
    companies_design_colors_text: 'Кольори',
    companies_design_confirmation_before_save_modal_title:
      'Ви впевнені що хочете редагувати інформацію про суб’єкт моніторингу?',
    companies_design_fonts_text: 'Шрифти',
    companies_design_logos_add_button: 'Завантажити файл',
    companies_design_logos_add_placeholder: 'Введіть назву логотипу',
    companies_design_logos_text: 'Зображення',
    companies_documents_add_localization: 'Локалізація',
    companies_documents_add_name_label: 'Назва',
    companies_documents_add_name_placeholder: 'Введіть назву документа',
    companies_documents_add_title: 'Додавання документа',
    companies_documents_add_title_editing: 'Редагування документа',
    companies_documents_file_caption: 'Файл',
    companies_documents_file_format: 'Формат',
    companies_documents_file_max_size: 'Максимальний розмір файлу',
    companies_documents_file_not_loaded: 'не завантажено',
    companies_documents_file_save_button: 'Завантажити файл',
    companies_documents_file_text_editor_label: 'Текст документа',
    companies_documents_file_text_editor_placeholder: 'Введіть текст документа',
    companies_documents_table_add_document_button: 'Додати документ',
    companies_documents_table_delete_text:
      'Ви впевнені що хочете видалити документ?',
    companies_documents_table_delete_title: 'Видалення документа',
    companies_documents_table_header_edit_date: 'Дата зміни',
    companies_documents_table_header_edit_languages: 'Мови',
    companies_documents_table_header_name: 'Назва',
    companies_form_add_anonymous_checkbox_no:
      'Жодна відповідь не може бути анонімною',
    companies_form_add_anonymous_checkbox_yes:
      'Так відповіді можуть бути анонімними',
    companies_form_add_anonymous_label: 'Можливість залишати анонімні відгуки',
    companies_form_add_create_title: 'Додати форму зворотного звязку',
    companies_form_add_edit_title: 'Редагування форми зворотного звязку',
    companies_form_add_link_label: 'Посилання на форму',
    companies_form_add_link_placeholder: 'Введіть посилання на форму заявки',
    companies_form_add_main_language_label: 'Основна мова',
    companies_form_add_main_language_placeholder: 'Не вибрано',
    companies_form_add_multi_languages_label: 'Багатомовна',
    companies_form_add_multi_languages_placeholder: 'Вибір мов',
    companies_form_add_name_label: 'Назва форми заявки',
    companies_form_add_name_placeholder: 'Введіть назву анкети',
    companies_form_add_status_not_published: 'Не опубліковано',
    companies_form_add_status_published: 'Опублікована',
    companies_form_add_status_title: 'Статус',
    companies_form_add_tied_node_label: 'Привязаний вузол',
    companies_form_add_tied_node_label_second: 'Другий відповідальний у вузлі',
    companies_form_add_tied_node_placeholder: 'Вузол',
    companies_form_constructor_add_an_option: 'Додати опцію',
    companies_form_constructor_add_document_button: 'Додати документ',
    companies_form_constructor_add_field_to_series: 'Додати до серії полів',
    companies_form_constructor_add_question_button: 'Додати питання',
    companies_form_constructor_background_color: 'Фон',
    companies_form_constructor_background_color_for_text_title:
      'Колір фону для тексту',
    companies_form_constructor_background_color_title: 'Колір фону',
    companies_form_constructor_caption_checkbox_label: 'Вибрати документ',
    companies_form_constructor_caption_placeholder:
      'Виберіть потрібний документ',
    companies_form_constructor_caption_title: 'Політика конфіденційності',
    companies_form_constructor_checked_elements_border_radius_title:
      'Відмічені перемикачі радіусу межі',
    companies_form_constructor_checked_elements_checked_size_title:
      'Перевірений розмір перемикача',
    companies_form_constructor_choice_description_info:
      'Ви можете відобразити текстове поле якщо хочете щоб користувач розширив відповідь коли вибрав цю опцію',
    companies_form_constructor_choice_description_placeholder:
      'Заповнювач опису вибору...',
    companies_form_constructor_choice_description_required:
      'Потрібен опис вибору',
    companies_form_constructor_choice_description_title:
      'Заголовок опису вибору...',
    companies_form_constructor_choose_form_font_placeholder:
      'Виберіть шрифт анкети',
    companies_form_constructor_choose_logo_button: 'Виберіть логотип',
    companies_form_constructor_choose_logo_label: 'Логотип',
    companies_form_constructor_choose_logo_title:
      'Виберіть логотип із завантажених зображень суб’єкту моніторингу',
    companies_form_constructor_choose_main_mascot_button: 'Виберіть талісман',
    companies_form_constructor_choose_main_mascot_label: 'Головний талісман',
    companies_form_constructor_choose_main_mascot_title:
      'Виберіть талісман із завантажених зображень суб’єкту моніторингу',
    companies_form_constructor_chose_field_placeholder: 'Виберіть поле',
    companies_form_constructor_communication_channels_call: 'Дзвінок',
    companies_form_constructor_communication_channels_checkbox_auto_answer_label:
      'Автовідповідь',
    companies_form_constructor_communication_channels_checkbox_auto_answer_placeholder:
      'Введіть текст авто-відповіді',
    companies_form_constructor_communication_channels_email: 'Email',
    companies_form_constructor_communication_channels_label:
      'Виберіть які канали звязку будуть доступні на сторінці форми',
    companies_form_constructor_communication_channels_placeholder:
      'Пояснення що потрібно вибрати канал звязку для зворотного звязку',
    companies_form_constructor_communication_channels_sms: 'SMS',
    companies_form_constructor_communication_channels_sub_label:
      'Виберіть месенджери для відповідей',
    companies_form_constructor_communication_channels_telegram: 'Telegram',
    companies_form_constructor_communication_channels_whatsapp: 'WhatsApp',
    companies_form_constructor_communication_channels_title: 'Канали звязку',
    companies_form_constructor_communication_channels_viber: 'Viber',
    companies_form_constructor_communication_channels_without_answer:
      'Без відповіді',
    companies_form_constructor_country_code_not_editable:
      'Код країни не редагується',
    companies_form_constructor_disabled_send_button_background_color:
      'Відключений колір фону кнопки',
    companies_form_constructor_display_node_address: 'Відображати адресу вузла',
    companies_form_constructor_display_scale_background_color:
      'Відображати колір фону основної шкали',
    companies_form_constructor_display_scale_background_color_info:
      'Колір фону повинен залежати від обраного значення основної шкали',
    companies_form_constructor_display_scale_mascot:
      'Відображати талісман головної шкали',
    companies_form_constructor_display_scale_mascot_info:
      'Талісман повинен залежати від обраного значення основної шкали',
    companies_form_constructor_document_description:
      'Виберіть потрібний документ',
    companies_form_constructor_document_description_placeholder:
      'Опис документа',
    companies_form_constructor_document_empty_list:
      'Суб’єкт моніторингу не має документів',
    companies_form_constructor_document_placeholder: 'Виберіть документ',
    companies_form_constructor_document_title: 'Документ',
    companies_form_constructor_elements_border_width_title:
      'Ширина межі елементів',
    companies_form_constructor_elements_color_title: 'Колір елементів',
    companies_form_constructor_email_placeholder: 'Введіть ваш email',
    companies_form_constructor_email_title: 'Електронна пошта',
    companies_form_constructor_form_font_title: 'Шрифт анкети',
    companies_form_constructor_form_name_label: 'Назва форми',
    companies_form_constructor_form_name_placeholder: 'Введіть назву форми',
    companies_form_constructor_full_answer_placeholder:
      'Ми будемо раді якщо ви залишите e-mail для звязку',
    companies_form_constructor_full_answer_title: 'Розгорнута відповідь',
    companies_form_constructor_info_additional_field_placeholder:
      'У цьому полі введіть заповнювач для додаткового поля запитання яке ви хочете поставити респондентам',
    companies_form_constructor_info_placeholder:
      'У цьому полі введіть заповнювач запитання яке ви хочете поставити респондентам. companies_form_constructor_info_placeholder',
    companies_form_constructor_info_question:
      'У цьому полі введіть запитання яке ви хочете поставити респондентам',
    companies_form_constructor_info_scale_max:
      'У цьому полі введіть короткий опис для максимального значення шкали.',
    companies_form_constructor_info_scale_min:
      'У цьому полі введіть короткий опис для мінімального значення шкали.',
    companies_form_constructor_info_step_text:
      'У цьому полі введіть текст який ви хочете показати як текст кроку',
    companies_form_constructor_info_thank_you_page_text:
      'У цьому полі введіть текст який ви хочете відобразити на сторінці подяки.',
    companies_form_constructor_info_thank_you_page_title:
      'У цьому полі введіть заголовок який ви хочете відобразити на сторінці подяки.',
    companies_form_constructor_key_question: 'Ключове питання',
    companies_form_constructor_language_label: 'Мова форми',
    companies_form_constructor_language_placeholder: 'Мова',
    companies_form_constructor_localization_label:
      'Локалізація для всієї форми',
    companies_form_constructor_logo_place_center: 'По центру',
    companies_form_constructor_logo_place_label: 'Місце розташування логотипу',
    companies_form_constructor_logo_place_left: 'Зліва',
    companies_form_constructor_logo_place_right: 'Праворуч',
    companies_form_constructor_main_question:
      'Залиште свою оцінку суб’єкту моніторингу',
    companies_form_constructor_main_scale: 'Основна шкала',
    companies_form_constructor_mascot: 'Талісман',
    companies_form_constructor_max_placeholder: 'Надзвичайно неймовірно',
    companies_form_constructor_min_placeholder: 'Малоймовірно',
    companies_form_constructor_name_field_required:
      'Обовязкове поле для введення імені',
    companies_form_constructor_name_placeholder: 'Як вас звати',
    companies_form_constructor_name_title: 'Назва',
    companies_form_constructor_next_field: 'Наступне поле',
    companies_form_constructor_not_checked_elements_color_title:
      'Колір не позначених елементів',
    companies_form_constructor_one_from_list_placeholder:
      'Як давно ви відвідуєте наш заклад?',
    companies_form_constructor_one_from_list_title: 'Один зі списку',
    companies_form_constructor_phone_placeholder: 'Введіть свій номер телефону',
    companies_form_constructor_phone_title: 'Номер телефону',
    companies_form_constructor_prev_field: 'Попереднє поле',
    companies_form_constructor_privacy_policy_label:
      'Виберіть потрібний документ',
    companies_form_constructor_privacy_policy_placeholder: 'Виберіть документ',
    companies_form_constructor_privacy_policy_title:
      'Політика конфіденційності',
    companies_form_constructor_quantity_button_signs_color:
      'Колір підписів кнопок',
    companies_form_constructor_quantity_button_signs_color_placeholder:
      'Колір підпису кнопки',
    companies_form_constructor_quantity_buttons_background_color:
      'Колір фону кнопок',
    companies_form_constructor_quantity_buttons_background_color_placeholder:
      'Виберіть колір фону кнопок',
    companies_form_constructor_quantity_default_value_title:
      'Значення за замовчуванням',
    companies_form_constructor_quantity_max_value_title: 'Максимальне значення',
    companies_form_constructor_quantity_min_value_title: 'Мінімальне значення',
    companies_form_constructor_quantity_placeholder:
      'Скільки разів ви приходили до нас?',
    companies_form_constructor_quantity_title: 'Кількість',
    companies_form_constructor_required_question: 'Обовязкове питання',
    companies_form_constructor_scale_change_design_button:
      'Змінити дизайн шкали',
    companies_form_constructor_scale_title: 'Шкала',
    companies_form_constructor_select_field: 'Поле для вибору',
    companies_form_constructor_send_button: 'Кнопка',
    companies_form_constructor_send_button_background_color:
      'Колір фону кнопки',
    companies_form_constructor_send_button_link: 'Посилання кнопки',
    companies_form_constructor_send_button_text: 'Текст кнопки',
    companies_form_constructor_send_button_text_color: 'Колір тексту кнопки',
    companies_form_constructor_send_button_title:
      'Заголовок кнопки відправлення',
    companies_form_constructor_send_disabled_button_text_color:
      'Колір тексту вимкненої кнопки',
    companies_form_constructor_set_default_agreed:
      'Встановлення за замовчуванням погоджено',
    companies_form_constructor_several_from_list_placeholder:
      'Як давно ви відвідуєте наш заклад?',
    companies_form_constructor_several_from_list_title: 'Кілька зі списку',
    companies_form_constructor_show_additional_field: 'Показати додаткове поле',
    companies_form_constructor_show_name_input_field:
      'Показати поле для введення назви',
    companies_form_constructor_show_text_in_two_rows:
      'Показати текст у два рядки',
    companies_form_constructor_signature_label: 'Підпис',
    companies_form_constructor_signature_max_label: 'макс',
    companies_form_constructor_signature_min_label: 'мін',
    companies_form_constructor_start_page_button_width:
      'Ширина кнопки стартової сторінки',
    companies_form_constructor_status_label: 'Статус',
    companies_form_constructor_step_text: 'Текст кроку',
    companies_form_constructor_step_text_color: 'Колір тексту кроку',
    companies_form_constructor_step_text_placeholder: 'Написати текст кроку',
    companies_form_constructor_step_text_underline_color:
      'Колір підкреслення тексту кроку',
    companies_form_constructor_step_text_underline_color_placeholder:
      'Виберіть колір підкреслення тексту кроку',
    companies_form_constructor_submit_button: 'Зберегти форму',
    companies_form_constructor_tab_diagram: 'Діаграма',
    companies_form_constructor_tab_field: 'Поле',
    companies_form_constructor_tab_fields: 'Поля',
    companies_form_constructor_tab_settings: 'Налаштування',
    companies_form_constructor_text: 'Текст...',
    companies_form_constructor_thank_you_page_field_title: 'Сторінка подяки',
    companies_form_constructor_thank_you_page_text: 'Текст сторінки подяки',
    companies_form_constructor_thank_you_page_text_first_line:
      'Текст сторінки подяки перший рядок',
    companies_form_constructor_thank_you_page_text_second_line:
      'Текст подяки другий рядок',
    companies_form_constructor_thank_you_page_title:
      'Заголовок сторінки подяки',
    companies_form_constructor_title: 'Конструктор форми',
    companies_form_constructor_title_additional_field_placeholder: 'Інше',
    companies_form_constructor_title_additional_field_placeholder_title:
      'Заповнювач додаткового поля',
    companies_form_constructor_title_placeholder:
      'Ми будемо раді якщо ви залишите відгук',
    companies_form_constructor_title_placeholder_title: 'Заповнювач',
    companies_form_constructor_title_title: 'Підпис',
    companies_form_constructor_upload_files_label:
      'Виберіть які типи завантаження файлів будуть доступні на сторінці форми',
    companies_form_constructor_upload_files_placeholder:
      'Пояснення що до форми можна прикріпити файли',
    companies_form_constructor_upload_files_title: 'Завантаження файлів',
    companies_form_download_qr: 'Завантажити QR',
    companies_form_scale_constructor_change_background_checkbox_label:
      'Змінювати фон при зміні рейтингу',
    companies_form_scale_constructor_change_background_select_label:
      'Колір фону',
    companies_form_scale_constructor_change_background_select_placeholder:
      'Виберіть колір',
    companies_form_scale_constructor_change_mascot_checkbox_label:
      'Змінювати талісман при зміні рейтингу',
    companies_form_scale_constructor_change_mascot_select_label: 'Талісмани',
    companies_form_scale_constructor_change_mascot_select_placeholder:
      'Виберіть талісман',
    companies_form_scale_constructor_choose_color_placeholder: 'Виберіть колір',
    companies_form_scale_constructor_file_not_uploaded: 'Файл не завантажено',
    companies_form_scale_constructor_main_gradient_colors_label:
      'Основні кольори градієнта',
    companies_form_scale_constructor_maximum_value_label:
      'Максимальне значення',
    companies_form_scale_constructor_maximum_value_placeholder:
      'Введіть максимальне значення',
    companies_form_scale_constructor_minimum_value_label:
      'Введіть значення за замовчуванням',
    companies_form_scale_constructor_minimum_value_placeholder:
      'Значення за замовчуванням',
    companies_form_scale_constructor_numbers_chosen_colors_label:
      'Колір обраної відповіді',
    companies_form_scale_constructor_numbers_colors_label: 'Колір чисел',
    companies_form_scale_constructor_pointer_color_label: 'Колір вказівника',
    companies_form_scale_constructor_show_default_value:
      'Показати значення за замовчуванням',
    companies_form_scale_constructor_signature_text_color_label:
      'Колір тексту підпису',
    companies_form_scale_constructor_star_chosen_color_label:
      'Колір обраної зірки',
    companies_form_scale_constructor_star_not_chosen_color_label:
      'Колір заливки невибраної зірки',
    companies_form_scale_constructor_star_not_chosen_contour_color_label:
      'Колір контуру невибраної зірки',
    companies_form_scale_constructor_tab_title: 'Масштаб',
    companies_form_scale_constructor_title: 'Масштабний конструктор',
    companies_form_select_color_placeholder: 'Вибрати колір',
    companies_form_show_node_code: 'Показати код вузла',
    companies_form_table_add_form_button: 'Додати форму',
    companies_form_table_constructor_button: 'Конструктор форми',
    companies_form_table_copy_button: 'Копіювання форми',
    companies_form_table_copy_confirm_text:
      'Ви впевнені що хочете скопіювати форму?',
    companies_form_table_delete_button: 'Видалення форми',
    companies_form_table_delete_confirm_text:
      'Ви впевнені що хочете видалити форму?',
    companies_form_table_edit_button: 'Редагувати',
    companies_form_table_header_feedbacks: 'Відгуки',
    companies_form_table_header_last_edit: 'Останнє редагування',
    companies_form_table_header_link: 'Посилання',
    companies_form_table_header_name: 'Назва форми',
    companies_form_table_header_status: 'Статус',
    companies_form_table_new_form_language_placeholder:
      'Виберіть нову мову форми',
    companies_form_table_new_form_language_title: 'Нова мова форми',
    companies_form_table_new_form_name_placeholder: 'Введіть нову назву форми',
    companies_form_table_new_form_name_title: 'Нова назва форми',
    companies_form_table_new_node_language_placeholder:
      'Виберіть нову мову вузла',
    companies_form_table_new_node_language_title: 'Нова мова вузла',
    companies_form_table_new_node_name_placeholder: 'Введіть нову назву вузла',
    companies_form_table_new_node_name_title: 'Нова назва вузла',
    companies_form_table_node_to_copy: 'Куди ви хочете скопіювати форму?',
    companies_form_table_thank_button: 'Сторінка подяки',
    companies_form_table_title:
      'Форми зворотного звязку фронт-офісу суб’єкт моніторингу',
    companies_form_use_as_default_form:
      'Використовувати як форму за замовчуванням',
    companies_form_with_steps: 'Форма з кроками',
    companies_info_add_field_button: 'Додати поле',
    companies_info_after_save_modal_confirm_button:
      'Редагувати дані суб’єкту моніторингу',
    companies_info_after_save_modal_title:
      'Суб’єкт моніторингу успішно створена',
    companies_info_choose_file_button: 'Вибрати файл',
    companies_info_company_domain_label: 'Домен суб’єкту моніторингу',
    companies_info_company_domain_placeholder:
      'Введіть домен суб’єкту моніторингу',
    companies_info_company_extra_field_name_placeholder: 'Введіть назву поля',
    companies_info_company_extra_field_value_placeholder:
      'Введіть значення поля',
    companies_info_company_name_label: 'Назва суб’єкту моніторингу',
    companies_info_company_name_placeholder:
      'Введіть назву суб’єкту моніторингу',
    companies_info_confirmation_before_save_modal_title:
      'Ви впевнені що хочете редагувати інформацію про суб’єкт моніторингу?',
    companies_info_file_max_size: 'JPG GIF або PNG. Максимальний розмір 800K',
    companies_info_file_not_selected: 'Файл не вибрано',
    companies_info_submit_create_button: 'Зберегти суб’єкт моніторингу',
    companies_info_submit_edit_button: 'Зберегти зміни',
    companies_info_tab_base_info: 'Основна інформація',
    companies_info_tab_design: 'Дизайн',
    companies_info_tab_documents: 'Документи',
    companies_info_tab_issues_statuses_and_types: 'Статуси делегованих завдань',
    companies_info_tab_review_table_columns_settings: 'Поля реєстру відгуків',
    companies_info_tab_sources: 'Канали комунікації',
    companies_info_tab_templates: 'Шаблони',
    companies_info_title_add: 'Додати суб’єкт моніторингу',
    companies_info_title_edit: 'Інформація про суб’єкт моніторингу',
    companies_issue_statuses_and_types_add_status_button: 'Додати статус',
    companies_issue_statuses_and_types_add_status_placeholder:
      'Введіть назву статусу',
    companies_issue_statuses_and_types_statuses_text: 'Статуси',
    companies_issue_statuses_and_types_type_add_button: 'Додати тип',
    companies_issue_statuses_and_types_type_add_placeholder:
      'Введіть назву типу',
    companies_issue_statuses_and_types_types_text: 'Типи',
    companies_issues_and_types_confirmation_before_save_modal_title:
      'Ви впевнені що хочете редагувати статуси делегованих завдань?',
    companies_main_add_company_button: 'Додати суб’єкт моніторингу',
    companies_main_delete_description:
      'Ви впевнені що хочете видалити суб’єкт моніторингу?',
    companies_main_delete_title: 'Видалення суб’єкт моніторингу',
    companies_main_forms_button: 'Контактні форми',
    companies_main_structure_button: 'Структура',
    companies_main_table_company_name_header: 'Суб’єкт моніторингу',
    companies_main_title: 'Суб’єкти моніторингу',
    companies_review_table_columns_settings_title: 'Поля',
    companies_review_title: 'Відгуки',
    companies_reviews_table_header_button_details: 'Деталі',
    companies_reviews_table_header_contact: 'Канал',
    companies_reviews_table_header_contact_type: 'Тип звязку',
    companies_reviews_table_header_create: 'Дата створення',
    companies_reviews_table_header_estimate: 'NPS',
    companies_reviews_table_header_files: 'Прикріплені файли',
    companies_reviews_table_header_key_answer: 'Ключова відповідь',
    companies_reviews_table_header_language: 'Мова',
    companies_reviews_table_header_location: 'Місцезнаходження',
    companies_reviews_table_header_source: 'Канал комунікації',
    companies_reviews_table_header_status: 'Статус',
    companies_reviews_table_title: 'Відгуки',
    companies_source_must_be_unique_text: 'має бути унікальним',
    companies_source_name_label: 'Назва каналу комунікації',
    companies_source_name_max_length_error:
      'Назва каналу комунікації має бути менше 100 символів',
    companies_sources_add_source_button: 'Додати джерело',
    companies_sources_confirmation_before_save_modal_title:
      'Ви впевнені що хочете редагувати канали комунікації?',
    companies_sources_create_new_source_title: 'Створити нове джерело',
    companies_sources_create_source_text: 'Створити джерело',
    companies_sources_placeholder: 'Введіть назву каналу комунікації',
    companies_sources_title: 'Канали комунікації',
    companies_structure: 'Структура',
    companies_structure_create_accountable: 'Відповідальний(і)',
    companies_structure_create_address_label: 'Адреса',
    companies_structure_create_address_placeholder: 'Введіть адресу',
    companies_structure_create_advanced_settings: 'Додаткові налаштування',
    companies_structure_create_edit_title: 'Редагувати вузол',
    companies_structure_create_email: 'Електронна пошта',
    companies_structure_create_google_place_id_label: 'Google Place ID',
    companies_structure_create_integration_alpha_name_label: 'Альфа-назва',
    companies_structure_create_integration_key_label: 'Ключ',
    companies_structure_create_integration_title: 'Налаштування інтеграції',
    companies_structure_create_modal_title:
      'Ви впевнені що хочете створити вузол?',
    companies_structure_create_node_code_label: 'Код вузла',
    companies_structure_create_node_code_placeholder: 'Введіть код вузла',
    companies_structure_create_node_name_label: 'Назва вузла',
    companies_structure_create_node_name_placeholder: 'Введіть назву вузла',
    companies_structure_create_sms: 'SMS',
    companies_structure_create_telegram: 'Telegram',
    companies_structure_create_tied_form_placeholder: 'Форма',
    companies_structure_create_tied_node_label: 'Привязаний вузол',
    companies_structure_create_tied_node_placeholder: 'Вузол',
    companies_structure_create_title: 'Створити вузол',
    companies_structure_create_viber: 'Viber',
    companies_structure_lang_delay:
      'Використовувати таймер закінчення терміну дії',
    companies_structure_lang_detection:
      'Використовувати автоматичне визначення мови форми',
    companies_structure_table_accountable: 'Відповідальний',
    companies_structure_table_add_node_button: 'Додати вузол',
    companies_structure_table_delete_description_1:
      'Ви впевнені що хочете видалити вузол',
    companies_structure_table_delete_description_2:
      'структури суб’єкту моніторингу',
    companies_structure_table_delete_description_3:
      'Це призведе до видалення всіх вкладених вершин',
    companies_structure_table_delete_title:
      'Видалення вузла структури суб’єкту моніторингу',
    companies_structure_table_header_name: 'Вузли',
    companies_structure_title: 'Структура суб’єкту моніторингу',
    companies_structure_update_modal_title:
      'Ви впевнені що хочете відредагувати вузол?',
    company: 'Суб’єкт моніторингу',
    company_main_info_delete_title: 'Видалення суб’єкту моніторингу',
    company_main_info_edit_title:
      'Редагування основної інформації про суб’єкт моніторингу\nдокументів суб’єкту моніторингу',
    company_main_info_go_to_company_structure:
      'Перейти до структури суб’єкту моніторингу',
    company_structure_delete_button_title: 'Видалення вузла',
    company_structure_edit_button_title: 'Редагування вузла',
    confirm_button: 'Підтвердити',
    connect_button: 'Підключити',
    continue_editing_button: 'Продовжити редагування',
    continue_review_dialog_button: 'Продовжити діалог',
    copy_button: 'Копіювати',
    copy_error: '* Виникла помилка під час копіювання',
    create_button: 'Створити',
    data_save_error: '* Виникла помилка при збереженні даних',
    delete_button: 'Видалити',
    delete_error: '* Виникла помилка при видаленні',
    document: 'Документ',
    done_button: 'Виконано',
    edit_button: 'Редагувати',
    email: 'Email',
    email_label: 'Email',
    email_placeholder: 'Введіть email',
    employee_profile_title: 'Профіль користувача',
    enter_button: 'Увійти',
    enter_forgot_button: 'Відновити пароль',
    enter_task_name: 'Введіть назву завдання',
    error: '* Виникла помилка',
    error_text: 'Текст помилки',
    error_title: 'Помилка',
    expecting_reply_from_client: 'Очікуємо відповідь',
    filter_title_sources: 'Канали комунікації',
    filter_title_status: 'Статуси',
    filters: 'Фільтри',
    finish_review_dialog_button: 'Завершити діалог',
    finish_review_dialog_modal_description:
      'Ви впевнені що хочете завершити діалог?',
    finish_review_dialog_modal_title: 'Завершити діалог',
    form: 'Форма',
    form_constructor_connection_case_label: 'Опція',
    form_constructor_connection_case_placeholder: 'Виберіть опцію',
    form_constructor_connection_go_to: 'перейти до',
    form_constructor_connection_render_minifield_connection_always: 'Завжди',
    form_constructor_connection_render_minifield_connection_type_texts_always:
      'У всіх інших випадках',
    form_constructor_connection_render_minifield_connection_type_texts_if:
      'Якщо є',
    form_constructor_connection_select_field: 'Вибрати поле',
    form_constructor_connection_select_with_any_items_label: 'Наступне поле',
    form_constructor_connection_select_with_any_items_options_1_question:
      'Не вибрано',
    form_constructor_connection_select_with_any_items_placeholder:
      'Виберіть поле',
    form_constructor_connection_statement_label: 'Правило',
    form_constructor_connection_statement_placeholder: 'Правило вибору',
    form_constructor_delete_field_modal_description:
      'Ви впевнені що хочете видалити це поле?',
    form_constructor_delete_field_modal_title: 'Попередження!',
    form_constructor_diagram_fields_list_is_empty: 'Список полів порожній',
    form_constructor_fields_form_content: 'Вміст',
    form_constructor_fields_form_documents: 'Документи',
    form_constructor_fields_form_no_fields_selected: 'Немає вибраних полів',
    form_constructor_fields_form_send_button: 'Кнопка відправлення',
    form_constructor_fields_form_thank_you_page: 'Сторінка подяки',
    form_copy_save_error: '* На вибраному вузлі вже існує форма з такою назвою',
    form_language_error: '* Ви вже маєте форму з вибраною мовою',
    form_name_error: '* Назва форми має бути унікальною',
    form_save_error: '* Виникла помилка при збереженні форми',
    from: 'Від',
    full_name_label: 'Повне імя співробітника',
    full_name_placeholder: 'Введіть ПІБ співробітника',
    generate_button: 'Згенерувати',
    go_to_forms_page_button_title: 'Перейти до форм зворотного звязку',
    go_to_issues_create: 'Перейти до створення завдань',
    go_to_reviews_page_button_title: 'Перейти до відгуків',
    go_to_task_create: 'Перейти до створення завдання',
    in_progress: 'В роботі',
    issues_add_issue: 'Додати завдання',
    issues_form_company_label: 'Суб’єкт моніторингу',
    issues_form_company_placeholder: 'Оберіть суб’єкт моніторингу',
    issues_form_connect_issue_label: 'Підключити завдання',
    issues_form_connect_issue_placeholder: 'Виберіть завдання',
    issues_form_create_new_issue_status: 'Створити новий статус завдання',
    issues_form_create_new_issue_status_title: 'Створити статус',
    issues_form_create_new_issue_type: 'Створити новий тип завдання',
    issues_form_create_new_issue_type_title: 'Створити тип',
    issues_form_must_be_unique: 'має бути унікальною',
    issues_form_name_label: 'Назва завдання',
    issues_form_name_placeholder: 'Введіть назву форми',
    issues_form_node_label: 'Вузол',
    issues_form_node_placeholder: 'Виберіть вузол',
    issues_form_priority_label: 'Пріоритет завдання',
    issues_form_priority_placeholder: 'Виберіть пріоритет',
    issues_form_responsible_label: 'Відповідальний',
    issues_form_responsible_placeholder: 'Виберіть відповідального',
    issues_form_review_label: 'Рецензування',
    issues_form_review_placeholder: 'Виберіть рецензію',
    issues_form_status_label: 'Статус завдання',
    issues_form_status_name_label: 'Назва статусу завдання',
    issues_form_status_placeholder: 'Виберіть статус',
    issues_form_type_label: 'Тип завдання',
    issues_form_type_name_label: 'Назва типу завдання',
    issues_form_type_placeholder: 'Виберіть тип завдання',
    issues_save_modal_create_title: 'Ви впевнені що хочете створити завдання?',
    issues_save_modal_edit_title:
      'Ви впевнені що хочете відредагувати завдання? issues_save_modal_edit_title',
    issues_search_by_name: 'Шукати завдання за назвою',
    issues_sub_header_create_issue_title: 'Створити завдання',
    issues_sub_header_create_task_title: 'Створення завдання',
    issues_sub_header_edit_issue_title: 'Редагування завдання',
    issues_sub_header_title: 'Делегування',
    issues_table_empty: 'Оберіть суб’єкт моніторингу для відображення завдань',
    issues_table_header_company: 'Суб’єкт моніторингу',
    issues_table_header_date: 'Дата створення',
    issues_table_header_name: 'Назва',
    issues_table_header_node: 'Вузол',
    issues_table_header_priority: 'Пріоритет',
    issues_table_header_status: 'Статус',
    issues_table_header_type: 'Тип завдання',
    language: 'Мова',
    languages_add_name_placeholder: 'Введіть назву',
    languages_add_short_name_label: 'Коротка назва',
    languages_add_short_name_placeholder: 'Введіть коротку назву',
    languages_add_title: 'Додавання мови',
    languages_table_add_language_button: 'Додати мову',
    languages_table_header_full_name: 'Повна назва',
    languages_table_header_short_name: 'Коротка назва',
    languages_table_modal_delete_buttons_label_end: 'мова?',
    languages_table_modal_delete_buttons_label_start:
      'Ви впевнені що хочете видалити',
    languages_table_modal_delete_title: 'Видалення мови',
    languages_table_title: 'Мови',
    leave_the_page: 'Залишити сторінку',
    loading_error: '* Виникла помилка під час завантаження даних',
    login_recovery_auth: 'Повернутися',
    login_recovery_complete:
      'Повідомлення з інструкціями щодо відновлення пароля буде надіслано на вашу електронну адресу найближчим часом',
    mass_mailing_type_by_addresses: 'Адресна розсилка',
    mass_mailing_type_by_company: 'По суб’єктах моніторингу',
    mass_mailing_confirmation_modal_subtitle1:
      'У таблиці виявлено не всі можливі дані: ',
    mass_mailing_confirmation_modal_subtitle2:
      '. Ви впевнені, що хочете продовжити розсилку?',
    mass_mailing_confirmation_modal_title: 'Ви впевнені?',
    max_size_error: '* Перевищено максимально дозволений розмір файлу',
    max_size_error_25: '* Максимально дозволений розмір файлу - 25 Мб',
    max_table_column_settings_apply_warning: 'Максимум 6 активних налаштувань',
    more_detailed: 'Більш детально',
    name: 'Назва',
    new_task_name: 'Опис завдання',
    next_button: 'Далі',
    no_options_available: 'Немає доступних опцій',
    node: 'Вузол',
    non_submit_description:
      'Будь ласка поверніться та збережіть зміни перед закриттям',
    non_submit_title: 'Ви не зберегли зміни',
    not_decided: 'Не вирішено',
    not_viewed: 'Не переглянуто',
    nothing_find: 'Не знайдено',
    login_recovery_password_title: 'Відновлення втраченого пароля',
    open_issue_title: 'Відкрити завдання',
    open_language_title: 'Відкрити мову',
    open_permission_title: 'Відкрити дозвіл',
    open_review_title: 'Відкрити відгук',
    open_role_title: 'Відкрити роль',
    open_user_title: 'Відкрити користувача',
    other: 'Інше',
    overdue_status_text: 'Прострочений',
    pagination_page_number_placeholder: 'Номер сторінки',
    pagination_title: 'Рядки на сторінці',
    password: 'Пароль',
    password_confirmation_label: 'Повторіть пароль',
    password_confirmation_placeholder: 'Введіть пароль ще раз',
    password_label: 'Пароль',
    password_placeholder: 'Введіть пароль',
    permission_create_title: 'Створити дозвіл',
    permission_edit_title: 'Редагувати дозвіл',
    permission_name_placeholder: 'Введіть назву дозволу',
    permission_name_title: 'Назва дозволу',
    permission_resource_placeholder: 'Введіть ресурс дозволу',
    permission_resource_title: 'Ресурс',
    permissions_main_add_permission_button: 'Додати дозвіл',
    permissions_main_delete_description:
      'Ви впевнені що хочете видалити дозвіл?',
    permissions_main_delete_title: 'Видалення дозволу',
    permissions_main_permissions_button: 'Дозволи',
    permissions_main_table_create_header: 'Створення',
    permissions_main_table_delete_header: 'Видалення',
    permissions_main_table_permission_header: 'Назва дозволу',
    permissions_main_table_read_header: 'Перегляд',
    permissions_main_table_resource_header: 'Ресурс',
    permissions_main_table_update_header: 'Редагування',
    permissions_main_title: 'Дозволи',
    phone: 'Телефон',
    phone_number_label: 'Номер телефону',
    phone_number_placeholder: 'Введіть номер телефону',
    photo: 'Фото',
    preview_button: 'Попередній перегляд',
    privacy_policy: 'угода користувача',
    privacy_policy_confirm: 'Я згоден',
    record: 'Аудіо',
    refresh_all_fitlers: 'Очистити всі фільтри',
    registration_profile_completion: 'Заповнення профілю',
    reopened: 'Відкрито знову',
    reset_reviews_filter: 'Скинути',
    reset_table_settings: 'Скинути налаштування таблиці',
    review_choose_template: 'Вибрати шаблон відповіді',
    review_created_at: 'Дата та час створення відгуку',
    review_dialog_finished_text: 'Діалог було завершено',
    review_form_name: 'Назва форми',
    review_form_source: 'Джерело форми',
    review_handle_continue_dialog_error_message:
      'Щось не так. Будь ласка спробуйте пізніше.',
    review_message_input_placeholder: 'Напишіть своє повідомлення',
    review_no_asscociated_issues: 'Немає повязаних завдань',
    review_no_associated_tasks: 'Немає повязаних завдань',
    review_node_code: 'Код вузла',
    review_node_name: 'Назва вузла',
    review_on_submit_3_error_message:
      'Клієнт вже має діалог з іншим менеджером. Будь ласка спробуйте ще раз пізніше',
    review_open_task_button_text: 'Відкрити завдання',
    review_page_type_of_task: 'Тип завдання',
    review_review_id: 'Ідентифікатор відгуку',
    review_save_modal_title: 'Ви впевнені що хочете редагувати відгук?',
    review_updated_at: 'Дата обробки',
    reviews_page_choose_company_title: 'Оберіть субʼєкт моніторингу зі списку',
    reviews_table_comment_column_title: 'Нотатки',
    reviews_table_email_column_title: 'Email',
    reviews_table_filter_title: 'Фільтр',
    reviews_table_key_question_column_title: 'CSI',

    reviews_table_tags_column_title: 'Теги',
    reviews_table_categories_column_title: 'Категорія відгуку',

    reviews_table_node_column_title: 'Фронт-офіс',
    reviews_table_attachments_column_title: 'Вкладення до відгуку',

    reviews_table_parent_node_column_title: 'Батьківський вузол',
    reviews_table_phone_column_title: 'Номер телефону суб’єкта звернення',
    reviews_table_review_id_column_title: 'ID відгуку',
    reviews_table_ukrposhta_settings_button: 'Необроблені відгуки',
    reviews_table_user_name_column_title: 'ПІБ суб’єкта звернення',
    reviewType: 'Тип відгуку',
    role_create_title: 'Створити роль',
    role_edit_title: 'Редагувати роль',
    role_name_placeholder: 'Введіть назву ролі',
    role_name_title: 'Назва ролі',
    role_page_create_title: 'Створити роль',
    role_permissions: 'Дозволи',
    role_select_permissions_placeholder: 'Вибрати дозволи',
    roles_main_add_role_button: 'Додати роль',
    roles_main_delete_description: 'Ви впевнені що хочете видалити роль?',
    roles_main_delete_title: 'Видалення ролі',
    roles_main_permissions_button: 'Дозволи',
    roles_main_table_user_role_header: 'Назва ролі',
    roles_main_title: 'Ролі',
    roles_part_analytic: 'Аналітична',
    roles_part_companies: 'Суб’єкти моніторингу',
    roles_part_documents: 'Документи',
    roles_part_forms: 'Форми',
    roles_part_languages: 'Мови',
    roles_part_mass_mailings: 'Масові розсилки',
    roles_part_nodes: 'Вузли',
    roles_part_reviews: 'Відгуки',
    roles_part_users: 'Користувачі',
    roles_sub_permission_create: 'Створювати',
    roles_sub_permission_delete: 'Видалення',
    roles_sub_permission_read: 'Читати',
    roles_sub_permission_update: 'Оновити',
    save_button: 'Зберегти',
    save_changes_button: 'Зберегти зміни',
    save_document_button: 'Зберегти',
    save_draft_button: 'Зберегти чернетку',
    save_field_button: 'Зберегти поле',
    save_form_button: 'Зберегти форму',
    save_form_without_changes_button: 'Зберегти форму без змін',
    search: 'Шукати...',
    search_for_name: 'Шукати за іменем',
    search_for_email: 'Шукати за електронною поштою',
    select_company: 'Вибрати суб’єкт моніторингу',
    select_form: 'Вибрати форму',
    select_language: 'Вибрати мову',
    select_multiple_sources: 'Вибрати канали комунікації',
    select_multiple_statuses: 'Вибрати статуси',
    select_node: 'Вибрати вузол',
    select_status: 'Вибрати статус',
    select_type: 'Вибрати тип',
    send_button: 'Надіслати',
    sidebar_analytic: 'Аналітика',
    sidebar_companies: 'Суб’єкти моніторингу',
    sidebar_exit: 'Вийти з профілю',
    sidebar_issues: 'Делегування',
    sidebar_languages: 'Мови',
    sidebar_profile: 'Профіль',
    sidebar_reviews: 'Відгуки',
    sidebar_tasks: 'Делегування',
    sidebar_users: 'Користувачі',
    sms: 'SMS',
    social_networks_module_text:
      'Оберіть зручний для вас спосіб спілкування з нами',
    solution_not_required: 'Вирішення не потрібне',
    solved: 'Вирішено',
    source: 'Канал комунікації',
    status: 'Статус розгляду',
    status_code: 'Код статусу',
    table_columns_settings_comment_option: 'Нотатки',
    table_columns_settings_communication_channel_option: 'Тип звязку',
    table_columns_settings_company_option: 'Суб’єкт моніторингу',
    table_columns_settings_created_at_option: 'Дата створення',
    table_columns_settings_email_option: 'Email',
    table_columns_settings_estimation_option: 'NPS',
    table_columns_settings_key_question_option: 'Коментар суб’єкта звернення',
    table_columns_settings_categories_option: 'Категорія відгуку',
    table_columns_settings_tags_option: 'Теги',
    table_columns_settings_language_option: 'Мова',
    table_columns_settings_name_option: 'ПІБ суб’єкта звернення',
    table_columns_settings_node_option: 'Фронт-офіс',
    table_columns_settings_parent_node_option: 'Батьківський вузол',
    table_columns_settings_phone_option: 'Номер телефону суб’єкта звернення',
    table_columns_settings_reviewId_option: 'ID відгуку',
    table_columns_settings_source_option: 'Канал комунікації',
    table_columns_settings_status_option: 'Статус',
    table_settings: 'Налаштування таблиці',
    task_form_category_label: 'Категорія завдання',
    task_form_create_task: 'Створити завдання',
    task_form_list_category_label: 'Категорія відгуку',
    task_form_list_subcategory_label: 'Підкатегорія',
    task_form_list_type_label: 'Тип',
    task_form_name_label: 'Назва завдання',
    task_form_priority_label: 'Пріоритет завдання',
    task_form_status_label: 'Статус завдання',
    task_form_subcategory_label: 'Підкатегорія завдання',
    task_form_type_label: 'Тип завдання',
    telegram: 'Телеграма',
    to: 'Кому',
    to_plug_button: 'Підключити',
    turn_off_button: 'Вимкнути',
    upload: 'Завантажити',
    upload_color_button: 'Завантажити колір',
    uploading_files: 'Завантаження файлів',
    user_email_placeholder: 'Введіть email',
    user_email_title: 'Email',
    user_name_placeholder: 'Введіть імя',
    user_name_title: 'Імя та прізвище',
    user_new_password_placeholder: 'Введіть новий пароль',
    user_new_password_title: 'Новий пароль',
    user_old_password_placeholder: 'Введіть старий пароль',
    user_old_password_title: 'Старий пароль',
    user_page_create: 'Створити користувача',
    user_page_edit: 'Редагувати користувача',
    user_password_confirmation_placeholder: 'Повторити пароль',
    user_password_confirmation_title: 'Підтвердження пароля',
    user_password_placeholder: 'Введіть пароль',
    user_password_title: 'Пароль',
    user_role_placeholder: 'Виберіть ролі користувачів',
    user_role_title: 'Ролі',
    users_main_add_user_button: 'Додати користувача',
    users_main_delete_description:
      'Ви впевнені що хочете видалити користувача?',
    users_main_delete_title: 'Видалення користувача',
    users_main_roles_button: 'Ролі',
    users_main_table_user_company_header: 'Суб’єкт моніторингу',
    users_main_table_user_email_header: 'Email',
    users_main_table_user_name_header: 'Імя',
    users_main_table_user_notifications_header: 'Notifications',
    users_main_table_user_role_header: 'Роль',
    users_main_title: 'Користувачі',
    users_main_upload_users_button: 'Завантажити користувачів',
    validation_constructor_at_least_2_possible_answers:
      '* Питання повинно мати щонайменше 2 можливі відповіді',
    validation_constructor_should_have_at_least_1_document:
      '* Форма повинна мати мінімум один документ',
    validation_constructor_should_select_file_types:
      '* Ви повинні вибрати типи файлів дозволені для завантаження або видалити це поле з форми',
    validation_domain: 'Домен може містити лише англійські символи . та -',
    validation_form_should_have_minimum_1:
      '* Форма повинна мати мінімум одне поле',
    validation_full_type_required: '* Ви повинні вибрати кожну частину типу',
    validation_invalid_email: '* Неправильний формат електронної пошти',
    validation_invalid_link: '* Неправильний формат посилання',
    validation_more_one_accountable:
      '* Ви повинні вибрати принаймні 1 менеджера вузлів',
    validation_more_one_option: '* Ви повинні вибрати принаймні 1 опцію',
    validation_must_fill_all_fields_for: '* Ви повинні заповнити всі поля для',
    validation_must_fill_this_field: '* Ви повинні заповнити це поле',
    validation_name_must_be_longer_4:
      '* Імя занадто коротке - має бути щонайменше 4 символи',
    validation_required: '* Обовязково',
    viber: 'Viber',
    video: 'Video',
    viewed: 'Переглянуто',
    your_changes_lose:
      'Вам потрібно зберегти зміни. Інакше вони будуть втрачені',
    send_feedback: 'Надіслати відгук',
    send_feedback_title: 'Відгук',
    send_feedback_label: 'Ваші побажання або коментарі',
    send_feedback_complete: 'Дякуємо за ваш відгук',
    sidebar_impex: 'Імпорт/Експорт',
    impex_page_title: 'Імпорт/Експорт',
    impex_import_tab: 'Імпорт',
    impex_export_tab: 'Експорт',
    select_import_entity: 'Що ви хочете імпортувати?',
    start_import_button_text: 'Почати імпорт',
    choose_file_for_import: 'Виберіть файл для імпорту',
    import_entity_selection_title: 'Для імпорту',
    import_file_sheet_name_label: 'Назва аркуша у файлі імпорту',
    import_file_sheet_name_input_placeholder: 'Введіть назву аркуша',
    interrupt_import_script_button_text: 'Перервати імпорт',
    import_file_format_text: 'Формат має бути',
    import_progress_bar_title: 'Прогрес імпорту',
    import_users_entity_name: 'Користувачі',
    import_nodes_entity_name: 'Вузли',
    qr_title: 'Відскануйте QR для подальшого налаштування сповіщень',
    mass_mailings_channels_of_connections: "Канали зв'язку",
    mass_mailings_send_button: 'Надіслати',
    yes_button_text: 'Так',
    no_button_text: 'Ні',
    confirm_send_mass_mailings_modal_title:
      'Ви впевнені що хочете зробити масові розсилки?',
    modal_mass_mailings_sent_success_title: 'Повідомлення успішно відправлено',
    ok_button_text: 'Гаразд',
    mass_mailings_error_text: 'Помилка надсилання повідомлень',
    mass_mailings_recipient_label: 'Одержувач',
    mass_mailings_recipients_list_title: 'Одержувачі',
    add_at_least_one_recipient_error:
      'Потрібно додати принаймні одного отримувача',
    sidebar_mass_mailings: 'Масові розсилки',
    create_mass_mailings_page_title: 'Створити масові розсилки',
    create_mass_mailings_first_step_subheader_title:
      'Масові розсилки - Створення - Крок 1 з 3',
    choose_mass_mailings_communication_channel: "Виберіть канал зв'язку",
    mass_mailings_add_recipient_button_text: 'Додати отримувача',
    download_recipients_file_label: 'Завантажити файл з отримувачами',
    format: 'Формат',
    choose_file: 'Виберіть файл',
    file_data_downloading_text: 'Завантаження даних з файлу',
    mass_mailings_mails_count: 'Кількість листів які буде надіслано',
    create_sms_mass_mailings_second_step_subheader_title:
      'Створення SMS-розсилки - крок 2 з 3',
    create_viber_mass_mailings_second_step_subheader_title:
      'Створення Viber-розсилки - Крок 2 з 3',
    create_sms_mass_mailings_third_step_subheader_title:
      'Створення SMS-розсилки - Крок 3 з 3',
    create_viber_mass_mailings_third_step_subheader_title:
      'Створення Viber-розсилки - Крок 3 з 3',
    mass_mailings_sender_account: 'Акаунт відправника',
    sms_text: 'Текст SMS',
    message: 'Повідомлення',
    message_text_placeholder: 'Текст повідомлення',
    responsible: 'Відповідальний',
    responsible_id: 'Ідентифікатор відповідального',
    companies_form_constructor_dividing_decorative_line:
      'Параметри форми що розділяє декоративну лінію',
    companies_form_constructor_show_form_dividing_decorative_line:
      'Показати розділову декоративну лінію форми',
    companies_form_constructor_dividing_decorative_line_width_in_percent:
      'Довжина декоративної лінії у відсотках',
    companies_form_constructor_dividing_decorative_line_height:
      'Висота декоративної лінії у пікселях',
    companies_form_constructor_dividing_decorative_line_height_placeholder:
      'Введіть висоту декоративної лінії',
    companies_form_constructor_dividing_decorative_line_color:
      'Колір декоративної лінії',
    companies_form_constructor_dividing_decorative_line_color_placeholder:
      'Виберіть колір декоративної лінії',
    export_entity_selection_title: 'Для експорту',
    select_export_entity: 'Що ви хочете експортувати?',
    export_button_text: 'Експортувати',
    export_nodes_error_text: 'Помилка експорту вузлів',
    export_users_error_text: 'Помилка експорту користувачів',
    target: 'Поточний відгук',
    all: 'Вся історія',
    quarter: 'Останні 3 місяці',
    months: 'Останній місяць',
    days7: 'Останні 7 днів',
    telegram_network: 'Telegram',
    viber_network: 'Viber',
    phone_network: 'Телефон',
    email_network: 'Електронна пошта',
    whatsapp_network: 'WhatsApp',

    campaign_table_header_state: 'Область',
    campaign_table_header_node: 'Орган',
    campaign_table_header_district: 'Регіон',
    campaign_table_header_visited_at: 'Дата відвідування',
    campaign_table_header_service_name: 'Назва послуги',
    campaign_table_header_service_status: 'Статус послуги',
    campaign_table_header_service_result: 'Результат послуги',
    campaign_table_header_service_in_time: 'Результат виконання',
    campaign_table_header_client_gender: 'Гендер',
  },
};

export function getTranslationByLangOrEng(lang: string, key: string) {
  return translations[lang][key]
    ? translations[lang][key]
    : translations.EN[key];
}
