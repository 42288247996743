import styled from 'styled-components';

export const ChartStyles = styled.header`
  width: 100%;
  height: 300px;
  padding: 16px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
`;
