/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import { ApiContacts, ApiResponse } from '../entities';
import IUserContactsApi from './IUserContactsApi';

class UserContactsApi implements IUserContactsApi {
  public async getUserContacts(
    id: number,
  ): Promise<ApiResponse<ApiContacts[]>> {
    return baseApi.getData(`/user_contacts?userID=${id}`);
  }

  public async putContactNotification(
    id: number,
    data: { active: boolean },
  ): Promise<ApiResponse<any>> {
    return baseApi.putData(`/user_contacts/${id}`, data);
  }
}

export const userContactsApi = new UserContactsApi();
