import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ExportStyles } from './ExportStyles';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import CustomSelect from '../../form/customSelect/CustomSelect';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { exportApi, companyApi, formApi } from '../../../api';
import { CompaniesMainInfo } from '../../../entities/ICompany';
import { FormValues, validationSchema } from './formValuesAndValidation';
import { saveAs } from 'file-saver';
import { Loader } from '../../atoms';
export const Export = () => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [submittingError, setSubmittingError] = useState<string>();
  const [exporting, setExporting] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const exportEntitiesRef = useRef<{
    name: string;
  }[]>([{
    name: 'Users'
  }, {
    name: 'Nodes'
  }]);
  const companiesRef = useRef<CompaniesMainInfo[]>([]);
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setExporting(true);
    setSubmitting(false);
    setSubmittingError('');
    if (values.entity && values.company && values.entity.name === 'Nodes') {
      try {
        const res = await formApi.exportNodes(values.company.id);
        if (res.statusCode === 200) {
          saveAs(res.data, `${values.company.name} nodes.xlsx`);
        } else {
          setSubmittingError(res.data.message);
        }
      } catch (e) {
        setSubmittingError(getTranslationByLangOrEng(interfaceLanguage, 'export_nodes_error_text'));
      }
    }
    if (values.entity && values.entity.name === 'Users') {
      try {
        const res = await exportApi.exportUsers();
        if (res.statusCode === 200) {
          saveAs(res.data, 'users.xlsx');
        } else {
          setSubmittingError(res.data.message);
        }
      } catch (e) {
        setSubmittingError(getTranslationByLangOrEng(interfaceLanguage, 'export_users_error_text'));
      }
    }
    setExporting(false);
  }
  useEffect(() => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        companiesRef.current = res.data;
      }
      setIsLoaded(true);
    });
    setInitialValues({
      entity: undefined,
      company: undefined
    });
  }, []);
  const renderForm = ({
    values,
    errors,
    setFieldValue
  }: FormikProps<any>) => <Form>
      <div className="exportPageWrapper">
        <div className="formSection">
          <CustomSelect label={getTranslationByLangOrEng(interfaceLanguage, 'export_entity_selection_title')} name="entity" options={exportEntitiesRef.current} selectKey="name" placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_export_entity')} value={values.entity} handleSelect={value => {
          setFieldValue('entity', value);
        }} search emptyOption emptyList={getTranslationByLangOrEng(interfaceLanguage, 'no_options_available')} formGroupStyles={{
          maxWidth: 300,
          width: '100%'
        }} required />
          {values.entity && values.entity.name === 'Nodes' && <CustomSelect label={getTranslationByLangOrEng(interfaceLanguage, 'company')} name="company" options={companiesRef.current} selectKey="name" placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_company')} value={values.company} handleSelect={value => {
          setFieldValue('company', value);
        }} search emptyOption emptyList={getTranslationByLangOrEng(interfaceLanguage, 'no_options_avaliable')} formGroupStyles={{
          maxWidth: 300,
          width: '100%'
        }} />}
          <div>
            <SubmitButton isLoading={exporting} extraBlockStyles={{
            maxWidth: 300,
            width: '100%'
          }}>
              {getTranslationByLangOrEng(interfaceLanguage, 'export_button_text')}
            </SubmitButton>
            {submittingError && <p className="submitError">{submittingError}</p>}
          </div>
        </div>
      </div>
    </Form>;
  return <ExportStyles>
      {!initialValues || !isLoaded ? <Loader /> : <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema(interfaceLanguage)}>
          {renderForm}
        </Formik>}
    </ExportStyles>;
};