import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ComplexGeneralDashboardStyles } from './styles';
import { analyticsApi, ApiComplexGeneral, ApiComplexRating, ApiDissatisfactionsAnswer, ApiDissatisfactionsItem, TPostComplexRating, TPostDissatisfaction } from '../../../../api';
import { Table } from '../../../organisms';
import { PieChart } from '../../analyticsCharts';
import { Loader } from '../../../atoms';
type TComplexGeneralDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[];
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const colors: string[] = ['#5B5AFF', '#89DB33', '#CC2E4F', '#FFDB4D'];
const ComplexGeneralDashboard_1 = _compiledBlock(_props => _props.v0, {
  name: "ComplexGeneralDashboard_1",
  portals: ["v0"]
});
export const ComplexGeneralDashboard: FC<TComplexGeneralDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const [data, setData] = useState<ApiComplexRating[]>([]);
  const [pie, setPie] = useState<ApiComplexGeneral>();
  const [loading, setLoading] = useState<boolean>(true);
  const [preloading, setPreloading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const dataCount = useRef<number>(0);
  useEffect(() => {
    if (!loading) {
      setData([]);
      setPreloading(true);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostComplexRating = {
      from: from as string,
      to: to as string,
      csiFieldIDs: [858718, 858809],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      npsFieldIDs: [858735, 858818],
      npsPositiveAnswers: ['9', '10'],
      npsNegativeAnswers: ['1', '2', '3', '4', '5', '6'],
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[]
    };
    setLoading(true);
    Promise.all([analyticsApi.getComplexRatingData(+companyID, filteredData, (pageNumber ? +pageNumber - 1 : 0) * 10, 10), analyticsApi.getComplexGeneral(+companyID, filteredData)]).then(([data, pie]) => {
      setData(prev => [...prev, ...data.data]);
      dataCount.current = data.count ? data.count : 0;
      setPie(pie.data);
      setLoading(false);
      setPreloading(false);
    }).finally(() => {
      setLoading(false);
    });
  }, [pageNumber, reload]);
  useEffect(() => {
    setTableHeaders([{
      Header: 'IDF',
      accessor: 'idf',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.idf}</span>
          </div>
    }, {
      Header: 'Назва центру (у точу числі територіальних підрозділів центру/віддалених робчих місць адміністраторів центру/мобільних центрів)',
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.name}</span>
          </div>
    }, {
      Header: 'CSI',
      accessor: 'csi',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.csi}
            </span>
          </div>
    }, {
      Header: 'NPS',
      accessor: 'nps',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.nps}
            </span>
          </div>
    }, {
      Header: 'Кількість відгуків',
      accessor: 'reviews',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.reviews}
            </span>
          </div>
    }]);
  }, [data]);
  return /*@million jsx-skip*/<ComplexGeneralDashboard_1 v0={<ComplexGeneralDashboardStyles>
      <h2>Результат оцінки рівня задоволеності у ЦНАП</h2>
      <div className="chart">
        <div className="chart-cards">
          <div className="chart-card">
            <p>{pie?.csi}</p>
            <h6>CSI</h6>
          </div>
          <div className="chart-card">
            <p>{pie?.nps}</p>
            <h6>NPS</h6>
          </div>
          <div className="chart-card">
            <p>{pie?.reviews}</p>
            <h6>Відгуків</h6>
          </div>
          <div className="chart-card">
            <p>{pie?.locations}</p>
            <h6>Кількість точок</h6>
          </div>
        </div>
        <div className="chart-wrapper">
          <div className="chart-legend">
            {pie?.csiDistribution.map((el, index) => <div className="chart-item">
                <div style={{
              background: colors[index]
            }} />
                <p>{el.value}</p>
              </div>)}
          </div>
          <PieChart width={dimensions.height / 4} height={dimensions.height / 4} data={pie ? pie?.csiDistribution.map((el, index) => ({
          label: el.value,
          value: el.part,
          fill: colors[index]
        })) : []} />
        </div>
      </div>
      <div className="rating">
        {tableHeaders && <Table columns={tableHeaders} data={data} fullWidthColumns={['name', 'answers']} totalCount={dataCount.current} currentPage={pageNumber} offset={10} loading={loading} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
      </div>
    </ComplexGeneralDashboardStyles>} />;
};