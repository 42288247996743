import { utils, writeFile } from 'xlsx';

export const extraFieldsSettings = {
  date: {
    justifyContent: 'center',
  },
  state: {
    justifyContent: 'center',
  },
  phone: {
    justifyContent: 'center',
  },
  id: {
    justifyContent: 'center',
  },
  unsubscribed: {
    justifyContent: 'center',
  },
  email: {
    justifyContent: 'center',
  },
  'opened-submitted': {
    justifyContent: 'center',
  },
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      display: false,
    },
  },
};

export const downloadSheet = async (data: any[]) => {
  const headers = Object.keys(data[0]);
  const sheetData = [
    headers,
    ...data.map((obj) => headers.map((key) => obj[key])),
  ];

  const ws = await utils.aoa_to_sheet(sheetData);

  const wb = await utils.book_new();
  await utils.book_append_sheet(wb, ws, 'Result');

  const excelFileName = 'output.xlsx';

  const excelData = await writeFile(wb, excelFileName, {
    bookType: 'xlsx',
    type: 'base64',
  });

  const byteCharacters = atob(excelData);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: 'application/vnd.ms-excel' });

  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = excelFileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
