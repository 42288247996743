import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { languagesApi } from '../../api';
import { getTranslationByLangOrEng } from '../../i18n';
import { DeleteIcon, PencilIcon, ThreeDots } from '../../assets';
import { LanguagesStyles } from './LanguagesStyles';
import { useClickOutside } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../state';
import { getLanguages } from '../../utils';
import { ModalConfirmDecline, SubHeader, Table, TransparentButton } from '../../components';
function EditButtons({
  name,
  reQuery
}: {
  name: string;
  reQuery: () => void;
}) {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [modalErrorIndex, setModalErrorIndex] = useState<number>(0);
  const menuRef = useRef<any>();
  useClickOutside(menuRef, () => setMenuOpen(false));
  return <div className="editButtonsContainer">
      <div className="menuOpenerContainer" ref={menuRef}>
        <button className="menuOpenerButton" type="button" onClick={() => {
        setMenuOpen(!menuOpen);
      }}>
          <ThreeDots />
        </button>
        {menuOpen && <ul className="menuList">
            <li>
              <button type="button" onClick={() => setModalIndex(1)}>
                <DeleteIcon />{' '}
                <span>
                  {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
                </span>
              </button>
            </li>

            <li>
              <button type="button" onClick={() => setModalIndex(2)}>
                <PencilIcon />{' '}
                <span>
                  {getTranslationByLangOrEng(interfaceLanguage, 'edit_button')}
                </span>
              </button>
            </li>
          </ul>}
      </div>
      {modalIndex === 1 && <ModalConfirmDecline onClose={() => setModalIndex(0)} onDecline={() => setModalIndex(0)} onConfirm={() => {
      languagesApi.deleteLanguage({
        name
      }).then(res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          reQuery();
          setModalIndex(0);
        } else {
          setModalErrorIndex(1);
        }
      });
    }} title={getTranslationByLangOrEng(interfaceLanguage, 'languages_table_modal_delete_title')} buttonsLabel={`${getTranslationByLangOrEng(interfaceLanguage, 'languages_table_modal_delete_buttons_label_start')} ${name} ${getTranslationByLangOrEng(interfaceLanguage, 'languages_table_modal_delete_buttons_label_end')}`} confirmText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')} declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} firstButtonStyles={{
      border: '1px solid #C4C4C4',
      backgroundColor: '#ffffff',
      color: '#000000'
    }} secondButtonStyles={{
      backgroundColor: '#FF0101',
      border: '1px solid #FF0101'
    }} error={modalErrorIndex === 1} errorText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')} />}
    </div>;
}
export const Languages = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    languages: languagesState
  } = useAppSelector(state => state.languages);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [reQuery, setReQuery] = useState<boolean>(false);
  useEffect(() => {
    if (!languagesState) {
      getLanguages(dispatch);
    }
  }, [reQuery]);
  const companiesTableHeaders = React.useMemo(() => [{
    Header: getTranslationByLangOrEng(interfaceLanguage, 'name'),
    accessor: 'name'
  }, {
    Header: 'Edit buttons',
    accessor: 'editButtons',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: (data: any) => <EditButtons name={data.row.original.name} reQuery={() => setReQuery(!reQuery)} />
  }], [languagesState]);
  return <LanguagesStyles>
      <div>
        <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'languages_table_title')}>
          <TransparentButton handleClick={() => navigate('/languages/create')} text={getTranslationByLangOrEng(interfaceLanguage, 'languages_table_add_language_button')} filled />
        </SubHeader>
        {languagesState && <Table pagination columns={companiesTableHeaders} data={languagesState} hideFieldsSplitters hiddenHeaders={['editButtons']} fullWidthColumns={['editButtons']} />}
      </div>
    </LanguagesStyles>;
};