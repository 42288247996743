import React, { Dispatch, FC, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { StatusesSettingsStyles } from './StatusesSettingsStyles';
import { useAppSelector } from '../../../state';
import { reviewsStatusIconsAndText } from '../../../constants';
import { ArrowDownIcon, CheckMark } from '../../../assets';
import { useClickOutside } from '../../../hooks';
import { ApiChangesStatuses, companySettingsApi } from '../../../api';
import { useParams } from 'react-router-dom';
type TStatusesSettings = {
  statId: string;
  selected: number[];
  setSourcesSettings: Dispatch<SetStateAction<ApiChangesStatuses[]>>;
  sourcesSettings: ApiChangesStatuses[];
  selectedSource: number;
};
export const StatusesSettings: FC<TStatusesSettings> = React.memo(({
  statId,
  selected,
  selectedSource,
  sourcesSettings,
  setSourcesSettings
}) => {
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [open, setOpen] = useState(false);
  const targetStatus = useMemo(() => reviewsStatusIconsAndText(interfaceLanguage)[statId], [statId]);
  const TargetIcon = targetStatus.icon;
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  const handleUpdate = useCallback((tarId: number) => {
    if (id) {
      const newData = [...sourcesSettings];
      const itemIndex = newData.findIndex(item => item.sourceID === selectedSource);
      if (itemIndex !== -1) {
        const arrayForKey = newData[itemIndex].changes[+statId];
        const valueIndex = arrayForKey.indexOf(tarId);
        if (valueIndex === -1) {
          arrayForKey.push(tarId);
        } else {
          arrayForKey.splice(valueIndex, 1);
        }
      }
      companySettingsApi.updateCompanySettingsKey(+id, 'client-status-changes', {
        value: newData
      }).then(res => {
        setSourcesSettings(newData);
      });
    }
  }, [selectedSource]);
  return <StatusesSettingsStyles>
        <div className="header">
          <TargetIcon />
          {targetStatus.text}
        </div>
        <div className="selectField">
          <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
            <span className="placeholder">Оберіть статуси</span>
            <div className={open ? 'arrowDownActive' : ''}>
              <ArrowDownIcon />
            </div>
          </div>

          {open && <div className="options" ref={optionsListRef}>
              {Object.entries(reviewsStatusIconsAndText(interfaceLanguage)).filter(([key, value]) => +key !== targetStatus.value).map(([key, value]) => {
          const Icon = value.icon;
          return <div key={key} className="option">
                      <div className="rowSel">
                        <button type="button" onClick={() => handleUpdate(value.value)}>
                          <div className="labelContent">
                            <div className="default">
                              {selected.includes(+key) && <CheckMark />}
                            </div>
                            <Icon />
                            <span>
                              {value.text.length > 20 ? `${value.text.slice(0, 20)}...` : value.text}
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>;
        })}
            </div>}
        </div>
      </StatusesSettingsStyles>;
});