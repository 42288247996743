import styled from 'styled-components';

export const UploadFileAreaStyles = styled.div`
  width: 100%;
  padding: 15px;
  border: 2px dashed #808080;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    display: none;
  }

  p {
    opacity: 0.5;
    font-size: 12px;
    letter-spacing: -0.02em;
    line-height: 200%;
    color: #000;
  }

  h6 {
    margin: 0;
    font-size: 18px;
    word-break: break-word;
    line-height: 150%;
    letter-spacing: 0.00938em;
    font-weight: 400;
    text-align: center;
    color: #000;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
