import styled from 'styled-components';
export const RecoveryStyles = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  .formWrapper {
    position: relative;
    width: 95%;
    max-width: 544px;
    min-width: 320px;

    &::after {
      position: absolute;
      top: -2px;
      left: -2px;
      z-index: 0;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      content: "";
      background: linear-gradient(99.68deg, #c4ed99 7.23%, #88c5f2 106.37%);
    }

    .formContainer {
      background: #fff;
      position: relative;
      z-index: 1;
      padding: 56px 48px;
      display: flex;
      flex-direction: column;
      gap: 46px;

      @media screen and (max-width: 768px) {
        padding: 32px 16px;
      }
      
      h2 {
        font-size: 38px;
        line-height: 135%;
        font-weight: 400;

        @media screen and (max-width: 768px) {
          font-size: 25px;
        }
      }

      h3 {
        font-size: 28px;
        line-height: 135%;
        font-weight: 400;

        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
      
      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 46px;
        position: relative;
      }
      
      .form__link {
        line-height: 160%;
        font-weight: 400;
        font-size: 16px;
        text-decoration: none;
        color: #353637;
        cursor: pointer;

        &:link, &:visited, &:active, &:hover, &:focus {
          color: #353637;
        }
      }
    }
  }
`;