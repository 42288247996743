import styled from 'styled-components';
export const AcessRequestStyles = styled.div`
  width: 100%;

  .togglerContainer {
    padding-top: 20px;
    padding-left: 16px;
    display: flex;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      margin-left: 24px;
      min-width: 100px;

      & > button {
        width: 100%;
      }
    }
  }

  .searchWrapper {
    display: flex;
    align-items: center;
    padding: 0 19px;
    border-bottom: 1px solid #f0f0f0;

    input {
      width: 100%;
      height: 48px;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    input::placeholder {
      font-size: 15px;
      color: #6c6c6c;
    }
  }

  .editButtonsContainer {
    .menuOpenerContainer {
      display: flex;
      justify-content: flex-end;
      position: relative;

      .menuOpenerButton {
        padding: 0 20px;
        cursor: pointer;
      }

      .menu {
        position: relative;

        .menuList {
          z-index: 99;
          position: absolute;
          top: calc(50% + 15px);
          right: 16px;
          width: 260px;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          overflow: hidden;
          filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));

          list-style: none;
          font-size: 14px;
          color: #000000;

          li {
            height: 41px;
            background: #ffffff;
            border-bottom: 1px solid #e5e5e5;
            box-sizing: border-box;

            &:last-child {
              border-bottom: none;
            }
          }

          button {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 16px;
            box-sizing: border-box;
            transition: background-color 0.3s;

            &:hover {
              background-color: #f0f5f5;
              transition: background-color 0.3s;
            }
          }

          span {
            margin-left: 12px;
          }
        }
      }
    }

    .modalBody {
      .modalButtonsWrap {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .modalButtonsCaption {
          margin-bottom: 16px;
          font-size: 14px;
          color: #000000;
        }

        div {
          display: flex;
          justify-content: space-between;
        }
      }

      .modalError {
        font-size: 11px;
        line-height: 16px;
        color: #ff3333;
        margin-bottom: 0;
      }
    }
  }

  .filterRow {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-left: 10px;
    }
  }

  .entityTitle {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 8px;
    }

    svg {
      transition: all 0.3s ease-in-out;
      width: 16px;
      height: 16px;
    }
  }

  .filterTitle {
    font-weight: 400;
    margin-right: 10px;
  }

  .filtersOpener {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    min-width: 150px;
    align-items: center;
    padding: 4px 10px;
    border: 1px solid #e5e5e5;
    &-active {
      svg {
        transition: all 0.3s ease-in-out;
        transform: rotate(180deg);
      }
    }
  }
`;