import React from 'react';
import { ApiGetTemplates } from '../../../api';
export const initialValues = (companyID: number): ApiGetTemplates => ({
  name: '',
  template: '',
  companyID,
  id: undefined
});
export const tabsEdit: (lang: string) => {
  [key: string]: string;
}[] = lang => [{
  key: 'sms',
  text: 'SMS'
}, {
  key: 'viber',
  text: 'Viber'
}, {
  key: 'email',
  text: 'Email'
}];
export const WrapBlocks = ({
  text
}: {
  text: string;
}) => {
  const splitText = text.split(/({{[^}]+}})/);
  return <div className="template">
      {splitText.map((part, index) => {
      if (part.match(/{{\.[^}]+}}/)) {
        return <span key={index}>{part}</span>;
      }
      return part;
    })}
    </div>;
};