import styled from 'styled-components';
export const StatusesCounterStyles = styled.div`
  .wrapper {
    overflow: auto;
    display: flex;
    width: 100%;

    .status {
      padding: 9px;
      background-color: white;
      border-radius: 5px;
      margin-right: 8px;

      .statusHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .statusCount {
        margin-right: 12px;
        font-weight: 700;
        font-size: 18px;
      }

      svg {
        flex-shrink: 0;
      }

      .statusContent {
        color: #a1a1a1;

        @media screen and (max-width: 1200px) {
          display: none;
        }
      }

      .statusName {
        font-size: 14px;
        color: #0c0005;
      }
    }
  }
`;