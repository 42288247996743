/* eslint-disable no-underscore-dangle */
import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../i18n';
import { formApi } from '../../../api';
import { utils, write } from 'xlsx';

const isPhoneValid = (value: string | undefined): boolean => typeof value === 'string' && value.startsWith('+');

const dynamicObjectSchema = Yup.object().test({
  name: 'is-dynamic-object',
  test: (value) => {
    if (value == null || typeof value !== 'object') {
      return false;
    }
    const areAllValuesStrings = Object.values(value).every(
      (val) => typeof val === 'string',
    );
    const hasPhoneField = 'phone' in value;
    const phoneStartsWithPlus = hasPhoneField && isPhoneValid(value.phone);

    return areAllValuesStrings && hasPhoneField && phoneStartsWithPlus;
  },

  message:
    // eslint-disable-next-line no-template-curly-in-string
    '${path} must be an object with string values and contain a "phone" field',
});

const templateSchema = (lang: string) => Yup.lazy((value) => {
  if (value === null) {
    return Yup.mixed()
      .nullable()
      .required(getTranslationByLangOrEng(lang, 'validation_required'));
  }

  return Yup.object({
    id: Yup.number(),
    companyID: Yup.number().required(),
    name: Yup.string().required(),
    template: Yup.string().required(),
    createdAt: Yup.string(),
    updatedAt: Yup.string(),
  });
});

const formSchema = (lang: string) => Yup.lazy((value) => {
  if (value === null) {
    return Yup.mixed()
      .nullable()
      .required(getTranslationByLangOrEng(lang, 'validation_required'));
  }

  return Yup.object({
    id: Yup.number(),
    companyID: Yup.number().required(),
    createdAt: Yup.string(),
    updatedAt: Yup.string(),
  });
});

const providerSchema = (lang: string) => Yup.lazy((value) => {
  if (value === null) {
    return Yup.mixed()
      .nullable()
      .required(getTranslationByLangOrEng(lang, 'validation_required'));
  }

  return Yup.object({
    name: Yup.string().required(),
  });
});

export const validationSchema = (lang: string) => Yup.object({
  mailingType: Yup.object()
    .nullable()
    .required(getTranslationByLangOrEng(lang, 'validation_required')),
  campaign: Yup.string()
    .required(getTranslationByLangOrEng(lang, 'validation_required'))
    .min(1, 'Campaign should not be empty')
    .nullable(),
  template: templateSchema(lang),
  form: formSchema(lang),
  // provider: providerSchema(lang),
  // fileItems: Yup.array(dynamicObjectSchema)
  //   .required()
  //   .min(1, 'list must have at least 1 item'),
});

export function extractVariables(inputString: string): string[] {
  const regex: RegExp = /{{\.(.*?)}}/g;
  const matches: string[] = [];
  let match: RegExpExecArray | null = regex.exec(inputString);
  while (match !== null) {
    matches.push(match[1]); // Pushing without the curly braces
    match = regex.exec(inputString);
  }
  return matches;
}

export function areKeysPresent(
  obj: Record<string, any>,
  arr: string[],
): boolean {
  for (const key in obj) {
    if (!arr.includes(key)) {
      return false;
    }
  }
  return true;
}

export const validateE164PhoneNumber = (phoneNumber?: string) => {
  // E.164 standard regex pattern
  const e164Pattern = /^\+[1-9]\d{1,14}$/;
  // Test the phone number against the pattern
  return phoneNumber ? e164Pattern.test(phoneNumber) : false;
};

export const exportFile = async (formId: number) => {
  const data: { [key: string]: string } = { phone: 'phone value' };
  const fields = await formApi.getHiddenFormFields(formId);
  const filtered = fields.data.map(
    (e) => e.options.find((el) => el.key === 'hiddenKey')?.value,
  );
  // eslint-disable-next-line guard-for-in
  for (const key of filtered) {
    data[`url_${key}`] = `${key} value`;
  }
  const ws = utils.json_to_sheet([data]);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Sheet1');
  const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
  const fileName = 'template.xlsx';
  // @ts-ignore
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // @ts-ignore
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
};

export const validateXLSX = (
  data: { [key: string]: string }[],
  requiredColumns: {
    key: string;
    title: string;
  }[],
): {
  valid: boolean;
  errors: string[];
  warnings: string[];
} => {
  const phoneColumn = 'phone';
  const errors: string[] = [];
  let warnings: string[] = [];
  // Check if all required columns exist
  requiredColumns.push({ key: 'phone', title: 'Phone' });

  const missingColumns = requiredColumns.filter(
    (column) => !Object.keys(data[0]).includes(column.key),
  );
  warnings = missingColumns.map((e) => `${e.title}(${e.key})`);

  const phoneMissing = missingColumns.find((e) => e.key === 'phone');

  if (phoneMissing) {
    errors.push('Phone is required field');
  }
  if (!phoneMissing) {
    // Check if phone numbers are valid E.164 numbers
    const invalidPhoneNumbers = data.filter(
      (row, rowIndex) => !validateE164PhoneNumber(
        row[phoneColumn]?.[0] === '+'
          ? row[phoneColumn]
          : `+${row[phoneColumn]}`,
      ),
    );
    if (invalidPhoneNumbers.length > 0) {
      errors.push('Wrong phone number');
      invalidPhoneNumbers.forEach((row, rowIndex) => {
        // @ts-ignore
        errors.push(`row ${row.__rowNum__ + 1}: ${row[phoneColumn] ?? ''}`);
      });
    }
  }

  return {
    errors,
    warnings,
    valid: !errors.length,
  };
};

export const removeDuplicates = (array: any[], key: string) => array.filter(
  (item, index, self) => index === self.findIndex((t) => t[key] === item[key]),
);
