import arrowBack from './arrow_back.svg';
import close from './close.svg';
import filterAlt from './filter_alt.svg';
import filterAltOff from './filter_alt_off.svg';
import expandMore from './expand_less.svg';
import expandLess from './expand_more.svg';
export const ArrowBack = () => <img src={arrowBack} alt="" />;
export const Close = () => <img src={close} alt="" />;
export const FilterAlt = () => <img src={filterAlt} alt="" />;
export const FilterAltOff = () => <img src={filterAltOff} alt="" />;
export const ExpandMore = () => <img src={expandMore} alt="" />;
export const ExpandLess = () => <img src={expandLess} alt="" />;