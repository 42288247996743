import React, { CSSProperties, useEffect, useRef } from 'react';
import { ModalStyles } from './ModalStyles';
import { JustPlusIcon } from '../../../assets';
import { TModal } from './types';
import { CrossIcon } from '../../../assets/icons/CrossIcon';
export const Modal: React.FC<TModal> = React.memo(({
  extraStyles,
  children,
  onClose,
  title
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);
  return <ModalStyles onClick={event => {
    event.stopPropagation();
  }}>
        <div ref={modalRef} className="modalBody" style={extraStyles}>
          {title && <div className="modalTitle">
              <span>{title}</span>
              <button type="button" onClick={onClose}>
                <CrossIcon color="#999" width={49} height={48} />
              </button>
            </div>}
          <div className={title ? 'modalContent' : 'modalContent noTitle'}>
            {children}
          </div>
        </div>
      </ModalStyles>;
});