import styled from 'styled-components';
export const CompanyPostersStyles = styled.div`
  padding: 26px 32px;
  
  .noDataWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    text-align: center;

    .noDataText {
      line-height: 120%;
      font-size: 20px;
      font-weight: 700;
    }
  }
  
  .postersContent {
    max-width: 600px;
    
    .postersHead {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: space-between;
      
      margin-bottom: 40px;
    }

    .postersWrapper {
      display: flex;
      flex-direction: column;
      gap: 18px;
      
      margin-bottom: 32px;

      .poster {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .posterName {
          color: #000;
          text-decoration: none;
          
          font-size: 16px;
          line-height: 100%;
        }
      }

      .splitter {
        width: 100%;
        height: 1px;
        background-color: rgb(229, 229, 229);

        &:last-child {
          display: none;
        }
      }
    }
  }
`;