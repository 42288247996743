import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { RatingByReviewsDashboardStyles } from './styles';
import { analyticsApi, ApiReviewsRating, TPostReviewRating } from '../../../../api';
import { Table } from '../../../organisms';
type TRatingByReviewsDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[];
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
  title: string;
  ascCategories?: string;
  ascTypes?: string;
};
const RatingByReviewsDashboard_1 = _compiledBlock(_props => _props.v0, {
  name: "RatingByReviewsDashboard_1",
  portals: ["v0"]
});
export const RatingByReviewsDashboard: FC<TRatingByReviewsDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions,
  title,
  ascCategories,
  ascTypes
}) => {
  const [data, setData] = useState<ApiReviewsRating[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const dataCount = useRef<number>(0);
  useEffect(() => {
    if (!loading) {
      setData([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostReviewRating = {
      from: from as string,
      to: to as string,
      cnapTypesRating: ascTypes ? [ascTypes] : [],
      ascCategories: ascCategories ? [ascCategories] : [],
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[]
    };
    setLoading(true);
    analyticsApi.getReviewsRatingData(+companyID, filteredData, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
      setData(prev => [...prev, ...res.data]);
      dataCount.current = res.count ? res.count : 0;
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  }, [pageNumber, reload]);
  useEffect(() => {
    setTableHeaders([{
      Header: 'IDF',
      accessor: 'idf',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.idf}</span>
          </div>
    }, {
      Header: 'Назва центру (у точу числі територіальних підрозділів центру/віддалених робчих місць адміністраторів центру/мобільних центрів)',
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.name}</span>
          </div>
    }, {
      Header: 'Рейтинг',
      accessor: 'rating',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.rating}</span>
          </div>
    }, {
      Header: 'Відгуки',
      accessor: 'reviews',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>
              {data.row.original.reviews}
            </span>
          </div>
    }]);
  }, [data]);
  return /*@million jsx-skip*/<RatingByReviewsDashboard_1 v0={<RatingByReviewsDashboardStyles>
      <h2>{title}</h2>
      <div className="rating">
        {tableHeaders && <Table columns={tableHeaders} data={data} fullWidthColumns={['name']} totalCount={dataCount.current} currentPage={pageNumber} offset={10} loading={loading} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
      </div>
    </RatingByReviewsDashboardStyles>} />;
};