import { TAdditionalParams, TAnswer } from '../../../api';

export interface IFieldColor {
  name: string;
  color: { r: number; g: number; b: number; a: number } | string;
}
export interface IFieldFile {
  name: string;
  url: string;
}

type AdditionalOptionParams = {
  showChoiceDescription?: boolean;
  choiceDescriptionTitle?: string;
  choiceDescriptionPlaceholder?: string;
  choiceDescriptionRequired?: boolean;
};

export type ExtendedAnswer = TAnswer & AdditionalOptionParams;

export const addAdditionalParam = (
  value: boolean | string | number,
  key: string,
  array: TAdditionalParams[],
) => {
  const stringValue = value.toString();
  const index = array.findIndex((param) => param.key === key);
  if (index !== -1) {
    array[index].value = stringValue;
  } else {
    array.push({ key, value: stringValue });
  }
};

export const reduceAdditionalColorParam = (
  colorArray: IFieldColor[],
  array: TAdditionalParams[],
  startsWith: string,
) => {
  colorArray.forEach((el, index) => {
    const item: TAdditionalParams = {
      key: '',
      value: '',
    };

    item.key = `${startsWith}${index + 1}`;
    item.value = `${el.name}|||${el.color}`;

    array.push(item);
  });
};

export const reduceAdditionalImageParam = (
  imageArray: IFieldFile[],
  array: TAdditionalParams[],
  startsWith: string,
) => {
  imageArray.forEach((el, index) => {
    const item: TAdditionalParams = {
      key: '',
      value: '',
    };

    item.key = `${startsWith}${index + 1}`;
    item.value = `${el.name}|||${el.url}`;

    array.push(item);
  });
};

export const reduceFieldArray = (
  array: TAdditionalParams[],
  startsWith: string,
): TAdditionalParams[] => {
  const arr: TAdditionalParams[] = [];

  array.forEach((el) => {
    if (el.key.startsWith(startsWith)) {
      arr.push(el);
    }
  });

  return arr;
};

export const mapApiColorToFieldColor = (color: string): IFieldColor => {
  const resColor: IFieldColor = {
    name: '',
    color: '',
  };
  const apiArr = color.split('|||');

  resColor.color = apiArr[1];
  resColor.name = apiArr[0];

  return resColor;
};

export const mapApiFileToFieldFile = (color: string): IFieldFile => {
  const resFile: IFieldFile = {
    name: '',
    url: '',
  };
  const apiArr = color.split('|||');

  resFile.url = apiArr[1];
  resFile.name = apiArr[0];

  return resFile;
};

export const mapFieldColorToApiColor = (color: IFieldColor): string => `${color.name}|||${color.color}`;

export const createAnswersArray = (min: number, max: number): TAnswer[] => {
  const result: TAnswer[] = [];
  for (let i = min; i <= max; i++) {
    result.push({ text: i.toString() } as TAnswer);
  }
  return result;
};

export const reduceOptionsParam = (
  answers: ExtendedAnswer[],
  array: TAdditionalParams[],
  startsWith: string,
) => {
  answers.forEach((el) => {
    const item: TAdditionalParams = {
      key: '',
      value: '',
    };

    item.key = startsWith;
    item.value = `${el.text}|||${el.choiceDescriptionTitle || ''}|||${
      el.choiceDescriptionPlaceholder || ''
    }|||${!!el.choiceDescriptionRequired}`;

    if (el.showChoiceDescription) {
      array.push(item);
    }
  });
};

export const unfoldOptionsParam = (
  answers: ExtendedAnswer[],
  array: TAdditionalParams[],
  startsWith: string,
): ExtendedAnswer[] => {
  const additionalData: string[] = array
    .filter((el) => el.key === startsWith)
    .map((e) => e.value);
  const result = answers.map((el) => {
    const targetOption = additionalData.find(
      (e) => e.split('|||')[0] === el.text,
    );

    if (targetOption) {
      const targetOptionSplited: string[] = targetOption?.split('|||');
      return {
        ...el,
        showChoiceDescription: true,
        choiceDescriptionTitle: targetOptionSplited[1],
        choiceDescriptionPlaceholder: targetOptionSplited[2],
        choiceDescriptionRequired: targetOptionSplited[3] === 'true',
      };
    }

    return el;
  });

  return result;
};

export enum TabKeys {
  field = 'field',
  settings = 'settings',
}

export type TTab = {
  key: TabKeys;
  text: string;
};

export const tabs: TTab[] = [
  {
    key: TabKeys.field,
    text: 'Поле форми',
  },
  {
    key: TabKeys.settings,
    text: 'Додаткові налаштування поля',
  },
];

// export const scaleTypes: string[] = ['color', 'star', 'numeric', 'numericColor'];
export const scaleTypes: string[] = ['color', 'star', 'numeric'];

export const scaleColors = {
  colorScale: {
    colors: ['#EB5757', '#EF9644', '#F4E02D', '#18ECD6'],
    pointerColor: '#EB5757',
  },
  numericScale: {
    numbersColor: '#000',
    selectedButtonColor: '#eee',
  },
  starScale: {
    selectedStarColor: '#FF8C19',
    notSelectedStarBackground: '#fff',
    notSelectedStarBorder: '#FF8C19',
  },
  numericColorScale: {},
};

// const colorSchema = Yup.object({
//   id: Yup.number(),
//   name: Yup.string(),
//   color: Yup.lazy((value) => {
//     switch (typeof value) {
//       case 'object':
//         return Yup.object();
//       case 'string':
//         return Yup.string();
//       default:
//         return Yup.mixed();
//     }
//   }),
// });

// export const colorScaleValidationSchema = Yup.object({
//   mainScaleColors: Yup.array().of(colorSchema),
//   pointerColor: colorSchema,
//   maxValue: Yup.number(),
//   defaultValue: Yup.number(),
//   changeBackgroundColor: Yup.boolean(),
//   backgroundColors: Yup.array()
//     .of(colorSchema)
//     .test(
//       'background-colors-right-quantity',
//       '* Количество значений должно равняться максимальному значению шкалы',
//       (val, ctx) => {
//         const { maxValue, changeBackgroundColor } = ctx.parent;
//         return changeBackgroundColor ? val!.length >= (maxValue + 1 || 10) : true;
//       },
//     ),
//   changeMascot: Yup.boolean(),
//   mascots: Yup.array().test(
//     'mascots-right-quantity',
//     '* Количество значений должно равняться максимальному значению шкалы',
//     (val, ctx) => {
//       const { maxValue, changeMascot } = ctx.parent;
//       return changeMascot ? val!.length >= (maxValue + 1 || 10) : true;
//     },
//   ),
// });

// export const numberScaleValidationSchema = Yup.object({
//   numbersColor: colorSchema,
//   selectedButtonColor: colorSchema,
//   maxValue: Yup.number(),
//   defaultValue: Yup.number(),
//   changeBackgroundColor: Yup.boolean(),
//   backgroundColors: Yup.array()
//     .of(colorSchema)
//     .test(
//       'background-colors-right-quantity',
//       '* Количество значений должно равняться максимальному значению шкалы',
//       (val, ctx) => {
//         const { maxValue, changeBackgroundColor } = ctx.parent;
//         return changeBackgroundColor ? val!.length >= (maxValue + 1 || 5) : true;
//       },
//     ),
//   changeMascot: Yup.boolean(),
//   mascots: Yup.array().test(
//     'mascots-right-quantity',
//     '* Количество значений должно равняться максимальному значению шкалы',
//     (val, ctx) => {
//       const { maxValue, changeMascot } = ctx.parent;
//       return changeMascot ? val!.length >= (maxValue + 1 || 5) : true;
//     },
//   ),
// });

// export const starScaleValidationSchema = Yup.object({
//   selectedStarColor: colorSchema,
//   notSelectedStarBorder: colorSchema,
//   notSelectedStarBackground: colorSchema,
//   maxValue: Yup.number(),
//   defaultValue: Yup.number(),
//   changeBackgroundColor: Yup.boolean(),
//   backgroundColors: Yup.array()
//     .of(colorSchema)
//     .test(
//       'background-colors-right-quantity',
//       '* Количество значений должно равняться максимальному значению шкалы',
//       (val, ctx) => {
//         const { maxValue, changeBackgroundColor } = ctx.parent;
//         return changeBackgroundColor ? val!.length >= (maxValue || 5) : true;
//       },
//     ),
//   changeMascot: Yup.boolean(),
//   mascots: Yup.array().test(
//     'mascots-right-quantity',
//     '* Количество значений должно равняться максимальному значению шкалы',
//     (val, ctx) => {
//       const { maxValue, changeMascot } = ctx.parent;
//       return changeMascot ? val!.length >= (maxValue || 5) : true;
//     },
//   ),
// });
