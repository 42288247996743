import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FormsPagesStyles } from './styles';
import { ApiAddPage, TLocalizations, TPage, localizationsApi, pagesApi } from '../../../api';
import { Checkbox } from '../../molecules';
import { toast } from 'react-toastify';
import { DeleteIcon } from '../../../assets';
type TFormsPages = {
  localization: TLocalizations;
  setLocalization: Dispatch<SetStateAction<TLocalizations | null>>;
  reQuery: boolean;
  setReQuery: Dispatch<SetStateAction<boolean>>;
  selectedPage: TPage | null;
  setSelectedPage: Dispatch<SetStateAction<TPage | null>>;
};
const defPage: ApiAddPage = {
  options: [],
  styles: []
};
export const FormsPages: FC<TFormsPages> = ({
  localization,
  setLocalization,
  reQuery,
  setReQuery,
  setSelectedPage,
  selectedPage
}) => {
  const [formPages, setFormPages] = useState<TPage[]>([]);
  const handleToggleDefault = () => {
    localizationsApi.updateLocalizations(localization.id, {
      isDefault: !localization.isDefault
    }).then(res => {
      toast.success('Локалізацію оновлено');
      setReQuery(!reQuery);
    });
  };
  const handleRemovePage = (id: number) => {
    pagesApi.deletePage(id).then(res => {
      if (res.statusCode === 200) {
        setReQuery(!reQuery);
        toast.success('Сторінку видалено');
      }
    });
  };
  const handleAddPage = () => {
    pagesApi.addFormPage(localization.id, defPage).then(res => {
      if (res.statusCode === 201) {
        setFormPages(prev => [...prev, res.data]);
        toast.success('Сторінку додано');
      }
    });
  };
  useEffect(() => {
    localizationsApi.getLocalizations(localization.id).then(res => {
      setLocalization(res.data);
    });
  }, [reQuery]);
  useEffect(() => {
    localizationsApi.getLocalizationPages(localization.id).then(res => {
      setFormPages(res.data.sort((a, b) => a.id - b.id));
    });
  }, [localization, reQuery]);
  return <FormsPagesStyles>
      <div className="header">
        <h6>Доступні сторінки локалізації: {localization.language.icon}</h6>
      </div>
      <div className="utils">
        <Checkbox name="isSentiment" value={localization.isDefault} onChange={handleToggleDefault}>
          <span className="checkboxValue">Локалізація по замовчуванню</span>
        </Checkbox>
      </div>
      <div className="pages">
        {formPages.map((el, index) => <div key={el.id} className={`pages-item ${el.id === selectedPage?.id ? 'pages-item-selected' : ''}`} onClick={() => setSelectedPage(el)}>
            <h6>{index + 1}</h6>

            <div className="pages-info">
              <p>ID сторінки: <span>{el.id}</span></p>
              <p>ID наступної: <span>{el.nextPageID}</span></p>
            </div>

            <button type="button" className="pages-item-del" onClick={e => {
          e.stopPropagation();
          handleRemovePage(el.id);
        }}>
              <DeleteIcon color="#C4C4C4" />
            </button>
          </div>)}
        <div onClick={handleAddPage} className="pages-add">Додати нову сторінку</div>
      </div>
    </FormsPagesStyles>;
};