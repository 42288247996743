import styled, { css } from 'styled-components';

export const TransparentButtonStyles = styled.div<{
  disabled: boolean;
  filledColor?: string;
}>`
  .linkContentWrapper {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    text-decoration: none;
  }

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 32px;
    background: ${({ disabled }) => (disabled ? '#FAFAFA' : '#FFFFFF')};
    border: ${({ disabled, filledColor }) => (disabled ? '1px solid #E5E5E5' : `1px solid ${filledColor || '#000'}`)};
    box-sizing: border-box;
    border-radius: 33px;
    padding: 0 17px;
    transition: all 0.3s;
    /* min-width: 120px; */
    position: relative;

    span {
      font-size: 14px;
      color: ${({ disabled, filledColor }) => (disabled ? 'rgb(154, 154, 154)' : `${filledColor || '#000'}`)};
      transition: all 0.3s;
    }

    ${({ disabled, filledColor }) => !disabled
      && css`
        &:hover {
          background: ${filledColor || '#000'};
          transition: all 0.3s;

          span {
            color: #ffffff;
            transition: all 0.3s;
          }
        }
      `}

    &.filled {
      background: ${({ filledColor }) => `${filledColor || '#000'}`};

      span {
        color: #ffffff;
        transition: all 0.3s;
      }

      ${({ disabled, filledColor }) => !disabled
        && css`
          &:hover {
            background: #ffffff;
            transition: all 0.3s;

            span {
              color: ${filledColor || '#000'};
              transition: all 0.3s;
            }
          }
        `}
    }
  }

  .statusWrapper {
    position: absolute;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
  }
`;
