import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { setNodeMetadata, setNodeNames, useAppDispatch, useAppSelector } from '../../../state';
import { FormValues, getFilteredNodeNames, networks, validationSchema } from './utils';
import { ICompanyUser } from '../../../entities';
import { formApi, usersApi } from '../../../api';
import { getErrorMessage, getNodeNamesArray, getNodesDeepArray, handleKeyUp } from '../../../utils';
import { getTranslationByLangOrEng } from '../../../i18n';
import CustomSelectTiedNode from '../../form/customSelect/CustomSelectTiedNode';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { EditNodeStyles } from './EditNodeStyles';
import { Loader } from '../../atoms';
import { Checkbox, CustomSelect, InputField } from '../../molecules';
import { useDebounce } from '../../../hooks';
type TAddNode = {
  nodeId: string;
  companyId: number;
  onClose: () => void;
};
export const EditNode = React.memo(({
  nodeId,
  companyId,
  onClose
}: TAddNode) => {
  const dispatch = useAppDispatch();
  const {
    selectedCompany,
    nodeNames,
    nodeMetadata
  } = useAppSelector(state => state.company);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [usersByCompany, setUsersByCompany] = useState<ICompanyUser[]>([]);
  const [searchUser, setSearchUser] = useState<string>('');
  const usersCount = useRef<number>(0);
  const nodeNameRef = useRef<FormikProps<FormValues>>(null);
  const debouncedSearch = useDebounce(searchUser, 500);
  function getFormInitialData(nodes: {
    id: number;
    name: string;
    deep: number;
  }[]) {
    if (nodeId) {
      formApi.getNodeById(+nodeId).then(res => {
        if (res.statusCode === 200) {
          dispatch(setNodeMetadata(res.data));
          const data = {
            name: res.data.name,
            slug: res.data.slug,
            code: res.data.code,
            tiedNode: res.data.tiedNode,
            secondTiedNode: res.data.secondTiedNode,
            address: res.data.address || {
              value: ''
            },
            nodes: getNodesDeepArray(nodes),
            sms: res.data.sms || {
              active: false,
              key: '',
              alphaName: ''
            },
            viber: res.data.viber || {
              active: false,
              key: '',
              alphaName: ''
            },
            telegram: res.data.telegram || {
              active: false,
              key: '',
              alphaName: ''
            },
            whatsapp: res.data.whatsapp || {
              active: false,
              key: '',
              alphaName: ''
            },
            email: res.data.email || {
              active: false,
              key: '',
              alphaName: ''
            },
            responsibleIDs: res.data.responsibleIDs || [],
            enableOverdue: res.data.enableOverdue,
            isLocation: res.data.isLocation
          };
          setInitialValues({
            ...data
          });
          setIsLoading(false);
        }
      });
    }
  }
  useEffect(() => {
    setIsLoading(true);
    if (companyId !== undefined && +companyId === selectedCompany?.id && nodeNames) {
      const filteredNodeNames = [...nodeNames];
      getFormInitialData([{
        id: 0,
        name: '-',
        deep: 0
      }, ...(nodeId ? getFilteredNodeNames(filteredNodeNames, nodeId) : filteredNodeNames)]);
    } else if (companyId !== undefined) {
      formApi.getCompanyNodes(+companyId!).then(res => {
        const nodeNamesArray: {
          id: number;
          name: string;
          deep: number;
        }[] = [];
        getNodeNamesArray(nodeNamesArray, res.data || []);
        getFormInitialData([{
          id: 0,
          name: '-',
          deep: 0
        }, ...(nodeId ? getFilteredNodeNames(nodeNamesArray, nodeId) : nodeNamesArray)]);
        dispatch(setNodeNames(nodeNamesArray));
      });
    }
  }, [nodeId]);
  useEffect(() => {
    setUserLoading(true);
    usersApi.getUsersByCompany(+companyId, 0, 0, debouncedSearch).then(res => {
      usersCount.current = res.count ? res.count : 0;
      setUsersByCompany(res.data);
      setUserLoading(false);
    });
  }, [debouncedSearch]);
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setSubmitting(false);
    const data = {
      name: values.name,
      enableOverdue: values.enableOverdue,
      address: values.address,
      sms: values.sms,
      viber: values.viber,
      telegram: values.telegram,
      email: values.email,
      parentID: values.tiedNode || 0,
      referencedID: values.secondTiedNode || 0,
      isLocation: values.isLocation
    };
    if (nodeId && companyId) {
      const res = await formApi.updateNode(+nodeId, +companyId, data);
      if (res.statusCode >= 200 && res.statusCode < 300) {
        if (values.address.id) {
          await formApi.updateNodeMetadata(values.address.value, values.address.id);
        } else if (!values.address.id && values.address.value) {
          await formApi.createNodeMetadata({
            key: 'address',
            parentID: +nodeId,
            value: values.address.value
          });
        }
        const promises: Promise<any>[] = [];
        networks.forEach(socialNetwork => {
          const socialNetworkKey: 'sms' | 'viber' | 'telegram' | 'email' | 'whatsapp' = socialNetwork.key;
          if (values[socialNetworkKey].keyId) {
            promises.push(formApi.updateNodeMetadata(values[socialNetworkKey].key, values[socialNetworkKey].keyId!));
            promises.push(formApi.updateNodeMetadata(values[socialNetworkKey].alphaName, values[socialNetworkKey].alphaNameId!));
            promises.push(formApi.updateNodeMetadata(values[socialNetworkKey].active ? 'true' : 'false', values[socialNetworkKey].activeId!));
          }
          if (!values[socialNetworkKey].keyId && values[socialNetworkKey].key) {
            promises.push(formApi.createNodeMetadata({
              key: `${socialNetworkKey}_key`,
              parentID: +nodeId,
              value: values[socialNetworkKey].key
            }));
          }
          if (!values[socialNetworkKey].keyId && values[socialNetworkKey].alphaName) {
            promises.push(formApi.createNodeMetadata({
              key: `${socialNetworkKey}_alphaName`,
              parentID: +nodeId,
              value: values[socialNetworkKey].alphaName
            }));
          }
        });
        await Promise.all(promises);
      } else {
        setErrorMessage(res.data);
      }
    }
    onClose();
  }
  const renderForm = ({
    values,
    setFieldValue,
    handleChange
  }: FormikProps<FormValues>) => <Form>
        <div className="formSection additionalSettings">
          <InputField name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_name_placeholder')} value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_name_label')} required />

          <InputField name="code" disabled onChange={setFieldValue} onKeyUp={() => handleKeyUp('code', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_code_placeholder')} value={values.code} error={typeof errorMessage === 'object' ? getErrorMessage('code', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_code_label')} />

          <CustomSelectTiedNode label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_label')} name="tiedNode" options={values.nodes} selectKey="name" placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_placeholder')} value={nodeNames?.find(node => node.id === values.tiedNode)} handleSelect={node => {
        setFieldValue('tiedNode', node.id);
      }} search expandable />

          <CustomSelectTiedNode label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_add_tied_node_label_second')} name="secondTiedNode" options={values.nodes} selectKey="name" placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_placeholder')} value={nodeNames?.find(node => node.id === values.secondTiedNode)} handleSelect={node => {
        setFieldValue('secondTiedNode', node.id);
      }} formGroupStyles={{
        width: '100%'
      }} search expandable />

          <InputField name="address.value" onChange={setFieldValue} onKeyUp={() => handleKeyUp('address.value', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_address_placeholder')} value={values.address.value} error={typeof errorMessage === 'object' ? getErrorMessage('address.value', errorMessage) : undefined} label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_address_label')} />
          <Checkbox name="enableOverdue" value={values.enableOverdue} onChange={handleChange}>
            <span className="checkboxValue">
              Використовувати таймер закінчення терміну дії
            </span>
          </Checkbox>

          <Checkbox name="isLocation" value={values.isLocation} onChange={handleChange}>
            <span className="checkboxValue">Локація</span>
          </Checkbox>

          <CustomSelect options={usersByCompany} valueField="id" labelField="name" name="responsibleIDs" onChange={(value: any) => {
        const currentResponsibleIDs = [...values.responsibleIDs];
        const index = currentResponsibleIDs.indexOf(value.id);
        if (index !== -1) {
          formApi.removeResponsibleNode(+nodeId, value.id).then(res => {});
          currentResponsibleIDs.splice(index, 1);
        } else {
          formApi.addResponsibleNode(+nodeId, [...currentResponsibleIDs, value.id]).then(res => {});
          currentResponsibleIDs.push(value.id);
        }
        setFieldValue('responsibleIDs', currentResponsibleIDs);
      }} selected={values.responsibleIDs} search multiple loading={userLoading} label={getTranslationByLangOrEng(interfaceLanguage, 'responsible')} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'responsible_id')} customSearch={setSearchUser} />
          <SubmitButton>
            {getTranslationByLangOrEng(interfaceLanguage, 'save_button')}
          </SubmitButton>
          {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
        </div>
      </Form>;
  return <EditNodeStyles>
        {!initialValues || isLoading ? <Loader /> : <Formik innerRef={nodeNameRef} enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema(interfaceLanguage)}>
            {renderForm}
          </Formik>}
      </EditNodeStyles>;
});