/* eslint-disable class-methods-use-this */
import {
  ICompany,
  ICompanyForm,
  IFormListItem,
  INode,
} from '../../entities';
import {
  mapApiFormsToIFormListItems,
  mapFromApiNodeToINode,
  mapFromApiNodesToINodes,
  mapImportDataToFormData,
} from '../Mappers';
import baseApi from '../baseApi';
import {
  ApiCompany,
  ApiCopyForm,
  ApiNode,
  ApiResponse,
} from '../entities';
import IFormApi from './IFormApi';

class FormApi implements IFormApi {
  public async getHiddenFormFields(
    id: number,
  ): Promise<ApiResponse<{ options: { key: string; value: string }[] }[]>> {
    const { data, statusCode } = await baseApi.getData(
      `/forms/${id}/fields?type=hidden`,
    );

    return {
      data,
      statusCode,
    };
  }

  public async getFormsByNode(
    id: number,
  ): Promise<ApiResponse<IFormListItem[]>> {
    const { data, statusCode } = await baseApi.getData(`/nodes/${id}/forms`);

    return {
      data: mapApiFormsToIFormListItems(data),
      statusCode,
    };
  }

  public async getFormsByCompany(
    id: number,
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<ICompanyForm[]>> {
    const res = await baseApi.getData(
      `/companies/${id}/forms?offset=${offset || 0}&limit=${limit || 10}`,
    );

    return Promise.resolve({
      data: res.data.forms,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async createCompany(data: {
    name: string;
    alias: string;
  }): Promise<ApiResponse<ApiCompany>> {
    return baseApi.postData('/companies', data);
  }

  public async editCompanyMainInfo(data: {
    name: string;
    alias: string;
    id: number;
  }): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.putData(`/companies/${data.id}`, {
      name: data.name,
      alias: data.alias,
    });
  }

  // responsible methods
  public async addResponsibleNode(
    nodeId: number,
    responsibleIDs: number[],
  ): Promise<ApiResponse<any>> {
    return baseApi.postData(`/nodes/${nodeId}/responsibles`, {
      responsibleIDs,
    });
  }

  public async removeResponsibleNode(
    nodeId: number,
    responsibleID: number,
  ): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/nodes/${nodeId}/responsibles/${responsibleID}`);
  }

  public async deleteCompany(id: number): Promise<ApiResponse<ApiCompany>> {
    return baseApi.deleteData(`/companies/${id}`);
  }

  public async getNodeFeedbackForms(
    nodeId: number,
  ): Promise<ApiResponse<IFormListItem[]>> {
    const { data, statusCode } = await baseApi.getData(
      `/nodes/${nodeId}/forms`,
    );

    return {
      data: mapApiFormsToIFormListItems(data),
      statusCode,
    };
  }

  public async deleteFeedbackForm(formId: number): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.deleteData(`/forms/${formId}`);

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async getNodeByIdPartially(
    nodeId: number,
  ): Promise<ApiResponse<Partial<INode>>> {
    const { statusCode, data } = await baseApi.getData(`/nodes/${nodeId}`);

    return Promise.resolve({
      statusCode,
      data: mapFromApiNodeToINode(data),
    });
  }

  public async getNodeById(id: number): Promise<ApiResponse<INode>> {
    const { statusCode, data } = await baseApi.getData(`/nodes/${id}/full`);
    return Promise.resolve({
      statusCode,
      data: mapFromApiNodeToINode(data),
    });
  }

  public async getCompanyNodes(
    companyId: number,
  ): Promise<ApiResponse<INode[]>> {
    const { statusCode, data } = await baseApi.getData(
      `/companies/${companyId}/nodes`,
    );

    return Promise.resolve({
      statusCode,
      data: mapFromApiNodesToINodes(data),
    });
  }

  public async getCompanies(): Promise<ApiResponse<ICompany[]>> {
    const companies = await baseApi.getData('/auth/companies/list ');
    return Promise.resolve({
      statusCode: 200,
      data: companies.data,
    });
  }

  public async getCompanyNodesV2(
    companyId: number,
  ): Promise<ApiResponse<INode[]>> {
    const data = await baseApi.getData(
      `/auth/companies/${companyId}/nodes/list`,
    );

    return Promise.resolve({
      statusCode: 200,
      data: mapFromApiNodesToINodes(data.data),
    });
  }

  public async createNode(data: {
    companyID: number;
    name: string;
    code: string | undefined;
    parentID?: number;
    sourceID: number;
  }): Promise<ApiResponse<INode>> {
    const { statusCode, data: response } = await baseApi.postData(
      '/nodes',
      data,
    );

    return {
      statusCode,
      data: mapFromApiNodeToINode(response),
    };
  }

  public async updateNode(
    nodeId: number,
    companyId: number,
    data: Partial<ApiNode>,
  ): Promise<ApiResponse<INode>> {
    const { statusCode, data: response } = await baseApi.putData(
      `/nodes/${nodeId}`,
      { companyID: companyId, ...data },
    );

    return {
      statusCode,
      data: mapFromApiNodeToINode(response),
    };
  }

  public async deleteNode(nodeId: number): Promise<ApiResponse<undefined>> {
    const { statusCode } = await baseApi.deleteData(`/nodes/${nodeId}`);

    return Promise.resolve({ data: undefined, statusCode });
  }

  public async createNodeMetadata(data: {
    key: string;
    value: string;
    parentID: number;
  }): Promise<ApiResponse<any>> {
    const { statusCode, data: response } = await baseApi.postData(
      '/metadata/nodes',
      data,
    );

    return {
      statusCode,
      data: response,
    };
  }

  public async updateNodeMetadata(
    data: string,
    id: number,
  ): Promise<ApiResponse<{ code: number; message: string }>> {
    const { statusCode, data: response } = await baseApi.putData(
      `/metadata/nodes/${id}`,
      { value: data },
    );

    return {
      statusCode,
      data: response,
    };
  }

  public async deleteNodeMetadata(
    id: number,
  ): Promise<ApiResponse<{ code: number; message: string }>> {
    return baseApi.deleteData(`/metadata/nodes/${id}`);
  }

  public async importNodes(data: {
    file: File;
    sheet: string;
    companyID: string;
    width?: number;
    height?: number;
  }): Promise<ApiResponse<any>> {
    const formDataFromImportData = mapImportDataToFormData(
      data.file,
      data.sheet,
      data.companyID,
    );
    return baseApi.postData('/imports/nodes', formDataFromImportData, true);
  }

  public async exportNodes(companyID: number): Promise<ApiResponse<any>> {
    const { data, statusCode } = await baseApi.getData(
      `/exports/nodes/${companyID}`,
      true,
    );

    return {
      data,
      statusCode,
    };
  }

  public async copyForm(data: ApiCopyForm): Promise<ApiResponse<any>> {
    return baseApi.postData('/forms/copy', data);
  }
}

export const formApi = new FormApi();
