import React from 'react';
import { CustomTabsMenuStyles } from './CustomTabsMenuStyles';
import { TCustomTabsMenu } from './TCustomTabsMenu';
export function CustomTabsMenu<T extends React.Key>({
  tabs,
  selectedTab,
  setSelectedTab,
  withContainer
}: TCustomTabsMenu<T>) {
  if (withContainer) {
    return <CustomTabsMenuStyles>
        <div className="tabsContainer">
          {tabs.map(tab => <button className={selectedTab === tab.key ? 'active' : ''} key={tab.key} type="button" onClick={() => setSelectedTab(tab.key)}>
              {tab.text}
            </button>)}
        </div>
      </CustomTabsMenuStyles>;
  }
  return <CustomTabsMenuStyles>
      <div className="tabsContainer">
        {tabs.map(tab => <button className={selectedTab === tab.key ? 'active' : ''} key={tab.key} type="button" onClick={() => setSelectedTab(tab.key)}>
            {tab.text}
          </button>)}
      </div>
    </CustomTabsMenuStyles>;
}