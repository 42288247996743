import React, { CSSProperties, Dispatch, SetStateAction, useState } from 'react';
import { InputFieldStyles } from './styles';
import { EyeIcon } from '../../../../assets';
interface InputFieldProps {
  name: string;
  value: string | number | undefined;
  min?: number;
  max?: number;
  placeholder?: string;
  onChange: Dispatch<SetStateAction<string>>;
  error?: string | undefined;
  showError?: boolean;
  extraBlockStyles?: CSSProperties;
  extraInputStyles?: CSSProperties;
  label?: string;
  onKeyUp?: () => void;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  children?: any;
  autocomplete?: boolean;
}
export const InputSingleField = React.memo(({
  name,
  placeholder,
  label,
  error,
  showError = true,
  value,
  min,
  max,
  onChange,
  onKeyUp,
  extraBlockStyles,
  extraInputStyles,
  disabled,
  type,
  required,
  children,
  autocomplete
}: InputFieldProps) => {
  const [hide, setHide] = useState<boolean>(type === 'password');
  return <InputFieldStyles style={extraBlockStyles}>
        <label htmlFor={name}>
          {label && <span>
              {label}
              {required && <span className="required"> *</span>}
            </span>}
          <div className="inputWrap">
            <div className="inputContainer">
              <input id={name} type={(type === 'password' ? hide ? 'password' : 'text' : type) || 'text'} min={type === 'number' ? min : undefined} max={type === 'number' ? max : undefined} value={value} onChange={event => onChange(event.target.value)} onKeyUp={onKeyUp} placeholder={placeholder} disabled={disabled} className={disabled ? 'disabled' : ''} style={extraInputStyles} autoComplete={autocomplete ? 'on' : 'off'} />
              {type === 'password' && <button type="button" onClick={() => setHide(!hide)}>
                  <EyeIcon fill={hide ? '#c6c6c6' : '#000'} />
                </button>}
              {showError && <div className="formErrorContainer">
                  {error && <span>{error}</span>}
                </div>}
            </div>
            <div className="childrenContainer">{children}</div>
          </div>
        </label>
      </InputFieldStyles>;
});