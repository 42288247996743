export enum EditTabKeys {
  info = 'info',
  design = 'design',
  documents = 'documents',
  issuesStatusesAndTypes = 'issuesStatusesAndTypes',
  templates = 'templates',
  sources = 'sources',
  reviewTableColumnSettings = 'reviewTableColumnSettings',
  integrations = 'integrations',
  settings = 'settings',
  tags = 'tags',
  qrStyles = 'qrStyles',
  posters = 'posters',
}

export type TTabsEdit = {
  key: EditTabKeys;
  text: string;
};
