import styled from 'styled-components';
export const ClientStatusesStyles = styled.div`
  .clientStatuses {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(50% - 15px);
    }
  }
`;