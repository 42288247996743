import React, { useState } from 'react';
import { ExpandableProps } from './types';
import { ExpandableStyles } from './styles';
import { ExpandMore } from '../../../assets/material-icons';
import { TransparentButton } from '../transparentButton';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { Collapsible } from '../collapsible';
export const Expandable: React.FC<ExpandableProps> = ({
  children,
  isOpen
}) => {
  const {
    languages: languagesState,
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  return <ExpandableStyles>
      <div className="expandable">
        <div className={`expandable-content ${isOpen ? 'open' : ''}`}>
          {children}
        </div>
      </div>
    </ExpandableStyles>;
};