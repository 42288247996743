import React, { useEffect, useMemo, useState, CSSProperties, useRef, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { CalendarFilterStyles } from './CalendarFilterStyles';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import { useWindowSize } from 'usehooks-ts';
const periods: string[] = ['quarter', 'months', 'days7'];
interface Props {
  setReQuery: () => void;
  setFilterParams: (params: {
    [key: string]: number | string | string[] | number[] | any;
  }) => void;
  filterParams: {
    [key: string]: number | string | any;
  };
  onClose: () => void;
  extraBlockStyles?: CSSProperties;
  setApply?: (params: {
    [key: string]: number | string | any;
  }) => void;
}
function setMidnightTime(date: Date) {
  return new Date(date.setHours(0, 0, 0, 0));
}
function getStartDate() {
  const date = new Date();
  const result = setMidnightTime(new Date(date.setDate(date.getDate() - 4)));
  if (new Date(result).getMonth() !== new Date().getMonth()) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  return new Date(result);
}
export const CalendarFilter = React.memo(({
  setReQuery,
  onClose,
  filterParams,
  setFilterParams,
  extraBlockStyles,
  setApply
}: Props) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    width
  } = useWindowSize();
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<'from' | 'to'>('from');
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const addDay = (date: Date, quantity?: number) => new Date(date.getTime() + (quantity ? 86400000 * quantity : 86400000));
  const filterFrom = filterParams.from && filterParams.from.includes('%') ? toDate(filterParams.from) : filterParams.from && !filterParams.from.includes('%') ? filterParams.from : undefined;
  const filterTo = filterParams.to && filterParams.to.includes('%') ? toDate(filterParams.to) : filterParams.to && !filterParams.to.includes('%') ? filterParams.to : undefined;
  const initialFromDate: Date = useMemo(() => filterFrom ? new Date(filterFrom as number | string | Date) : getStartDate(), []);
  const initialToDate: Date = useMemo(() => filterTo ? new Date(filterTo as number | string) : setMidnightTime(new Date()), []);
  useEffect(() => {
    setDateFrom(initialFromDate);
    setDateTo(initialToDate);
    setLoaded(true);
  }, []);
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        submitButtonRef.current?.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  const handleDateFrom = (date: Date) => {
    if (date.toISOString() >= dateTo!.toISOString()) {
      setDateTo(date);
    }
    setDateFrom(date);
  };
  const handleSubmit = () => {
    const dateQuery: {
      [key: string]: string;
    } = {};
    const dateFromWithMidnightTime = setMidnightTime(dateFrom!);
    const dateToWithMidnightTime = setMidnightTime(dateTo!);
    if (dateFromWithMidnightTime.toISOString() !== initialFromDate!.toISOString() || !filterParams.from) {
      dateQuery.from = zonedTimeToUtc(new Date(dateFromWithMidnightTime!), timezone).toISOString();
    }
    if (dateToWithMidnightTime.toISOString() !== initialToDate!.toISOString() || !filterParams.to) {
      dateQuery.to = zonedTimeToUtc(new Date(addDay(dateToWithMidnightTime!, 1)), timezone).toISOString();
    }
    if (Object.keys(dateQuery).length) {
      setFilterParams({
        ...filterParams,
        ...dateQuery
      });
      if (setApply) {
        setApply({
          ...filterParams,
          ...dateQuery
        });
      }
      setReQuery();
    }
    onClose();
  };
  const handleReset = () => {
    const currentFilters = {
      ...filterParams
    };
    delete currentFilters.from;
    delete currentFilters.to;
    setFilterParams(currentFilters);
    if (setApply) {
      setApply(currentFilters);
    }
    setReQuery();
    onClose();
  };
  const comparedTime = useCallback((period: string): boolean => {
    const comparedDate: Date = new Date();
    switch (period) {
      case 'quarter':
        comparedDate.setMonth(comparedDate.getMonth() - 3);
        break;
      case 'months':
        comparedDate.setMonth(comparedDate.getMonth() - 1);
        break;
      case 'days7':
        comparedDate.setDate(comparedDate.getDate() - 7);
        break;
      default:
        break;
    }
    const dateFromWithMidnightTime = setMidnightTime(comparedDate);
    const dateToWithMidnightTime = addDay(setMidnightTime(new Date()), 1);
    if (dateFrom && dateTo) {
      return dateFromWithMidnightTime.toString() === dateFrom.toString() && dateToWithMidnightTime.toString() === dateTo.toString();
    }
    return false;
  }, [dateFrom, dateTo]);
  const handleSetTemplatePeriod = useCallback((period: string) => {
    const dateQuery: {
      [key: string]: string;
    } = {};
    const templateDateFrom: Date = new Date();
    switch (period) {
      case 'quarter':
        templateDateFrom.setMonth(templateDateFrom.getMonth() - 3);
        break;
      case 'months':
        templateDateFrom.setMonth(templateDateFrom.getMonth() - 1);
        break;
      case 'days7':
        templateDateFrom.setDate(templateDateFrom.getDate() - 7);
        break;
      default:
        break;
    }
    const dateFromWithMidnightTime = setMidnightTime(templateDateFrom);
    const dateToWithMidnightTime = setMidnightTime(new Date());
    dateQuery.from = zonedTimeToUtc(new Date(dateFromWithMidnightTime), timezone).toISOString();
    dateQuery.to = zonedTimeToUtc(new Date(addDay(dateToWithMidnightTime, 1)), timezone).toISOString();
    if (Object.keys(dateQuery).length) {
      console.log('subm');
      console.log(filterParams);
      console.log(dateQuery);
      setFilterParams({
        ...filterParams,
        ...dateQuery
      });
      if (setApply) {
        setApply({
          ...filterParams,
          ...dateQuery
        });
      }
      setReQuery();
    }
    onClose();
  }, []);
  return <CalendarFilterStyles style={extraBlockStyles} tableVersion={width < 1020}>
        {loaded && <>
            {width < 1020 && <div className="calendarFromToTabs">
                <button className={selectedTab === 'from' ? 'active' : ''} type="button" onClick={() => setSelectedTab('from')}>
                  From
                </button>
                <button className={selectedTab === 'to' ? 'active' : ''} type="button" onClick={() => setSelectedTab('to')}>
                  To
                </button>
              </div>}

            <div className="calendars">
              {(width < 1020 && selectedTab === 'from' || width >= 1020) && <div className="calendar">
                  {width >= 1020 && <span>
                      {getTranslationByLangOrEng(interfaceLanguage, 'from')}
                    </span>}
                  <Calendar onChange={handleDateFrom} value={dateFrom} minDetail="year" minDate={new Date(2022, 4, 1)} maxDate={new Date()} locale={interfaceLanguage.toLowerCase()} />
                </div>}
              {(width < 1020 && selectedTab === 'to' || width >= 1020) && <div className="calendar">
                  {width >= 1020 && <span>
                      {getTranslationByLangOrEng(interfaceLanguage, 'to')}
                    </span>}
                  <Calendar onChange={setDateTo} value={dateTo} minDetail="year" minDate={dateFrom!} maxDate={new Date()} locale={interfaceLanguage.toLowerCase()} />
                </div>}
            </div>

            <div className="templates">
              {periods.map(period => <button disabled={comparedTime(period)} key={period} type="button" onClick={() => handleSetTemplatePeriod(period)}>
                  {getTranslationByLangOrEng(interfaceLanguage, period)}
                </button>)}
            </div>

            <button className="submitButton" type="button" onClick={() => handleSubmit()} ref={submitButtonRef}>
              {getTranslationByLangOrEng(interfaceLanguage, 'apply_filters_button')}
            </button>

            <button className="resetButton" type="button" onClick={() => handleReset()}>
              {getTranslationByLangOrEng(interfaceLanguage, 'reset_reviews_filter')}
            </button>
          </>}
      </CalendarFilterStyles>;
});