export const extraOneOptionStyles = {
  justifyContent: 'flex-start',
  paddingLeft: '6px',
  height: '40px',
};
export const resetFilterButtonStyles = {
  width: '100%',
  // maxWidth: '270px',
  marginTop: '14px',
  marginBottom: '14px',
  backgroundColor: '#ccc',
  color: '#ffffff',
  border: 'none',
};
