import React, { FC, useEffect, useRef, useState } from 'react';
import { DropdownContainer } from './styles';
import { TDropdownProps } from './types';
import { useClickOutside } from '../../../hooks';
import { DiiaArrowDownIcon } from '../../../assets/icons/DiiaArrowDown';
export const Dropdown: FC<TDropdownProps> = ({
  id,
  placeholder,
  value,
  variants,
  setValue,
  extraStyles
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const closeMenu = (el: HTMLElement | null) => {
    setIsOpened(false);
    el?.classList.remove('opened');
    setTimeout(e => {
      el?.classList.remove('fade-in-out');
    }, 20);
  };
  const openMenu = (el: HTMLElement | null, elements: HTMLCollection) => {
    setIsOpened(true);
    // find or other dropdown menus and close them
    Array.from(elements).filter(e => e.id !== `dropdown-menu-${id}`).forEach(e => e.classList.remove('fade-in-out', 'opened'));
    // add class for fade animation (changes display from none to flex)
    el?.classList.add('fade-in-out');
    // runs animation
    setTimeout(e => {
      el?.classList.add('opened');
    }, 0);
  };
  const toggleMenu = (isOpen: boolean) => {
    // current dropdown element
    const el = document.getElementById(`dropdown-menu-${id}`);
    // all dropdown elements
    const elements = document.getElementsByClassName('dropdown-menu');
    if (!isOpen) {
      closeMenu(el);
    } else {
      openMenu(el, elements);
    }
  };
  const setNewValue = (val: any) => {
    if (isOpened) {
      setValue(val);
      toggleMenu(false);
    }
  };
  useClickOutside(optionsListRef, () => {
    const el = document.getElementById(`dropdown-menu-${id}`);
    closeMenu(el);
  }, selectedValueFieldRef.current);
  return <DropdownContainer style={extraStyles}>
      <div className="container" ref={selectedValueFieldRef} onClick={() => toggleMenu(!isOpened)}>
        <div className={`placeholder ${value?.id ? 'selected' : ''}`}>
          <p>{placeholder}</p>
        </div>
        <div className="value">
          <p>{value?.value}</p>
        </div>
        <DiiaArrowDownIcon className={`icon ${variants.length > 1 && isOpened ? 'opened' : ''}`} />
      </div>
      {variants.length > 1 && <div ref={optionsListRef} id={`dropdown-menu-${id}`} className="dropdown-menu">
          {variants.filter(e => e.id !== value?.id).map(e => <div onClick={() => setNewValue(e)} className="dropdown-menu-item">
                {e.value}
              </div>)}
        </div>}
    </DropdownContainer>;
};