import styled from 'styled-components';

export const LocationSelectNodeItemStyles = styled.div<{ selectable: boolean }>`
  .rowItem {
    width: 100%;
    display: flex;
    height: 32px;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    box-sizing: border-box;

    & > button {
      padding: 0 10px;
    }
  }

  input {
    display: none;
    height: 32px;
  }

  div {
    width: 100%;
    height: 100%;
    font-size: 14px;
    grid-template-columns: 1fr;

    .labelContent {
      display: grid;
      align-items: center;
      text-align: left;
      padding-left: 10px;
      width: 100%;
      height: 100%;
      color: #6e6e6e;
      box-sizing: border-box;
      grid-template-columns: ${({ selectable }) => (selectable ? 'auto 1fr' : '1fr')};
    }

    &.expandButton {
      align-items: center;
      width: 24px;
    }

    .expandButton {
      transition: all 0.3s ease-in-out;

      &.expandable {
        transform: rotate(180deg);
      }
    }
  }
`;
