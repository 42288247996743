import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../i18n';
import { ILanguage, DeepArrayItem } from '../../../entities';

export interface ICopyForm {
  nodesList: DeepArrayItem[];
  nodeId: number;
  newFormName: string;
  newFormLanguage: ILanguage;
}

export const extraFieldsSettings = {
  url: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  updateAt: {
    textColor: '#6c6c6c',
  },
  status: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  feedbacks: {
    alignItems: 'center',
    justifyContent: 'center',
  },
};
