import styled from 'styled-components';

export const FieldConstructorStyles = styled.div`
    max-width: 540px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.02) 1px 1px 20px;
    border: 1px solid rgb(240, 245, 245);
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    position: relative;
    max-height: 100%;
    overflow: auto;

    .tabsContainer {
        padding-top: 20px;
    }

    .field {
        &-header {
            flex-shrink: 0;
            height: 60px;   
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: rgb(240, 245, 245);
            padding: 0px 16px;
            box-sizing: border-box;

            button {
                cursor: pointer;
            }
        }

        &-utils {
            padding: 16px; 
            display: flex;
            justify-content: flex-end;

            button {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;
            }
        }

        &-type {
            display: flex;
            align-items: center;
            gap: 10px;

            div {
                width: 40px;
            }
        }

        &-body {
            padding: 32px 16px;

            form {
                display: flex;
                flex-direction: column;
                gap: 25px;
            }
        }

        &-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-scales {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    .color {
        width: 100px;

        &-wrapper {
            max-width: 320px;
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        &-selected {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 320px;
            width: 100%;
            gap: 16px;
        }

        &-row {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        &-item {
            width: 24px;
            height: 24px;
            border-radius: 4px;
        }

        &-selector {
            display: flex;
        }
    }
`;

export const FieldScaleStyles = styled.div`
    .img {
        width: 100px;

        &-wrapper {
            max-width: 320px;
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        &-selected {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 320px;
            width: 100%;
            gap: 16px;
        }

        &-row {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        &-item {
            width: 24px;
            height: 24px;
            border-radius: 4px;
        }

        &-target {
            width: 70px;
            height: 50px;
        }

        &-addwrapper {
            width: 100%;
        }

        &-optionwrapper {
            display: flex;
            align-items: center;
            gap: 15px;
            width: 100%;
            padding: 5px 10px;
        }

        &-optionimg {
            max-width: 70px;

            img {
                width: 100%;
            }
        }

        &-target {
            width: auto;
        }

        &-selector {
            display: flex;
        }
    }
`;

export const FieldOptionsStyles = styled.div`
    .options {
        width: 100%;

        &-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        &-field {
            display: flex;
            flex-direction: column;
            gap: 30px;

            &-detailed {
                padding: 25px 10px;
                border: 1px dashed #ccc;
            }
        }

        &-raw {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
        }

        &-detailedFields {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        &-verticalLine {
            width: 1px;
            height: 40px;
            background-color: rgb(238, 238, 238);
        }

        &-detailed {
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }
    }

    .dropdown {
        width: 100%;

        &-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        &-field {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
`;

export const FieldFilesStyles = styled.div`
    .files {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;

        &-checkbox {
            margin-bottom: 0px !important;
            display: flex;
            align-items: center;
            gap: 8px;
        }

        &-icon {
            width: 22px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: auto;
                height: 18px;
            }
        }
    }
`;

export const FieldQuantityStyles = styled.div`
    .quantity {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
    }
`;
