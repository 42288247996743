import styled from 'styled-components';

export const FooterStyles = styled.footer`
  padding: 12px;
  width: 100%;
  background: #000;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  p {
    color: #fff;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: -0.02em;

    @media screen and (max-width: 768px) {
      font-size: 13px;
    }
  }

  .footer-logo {
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: 768px) {
      padding: 32px 0;
      order: 2;
      border-bottom: 1px solid #4C4C4C;

      svg {
        width: 70px;
      }
    }
  }

  .footer-links {
    display: flex;
    flex-direction: column;
  }

  .footer-social {
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: 768px) {
      padding: 32px 0;
      order: 1;
      border-bottom: 1px solid #4C4C4C;

      p {
        max-width: 100px;
      }
    }
  }
`;
