import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiChatCloseStatuses, companySettingsApi } from '../../../../api';
import { useClickOutside } from '../../../../hooks';
import { ChatCloseStatusesStyles } from './ChatCloseStatusesStyles';
import { ArrowDownIcon, CheckMark } from '../../../../assets';
import { reviewsStatusIconsAndText } from '../../../../constants';
import { useAppSelector } from '../../../../state';
type TChatCloseStatuses = {
  apiChatCloseStatuses: ApiChatCloseStatuses | undefined;
};
export const ChatCloseStatuses: FC<TChatCloseStatuses> = React.memo(({
  apiChatCloseStatuses
}) => {
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [chatCloseStatuses, setChatCloseStatuses] = useState<ApiChatCloseStatuses>({});
  const [open, setOpen] = useState(false);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const handleUpdate = useCallback((statusID: number) => {
    if (id) {
      const data: {
        [key: number]: boolean;
      } = {
        ...chatCloseStatuses
      };
      if (chatCloseStatuses.hasOwnProperty(statusID)) {
        data[statusID] = !chatCloseStatuses[statusID];
      } else {
        data[statusID] = true;
      }
      companySettingsApi.updateCompanySettingsKey(+id, 'chat-close-statuses', {
        value: data
      }).then(res => {
        setChatCloseStatuses(data);
      });
    }
  }, [chatCloseStatuses]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  useEffect(() => {
    if (apiChatCloseStatuses) {
      setChatCloseStatuses(apiChatCloseStatuses);
    }
  }, [apiChatCloseStatuses]);
  return <ChatCloseStatusesStyles>
        <div className="chatCloseStatuses">
          <h3>Статуси при яких автоматично закривається чат</h3>
          <div className="selectField">
            <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
              <span className="placeholder">Оберіть статуси</span>
              <div className={open ? 'arrowDownActive' : ''}>
                <ArrowDownIcon />
              </div>
            </div>

            {open && <div className="options" ref={optionsListRef}>
                {Object.entries(reviewsStatusIconsAndText(interfaceLanguage)).map(([key, value]) => {
            const Icon = value.icon;
            return <div key={key} className="option">
                      <div className="rowSel">
                        <button type="button" onClick={() => handleUpdate(+key)}>
                          <div className="labelContent">
                            <div className="default">
                              {chatCloseStatuses[+key] && <CheckMark />}
                            </div>
                            <Icon width={25} height={25} />
                            <span>
                              {value.text.length > 20 ? `${value.text.slice(0, 20)}...` : value.text}
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>;
          })}
              </div>}
          </div>
        </div>
      </ChatCloseStatusesStyles>;
});