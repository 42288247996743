import { ResizeCallbackData } from 'react-resizable';
import React from 'react';
import { ICreatePosterTemplate } from '../../../../entities/ICreatePosters';
import { ApiQRStyles, ApiQRTextStyles, filesApi } from '../../../../api';

export const dpiBackend = 300;
export const dpiFrontend = 72;

export type AvailableQRBordersColor = '#000' | '#FFF';

export interface PosterModalFormValues {
  name: string;
  poster: File | null;
  qrSettings: {
    x: number;
    y: number;
    w: number;
    h: number;
    invisibleQR: boolean;
    bordersColor: AvailableQRBordersColor;
  };
  textSettings: {
    withText: false;
    x: number;
    y: number;
    w: number;
    h: number;
  };
}

export const convertPixelsToPoints = (valueInPixels: number, dpi: number): number => {
  const inches = valueInPixels / dpi;
  return Math.round(inches * 72);
};

export const convertPointsToPixels = (valueInPoints: number, dpi: number): number => {
  const inches = valueInPoints / 72;
  return inches * dpi;
};

export const onResize = (
  e: React.SyntheticEvent,
  data: ResizeCallbackData,
  formikValues: PosterModalFormValues,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => any,
  key
: keyof PosterModalFormValues,
) => {
  const settings = formikValues[key
  ];

  if (settings && typeof settings === 'object' && 'x' in settings && 'y' in settings && 'w' in settings && 'h' in settings) {
    const { width, height } = data.size;
    const dimension = data.handle;
    const prevWidth = settings.w;
    const prevHeight = settings.h;

    const diffWidth = width - prevWidth;
    const diffHeight = height - prevHeight;

    const isExpanded = diffWidth > 0;
    const isExpandedHeight = diffHeight > 0;

    switch (dimension) {
      case 'n':
        break;

      case 'e':
        break

      case 'w':
        setFieldValue(`${key}.x`, Math.round(
          isExpanded ? settings
            .x - diffWidth : settings
            .x + -diffWidth,
        ))
        break;

      case 's':
        setFieldValue(`${key}.y`, Math.round(
          isExpandedHeight ? settings
            .y - diffHeight : settings
            .y + -diffHeight,
        ))
        break;

      case 'nw':
        setFieldValue(`${key}.x`, Math.round(
          isExpanded ? settings
            .x - diffWidth : settings
            .x + -diffWidth,
        ))
        break;

      case 'ne':
        break;

      case 'se':
        setFieldValue(`${key}.y`, (Math.round(
          isExpanded ? settings
            .y - Math.abs(diffWidth) : settings
            .y + Math.abs(diffWidth),
        )));
        break;

      case 'sw':
        setFieldValue(`${key}.y`, (Math.round(
          isExpanded ? settings
            .y - Math.abs(diffWidth) : settings
            .y + -diffWidth,
        )));
        setFieldValue(`${key}.x`, Math.round(
          isExpanded ? settings
            .x - diffWidth : settings
            .x + -diffWidth,
        ));
        break;
    }

    setFieldValue(`${key
    }.w`, Math.round(width));
    setFieldValue(`${key
    }.h`, Math.round(height));
  } else {
    console.error(`Invalid settings object for key: ${key}`);
  }
}

export async function prepareDataToPost(
  values: PosterModalFormValues,
  pdfDimensions: { width: number, height: number },
  qrStyles: ApiQRStyles,
  textStyles: ApiQRTextStyles,
): Promise<ICreatePosterTemplate> {
  const templateURL = await filesApi.createFile({ file: values.poster! }).then((res) => res.data.url);

  const preparedDataToPost: ICreatePosterTemplate = {
    name: values.name,
    url: templateURL,
    items: [
      {
        type: 'qr',
        x: convertPixelsToPoints(values.qrSettings.x, dpiFrontend),
        y: convertPixelsToPoints(pdfDimensions.height - values.qrSettings.h - values.qrSettings.y, dpiFrontend), // y with inversion
        width: convertPixelsToPoints(values.qrSettings.w, dpiFrontend),
        height: convertPixelsToPoints(values.qrSettings.h, dpiFrontend),
        qrStyles: {
          logoURL: qrStyles.logoURL,
          eyesStyle: qrStyles.eyesStyle,
          style: qrStyles.style,
          bgColor: qrStyles.bgColor,
          innerEyeColor: qrStyles.innerEyeColor,
          mainColor: qrStyles.mainColor,
          outerEyeColor: qrStyles.outerEyeColor,
        },
      },
    ],
  };

  if (values.textSettings.withText) {
    preparedDataToPost.items.push({
      x: convertPixelsToPoints(values.textSettings.x, dpiFrontend),
      y: convertPixelsToPoints(pdfDimensions.height - values.textSettings.h - values.textSettings.y, dpiFrontend), // y with inversion
      width: convertPixelsToPoints(values.textSettings.w, dpiFrontend),
      height: convertPixelsToPoints(values.textSettings.h, dpiFrontend),
      type: 'text',
      textStyles,
    });
  }

  return preparedDataToPost;
}
