import styled from 'styled-components';

export const CsiDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;

  h2 {
    width: 80%;
    text-align: center;
    font-size: 24px;

    @media screen and (max-width: 1400px) {
      font-size: 18px;
    }
  }

  .csi {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: auto;

    &-bar {
      width: 100%;
    }

    &-pie {
      margin-top: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 25px;
    }

    &-width {
      stroke-width: 1px;
    }

    &-pieitem {
      display: flex;
      gap: 35px;
      margin-top: 20px;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      h4 {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
`;
