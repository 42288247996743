import React from 'react';
import { FieldType } from '../../../api';
import { ButtonIcon, CalendarIcon, CheckboxIcon, DocumentCompanyIcon, DropdownIcon, EmailCompanyIcon, ExtraQuestionIcon, EyeIcon, FileCompanyIcon, NameIcon, NetworkIcon, PhoneCompanyIcon, PhotoWithPlusIcon, QuantityIcon, RadioButtonIcon, ScaleIcon, TitleIcon } from '../../../assets';
export type TFieldConstruct = {
  id?: number;
  type: FieldType;
};
export const typeColors: { [key in FieldType]: string } = {
  [FieldType.Caption]: '#F06292',
  [FieldType.Ces]: '#64B5F6',
  [FieldType.Checkbox]: '#81C784',
  [FieldType.ConnectionChannel]: '#FFB74D',
  [FieldType.Csat]: '#64B5F6',
  [FieldType.Date]: '#9575CD',
  [FieldType.DetailedResponse]: '#FF8A65',
  [FieldType.Dropdown]: '#DCE775',
  [FieldType.Email]: '#9575CD',
  [FieldType.FileUpload]: '#FFD54F',
  [FieldType.Hidden]: '#e0e0e0',
  [FieldType.Name]: '#BA68C8',
  [FieldType.Nps]: '#64B5F6',
  [FieldType.Paragraph]: '#FF8A65',
  [FieldType.Phone]: '#7986CB',
  [FieldType.Picture]: '#FF8A65',
  [FieldType.Quantity]: '#c04848',
  [FieldType.RadioButton]: '#DCE775',
  [FieldType.Scale]: '#FFF176',
  [FieldType.Confirmation]: '#FF8A65',
  [FieldType.Button]: '#F06292'
};
export const typeIcons: {
  [key: string]: React.ReactElement;
} = {
  [FieldType.Caption]: <TitleIcon height={12} width={448 / 512 * 12} color="#FFF" />,
  [FieldType.Paragraph]: <TitleIcon height={12} width={448 / 512 * 12} color="#FFF" />,
  [FieldType.Name]: <NameIcon height={12} width={640 / 512 * 12} color="#FFF" />,
  [FieldType.Email]: <EmailCompanyIcon height={12} width={512 / 512 * 12} color="#FFF" />,
  [FieldType.Phone]: <PhoneCompanyIcon height={12} width={512 / 512 * 12} color="#FFF" />,
  [FieldType.Checkbox]: <CheckboxIcon height={12} width={448 / 512 * 12} color="#FFF" />,
  [FieldType.RadioButton]: <RadioButtonIcon height={12} width={512 / 512 * 12} color="#FFF" />,
  [FieldType.Scale]: <ScaleIcon height={12} width={448 / 512 * 12} color="#FFF" />,
  [FieldType.Nps]: <ScaleIcon height={12} width={448 / 512 * 12} color="#FFF" />,
  [FieldType.Csat]: <ScaleIcon height={12} width={448 / 512 * 12} color="#FFF" />,
  [FieldType.Ces]: <ScaleIcon height={12} width={448 / 512 * 12} color="#FFF" />,
  [FieldType.Hidden]: <EyeIcon height={12} width={448 / 512 * 8} fill="#FFF" />,
  [FieldType.FileUpload]: <FileCompanyIcon height={12} width={512 / 512 * 12} color="#FFF" />,
  [FieldType.Date]: <CalendarIcon height={12} width={512 / 512 * 12} color="#FFF" />,
  [FieldType.ConnectionChannel]: <NetworkIcon height={14} width={384 / 512 * 14} color="#FFF" />,
  [FieldType.Picture]: <PhotoWithPlusIcon height={14} width={384 / 512 * 18} color="#FFF" />,
  [FieldType.Dropdown]: <DropdownIcon height={14} width={384 / 512 * 18} color="#FFF" />,
  [FieldType.Quantity]: <QuantityIcon height={14} width={448 / 512 * 14} color="#FFF" />,
  [FieldType.DetailedResponse]: <ExtraQuestionIcon height={12} width={320 / 512 * 12} color="#FFF" />,
  [FieldType.Confirmation]: <DocumentCompanyIcon height={12} width={512 / 512 * 12} color="#FFF" />,
  [FieldType.Button]: <ButtonIcon height={20} width={512 / 512 * 20} color="#FFF" />
};
export const typeNames: { [key in FieldType]: string } = {
  [FieldType.Caption]: 'Заголовок',
  [FieldType.Ces]: 'Показник зусиль клієнтів',
  [FieldType.Checkbox]: 'Декілька зі списку',
  [FieldType.ConnectionChannel]: 'Канали звʼязку',
  [FieldType.Csat]: 'Показник задоволеності клієнтів',
  [FieldType.Date]: 'Дата',
  [FieldType.DetailedResponse]: 'Детальна відповідь',
  [FieldType.Dropdown]: 'Випадаючий список',
  [FieldType.Email]: 'Електронна адреса',
  [FieldType.FileUpload]: 'Підвантаження файлів',
  [FieldType.Hidden]: 'Приховане поле',
  [FieldType.Name]: 'Імʼя',
  [FieldType.Nps]: 'Показник рекоментадії клієнтом',
  [FieldType.Paragraph]: 'Текстове поле',
  [FieldType.Phone]: 'Номер телефону',
  [FieldType.Picture]: 'Картинка',
  [FieldType.Quantity]: 'Лічильник',
  [FieldType.RadioButton]: 'Один зі списку',
  [FieldType.Scale]: 'Кастомна шкала',
  [FieldType.Confirmation]: 'Підтвердження',
  [FieldType.Button]: 'Кнопка'
};