import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../state';
import * as Yup from 'yup';
import { Modal } from '../Modal';
import { CopyRolesModalStyles } from './CopyRolesModalStyles';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { getTranslationByLangOrEng } from '../../../../i18n';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import { rolesApi } from '../../../../api';
import { IUserRole } from '../../../../entities';
import { InputField } from '../../../molecules';
type TCopyRolesModal = {
  onClose: () => void;
  roleId: number;
};
export const CopyRolesModal: FC<TCopyRolesModal> = React.memo(({
  onClose,
  roleId
}) => {
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [targetRole, setTargetRole] = useState<IUserRole>();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const fieldsValidation = (lang: string) => Yup.object({
    name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')).notOneOf([targetRole?.name], getTranslationByLangOrEng(lang, 'enter_uniq_name'))
  });
  function onSubmit(values: {
    name: string;
  }, {
    setSubmitting
  }: FormikHelpers<{
    name: string;
  }>) {
    setSubmitting(false);
    if (targetRole) {
      const data: IUserRole = {
        name: values.name,
        permissions: targetRole.permissions
      };
      rolesApi.createRole(data).then(res => {
        onClose();
      });
    }
  }
  function renderForm({
    values,
    setFieldValue
  }: FormikProps<{
    name: string;
  }>) {
    return <Form>
          <div className="fieldWrap">
            <InputField label={getTranslationByLangOrEng(interfaceLanguage, 'role_name')} name="name" onChange={setFieldValue} onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'enter_role_name')} value={values.name} error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined} extraBlockStyles={{
          width: '100%'
        }} required />
          </div>
          <div className="modalButtonsWrap">
            <SubmitButton>
              {getTranslationByLangOrEng(interfaceLanguage, 'save_button')}
            </SubmitButton>
          </div>
        </Form>;
  }
  useEffect(() => {
    rolesApi.getRole(+roleId).then(res => {
      setTargetRole(res.data);
    });
  }, []);
  return <CopyRolesModalStyles>
        <Modal title={getTranslationByLangOrEng(interfaceLanguage, 'copy_permissions')} onClose={onClose}>
          <Formik initialValues={{
        name: ''
      }} onSubmit={onSubmit} validationSchema={() => fieldsValidation(interfaceLanguage)}>
            {renderForm}
          </Formik>
        </Modal>
      </CopyRolesModalStyles>;
});