import React, { useEffect, useState } from 'react';
import { FormsStyles } from './styles';
import { TransparentButton, CustomNameField, FormPicker, SubHeader, Table } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiConstructorForm, ApiNode, ApiPosterTemplate, companyApi, constructorApi, nodesApi } from '../../../api';
import { useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { extraFieldsSettings } from './utils';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import PostersDropdown from '../../../components/molecules/postersDropdown/PostersDropdown';
import { companyPostersApi } from '../../../api/companyPosters/CompanyPostersApi';
export default function Forms() {
  const navigate = useNavigate();
  const {
    companyId,
    nodeId
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [feedbackForms, setFeedbackForms] = useState<ApiConstructorForm[]>([]);
  const [node, setNode] = useState<ApiNode | null>(null);
  const [posters, setPosters] = useState<ApiPosterTemplate[] | null>(null);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string;
    accessor: string;
    Cell?: any;
  }[]>();
  const [modal, setModal] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [companyAlias, setCompanyAlias] = useState<string>('');
  const handleDeleteRow = (id: number) => {
    setFeedbackForms([...feedbackForms.filter(item => item.id !== id)]);
  };
  const handleAdd = (form: ApiConstructorForm) => {
    if (nodeId) {
      constructorApi.attachForm(+nodeId, {
        isActive: true,
        enableOverdue: true,
        formID: form.id
      }).then(res => {
        if (res.statusCode === 201) {
          toast.success('Форму прикріплено', {
            autoClose: 10
          });
          setReQuery(!reQuery);
        }
      });
    }
  };
  useEffect(() => {
    if (nodeId) {
      constructorApi.getNodeForms(+nodeId).then(res => {
        setFeedbackForms(res.data);
      });
    }
    if (companyId) {
      companyApi.getCompanyById(+companyId).then(res => {
        if (res.statusCode === 200) {
          setCompanyAlias('https://diia.voicerhub.com/reviews/');
        }
      });
    }
  }, [reQuery]);
  useEffect(() => {
    async function getInitialData() {
      const nodeFromAPI = await nodesApi.getNodeByID(+nodeId!);
      const postersFromAPI = await companyPostersApi.getCompanyPosterTemplates(+companyId!);
      if (nodeFromAPI.statusCode >= 200 && nodeFromAPI.statusCode < 300 && postersFromAPI.statusCode >= 200 && postersFromAPI.statusCode) {
        setNode(nodeFromAPI.data);
        setPosters(postersFromAPI.data);
      }
    }
    if (nodeId && companyId) {
      getInitialData();
    }
  }, [nodeId]);
  useEffect(() => {
    if (feedbackForms.length && node) {
      setTableHeaders([{
        Header: 'Назва форми',
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <CustomNameField name={row.original.form.name} index={row.index} link={`${companyAlias}${row.original.slug}`} />
      }, {
        Header: 'menu',
        accessor: 'menu',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => {
          if (node && posters && posters.length > 0) {
            return <div className="form-btns">
                  <PostersDropdown node={node} posters={posters} form={row.original.form} />
                </div>;
          }
          return null;
        }
      }]);
    }
  }, [feedbackForms, companyAlias, node, posters]);
  return <FormsStyles>
      <Helmet>
        <title>Company node forms Voicer</title>
      </Helmet>

      <SubHeader title="Форми зворотного звязку фронт-офісів суб’єкту моніторингу">
        <div className="buttonsContainer">
          <TransparentButton handleClick={() => setModal(true)} filled text="Додати форму" />
          <TransparentButton handleClick={() => navigate(`/companies/${companyId}/nodes`)} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
        </div>
      </SubHeader>

      <Table pagination columns={tableHeaders || []} data={feedbackForms} hideFieldsSplitters extraFieldsSettings={extraFieldsSettings} hiddenHeaders={['customEditLink', 'editButtons', 'menu']} handleDeleteRow={handleDeleteRow} />

      {companyId && modal && <FormPicker companyID={+companyId} onClose={() => setModal(false)} handleSelect={handleAdd} />}
    </FormsStyles>;
}