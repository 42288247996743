import styled from 'styled-components';

export const ReviewsTableStyles = styled.div<{
  tableVersion: boolean;
  mobileVersion: boolean;
}>`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  .headerWrapper {
    display: flex;

    gap: 15px;
    padding: 5px;
  }

  .searchContainer {
    border-top: 1px solid #f0f0f0;
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    display: flex;
    align-items: center;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }

    div {
      display: flex;
      width: 50%;
      align-items: center;
      padding: 0 20px;
      border-bottom: 1px solid #f0f0f0;

      &:not(:last-child) {
        border-right: 1px solid #f0f0f0;
      }

      @media screen and (max-width: 991px) {
        width: calc(100% - 40px);
        border-left: 1px solid #f0f0f0;
        border-right: 1px solid #f0f0f0;
      }
    }

    input {
      width: 100%;
      height: 48px;
      margin-left: 11px;
      border: none;
      outline: none;

      @media screen and (max-width: 768px) {
        height: 32px;
      }
    }

    input::placeholder {
      font-size: 15px;
      color: #6c6c6c;
    }
  }

  .customReviewsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    .reviewContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 8px;
      gap: 4px;
      background: #f0f5f5;
      border-radius: 4px;

      span {
        background: #ff0101;
        border-radius: 35px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        padding: 0 6px;
      }
    }
  }

  .tableFiltersWrapper {
    padding: 5px 0px;
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;

    @media screen and (max-width: 768px) {
      align-items: flex-start;
    }

    .tableFiltersWrapperBtns {
      display: flex;
      gap: 10px;
      align-items: center;

      @media screen and (max-width: 768px) {
        align-items: flex-start;
        width: 100%;
        div,
        button {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      padding: 5px 16px;
      justify-content: flex-start;
      gap: 10px;
    }

    button {
      width: fit-content;
    }

    .calendarWrapper {
      display: flex;
      gap: 10px;
      height: 100%;
      align-items: center;
      position: ${({ tableVersion }) => (tableVersion ? 'initial' : 'relative')};

      .calendarOpenerWrapper {
        display: grid;
        grid-template-columns: repeat(3, auto);
        place-items: center;
        gap: 10px;
        cursor: pointer;

        .active {
          transform: rotate(180deg);
        }
      }

      .splitter {
        width: 1px;
        background: #f0f0f0;
        height: 100%;
      }
    }

    .filtersWrapper {
      position: ${({ tableVersion }) => (tableVersion ? 'initial' : 'relative')};

      .filtersOpener {
        cursor: pointer;
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr auto;
        align-items: center;

        &-active {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .connectionChannelContainer {
    max-width: 150px;
    width: 100%;
    display: flex;
    gap: 16px;

    .networkDataContainer {
      display: flex;
      align-items: center;

      span {
        font-size: 10px;
      }

      svg {
        flex-shrink: 0;

        @media screen and (max-width: 768px) {
          width: 20px;
        }
      }

      .botIsNotConnected {
        font-size: 12px;
      }
    }
  }

  .statusContainer {
    display: grid;
    place-items: center;
    gap: 2px;
    white-space: pre-line;
    text-align: center;
    font-size: 12px;
    color: #444;
  }

  .sourceContainer {
    text-align: center;
  }

  .answersContainer {
    .checkboxesList {
      list-style: none;
      display: grid;
      gap: 8px;
      color: #000000;
    }

    .answersExtraQuestionField {
      width: 200px;
    }

    .scaleContainer {
      font-weight: 700;
      font-size: 14px;
      color: #000000;
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: 4px;
    }
  }

  .nameContainer {
    position: relative;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      height: 12px;
    }

    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #6c6c6c;
      margin-bottom: 8px;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      color: #000000;
    }
  }

  .tableSettings {
    position: relative;
  }

  .chooseCompanyTitleContainer {
    position: relative;
    height: 50vh;

    .chooseCompanyTitle {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      font-weight: 700;
    }
  }

  .commentContainer {
    display: flex;
    justify-content: center;

    .commentField {
      width: 180px;
      text-align: center;
      word-wrap: break-word;
      font-size: 14px;
    }
  }

  .extraQuestionFieldAnswerContainer {
    display: flex;
    justify-content: center;

    .extraQuestionFieldAnswer {
      font-size: 14px;
      width: 180px;
      text-align: center;
      word-wrap: break-word;
    }
  }

  .categoryContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 200px;
    flex-wrap: wrap;
    position: relative;

    .categoryName {
      background-color: #f5f5f5;
      padding: 5px 8px;
      margin: 5px 5px;
      font-size: 14px;
      border-radius: 9px;
      text-align: center;
      word-wrap: break-word;
      border: 1px solid #ccc;
      position: relative;

      .details {
        z-index: 10;
        top: 100%;
        left: 100%;
        position: absolute;
        background-color: #f5f5f5;
        font-size: 14px;
        border-radius: 9px;
        text-align: center;
        word-wrap: break-word;
        border: 1px solid #ccc;
        width: 150px;
        border-top-left-radius: 0px;

        p:last-child {
          border-bottom: none;
        }
        .detail {
          text-align: start;
          padding: 5px 10px;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }

  .extraFilterFormStyles {
    height: 100%;
  }

  .extraFilterStyles {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-gap: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .optionsExtraStyles {
    border: none !important;
    border-radius: 0 !important;
    overflow-y: auto !important;
    max-height: none !important;
  }

  .chip-container {
    padding: 5px 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .attachmentContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .attachmentItem {
      display: flex;
      align-items: center;
      gap: 5px;
      color: rgb(101, 101, 101);
      font-size: 14px;
    }
  }
`;

export const SearchContainer = styled.div`
  padding: 4px;
  width: 100%;
  left: 15px;
  background-color: white;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-radius: 5px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    margin-left: 4px;
    width: calc(100% - 20px);
    height: 42px;
    padding: 0px;
    border: none;
    outline: none;
    background-color: #eef3f7;
    border-radius: 5px;
    padding-left: 10px;

    @media screen and (max-width: 768px) {
      height: 40px;
      margin-left: 0px;
    }
  }

  input::placeholder {
    font-size: 15px;
    color: #6c6c6c;
  }
`;

export const DropDownContainer = styled.div`
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  height: 42px;
  min-width: 150px;
  background-color: #eef3f7;

  /* border-top: 4px solid white;
  border-bottom: 4px solid white;

  border-right: 4px solid white;
  border-left: 4px solid white; */

  border-radius: 5px;

  @media screen and (max-width: 991px) {
    flex-direction: row;
    width: calc(100% - 20px);
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 10px;
`;

export const StyledSelect = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Separator = styled.div`
  width: 1px;
  /* background-color: rgb(240, 240, 240); */
  height: 100%;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 1px;
  }
`;

export const CustomReviewsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  .reviewContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;
    background: #f0f5f5;
    border-radius: 4px;

    span {
      background: #ff0101;
      border-radius: 35px;
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
      padding: 0 6px;
    }
  }
`;

export const TableFiltersWrapper = styled.div`
  padding: 5px 16px;
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }

  .tableFiltersWrapperBtns {
    display: flex;
    gap: 10px;
    align-items: center;

    @media screen and (max-width: 768px) {
      align-items: flex-start;
      width: 100%;
      flex-wrap: wrap;
      div,
      button {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    padding: 5px 16px;
    justify-content: flex-start;
    gap: 10px;
  }

  button {
    width: fit-content;
  }
`;

export const ConnectionChannelContainer = styled.div`
  max-width: 150px;
  width: 100%;
  display: flex;
  gap: 16px;
  .networkDataContainer {
    display: flex;
    align-items: center;
    span {
      font-size: 10px;
    }
    svg {
      flex-shrink: 0;

      @media screen and (max-width: 768px) {
        width: 20px;
      }
    }
    .botIsNotConnected {
      font-size: 12px;
    }
  }
`;

export const StatusContainer = styled.div`
  display: grid;
  place-items: center;
  gap: 2px;
  white-space: pre-line;
  text-align: center;
  font-size: 12px;
  color: #444;
`;

export const SourceContainer = styled.div`
  text-align: center;
`;

export const AnswersContainer = styled.div`
  .checkboxesList {
    list-style: none;
    display: grid;
    gap: 8px;
    color: #000000;
  }

  .answersExtraQuestionField {
    width: 200px;
  }

  .scaleContainer {
    font-weight: 700;
    font-size: 14px;
    color: #000000;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 4px;
  }
`;

export const NameContainer = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    height: 12px;
  }

  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #6c6c6c;
    margin-bottom: 8px;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #000000;
  }
`;

export const TableSettings = styled.div`
  position: relative;
`;

export const ChooseCompanyTitleContainer = styled.div`
  position: relative;
  height: 50vh;

  .chooseCompanyTitle {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: 700;
  }
`;

export const CommentContainer = styled.div`
  display: flex;
  justify-content: center;

  .commentField {
    width: 180px;
    text-align: center;
    word-wrap: break-word;
    font-size: 14px;
  }
`;

export const ExtraQuestionFieldAnswerContainer = styled.div`
  display: flex;
  justify-content: center;

  .extraQuestionFieldAnswer {
    font-size: 14px;
    width: 180px;
    text-align: center;
    word-wrap: break-word;
  }
`;

export const ExtraFilterFormStyles = styled.div`
  height: 100%;
`;

export const ExtraFilterStyles = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OptionsExtraStyles = styled.div`
  border: none !important;
  border-radius: 0 !important;
  overflow-y: auto !important;
  max-height: none !important;
`;

export const FilterBottomBtns = styled.div`
  position: absolute;
  bottom: 110px;
  left: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;
