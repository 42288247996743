/* eslint-disable class-methods-use-this */
import IAuthApi from './IAuthApi';
import baseApi from '../baseApi';
import { ChangeUserData, LoginData, RegisterData } from '../../state';
import { ApiAuthResponse, ApiResponse, ApiUser } from '../entities';
import {
  ICompleteUser, ICreateUser, IEdiUser, IUser,
} from '../../entities';
import { mapApiUserToIUser } from '../Mappers';
import { AuthUser } from '../../entities/AuthUser';

class AuthApi implements IAuthApi {
  public async login(data: LoginData): Promise<ApiResponse<ApiAuthResponse>> {
    const { statusCode, data: userData } = await baseApi.postData(
      '/login',
      data,
    );

    return {
      data: userData,
      statusCode,
    };
  }

  public async getMyAccount(id: number): Promise<ApiResponse<AuthUser>> {
    const { statusCode, data } = await baseApi.getData(`/auth/users/${id}`);

    return {
      data,
      statusCode,
    };
  }

  public async changeUserData(
    data: ChangeUserData,
  ): Promise<ApiResponse<ApiUser>> {
    return baseApi.putData(`/user/${data.id}`, data);
  }

  public async editUser(
    id: number,
    data: IEdiUser,
  ): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.putData(`/auth/users/${id}`, data);

    return {
      data: null,
      statusCode,
    };
  }

  public async completeUserData(
    id: number,
    data: ICompleteUser,
  ): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.postData(
      '/auth/steps/update-user',
      data,
    );
    return {
      data: null,
      statusCode,
    };
  }

  public async attachRole(data: {
    company: string;
    organization: string;
    userType: string;
  }): Promise<
    ApiResponse<{
      roleID: number;
      roleName: string;
    }>
  > {
    const { statusCode, data: response } = await baseApi.postData(
      '/auth/steps/select-role',
      data,
    );
    return {
      data: response,
      statusCode,
    };
  }

  public async createUser(data: ICreateUser): Promise<ApiResponse<IUser>> {
    const { data: response, statusCode } = await baseApi.postData(
      '/users',
      data,
    );

    return {
      data: mapApiUserToIUser(response),
      statusCode,
    };
  }

  public async attachRolesToUser(
    userId: number,
    roleIds: number[],
  ): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.postData(
      `/auth/users/${userId}/roles:add`,
      {
        rolesIDs: roleIds.map((role) => role),
      },
    );

    return {
      data: null,
      statusCode,
    };
  }

  public async detachRolesFromUser(
    userId: number,
    roleIds: number[],
  ): Promise<ApiResponse<null>> {
    const { statusCode } = await baseApi.postData(
      `/auth/users/${userId}/roles:remove`,
      {
        rolesIDs: roleIds.map((role) => role),
      },
    );

    return {
      data: null,
      statusCode,
    };
  }

  public async register(
    data: RegisterData,
  ): Promise<ApiResponse<ApiAuthResponse>> {
    return baseApi.postData('/register', data);
  }

  public async forgotPassword(
    email: string,
    language: string,
  ): Promise<ApiResponse<any>> {
    return baseApi.postData('/auth/password-forgot', {
      email,
      languageCode: language,
    });
  }

  public async passwordReset(
    data: { password: string; passwordConfirmation: string },
    code: string,
  ): Promise<ApiResponse<any>> {
    return baseApi.putData(`/auth/password-reset?code=${code}`, data);
  }

  public async validateResetCode(
    code: string,
  ): Promise<ApiResponse<{ isValid: boolean }>> {
    const { statusCode, data } = await baseApi.getData(
      `/auth/password-reset/${code}`,
    );
    return {
      data,
      statusCode,
    };
  }
}

export const authApi = new AuthApi();
