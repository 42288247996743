import styled from 'styled-components';

export const ScoreFilterStyles = styled.div`
  font-weight: 400;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: 200;
  width: 270px;
  padding: 16px;
  border-radius: 4px;
`;
