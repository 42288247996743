import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { CustomSelectStyles } from './CustomSelectStyles';
import { ArrowDownIcon, SearchIcon } from '../../../assets';
import { useClickOutside } from '../../../hooks';
import { IColor } from '../../../entities/IColor';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { Modal } from '../../organisms';
import { FormErrorMessage, TransparentButton } from '../../atoms';
import { ColorPicker } from '../../molecules';
interface SelectProps {
  label?: string;
  name: string;
  options: any[];
  placeholder: string;
  value: any;
  handleSelect: (option: IColor) => any;
  search?: boolean;
  formGroupStyles?: CSSProperties;
  optionsContainerStyles?: CSSProperties;
  extraComponentPosition?: 'left' | 'right';
  extraStyles?: CSSProperties;
  hideErrors?: boolean;
  handleAddColor?: (color: IColor) => void;
  required?: boolean;
  disabled?: boolean;
}
const CustomColorsSelect: React.FC<SelectProps> = ({
  label,
  name,
  options,
  placeholder,
  value,
  handleSelect,
  formGroupStyles,
  optionsContainerStyles,
  search,
  extraComponentPosition,
  extraStyles,
  hideErrors,
  handleAddColor,
  required,
  disabled
}) => {
  const {
    errors,
    touched
  } = useFormikContext();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [color, setColor] = useState<{
    rgb: {
      r: number;
      g: number;
      b: number;
      a?: number;
    };
    hex: string;
  }>({
    rgb: {
      r: 255,
      g: 255,
      b: 255,
      a: 1
    },
    hex: '#FFFFFF'
  });
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const filteredParams = useMemo(() => {
    if (search) {
      return options.filter(option => option.name.toLowerCase().includes(searchParams.toLowerCase()));
    }
    return options;
  }, [searchParams, options]);
  useEffect(() => {
    setSearchParams('');
  }, [open]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  return <CustomSelectStyles isOpened={open} style={extraStyles}>
      <div className={`form-group${
    // @ts-ignore
    touched[name] && !errors[name] ? ' valid' // @ts-ignore
    : touched[name] && errors[name] ? ' error' : ''}`} style={formGroupStyles || {}}>
        {label && <div className="labelContainer">
            <label htmlFor={name}>{label}</label>
            {required && <span className="required"> *</span>}
          </div>}
        <div className="selectField">
          <div className="selectedValue" onClick={() => disabled ? null : setOpen(!open)} ref={selectedValueFieldRef}>
            {value ? <div className="valueContainer colors">
                {extraComponentPosition === 'left' && <div className="color" style={{
              backgroundColor: typeof value.color === 'string' ? value.color : `rgba(${value.color.r}, ${value.color.g}, ${value.color.g}, ${value.color.a || 100}`
            }} />}
                <span>{value.name}</span>
                {extraComponentPosition === 'right' && <div className="color" style={{
              backgroundColor: typeof value.color === 'string' ? value.color : `rgba(${value.color.r}, ${value.color.g}, ${value.color.g}, ${value.color.a || 100}`
            }} />}
              </div> : <span className="placeholder">{placeholder}</span>}
            <div className="icon">
              <ArrowDownIcon />
            </div>
          </div>

          {disabled && <div className="loading" />}

          {open && <div className="options" style={optionsContainerStyles || {}} ref={optionsListRef}>
              {search && <div className="searchWrapper">
                  <SearchIcon />
                  <input type="text" value={searchParams} onChange={e => setSearchParams(e.target.value)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')} />
                </div>}
              {filteredParams.map(option => <div className="option color" key={`selectOption:${option.id}:${option.name}`}>
                  <div className="row">
                    <input autoComplete="off" type="radio" name={name} value={option.name} id={`${option.name}:${option.id}`} />
                    <button type="button" onClick={() => {
                handleSelect(option);
                setOpen(!open);
              }}>
                      <div className="colorOptionContent colors">
                        {extraComponentPosition === 'left' && <div className="color" style={{
                    backgroundColor: typeof option.color === 'string' ? option.color : `rgba(${option.color.r}, ${option.color.g}, ${option.color.g}, ${option.color.a || 100}`
                  }} />}
                        <span>{option.name}</span>
                        {extraComponentPosition === 'right' && <div className="color" style={{
                    backgroundColor: typeof option.color === 'string' ? option.color : `rgba(${option.color.r}, ${option.color.g}, ${option.color.g}, ${option.color.a || 100}`
                  }} />}
                      </div>
                    </button>
                  </div>
                </div>)}
            </div>}
        </div>

        {handleAddColor && <div className="addButtonWrapper">
            <TransparentButton disabled={disabled} handleClick={() => setModalVisible(true)} text={getTranslationByLangOrEng(interfaceLanguage, 'upload_color_button')} />
            {modalVisible && <Modal onClose={() => setModalVisible(false)}>
                <ColorPicker color={color} handleSubmit={(color: {
            rgb: {
              r: number;
              g: number;
              b: number;
              a?: number;
            };
            hex: string;
          }) => {
            setColor(color);
            handleAddColor({
              color: color.rgb.a === undefined || color.rgb.a === 1 ? color.hex : {
                ...color.rgb,
                a: color.rgb.a === undefined ? 100 : color.rgb.a
              },
              name: color.rgb.a === undefined || color.rgb.a === 1 ? color.hex : `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
              id: Math.random()
            });
            setModalVisible(false);
          }} />
              </Modal>}
          </div>}

        {!hideErrors && <div className="selectErrorContainer">
            <ErrorMessage name={name} component={FormErrorMessage} />
          </div>}
      </div>
    </CustomSelectStyles>;
};
export default CustomColorsSelect;