import styled from 'styled-components';

export const ModalStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;

  .modalBody {
    background-color: aqua;
    background: #fff;
    display: flex;
    flex-direction: column;
    max-height: 85%;
    position: relative;

    /* @media screen and (max-width: 768px) {
      gap: 15px;
    } */

    .modalContent {
      padding: 10px 30px;
      margin-bottom: 20px;
      position: relative;
      overflow: auto;
      box-sizing: border-box;

      @media screen and (max-width: 768px) {
        /* padding: 0 15px 10px; */
      }

      &.noTitle {
        padding: 20px 30px;

        @media screen and (max-width: 768px) {
          padding: 10px 15px;
        }
      }
    }

    .modalTitle {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      padding: 30px;

      @media screen and (max-width: 768px) {
        padding: 15px 0px 15px 30px;
      }

      span {
        font-size: 16px;
        font-weight: 700;

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      button {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);
      }

      svg {
      }
    }
  }
`;
