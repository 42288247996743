import styled from 'styled-components';

export const ClientButtonStyles = styled.div`
   padding: 12px 16px;
   max-width: 600px;
   flex: 1;
   box-sizing: border-box;
   position: relative;
   overflow: auto;

   form {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 25px;
   }

   .button {
      width: 100%;

      &-wrapper {
         display: flex;
         flex-direction: column;
         gap: 20px;
      }

      &-item {
         width: 240px;
         height: 40px;
         border-radius: 4px;
         box-sizing: border-box;
         font-weight: 500;
         font-size: 14px;
      }
   }
`;
